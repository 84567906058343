<template lang="pug">
    #modal_event.modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 Change Monthly Payment
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            .col-lg-12.col-md-12
                                .row
                                    .col-lg-6.col-md-6
                                        .form-group(style="background: #f1f1f1;border-radius: 5px;padding: 2px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label(style="margin-top: 2px;") Fee :
                                                .col-md-6(style="border-left: 2px solid #cccccc;display:inherit") $
                                                    input.input-card.input-card-border(type="text" v-model="fee" id="fee")
                                        .form-group(style="background: #f1f1f1;border-radius: 5px;padding: 4px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label(style="margin-top: 2px;") Balance :
                                                .col-md-6(style="border-left: 2px solid #cccccc;display: inherit;") $                                                    
                                                    input.input-card.input-show( :id="'campo'+1" type="text" v-model="balance" step="any" style="background: #f1f1f1 !important;")
                                        .form-group
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Monthly Payment :
                                                .col-md-6(style="display: inherit;") $
                                                    input.input-card( style="border-radius: 5px;" type="text" v-model="monthly_amount" :id="'campo'+2" v-on:blur="validMounthly(2)" @keypress="justNumbers")
                                    .col-lg-6.col-md-6
                                        .form-group(style="background: #f1f1f1;padding: 6px;border-radius: 5px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Method of Payment :                                                    
                                                .col-md-6
                                                    .row
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="method" id="card" v-model="method_payment" value="0")
                                                            label(for="card" style="margin-left: 15px;") Credit Card
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="method" id="cheque" v-model="method_payment" value="1" @click="otherclick")
                                                            label(for="cheque" style="margin-left: 15px;") Others                            
                                        .form-group( v-if="method_payment == 0" style="background: #f1f1f1;padding: 6px;border-radius: 5px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Type : #[span(v-if="errorType" style="color:red") Required]
                                                    .row
                                                        .col-md-6
                                                .col-md-6
                                                    .row
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="type" id="automatic" v-model="type_payment" value="0" )
                                                            label(for="automatic" style="margin-left: 15px;") Automatic
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="type" id="manul" v-model="type_payment" value="1" @click="manualclick")
                                                            label(for="manul" style="margin-left: 15px;") Manual
                                        .form-group(v-if="method_payment == 0 && type_payment == 0")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Day of Payment : #[span(v-if="errorDay" style="color:red") Required]
                                                    .row
                                                        .col-md-6
                                                .col-md-6
                                                    .row
                                                        select.input-card(name="days" id="days5" v-model="day_payment")
                                                            option(:value="d.day" v-for="d in payment_days" :key="d.id") {{ d.day }}
                            br
                            .col-lg-12.col-md-12(v-if="method_payment == 0 && type_payment == 0")
                                .form-group.row
                                    .col-md-6.col-lg-6
                                        .row
                                            .col-md-4
                                                label merchant
                                            .col-md-8(style="background: #f1f1f1;padding: 5px;margin-bottom: 0;border-radius: 5px")
                                                .row
                                                    .col-md-6
                                                        input(type="radio" value="2" name="merchant" v-model="merchant" id="wwce")
                                                        label(for="wwce" style="margin-bottom:0") WWCE                                            
                                                    .col-md-6
                                                        input(type="radio" value="1" name="merchant" v-model="merchant" id="amg")
                                                        label(for="amg" style="margin-bottom:0") AMG
                                    .col-md-6.col-lg-6
                                        .row
                                            .col-md-4
                                                label Transaction ID
                                            .col-md-8(style="display:inherit")
                                                input.input-card.input-form(type="text" v-model="transaction" step="any")
                            br
                            .col-lg-12.col-md-12(v-if="method_payment == 0 && type_payment == 0") 
                                .col-lg-12.col-md-12(:style=" cards != null ? cards.length > 3 ? 'height: 120px;margin-bottom:15px;margin-top: 15px;overflow-y: auto;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;'")
                                    label #[span(v-if="errorCard" style="color:red") Required]
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th 
                                                th Card Holder Name
                                                th Card Number
                                                th MM
                                                th YY
                                                th CVC
                                            tr(v-for="(card, index) in cards" :key="index" v-show="card.cardnumber != null")
                                                td 
                                                    input(type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id")
                                                td(style="color: #9b9b9b;text-transform: capitalize") {{card.cardholdername}}
                                                td(style="color: #9b9b9b;") {{'XXXX-XXXX-XXXX-'+card.cardnumber}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_month}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_year}}
                                                td(style="color: #9b9b9b;") {{card.cardsecuritycode != null ?  card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3) : ''}}
                                .col-lg-12.col-md-12(style="text-align: right" )
                                    a(@click="createcard" class="btn class-button-green" style="width:80px;color:white") #[img(:src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;")] ADD                                        

            .modal-footer
                button.btn.btn-success.btn-color-green(type="button" @click="saveUpdate" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-save] SAVE
        modal-card-create(v-if="createmodal" :idlead="this.lead_id" @new="addCard" @click="closedModalCar" style="z-index:99 !important")

</template>


<script>
export default {
    props:[ 'lead_id','nameProgram' ,'nameClient','id','session' ],
    data() {
        return {
            program: this.nameProgram,
            client_name:this.nameClient,
            createmodal:false,            
            payment_days:[],
            card_id : '',
            cards : [],
            day_payment : null,
            method_payment : 1,
            monthly_amount : '',
            type_payment : 1,
            fee : '',
            balance : '',
            idAutorize: '',
            errorType:false,
            errorDay:false,
            errorCard:false,
            merchant:'',
            transaction:''
        }
    },
    mounted() {        
    },
    methods: {
        otherclick(){
            this.day_payment = null
            this.card_id = ''
            this.type_payment = ''
        },
        manualclick(){
            this.day_payment = null
            this.card_id = ''            
        },
        validMounthly(id){
            this.monthly_amount = this.globalFunction(id)        
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        closeModal(){
            this.$emit('click',false)
        },
        createcard(){
            this.createmodal = true;
            var x = document.getElementById('modal_event')
            //console.log(x)
            x.classList.add('preloader-new')
        },
        addCard(cards) {
            this.cards = cards;
        },
        closedModalCar(click){
            this.createmodal = false
            var x = document.getElementById('modal_event')
            x.classList.remove('preloader-new')
        },
        search(){
            axios.post('/api/searchpayment',{
                id: this.id
            }).then(response => {
                if(response.status == 200){
                    this.montlydata = response.data[0]
                    //console.log(this.montlydata)
                    this.card_id = this.montlydata.card_id
                    this.cards = JSON.parse(this.montlydata.cards)
                    this.day_payment = this.montlydata.day_payment
                    this.method_payment = this.montlydata.method_payment
                    this.monthly_amount = this.montlydata.monthly_amount
                    this.type_payment = this.montlydata.type_payment
                    this.fee = this.montlydata.fee
                    this.balance = this.montlydata.balance
                    this.idAutorize = this.montlydata.authorizel_id
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2
                    })
                    if(this.balance != null){
                        //console.log(this.balance)
                        var data = formatter.format(this.balance    )
                        this.balance = data.replace('$', "")
                    }
                }
            })
        },
        saveUpdate(){
            if(this.method_payment == 0){
                if(this.type_payment == null || this.type_payment == ''){
                    this.errorType = true
                }else{
                    this.errorType = false
                    if(this.type_payment == 0){
                        if((this.day_payment == null || this.day_payment == '') && (this.card_id == null || this.card_id == '')){
                            this.errorDay = true
                            this.errorCard = true
                        }else if((this.day_payment == null || this.day_payment == '') && (this.card_id != null || this.card_id != '')){
                            this.errorDay = true
                            this.errorCard = false
                        }else if((this.day_payment != null || this.day_payment != '') && (this.card_id == null || this.card_id == '')){
                            this.errorDay = false
                            this.errorCard = true
                        }else if((this.day_payment != null || this.day_payment != '') && (this.card_id != null || this.card_id != '')){
                            this.errorDay = false
                            this.errorCard = false
                            this.axiospost()
                        }
                    }else if(this.type_payment == 1){
                        this.axiospost()
                    }
                }
            }else if(this.method_payment == 1){
                this.axiospost()
            }
        },
        axiospost(){
            var x = document.getElementById('modal_event')
            x.classList.add('preloader-new')
            swal.fire({
                title: 'Change Monthly Payment ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/updatedautorize',{
                            id: this.id,
                            card_id: this.card_id,
                            day_payment: this.day_payment,
                            method_payment: this.method_payment,                
                            type_payment: this.type_payment,
                            monthly_amount: this.monthly_amount.replace(',', ""),
                            transcationdid: this.idAutorize,
                            user_id: this.session
                        })
                        .then(response => {
                            if(response.status == 200){
                                x.classList.remove('preloader')
                                this.search()                    
                                swal.fire({
                                    type: 'success',
                                    title: 'Monthly Payment Updated'
                                }).then( (res) => {
                                    if(res){
                                        this.$emit('actuals' , true)
                                        this.$emit('click',false)
                                        }
                                    });
                            }
                        })
                        .catch(err => {
                            
                        })
                    }else{
                        var x = document.getElementById('modal_event')
                        x.classList.remove('preloader-new')
                    }
                })
        }
    },
    created() {
        axios.get('/api/paymentdays')
        .then(response => {
            this.payment_days =  response.data 
        });
        this.search()
    },
}
</script>