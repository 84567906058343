<template>
<div>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Lists</span>
                            </div>
                            <div class="col-lg-6">
                                <button v-if="add" class="btn class-button-green" @click="addlist"> <img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 15%;margin-top: -3px;"> CREATE LIST</button>
                                <button v-if="cancelList" class="btn class-button-red" style="float: right" @click="closelist"> <i class="far fa-times-circle"></i> CANCEL</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" v-if="newList">
                        <div id="formcreate" class="form-create open-form" >
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="container">
                                        <h3 style="color:#dbca00;display: inline-block">Create List</h3> <span style="display: inline-block;margin-left: 10px;color: black;">Total Leads Pending : {{pending}}</span> 
                                        <div class="row group-leads-selec fond-white">                                
                                            <div class="col-lg-8">
                                                <div class="form-group">
                                                    <label>Select users</label> 
                                                    <multiselect class="input-filtro-calendar input-form" v-model="value" return="id" tag-placeholder="Add this as new tag" placeholder="Search user" :custom-label="customLabel" label="user_name" track-by="user_name" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label>Number of leads by user</label>
                                                    <input class="input-form" type="number" v-model="number" style="width: 100%;height: 41px;">
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <button type="submit" @click="savegroup" class="btn btn-success btn-color-green create"> <i class="far fa-save"></i> Save</button>
                                                </div>
                                            </div>                                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totallists : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totallists }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="listsgroups()"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="display: inline-flex;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">                                        
                                        <kendo-datepicker
                                            placeholder="From"
                                            v-model="from"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">                                        
                                        <kendo-datepicker
                                            placeholder="To"
                                            v-model="to"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>                                    
                                    <div class="form-group" style="margin-top: 0px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search()" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:0px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user" v-if="global.layout.role_id == 1 || global.layout.role_id == 2" style="position: relative">
                                    <tr class="th-bg">
                                        <th>Date Create</th>
                                        <th>Create By</th>                                        
                                        <th>Number of Leads by user</th>
                                        <th>Users</th>
                                        <th>Done</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr v-for="list in lists" :key="list.id">                                        
                                        <td :id="'tdpadding'+list.id" class="gris-color">{{list.created_at | myDateGlobal}}</td>
                                        <td style="color:#dbca00">{{ list.create_name }}</td>
                                        <td style="color:#dbca00">
                                            <a style="cursor: pointer">{{list.cant}}</a>
                                        </td>
                                        <td>
                                            <ul style="text-align: left;list-style: none;padding:0">
                                                <li style="margin-right: 5px;margin-bottom: 5px" v-for="groupuser in JSON.parse(list.users)" :key="groupuser.user_id">
                                                    <a style="padding: 2px 0px;color: blue;cursor: pointer" @click="modalopen(groupuser.id , list.id)" >{{groupuser.user_name}}</a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul style="text-align: left;list-style: none;padding:0">
                                                <li style="margin-right: 5px;margin-bottom: 5px;color:#dbca00" v-for="groupuser in JSON.parse(list.users)" :key="groupuser.user_id">
                                                    <span>{{groupuser.done}}</span>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <button @click="deleteuser(list.id)" href="#" title="Delete"><img :src="'/images/delete.png'"></button>
                                        </td>                                        
                                    </tr>
                                </tbody>
                                <tbody  id="busq-user"  v-else style="position: relative">
                                    <tr class="th-bg">
                                        <th>Date Create</th>
                                        <th>Create By</th>                                        
                                        <th>Number of Leads by user</th>                                        
                                        <th>Done</th>
                                        <th>Actions</th>
                                    </tr>
                                    <tr v-for="list in lists" :key="list.id">
                                        <td class="gris-color">{{list.created_at | myDateGlobal}}</td>
                                        <td style="color:#dbca00">{{list.create_name }}</td>
                                        <td style="color:#dbca00">
                                            <a>{{list.cant}}</a>
                                        </td>
                                        <td style="color:#dbca00">
                                            {{list.done}}
                                        </td>
                                        <td>
                                            <button @click="modalopen(global.layout.id , list.id)" href="#" title="Delete"><i class="fas fa-eye"></i></button>                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totallists : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totallists }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
    <div v-if="open" class="box-login modal-list-user " style="padding-top:0">
        <div class="row title-modal-edit" style="margin: 0px;    border-radius: 10px;">
            <div class="col-lg-12">
                <h3 >{{ resultado[0].user_name }}</h3>                            
                <i class="fas fa-times icon-close-target"  @click="modalclose"></i>
            </div>
        </div>
        <div class="container">
            <div class="row">                    
                <div class="col-lg-6" style="display: inline-flex">                    
                    <div class="form-group" style="width:100%">
                        <label for="" style="    margin-right: 10px;text-transform: uppercase;">Filter by:</label>
                        <select name="" id="" v-model="valueselect" @change="filterList(valueselect)" class="input-form" style="width:60%">
                            <option value="0">All</option>
                            <option value="1">Missing</option>
                            <option value="2">Done</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group class-inline row">
                                <div class="class-campo-icon add-class-campo-icon col-md-5" style="    background: #BAA345;">
                                    <span>Missing</span>
                                </div>
                                <div class="class-campo-text col-md-5" style="color: #BAA345">
                                    <span name="start" id="date"> {{resultado[0].quantity_pending}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group class-inline row">
                                <div class="class-campo-icon add-class-campo-icon col-md-5">
                                    <span>Done</span>
                                </div>
                                <div class="class-campo-text col-md-5">
                                    <span name="start" id="date"> {{resultado[0].quantity_done}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row borde-table-user" v-if="resultado && resultado.length >= 0">                        
                <table class="table table-hover">
                    <tbody id="busq-user">
                        <tr class="th-user">
                            <th>Names</th>                                
                            <th>Status</th>
                            <th>CR</th>
                            <th>Mobile</th>
                            <th>Done</th>
                        </tr>
                        <tr v-for="leads in resultado" :key="leads.id">
                            <td class="blue-color"><a target="_blank" class="lead-style" :href="'/crm/leads/edit/'+ leads.lead_id">{{ leads.lead_name }}</a></td>                                
                            <td style="color:#666666">{{leads.status_lead}}</td>
                            <td>
                                <span v-if="leads.credit_report == '1'" class="text-yes">NO</span>                                            
                                <span v-else class="text-no">YES</span>
                            </td>
                            <td style="color:#666666">{{leads.mobile}}</td>
                            <td>
                                <input :disabled="global.layout.role_id == 1 || global.layout.role_id == 2" v-model="leads.done" type="checkbox" :id="'leadid'+leads.lead_id" @click="callead(leads.lead_id,leads.id_list,leads.user_id)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="content-close-btn" style="float:right">                
        </div>
    </div>
</div>    
</template>


<script>
import Push from 'push.js';

import Multiselect from 'vue-multiselect'
export default {
    props:['global'],
    components: { Multiselect },
    data() {
        return {
            add: this.global.layout.role_id == 2 ? true : false,
            cancelList: false,
            open: false,
            checkleads: [],
            number: '',
            lists: [],
            options: [],
            value: [],
            resultado: {},
            id: '',
            listleads: [],
            listad: [],
            users: [],
            newList: false,
            idlist:'',
            listmodal:'',
            from:'',
            to:'',
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totallists:'',
            perpage:'',
            idsession:'',
            pending: this.global.list,
            ocultbotton: false,
            valueselect: 0
        }
    },
    mounted() {
        /*setTimeout(function(){
            Push.create("Hello world!", {
                body: "How's it hangin'?",
                icon: '/icon.png',
                timeout: 4000,
                onClick: function () {
                    window.focus();
                    this.close();
                }
            });
        },5000)*/
        var boton = document.getElementById('app');
        boton.classList.add('preloader')
    },
    methods: {
        filterList(value){
            console.log(value)
            this.modalopen(this.idlist , this.listmodal , value)
        },
        resertSearch(){
            this.from = ''
            this.to = ''
            this.listsgroups()
            this.ocultbotton = false
        },
        customLabel ({ user_name }) {
            return `${user_name}`
        },
        callead(idlead , idlist, iduser){
            var checkBox = document.getElementById("leadid"+idlead);
            if (checkBox.checked == true){
                axios.post('/api/callead',{ 
                    idlead : idlead,
                    idlist : idlist,
                    iduser : iduser,
                    status : 1
                }).then(response => {
                    this.open = true;
                    this.listsgroups(1)
                    this.modalopen(this.idlist , this.listmodal , 0)
                })
            } else {
                axios.post('/api/callead',{ 
                    idlead : idlead,
                    idlist : idlist,
                    iduser : iduser,
                    status : 0
                }).then(response => {
                    this.open = true;
                    this.listsgroups(1)
                    this.modalopen(this.idlist , this.listmodal ,0)
                })
            }
        },
        addlist(){
            this.newList = true
            this.add = false
            this.cancelList = true
        },
        closelist(){
            this.newList = false
            this.add = true
            this.cancelList = false
        },
        modalclose(){
            this.open = false;
            var x = document.getElementById('loading')
            x.classList.remove('preloader')
        },
        modalopen(id , list , value){            
            this.idlist = id
            this.listmodal = list
            var x = document.getElementById('loading')
            x.classList.add('preloader')
            axios.post('/api/showlist',{ 
            id : id,
            listid : list,
            filter: value
            }).then(response => {
                this.resultado = response.data
                this.open = true;                
            })
        },
        vueusers(id){
            var x = document.getElementById('div'+id);
            var y = document.getElementById('tdpadding'+id);
            x.classList.toggle('table-users-list');
            y.classList.toggle('td-padding-users');
        },
        groupusers(){
                axios.post('/api/sellerall/2',{ 
                    roles : '[]',
                    type : '1'
                }).then(response => {
                    this.options = response.data;
                })
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        listsgroups(valor){            
            axios.post('/api/listusers',{
                id: this.global.layout.role_id == 1 || this.global.layout.role_id == 2 ? null : this.global.layout.id,
                from : this.from,
                to: this.to
            })
            .then(response => {
                if(response.status == 200){
                    this.lists = response.data.data;
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    this.next_page = this.start_page+1;
                    this.ultima_pagina = response.data.last_page;
                    this.totallists = response.data.total;
                    if(valor != 1){
                        var boton = document.getElementById('app');
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                }
            })
        },
        search(){
            this.ocultbotton = true
            this.listsgroups()
        },
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/listusers?page='+newval, {
                from: this.from,
                to: this.to,
            }).then(response => {
                if(response.status == 200){
                    this.lists = response.data.data                
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totallists = response.data.total;                    
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/listusers?page='+next, {                
                from: this.from,
                to: this.to,
            }).then(response => {
                if(response.status == 200){
                    this.lists = response.data.data                                     
                    this.start_page =  response.data.current_page;                    
                    this.ultima_pagina = response.data.last_page;
                    this.totallists = response.data.total;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },
        savegroup(){
            const params = {
                users: this.value.map((user)=>{return user.id}),
                number: this.number,
                create_id: this.global.layout.id
            };
            axios.post('/api/savegroup' , params)
            .then(response => {
                Fire.$emit('AfterCreate');
                swal.fire({
                    type: 'success',
                    title: 'List Created in successfully'
                    }).then( (res) => {
                        if(res){
                            this.listsgroups();
                            this.value = [],
                            this.number = ''
                            this.cancelList = false,
                            this.add = true
                            this.newList = false
                            }
                        });
                
            })
        },
        deleteuser(id){
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                        if (result.value) {
                            axios.post('/api/deletelist',{ 
                                id : id
                            }).then((response)=>{
                                    swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success',
                                    ).then( (res) => {
                                        if(res){
                                                this.listsgroups();
                                            }
                                        });
                                Fire.$emit('AfterCreate');
                            }).catch(()=> {
                                swal("Failed!", "There was something wronge.", "warning");
                            });
                        }
                });
        }
    },
    created() {        
        this.groupusers();
        this.listsgroups();
        //this.listadviser();
        Fire.$on('AfterCreate',() => {
           });
    },
}
</script>