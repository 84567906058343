<template>
    <div>
        <div class="cont-title">
            <div class="row">
                <div class="col-lg-6">
                    <span>Payments</span>
                </div>
                <div class="col-lg-6">
                    <button  @click="processFunction" class="btn class-button-green"> PROCESS <img :src="'/images/icons/ADM-process.ico'" style="margin-left: 5px;width: 10%;margin-top: -3px;"></button>
                </div>
            </div>
        </div>
        <div class="cont-search-paginate">
            <div class="row">
                <div class="col-lg-3">
                    <div style="display: inline-flex">
                        <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalpayments : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalpayments }} </span>
                        <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                            <i class="fas fa-caret-left"></i>
                        </button>
                        <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                            <i class="fas fa-caret-right"></i>
                        </button>
                        <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div style="float:right;display:inline-flex">
                        <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                        <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                        <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                        <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                    </div>
                    <br>
                    <br>
                    <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;    float: right;">
                        <div class="form-group" style="margin: 0px 5px;">
                            <label for="">From</label>
                            <kendo-datepicker
                                v-model="from"
                                :min="minDate"
                                :max="maxDate"
                                :format="'MM/dd/yyyy'"
                                class="input-form"
                                name="date"
                                id="date"
                                style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                            </kendo-datepicker>
                        </div>
                        <div class="form-group" style="margin: 0px 5px;">
                            <label for="">To</label>
                            <kendo-datepicker
                                v-model="to"
                                :min="minDate"
                                :max="maxDate"
                                :format="'MM/dd/yyyy'"
                                class="input-form"
                                name="date" 
                                id="date"
                                style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                            </kendo-datepicker>
                        </div>
                        <div class="form-group" style="margin: 0px 5px;">
                            <label for="">Type</label>
                            <select v-model="type" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                <option value="0">All</option>
                                <option value="3">Inital Payment</option>
                                <option value="1">Realtor</option>
                                <option value="2">Appointment</option>
                                <option value="4">Others</option>
                            </select>
                        </div>
                        <div class="form-group" style="margin: 0px 5px;">
                            <label for="">Result</label>
                            <select v-model="result" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;" >
                                <option value="0">All</option>
                                <option value="1">Approved</option>
                                <option value="2">Declined</option>
                            </select>
                        </div>
                        <div v-if="this.global.layout.role_id == 1 || this.global.layout.role_id == 2" class="form-group" style="margin: 0px 5px;">
                            <label for="">User</label>
                            <select v-model="user" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                <option value="0">All</option>
                                <option v-for="(item, index) in users" :key="index" :value="item.id">{{ item.user_name }}</option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-top: 25px;">                                        
                            <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                        </div>
                        <div class="form-group" style="margin-top:25px">
                            <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>
        <div>
            <div >
                <table class="table">
                    <tbody id="busq-user">
                        <tr class="th-bg">
                            <th>Name</th>
                            <th>Type</th>
                            <th>Transaction</th>
                            <th>Ammount</th>
                            <th>Result</th>
                            <th>Credit Card</th>
                            <th>Account</th>
                            <th>Program</th>
                            <th>User</th>
                            <th>Creation Date</th>
                        </tr>
                        <tr v-for="(payment , index) in payments" :key="index">
                            <td class="blue-color">
                                <a v-if="payment.type_lead == 1" :href="'/crm/clients/edit/'+payment.lead_id">{{payment.lead_name}}</a>
                                <a v-else :href="'/crm/leads/edit/'+payment.lead_id">{{payment.lead_name}}</a>
                            </td>
                            <td>{{payment.type_transaction}}</td>
                            <td>{{payment.transaction_id}}</td>
                            <td>${{payment.amount}}</td>
                            <td v-if="payment.result == 'Approved'" style="color:#00CC00;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">{{payment.result}}</td>
                            <td v-else style="color:#FF0000;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">{{payment.result}}</td>
                            <td>{{payment.card_number}}</td>
                            <td>{{payment.account}}</td>
                            <td>{{payment.program}}</td>
                            <td>{{payment.user_name}}</td>
                            <td>{{payment.created_at | myGlobalDay}}</td>                                                          
                        </tr>
                        <tr style="background:#f1f1f1">
                            <td colspan="2" style="padding:5px">
                                <div class="cont-search-paginate" style="padding:0px">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div>
                                                <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalpayments : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalpayments }} </span>
                                                <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                                    <i class="fas fa-caret-left"></i>
                                                </button>
                                                <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                                    <i class="fas fa-caret-right"></i>
                                                </button>                                   
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td colspan="2" style="background: #666666;color: #fff;padding:5px">
                                <div class="row" style="margin-top:7px">
                                    <div class="col-lg-6">
                                        <span>Total Ammount</span>
                                    </div>
                                    <div class="col-lg-6" style="padding-right: 40px">
                                        <span style="color: #666666;background: #fff;padding: 5px 10px;border-radius: 5px;">{{ payments[0] != null ? '$ '+payments[0].t_amount : '-'}}</span>
                                    </div>
                                </div>
                            </td>
                            <td colspan="1" style="background: #666666;color: #fff;padding:5px"></td>
                            <td colspan="5"></td>
                        </tr>
                    </tbody>
                </table>                            
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        props:['global'],
        data() {
            return {
                payments: [],
                campo1: '',
                advance : true,
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                searchadvance: true,
                resetadvance: false,
                from: '',
                to: '',
                result:null,
                type:null,
                user:this.global.layout.role_id == 1 || this.global.layout.role_id == 2 ? null : this.global.layout.id,
                users: [],
                iduser: this.global.layout.id,
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totalpayments:'',
                someData:'',
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            processFunction(){
                this.$emit('clickProcess', true);   
            },
            returnList(){
                this.grilla = true
                this.excel = false
            },
            advanceSearch(){
                this.advance = false
                this.searchadvance = false
                this.resetadvance =  true
                this.campo1 = ''
            },
            resertSearch(){
                this.advance = true
                this.searchadvance = true
                this.resetadvance =  false
                this.type = null
                this.result = null
                this.from = ''
                this.to = ''                
                this.search()
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/payment?page='+newval,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        result: this.result,
                        type: this.type,
                        user: this.user
                }).then(response => {
                    if(response.status == 200){
                        this.payments = response.data.data                
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalpayments = response.data.total;                    
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/payment?page='+next,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        result: this.result,
                        type: this.type,
                        user: this.user
                }).then(response => {
                    if(response.status == 200){
                        this.payments = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                  
                        this.ultima_pagina = response.data.last_page;
                        this.totalpayments = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },
            search(){
                console.log(this.user)
                axios.post('/api/payment',{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    result: this.result,
                    type: this.type,
                    user: this.user
                })
                .then(response => {
                    if(response.status == 200){                    
                        this.payments = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalpayments = response.data.total;                        
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
            programAdvisor(){
                axios.post('/api/usermodule/2',{
                    roles : '[1,2,5]',
                    type : '0'
                }).then(response => {
                    this.users = response.data;
                })
            },
        },
        created() {
            this.search();
            this.programAdvisor()
        },
    }
</script>