<template>
<div>
    <div id="loading">
        <v-app style="border: 2px solid #BAA345;margin-top: -4px;">
            <v-layout wrap>
                <div class="container calendar-estilo" style="padding:15px 24px 0px;">
                    <div class="row" style="width: 100%">
                        <div class=" col-lg-5 button-calendar-moun column-estilo">
                            <button @click="$refs.calendar.prev()" v-on:click="changeMonth(-1)" class="btn btn-next-prev" style="float: right">
                                <i class="fas fa-caret-left"></i>
                            </button>
                        </div>
                        <div class="col-lg-2 button-calendar-moun column-estilo" >
                            <div class="" style="text-align:center;font-size: 20px;color:#BAA345; font-family: 'RalewayBold' !important;">
                                <p id="mes" style="display: inline">{{monthact}} </p>
                                <span >{{ yearact }}</span>
                            </div>
                            <div v-if="global.layout.role_id == 1 || global.layout.role_id == 2" class="form-group div-campo-filtro-calendar" style="text-align: center">
                                <label>Filter by User</label>
                                <button type="button" class="btn-open-filtro" v-on:click="openfiltro"><i id="icon-giro" class="fas fa-angle-up"></i></button>
                                <select class="input-filtro-calendar close-filtro" v-model="host" id="filtro-task" @change="changeMonth(0)">
                                    <option :value="null" disabled selected="selected">Select User</option>
                                    <option v-bind:value="0">All</option>
                                    <option v-for="value in users" v-bind:value="value.id" :key="value.id"> {{ value.user_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class=" col-lg-5 button-calendar-moun column-estilo" >
                            <button @click="$refs.calendar.next()" v-on:click="changeMonth(1)" class="btn btn-next-prev" style="float:left"> 
                                <i class="fas fa-caret-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <v-flex xs12 class="mb-5" >
                    <v-sheet>
                        <v-calendar ref="calendar" v-model="start" color="primary">
                            <template v-slot:day="{ date }">
                                    <div :id="'scrolltop'+date" >
                                        <h3 id="retroceder"  @click="topEvent(date)"><i class="fas fa-sort-up"></i></h3>
                                    </div>
                                    <div :id="'scrollboton'+date">
                                        <h3 id="avamzar"  @click="botonEvent(date)"><i class="fas fa-sort-down" style="position: absolute;top: -4px;"></i></h3>
                                    </div> 
                                <div class="cont-events" :id="date" style="overflow-y: inherit;z-index: 2;height: 240px;">
                                    <input type="hidden" :value="date">
                                    <template v-for="event in eventsMap[date]">
                                        <v-menu v-if="event.type == 'task'" :key="event.id" v-model="event.open" full-width offset-x >
                                            <template v-slot:activator="{ on }">
                                                <div v-if="!event.time" v-ripple class="my-event row" v-on="on" @click="modal(event.id)" >
                                                    <div class="col-lg-3" style="padding-left: 0;">
                                                        <img class="int-event-img" v-bind:src="'/'+event.seller_image" alt="" width="30px" style="border-radius: 50%" />
                                                    </div>                                               
                                                    <div class="col-lg-9" style="padding-left: 0;color:black">
                                                        <span class="">{{ event.title}}</span>
                                                        <div class="row">
                                                            <div class="col-lg-3">
                                                                <img class="" :src="'/images/icons/phone.png'" width="12px"> 
                                                            </div>
                                                            <div class="col-lg-9" style="padding-left: 0;" >
                                                                <span class="">{{ event.lead_mobile }}</span>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <img class="" :src="'/images/icons/user.png'" width="12px">
                                                            </div>
                                                            <div class="col-lg-9" style="padding-left: 0;">
                                                                <span class="" >{{ event.lead_name }}</span>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <img class="" :src="'/images/icons/crono.png'" width="12px"> 
                                                            </div>
                                                            <div class="col-lg-9" style="padding-left: 0;">
                                                                <span class="">{{ event.from | myTime }} - {{ event.to | myTime }}</span>
                                                            </div>
                                                        </div>                                                    
                                                    </div> 
                                                </div>
                                            </template>
                                        </v-menu>
                                    </template>
                                </div>
                            </template>
                        </v-calendar>
                    </v-sheet>
                </v-flex>
            </v-layout>
        </v-app>
    </div>
    <modal-event-edit v-if="open" v-bind:campos="this.campos" @update="updateEvent" @click="close" @delete="deleteEvent"></modal-event-edit>
</div>
</template>

<script>
import moment from 'moment'
  export default {
    props:['global'],
        data(){
            return{       
            campos: {},
            open: false,
            start: this.global.date,
            eventos: [],
            users : [],
            yearact: moment().format('YYYY'),
            monthact: moment().format('MMM'),
            count_click: 0,
            host:0
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
        },
        computed: {
        // convert the list of events into a map of lists keyed by date
            eventsMap () {
                const map = {}
                //this.eventos.forEach(e => (map[e.date] = map[e.date] || []).push(e))
                this.eventos.forEach(function(e){
                    (map[e.date] = map[e.date] || []).push(e)
                    map[e.date].sort(function(a, b){
                        if(a.from < b.from) return -1;
                        if(a.from > b.from) return 1;
                        return 0;
                    })
                })
                return map
            }
        },
        methods: {
            functionGlobal(){
                setTimeout(function(){
                    var dates = document.getElementsByClassName('v-calendar-weekly__day');
                    for( var x = 0 ; x < dates.length ; x++){
                        if(dates[x].getElementsByClassName('cont-events')[0].getElementsByClassName('my-event').length < 4){
                            var dateCa = dates[x].getElementsByClassName('cont-events')[0].getElementsByTagName('input')[0].value
                            var otherScrTo = document.getElementById('scrolltop'+dateCa);
                            var otherScrBo = document.getElementById('scrollboton'+dateCa);
                            otherScrTo.style.display = 'none';
                            otherScrBo.style.display = 'none';
                        }
                    }
                },0)
                setTimeout(function(){
                    var semanas = document.getElementsByClassName('v-calendar-weekly__week');                
                    for(var z = 0 ; z < semanas.length ; z++){
                        for(var i = 0; i < semanas[z].childNodes.length; i++){
                            if(semanas[z].childNodes[i]){
                                semanas[z].childNodes[i].classList.remove("dia"+[i]);
                                semanas[z].childNodes[i].classList.add("dia"+[i]);
                            }
                        }
                    }
                }, 0);
            },
            topEvent(dt){
                var x = document.getElementById(dt)
                var y = x.getElementsByClassName('my-event')
                this.count_click -= 1;
                if(this.count_click <= 0){
                    this.count_click = 0
                }
                x.scrollTop = 80*this.count_click
            },
            botonEvent(dt){
                var x = document.getElementById(dt)
                var y = x.getElementsByClassName('my-event')
                if(this.count_click < (y.length - 3)){
                    this.count_click += 1;
                }              
                x.scrollTop = 80*this.count_click;                
            },
            openfiltro(){
                var slet = document.getElementById('filtro-task');
                var icon = document.getElementById('icon-giro');
                icon.classList.toggle("icon-giro-css");
                slet.classList.toggle("close-filtro");
            },
            changeMonth(m){   
                if(m!=0) {          
                    var boton = document.getElementById('app');
                    boton.classList.add('preloader')
                }
                var valuemes = parseInt(moment(this.monthact,'MMM').format('MM'))+m;
                if(valuemes>12){
                    valuemes = 1;
                    this.yearact = this.yearact + 1;
                }
                if(valuemes<1){
                    valuemes = 12;
                    this.yearact = this.yearact - 1;
                }
                axios.post('/api/filtermonthnexttaskall',{
                    year: this.yearact,
                    month: valuemes,
                    host:this.host,
                }).then(response => {
                    if(response.status == 200){
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        this.eventos = JSON.parse(response.data[0].events_month); 
                        this.yearact =  response.data[0].current_year;
                        this.monthact = moment(response.data[0].current_month,'MM').format('MMM');
                        this.functionGlobal();
                    }
                })
            },
            editEvent(eventos) {
                this.eventos.push(eventos);
            },
            close(click){
                setTimeout(() => {
                        var boton = document.getElementById('loading');
                        boton.classList.remove('preloader')
                    }, 50); 
                this.open = click;
            },
            modal(id){
                var boton = document.getElementById('loading');
                boton.classList.add('preloader')
                this.functionGlobal();                           
                axios.post('/api/eventcalendar',{ 
                    id : id
                }).then(response => {
                    if(response.status == 200){
                        this.campos = {"valor": response.data[0] , "idlayout" :this.global.layout.id ,"calendar" : "1" };
                        this.open = true;
                        this.functionGlobal();
                    }
                });
            },
            updateEvent(eventos) {
                this.changeMonth(0);
            },
            deleteEvent(eventos) {
                this.changeMonth(0);
            },
            userCreator(){
                axios.post('/api/sellerall/2',{ 
                    roles : '[]',
                    type : '0'
                }).then(response => {
                    this.users = response.data;
                })
            },
        },
        created(){            
            this.changeMonth(0);
            this.userCreator();
            Fire.$on('AfterCreate',() => {
                this.changeMonth(0);
           });
        },
  }
</script>
