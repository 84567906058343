<template lang="pug">
    .modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 NOTES
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div.campos-detail-modal
                        div.row
                            .col-lg-12.col-md-12(style="text-align: center;")
                                .row
                                    div.col-lg-12
                                        a#nohoverlink(:href="'/dowload?id='+this.notas[0].sale_id+'&program='+this.nameProgram+'&client='+this.nameClient+'&pack='+this.pack+'&radio='+this.sourcesname", title="title").btn.class-button-green EXPORT TO WORD #[img(:src="'/images/icons/CRM-exportword.ico'")]
                                    div(:class="notas[0].program_id == 1 ? 'col-md-3' : 'col-md-4'")
                                        table.table
                                            tbody(style="border: 1px solid #ccc;")
                                                tr(style="background: #999999;color: #ffffff;")
                                                    td(style="text-align: left;padding-left:20px") Program
                                                tr
                                                    td(style="text-align: left;padding-left:20px")
                                                        span {{program}}
                                    div(:class="notas[0].program_id == 1 ? 'col-md-3' : 'col-md-4'")
                                        table.table
                                            tbody(style="border: 1px solid #ccc;")
                                                tr(style="background: #999999;color: #ffffff;")
                                                    td(style="text-align: left;padding-left:20px") Client
                                                tr
                                                    td(style="text-align: left;padding-left:20px")
                                                        span {{client_name}}                                         
                                    div(v-if="notas[0].program_id == 1" :class="notas[0].program_id == 1 ? 'col-md-3' : ''")
                                        table.table
                                            tbody(style="border: 1px solid #ccc;")
                                                tr(style="background: #999999;color: #ffffff;")
                                                    td(style="text-align: left;padding-left:20px") Kind of Pack
                                                tr
                                                    td(style="text-align: left;padding-left:20px")
                                                        span {{pack}}
                                    div(:class="notas[0].program_id == 1 ? 'col-md-3' : 'col-md-4'")
                                        table.table
                                            tbody(style="border: 1px solid #ccc;")
                                                tr(style="background: #999999;color: #ffffff;")
                                                    td(style="text-align: left;padding-left:20px") Radio
                                                tr
                                                    td(style="text-align: left;padding-left:20px")
                                                        span {{sourcesname}}
                            .col-lg-12.col-md-12(style="height: 360px;overflow-y: auto;")
                                .row
                                    div(v-for="(nota , index) in notas" :key="index" :class="'col-md-'+nota.column" )
                                        div.form-group(v-if="findfather(nota.father)")
                                            label(style="") {{nota.question}}
                                            input.input-form(v-if="nota.type == 2" type='text' :name="'answer'+nota.question_id" v-model="nota.answer" :disabled="valorEdit" :id="'required'+nota.question_id" @keyup="deleteRequired(nota)")
                                            .row( :id="'required'+nota.question_id" :style="nota.question_id == 1 || nota.question_id == 27 || nota.question_id == 19 || nota.question_id == 11 || nota.question_id == 36 || nota.question_id == 38 || nota.question_id == 39? 'background: rgb(241, 241, 241);padding: 6px;border-radius: 5px;margin: 0;text-align: center;' : 'margin: 0'")
                                                .div(v-for="(option , index) in JSON.parse(nota.options)" :key="index"  v-if="nota.type == 1" style="margin-left: 15px;display:inherit" )
                                                    input( style="margin-top: 4px;margin-right: 4px" type='radio' :name="'answer'+nota.question_id" v-model="nota.answer" :value="option" :id="option" v-on:change="deleteRequired(nota)" :disabled="valorEdit")
                                                    label(:for="option" ) {{option}}
                                                textarea.textarea-style( style="background: #f1f1f1 !important" v-if="nota.type == 0" :name="'answer'+nota.question_id" v-model="nota.answer" :disabled="valorEdit" :id="'required'+nota.question_id" @keyup="deleteRequired(nota)")                                            
            .modal-footer
                button.btn.btn-success.btn-color-green(type="button" @click="saveNotas" v-if="valorEdit != true" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SAVE                 

</template>

<script>
    export default {
        props:['notesprogram','nameProgram','nameClient','type','editmodal','statusSale','sourcesname','pack'],
        data() {
            return {
                program: this.nameProgram,
                client_name:this.nameClient,
                notas: this.notesprogram,
                valorEdit : false,                            
            }
        },
        mounted() {
            if(this.type == 1 || this.editmodal == false || this.statusSale == 2 || this.statusSale == 4){
                this.valorEdit = true
            }else{
                this.valorEdit = false
            }
            //console.log(this.notesprogram)
        },
        methods: {
            functionprubea(id){
                if(id == 1){
                    return 'col-lg-4'
                }else if(id == 2){
                    return 'col-lg-8'
                }else if(id == 3){
                    return 'col-lg-6'
                }else if(id == 4 || id == 5){
                    return 'col-lg-3'
                }else{
                    return 'col-lg-12'
                }                                
            },
            closeModal(){
                this.$emit('click',false)
            },
            deleteRequired(nota){
                if(nota.answer != null || nota.answer != null ){
                    var not = document.getElementById('required'+nota.question_id)
                    not.classList.remove('required-text')
                }
            },
            findfather(id){
                if (id != null  ) {
                    var father = this.notas.find(function(element){return element.question_id == id;});
                    return father.answer == 'YES';
                }else{
                    return true;
                }
            },
            saveNotas(){
                var notes = []
                var boton = document.getElementById('app');
                var fr = true;
                var nr = 0;
                boton.classList.add('preloader')
                boton.classList.add('modal-preloader')
                for( var x = 0 ; x < this.notas.length ; x++){
                    if(!this.findfather(this.notas[x].father)){
                        fr = false;
                        nr++;
                        this.notas[x].answer = null;
                    }else{
                        fr = true;
                    }

                    if (fr == true && (this.notas[x].answer == null || this.notas[x].answer == '')){
                        var not = document.getElementById('required'+this.notas[x].question_id)
                        not.classList.add('required-text')
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        boton.classList.remove('modal-preloader')
                    }
                }                
                this.notas.map(index => {
                    if(index.answer != null && index.answer != ''){
                        notes.push(index)                        
                    }                            
                })
                if(this.notas.length == notes.length+nr){
                    axios.post('/api/insertSaleNotes',{
                        notes : notes,
                        sale_id: this.notas[0].sale_id
                    }).then(response => {
                        if(response.status == 200){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                            boton.classList.remove('modal-preloader')
                            swal.fire({
                                type: 'success',
                                title: 'Notes Successfull'
                            }).then( (res) => {
                                if(res){
                                    this.$emit('click',false)
                                    }
                                });
                        }
                    })
                }
            },
        },
        created() {
            
        },
    }
</script>

<style lang="stylus">
    .textarea-style
        border: 1px solid #ccc;
        width: 100%;
        height: 80px;
        border-radius: 15px;
        padding: 5px;
    .required-text
        border: 1px solid red !important
        margin: 0

    #nohoverlink:hover
        color #fff
    #nohoverlink
        float: left;
        margin-bottom: 8px;
        width: 11rem;
</style>