export default class Gate{

    constructor(user){
        this.user = user;
    }


   
    isAdmin(){
        //console.log(this.user);
        //return this.user;
    }



}