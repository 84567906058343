<template>
    <div class="row ">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Payment Report Detail</span>
                    </div>
                    <div class="col-lg-6">
                        <button @click="returnList" class="btn class-button-red" style="margin-right: 5px;text-transform:uppercase"><i class="fas fa-caret-left" style="margin-right:5px"></i>return</button>                            
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="cont-user-info">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="border-campo-user">                                
                                <div class="row" style="padding: 20px">
                                    <div class="col-lg-4">
                                        <div style="color:#B59200">
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>YEAR/MONTH</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.year}}</span> <span>|</span>
                                                    <span v-if="content.month == 1">January</span>
                                                    <span v-if="content.month == 2">Febrary</span>
                                                    <span v-if="content.month == 3">March</span>
                                                    <span v-if="content.month == 4">April</span>
                                                    <span v-if="content.month == 5">May</span>
                                                    <span v-if="content.month == 6">June</span>
                                                    <span v-if="content.month == 7">July</span>
                                                    <span v-if="content.month == 8">August</span>
                                                    <span v-if="content.month == 9">September</span>
                                                    <span v-if="content.month == 10">November</span>
                                                    <span v-if="content.month == 11">October</span>
                                                    <span v-if="content.month == 12">December</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>PROGRAM</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span name="description" id="description">{{ details[0] != null ? details[0].program_name : '-'}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>ADVISOR</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span >{{ details[0] != null ? details[0].advisor_name : '-'}}</span>                                                           
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                    <div class="col-lg-4">
                                        <div style="color:#B59200">
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>PERCENT</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.percent}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>ACCOUNTS</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.accounta}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>PAYMENTS</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.payment}}</span>
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                    <div class="col-lg-4">
                                        <div style="color:#B59200">
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>AUTOMATIC</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.automatic}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>MANUAL</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.manual}}</span>
                                                </div>
                                            </div>
                                            <div class="form-group class-inline row">
                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                    <span>OTHERS</span>
                                                </div>
                                                <div class="class-campo-text col-md-8">
                                                    <span>{{content.other}}</span>
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <div style="display: inline-flex">
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totaldetails : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totaldetails }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>
                            <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="search()"><i class="fas fa-sync-alt"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div style="float:right">
                            <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                            <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                            <button class="btn class-btn-next-prev class-color-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                        <button class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                        </div>
                        <br>
                        <br>
                        <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Type of Payment</label>
                                <select v-model="type" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                    <option value="0">All</option>
                                    <option value="1">Automatic</option>
                                    <option value="2">Manual</option>
                                    <option value="3">Others</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Payment</label>
                                <select v-model="payment" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                    <option value="0">All</option>
                                    <option value="1">YES</option>
                                    <option value="2">NO</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin-top: 25px;">                                        
                                <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search()" > <img :src="'/images/icon-search.png'" ></button>                                        
                            </div>
                            <div class="form-group" style="margin-top:25px">
                                <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <table class="table">
                        <tbody id="busq-user" style="position: relative">
                            <tr class="th-bg">
                                <th>Account</th>
                                <th>Name</th>                                        
                                <th>Type of Payment</th>                                
                                <th>Ammount</th>
                                <th>Payment</th>
                                <th>Date</th>
                            </tr>
                            <tr v-for="(item, index) in details" :key="index">
                                <td>{{item.account}}</td>
                                <td>{{item.client_name}}</td>
                                <td>{{item.type_payment}}</td>                                
                                <td>{{item.amount ? '$ '+item.amount : '' }}</td>
                                <td><span :style="item.ispayment == 1 ? 'color:#00CC00' : 'color:#FF0000'">{{ item.ispayment == 0 ? 'NO' : 'YES' }}</span></td>
                                <td><span v-if="item.settlement_date">{{item.settlement_date | myGlobal}} </span></td>
                            </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-3">
                        <div>
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totaldetails : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totaldetails }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>                                    
                        </div>
                    </div>
                </div>
            </div>                    
        </div>
    </div> 
</template>

<script>
    export default {
        props:['content'],
        data() {
            return {
                advance : true,
                searchadvance: true,
                resetadvance: false,
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totaldetails:'',
                campo1: '',
                type: null,
                payment: null,
                details:[]
            }
        },
        mounted() {
            console.log(this.content)
        },
        methods: {
            advanceSearch(){
                this.advance = false
                this.searchadvance = false
                this.resetadvance =  true
                this.campo1 = ''
                var x = document.getElementById('btnsearch')
                x.classList.add('margin-top-advance')
            
            },
            resertSearch(){
                this.advance = true
                this.searchadvance = true
                this.resetadvance =  false
                this.type = null
                this.search()
                var x = document.getElementById('btnsearch')
                x.classList.remove('margin-top-advance')
            },
            returnList(){
                this.$emit('clickList',true)
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
            },
            search(){
                axios.post('/api/reportpaymentdetail',{
                    year: this.content.year,
                    month: this.content.month,
                    program: this.content.program,
                    advisor: this.content.advisor,
                    type: this.type,
                    payment: this. payment,
                    campo1:this.campo1
                })
                .then(response => {
                    if(response.status == 200){
                        this.details = response.data.data
                        console.log(this.details)
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totaldetails = response.data.total;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/reportpaymentdetail?page='+newval, {
                    year: this.content.year,
                    month: this.content.month,
                    program: this.content.program,
                    advisor: this.content.advisor,
                    type: this.type,
                    payment: this. payment,
                    campo1:this.campo1
                })
                .then(response => {
                    if(response.status == 200){
                        this.details = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totaldetails = response.data.total;
                        setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/reportpaymentdetail?page='+next, {
                    year: this.content.year,
                    month: this.content.month,
                    program: this.content.program,
                    advisor: this.content.advisor,
                    type: this.type,
                    payment: this. payment,
                    campo1:this.campo1
                })
                .then(response => {
                    if(response.status == 200){
                        this.details = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                   
                        this.ultima_pagina = response.data.last_page;
                        this.totaldetails = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                    }
                });
            },
        },

        created() {
            this.search()
        },
    }
</script>