<template lang="pug">
    #modal_event.modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 Add Charge
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            br
                            br
                            .col-lg-12
                                .row
                                    .col-lg-6
                                        .form-group
                                            div.form-group.class-inline.row
                                                div.class-campo-icon.col-md-4 Concept #[span(v-if="errorConcept" style="color:red") Required]
                                                div.class-campo-text.col-md-8                                                    
                                                    select(v-model="concept" class="input-form")
                                                        option(v-for="item in typecharges" :key="item.id" :value="item.id") {{item.description}}
                                                    input.input-form(type="text" v-model="notcharges" v-if="concept == 5" style="margin-top:5px" placeholder="Specify")                                                    
                                    .col-lg-6
                                        .form-group
                                            div.form-group.class-inline.row
                                                div.class-campo-icon.col-md-4 Ammount #[span(v-if="errorAmmount" style="color:red") Required]
                                                div.class-campo-text.col-md-8(style="display: inherit;font-size: 15px;") $
                                                    input.input-form(type="text" v-model="ammount" :id="'campo'+2" v-on:blur="validMounthly(2)" @keypress="justNumbers")
                            .col-lg-12.col-md-12
                                .form-group.row
                                    .col-lg-8
                                        .form-group
                                            label Payment Method #[span(v-if="errorPayment" style="color:red") Required]
                                            .row(style="background: #f1f1f1;margin: 0;border-radius: 5px;padding: 7px;")
                                                .col-lg-4(style="display:inherit")
                                                    input(id="direct" name="direct" type="radio" value="1" v-model="method_payment" style="margin-top: 4px;margin-right: 4px;" @click="clickDP")
                                                    label(for="direct") Direct Payment
                                                .col-lg-4(style="display:inherit")
                                                    input(id="parcial" name="parcial" type="radio" value="2" v-model="method_payment" style="margin-top: 4px;margin-right: 4px;" @click="clickDP")
                                                    label(for="parcial") Parcial
                                                .col-lg-4(style="display:inherit")
                                                    input(id="fee" name="fee" type="radio" value="3" v-model="method_payment" style="margin-top: 4px;margin-right: 4px;" @click="clickDP")
                                                    label(for="fee") Fee Charge
                                    .col-lg-4
                                        .form-group
                                            label Date #[span(v-if="DOPerror" style="color:red") Required]
                                            kendo-datepicker(
                                                v-model="date"
                                                :min="minDate"
                                                :max="maxDate"
                                                :format="'MM/dd/yyyy'"
                                                class="input-form"
                                                name="date"
                                                id="date"
                                                style="background:white !important;width:150px;height: auto;display: inherit;")

                            .col-lg-12.col-md-12(v-if="method_payment == 1 || method_payment == 2")
                                .form-group.row                                    
                                    .col-md-8
                                        label Type Payment #[span(v-if="errorType" style="color:red") Required]
                                        .row(style="background: #f1f1f1;margin: 0;border-radius: 5px;padding: 7px;")
                                            .col-lg-4(style="display:inherit")
                                                input(style="margin-top: 5px;margin-right: 2px;" type="radio" name="method" id="card" v-model="type_payment" value="1" @click="clickCC")
                                                label(for="card" ) Credit Card
                                            .col-lg-4(style="display:inherit")
                                                input( style="margin-top: 5px;margin-right: 2px;" type="radio" name="method" id="cash" v-model="type_payment" value="2" @click="clickO")
                                                label(for="cash" ) Others                                    
                                    .col-lg-4(v-if="method_payment == 2")                                        
                                        label Ammount Partial #[span(v-if="errorAmmountPartial" style="color:red") Required]
                                        div(style="display: flex;font-size: 15px;")
                                            span(style="margin-right: 5px") $ 
                                            input.input-form(type="text" v-model="ammount_partial" style="height: 33px;" :id="'campo'+1" v-on:blur="validpartial(1)" @keypress="justNumbers")
                            .col-lg-6.col-md-6(v-if="type_payment == 2 && method_payment !=3")
                                .form-group
                                    label Type #[span(v-if="errorType2" style="color:red") Required]
                                    select(v-model="types" class="input-form")
                                        option(value="1") Cash
                                        option(value="2") Check
                                        option(value="3") Money Order
                            .col-lg-6.col-md-6(v-if="type_payment == 2 && method_payment !=3")
                                .form-group
                                    label Serial Number #[span(v-if="errorSerial" style="color:red") Required]
                                        input.input-card.input-form(v-model="specify" style="background: #f1f1f1 !important")
                            .col-lg-12.col-md-12(v-if="(method_payment == 1 || method_payment == 2) & type_payment == 1")
                                .form-group.row
                                    .col-md-6.col-lg-6
                                        .row
                                            .col-md-4
                                                label merchant #[span(v-if="Mererror" style="color:red") Required]
                                            .col-md-8(style="background: #f1f1f1;padding: 5px;margin-bottom: 0;border-radius: 5px")
                                                .row
                                                    .col-md-6
                                                        input(type="radio" value="2" name="merchant" v-model="merchant" id="wwce")
                                                        label(for="wwce" style="margin-bottom:0") WWCE                                            
                                                    .col-md-6
                                                        input(type="radio" value="1" name="merchant" v-model="merchant" id="amg")
                                                        label(for="amg" style="margin-bottom:0") AMG
                                    .col-md-6.col-lg-6
                                        .row
                                            .col-md-4
                                                label Transaction ID #[span(v-if="Traerror" style="color:red") Required]
                                            .col-md-8(style="display:inherit")
                                                input.input-card.input-form(type="text" v-model="transaction" step="any")                                                                
                            .col-lg-12.col-md-12(v-if="(method_payment == 1 || method_payment == 2) & type_payment == 1") 
                                .col-lg-12.col-md-12(:style=" cards != null ? cards.length > 3 ? 'height: 120px;margin-bottom:15px;margin-top: 15px;overflow-y: auto;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;'")
                                    label #[span(v-if="errorCard" style="color:red") Required]
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th 
                                                th Card Holder Name
                                                th Card Number
                                                th MM
                                                th YY
                                                th CVC
                                            tr(v-for="(card, index) in cards" :key="index" v-show="card.cardnumber != null")
                                                td 
                                                    input(type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id")
                                                td(style="color: #9b9b9b;text-transform: capitalize") {{card.cardholdername}}
                                                td(style="color: #9b9b9b;") {{'XXXX-XXXX-XXXX-'+card.cardnumber}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_month}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_year}}
                                                td(style="color: #9b9b9b;") {{card.cardsecuritycode != null ?  card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3) : ''}}
                                .col-lg-12.col-md-12(style="text-align: right" v-if="method_payment == 1 || method_payment == 2" )
                                    a(@click="createcard" class="btn class-button-green" style="width:80px;color:white") #[img(:src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;")] ADD
                                        span(v-if="CAerror" style="color:red;position: absolute;left: 15px;top: 25px;") Required  
            .modal-footer
                button.btn.btn-success.btn-color-green( @click="saveCharge" type="button" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-save] SAVE                
        modal-card-create(v-if="createmodal" :idlead="this.lead_id" @new="addCard" @click="closedModalCar" style="z-index:99 !important" )

</template>


<script>
export default {
    props:[ 'nameProgram' ,'nameClient' ,'lead_id','id','account','session','cantcards'],
    data() {
        return {
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            program: this.nameProgram,
            client_name:this.nameClient,
            method_payment: '',
            cards: JSON.parse(this.cantcards),
            CAerror:false,
            createmodal: false,
            type_payment:'',
            specify:'',            
            concept:'',
            ammount:'',
            ammount_partial:'',
            card_id:'',
            types:'',
            errorConcept:false,
            errorAmmount:false,
            errorPayment:false,
            errorType:false,
            errorCard:false,
            errorType2:false,
            errorSerial:false,
            errorAmmountPartial:false,
            Mererror:false,
            Traerror:false,
            DOPerror:false,
            merchant:'',
            transaction:'',
            date:'',
            typecharges:[],
            notcharges:''
        }
    },
    mounted() {        
    },
    methods: {
        clickDP(){
            this.type_payment = ''
            this.ammount_partial = ''
            this.merchant = ''
            this.transaction = ''
            this.card_id = ''
            this.types = ''
            this.specify = ''
        },
        clickCC(){
            this.types = ''
            this.specify = ''
        },
        clickO(){
            this.merchant = ''
            this.transaction = ''
            this.card_id = ''
        },
        validMounthly(id){
            this.ammount = this.globalFunction(id)
        },
        validpartial(id){
            this.ammount_partial = this.globalFunction(id)
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.length > 0){                
                if(x.indexOf('.') != -1){ 
                    var numw = x.replace(/,/gi, "");
                    var dec = numw.split('.')
                    var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.'+dec[1]
                    
                }else{
                    var numw = x.replace(/,/gi, "");
                    var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.00'
                }
                return num
            }else{
                return x
            }
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        closeModal(){
            this.$emit('click',false)
        },
        createcard(){
        },
        createcard(){
            this.createmodal = true;
            var x = document.getElementById('modal_event')            
            x.classList.add('preloader-new')
        },
        addCard(cards) {
            this.cards = cards;
        },
        closedModalCar(click){
            this.createmodal = false
            var x = document.getElementById('modal_event')
            x.classList.remove('preloader-new')
        },
        saveCharge(){
            if(this.concept == ''){
                this.errorConcept = true
            }else{
                this.errorConcept = false
                if(this.ammount == ''){
                    this.errorAmmount = true
                }else{
                    this.errorAmmount = false
                    if(this.method_payment == ''){
                        this.errorPayment = true
                    }else{
                        this.errorPayment = false
                        if(this.method_payment == 3){
                            if(this.date == ''){
                                this.DOPerror = true
                            }else{
                                this.DOPerror = false
                                this.axiospost()
                            }
                        }else if(this.method_payment == 1){
                            if(this.date == ''){
                                this.DOPerror = true
                            }else{
                                this.DOPerror = false
                                if(this.type_payment == ''){
                                    this.errorType = true
                                }else{
                                    this.errorType = false
                                    if(this.type_payment == 1){
                                        if(this.merchant == ''){
                                            this.Mererror = true
                                        }else{
                                            this.Mererror = false
                                            if(this.merchant == 1){
                                                if(this.transaction == ''){
                                                    this.Traerror = true
                                                }else{
                                                    this.Traerror = false
                                                    this.axiospost()
                                                }
                                            }else if(this.merchant == 2){
                                                if(this.transaction == ''){
                                                    this.Traerror = true
                                                }else{
                                                    this.Traerror = false
                                                    this.axiospost()
                                                }
                                            }
                                        }
                                    }else if(this.type_payment == 2){
                                        if(this.types == ''){
                                            this.errorType2 = true
                                        }else{
                                            this.errorType2 = false
                                            if(this.specify == ''){
                                                this.errorSerial = true
                                            }else{
                                                this.errorSerial = false
                                                this.axiospost()
                                            }
                                        }
                                    }
                                }
                            }                            
                        }else if(this.method_payment == 2){
                            if(this.date == ''){
                                this.DOPerror = true
                            }else{
                                this.DOPerror = false
                                if(this.type_payment == ''){
                                    this.errorType = true
                                }else{
                                    this.errorType = false
                                    if(this.ammount_partial == ''){
                                        this.errorAmmountPartial = true
                                    }else{
                                        this.errorAmmountPartial = false
                                        if(this.type_payment == 1){
                                            if(this.merchant == ''){
                                                this.Mererror = true
                                            }else{
                                                this.Mererror = false
                                                if(this.merchant == 1){
                                                    if(this.transaction == ''){
                                                        this.Traerror = true
                                                    }else{
                                                        this.Traerror = false
                                                        this.axiospost()
                                                    }
                                                }else if(this.merchant == 2){
                                                    if(this.transaction == ''){
                                                        this.Traerror = true
                                                    }else{
                                                        this.Traerror = false
                                                        this.axiospost()
                                                    }
                                                }
                                            }
                                        }else if(this.type_payment == 2){
                                            if(this.types == ''){
                                                this.errorType2 = true
                                            }else{
                                                this.errorType2 = false
                                                if(this.specify == ''){
                                                    this.errorSerial = true
                                                }else{
                                                    this.errorSerial = false
                                                    this.axiospost()
                                                }
                                            }
                                        }
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }
        },
        axiospost(){
            var x = document.getElementById('modal_event')
            x.classList.add('preloader-new')
            swal.fire({
                title: 'Add Charge ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/api/savechargemigrations',{
                            id: this.id,
                            account: this.account,
                            concept: this.concept,
                            ammount: this.ammount.replace(',', ""),
                            ammount_partial: this.method_payment == 1 ? this.ammount.replace(',', "") : this.ammount_partial.replace(',', ""),
                            method_payment: this.method_payment,
                            card_id: this.card_id,
                            type_payment: this.type_payment,
                            type: this.type_payment != 1 ? this.types : 0,
                            specify: this.specify,
                            user_id:this.session,
                            merchant: this.merchant == '' ? 1 : this.merchant,
                            transaction:  this.method_payment == 1 || this.method_payment == 2 ? this.transaction : this.specify,
                            date: this.date,
                            notcharges:this.notcharges
                        })
                        .then(response => {
                            if(response.status == 200){
                                x.classList.remove('preloader')
                                swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                }).then( (res) => {
                                    if(res){
                                        this.$emit('dato',true)
                                        this.$emit('click',false)
                                        }
                                    });                    
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }else{
                        var x = document.getElementById('modal_event')
                        x.classList.remove('preloader-new')
                    }
                });
        },
        getTypecharges(){
            axios.get('/api/typecharges')
            .then(response => {
                this.typecharges =  response.data 
            });
        },
    },
    created() {
        this.getTypecharges()
    },
}
</script>