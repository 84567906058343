<template>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Appointments Made</span>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalevents : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalevents }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right;display:inline-flex">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                                    <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Captured By</label>
                                        <select v-model="captured" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in captureds" :key="index" :value="item.id">{{ item.user_name }}</option>
                                        </select>
                                    </div>
                                    <div v-if="this.global.layout.role_id != 5" class="form-group" style="margin: 0px 5px;">
                                        <label for="">Seller</label>
                                        <select v-model="seller" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in sellers" :key="index" :value="item.id">{{ item.user_name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Status</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="1">Process</option>
                                            <option value="2">Finish</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Sale Made</label>
                                        <select v-model="salemade" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="2">YES</option>
                                            <option value="1">NO</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">                                        
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Captured By</th>
                                        <th>Seller</th>
                                        <th>Status</th>
                                        <th>Sale Made</th>
                                        <th style="text-decoration: underline;">
                                            <span v-if="orderDate" @click="search('ASC')" style=" cursor: pointer;">Date <i class="fas fa-long-arrow-alt-down"></i></span>
                                            <span v-else @click="search('DESC')" style=" cursor: pointer;">Date <i class="fas fa-long-arrow-alt-up"></i></span>
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                    <tr v-for="(event , index) in events" :key="index">                                        
                                        <td class="blue-color"><a :href="'/crm/leads/show/'+event.lead_id">{{ event.lead_name }}</a></td>
                                        <td class="gris-color">{{event.isclient == 1 ? 'Lead' : 'Client'}}</td>
                                        <td class="gris-color">{{ event.captured_name }}</td>
                                        <td class="gris-color">{{ event.seller_name }}</td>
                                        <td :style="event.attend == 1?'color:#B59200':''">
                                            <span v-if="event.attend == 1">Process</span>
                                            <span v-else>Finish</span>
                                        </td>
                                        <td :class="event.sale_made == '-' ? 'gris-color':event.sale_made=='YES'?'text-no':'text-yes'">{{ event.sale_made}}</td>
                                        <td class="gris-color">{{ event.date }}</td>
                                        <td class="gris-color">
                                            <a v-if="event.attend == 1 && event.user_id == iduser" title="Edit" style="position: relative;cursor:pointer" @click="editEvent(event.id)">
                                                <img :src="'/images/edit.png'">                                            
                                            </a>
                                            <a v-else title="Edit" style="position: relative;cursor:pointer" >                                                
                                                <i style="font-size: 20px;color: #848484;" class="fas fa-lock"></i>                                           
                                            </a>
                                            <a style="cursor:pointer" title="Details" @click="editEvent(event.id)">
                                                <img :src="'/images/icons/lupa.png'" width="20px">
                                            </a>
                                            <a style="cursor:pointer;" title="Change Status" @click="changeProcess(event.id)" v-if="event.sale_made == 'NO' && (global.layout.role_id == 2 || global.layout.role_id == 1)" >
                                                <i style="font-size: 20px;color: #00CC00;" class="fas fa-lock-open"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalevents : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalevents }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['global'],
    data() {
        return {
            events: [],
            campo1: '',
            advance : true,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: true,
            resetadvance: false,
            from: '',
            to: '',
            captured: null,
            seller: this.global.layout.role_id == 5 ? this.global.layout.id : null,
            status: null,
            salemade: null,
            captureds: [],
            sellers: [],
            iduser: this.global.layout.id,
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totalevents:'',
            orderDate: true,
            dato1: 'desc'
        }
    },
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader');
        //console.log(this.global.layout.role_id)
    },
    methods: {
        changeProcess(id){
            axios.post('/api/changeProcess',{
                id: id
            })
            .then(response => {
                if(response.status == 200){
                    this.search(this.dato1)
                }                
            })
            .catch(err => {
                console.error(err); 
            })
        },
        pulsar(){
            this.search();
        },
        editEvent(id){
            window.location.href='/crm/leads/attend/'+id;
        },
        advanceSearch(){
            this.advance = false
            this.searchadvance = false
            this.resetadvance =  true
            this.campo1 = ''
            var x = document.getElementById('btnsearch')
            x.classList.add('margin-top-advance')
        },
        resertSearch(){
            this.campo1 = null
            this.advance = true
            this.searchadvance = true
            this.resetadvance =  false
            this.from = ''
            this.to = ''
            this.captured = null
            this.seller = this.global.layout.role_id == 1 || this.global.layout.role_id == 2 ?  null : this.seller
            this.status = null
            this.salemade = null
            this.search('DESC')
            var x = document.getElementById('btnsearch')
            x.classList.remove('margin-top-advance')
        },
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/searchappointmentmade?page='+newval,{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    captured: this.captured,
                    seller: this.seller,
                    status: this.status,
                    order : this.dato1,
                    salemade: this.salemade,
                    idsession: this.global.layout.id
            }).then(response => {
                if(response.status == 200){
                    this.events = response.data.data                
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totalevents = response.data.total;                    
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/searchappointmentmade?page='+next,{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    captured: this.captured,
                    seller: this.seller,
                    status: this.status,
                    order : this.dato1,
                    salemade: this.salemade,
                    idsession: this.global.layout.id
            }).then(response => {
                if(response.status == 200){
                    this.events = response.data.data                                     
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;                  
                    this.ultima_pagina = response.data.last_page;
                    this.totalevents = response.data.total;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },
        search(valor){
            console.log(this.salemade)
                this.dato1 = valor
                if(valor == 'ASC'){
                    this.orderDate = false
                }else{
                    this.orderDate = true
                }   
                axios.post('/api/searchappointmentmade',{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    captured: this.captured,
                    seller: this.seller,
                    status: this.status,
                    order : this.dato1,
                    salemade: this.salemade,
                    idsession: this.global.layout.id
                })
                .then(response => {
                    if(response.status == 200){
                        //console.log(response.data);
                        this.events = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalevents = response.data.total;                        
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
        },
        capturedAll(){
            axios.post('/api/capturedall/2',{ 
                roles : '[]',
                type : '0'
            }).then(response => {
                this.captureds = response.data;
            })
        },
        sellerAll(){
            axios.post('/api/sellerall/2',{ 
                roles : '[1,5,2]',
                type : '0'
            }).then(response => {
                this.sellers = response.data;
            })
        }

    },
    created() {
        this.search('desc');
        this.capturedAll();
        this.sellerAll();
    },
}
</script>