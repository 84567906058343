<template>
    <div>
        <table class="table table-bordered">
            <tbody>
                <tr style="background: #DBCA00 ; color: white">
                    <th>Description</th>
                    <th>Price</th>
                    <th>Column</th>
                    <th>Total</th>
                </tr>
                <tr v-if="dataprices.length > 0 ? false : true" v-for="(prices, index) in prices" :key="index">
                    <td :class="'items'+program.id" style="border: 1px solid #dee2e6 !important;"><span :attr="prices.id">{{prices.description}}1</span></td>
                    <td style="border: 1px solid #dee2e6 !important;">$ <span :id="'price'+prices.id">{{prices.price}}</span>.00</td>
                    <td :class="'program'+program.id" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" class="input-form" type="text" name="" :id="'campo'+prices.id" @keyup="formul(prices.id , program.id)"></td>
                    <td :class="'results'+program.id" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" placeholder="$ 0.00" disabled type="text" name="" :id="'resultado'+prices.id"></td>
                </tr>                                                                
                <tr v-if="dataprices.length > 0 ? true : false" v-for="(value, indexe) in dataprices" :key="indexe">
                    <td :class="'items'+program.id" style="border: 1px solid #dee2e6 !important;"><span :attr="value.prices_program_id">{{value.name[0].description}}2</span></td>
                    <td style="border: 1px solid #dee2e6 !important;">$ <span :id="'price'+value.prices_program_id">{{value.name[0].price}}</span>.00</td>
                    <td :class="'program'+program.id" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" class="input-form" type="text" name="" :id="'campo'+value.prices_program_id" v-model="value.column" @keyup="formul(value.prices_program_id , program.id)"></td>
                    <td :class="'results'+program.id" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" placeholder="$ 0.00" :value="value.column*value.name[0].price" disabled type="text" name="" :id="'resultado'+value.prices_program_id"></td>
                </tr>
                <tr>
                    <td colspan="3" style="border: 1px solid #dee2e6 !important;text-align:right">Total</td>
                    <td v-if="detailprice.length > 0" colspan="1" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" placeholder="$ 0.00" disabled type="text" name="" :id="'pricetotal'+program.id" v-model="total"></td>
                </tr>
                <tr>
                    <td colspan="3" style="border: 1px solid #dee2e6 !important;text-align:right">Fee</td>
                    <td v-if="detailprice.length > 0" colspan="1" style="border: 1px solid #dee2e6 !important;"><input style="text-align: center" placeholder="$ 0.00" id="feescri" type="text" name="" v-model="fee"></td>
                </tr>
            </tbody>
        </table>
        <div class="form-group">
            <div>
                <label for="">Observations</label>
                <textarea v-if="program.detailprice.length > 0" name="" id="" class="input-form" v-model="observation" style="height:100px"></textarea>
            </div>
        </div>
        <div class="form-group">
            <div>
                <label for="">I sign contract</label>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="form-group">
                            <input v-if="program.detailprice.length > 0" type="radio" name="sign" id="yes" value="YES" v-model="contract">
                            <label for="yes">YES</label>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <input v-if="program.detailprice.length > 0" type="radio" name="sign" id="no" value="NO" v-model="contract">
                            <label for="no">NO</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div>
                <button v-if='oneclick' class="btn btn-success btn-color-green" @click="save(program.id,program.program_id,program.lead_id)">Save1</button>
                <button v-else class="btn btn-success btn-color-green" @click="update(program.id,program.program_id,program.lead_id)">Save2</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['program'],
    data() {
        return {
            dataprices: this.program.dataprices,
            prices: this.program.prices,
            detailprice: this.program.detailprice,
            total: this.program.detailprice[0].total,
            fee : this.program.detailprice[0].fee,
            observation: this.program.detailprice[0].observation,
            contract : this.program.detailprice[0].contract,
            oneclick: true,
        }
    },
    methods: {
        formul(id, program){            
            var campo = document.getElementById('campo'+id).value;
            var resultado = document.getElementById('resultado'+id);
            var price = document.getElementById('price'+id).innerHTML;      
            resultado.value = campo*price;

            var x = document.getElementsByClassName('results'+program);
            var numero = 0;
            for (let i = 0; i < x.length; i++) {
                    var nev = x[i].getElementsByTagName('input')[0].value
                    numero += +(nev);                   
            }            
            var total = document.getElementById('pricetotal'+program)
            total.value = '$ '+numero+'.00'
            //this.total = numero;
        },
        feescri(){
            var flag = true;
            var fee = document.getElementById('feescri').value
            if(fee.length == 1 && flag){
                document.getElementById('feescri').value = "$ "+fee;
                flag = false;
            }
        },
        save(id,program,lead){
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
            this.oneclick = false;
            var fee = document.getElementsByClassName('program'+id)
            var idpro = document.getElementsByClassName('items'+id)
            var results = document.getElementsByClassName('results'+id);            
            var nev = [];
            for (let i = 0; i < fee.length; i++) {
                nev.push({item : idpro[i].getElementsByTagName('span')[0].getAttribute('attr'), column : fee[i].getElementsByTagName('input')[0].value , total: (results[i].getElementsByTagName('input')[0].value).substr(2)})
            }

            const param = {
                prices : nev,
                observation : this.observation,
                contract : this.contract,
                program : program,
                lead : lead,
                lead_program : id,
                fee : this.fee,
            }

            axios.post('/api/attendend',param)
            .then(response => {
                if(response.status == 201){
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            })
        },
        update(id,program,lead){
            
            var boton = document.getElementById('app');
            boton.classList.add('preloader');            
            var fee = document.getElementsByClassName('program'+id)
            var idpro = document.getElementsByClassName('items'+id)
            var results = document.getElementsByClassName('results'+id);            
            var nev = [];
            for (let i = 0; i < fee.length; i++) {
                nev.push({item : idpro[i].getElementsByTagName('span')[0].getAttribute('attr'), column : fee[i].getElementsByTagName('input')[0].value , total: (results[i].getElementsByTagName('input')[0].value).substr(2)})
            }

            const param = {
                prices : nev,
                observation : this.observation,
                contract : this.contract,
                program : program,
                lead : lead,
                lead_program : id,
                fee : this.fee,
            }

            axios.post('/api/attendendupdate',param)
            .then(response => {
                if(response.status == 200){
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            })
        }
    },
    
}
</script>