<template>
    <div>
        <div v-if="grilla">
            <form-manual-grilla :global="this.global" @clickProcess="processOpen"></form-manual-grilla>
        </div>
        <div v-if="process">
            <form-manual-process :global="this.global" @clickList="listOpen"></form-manual-process>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                grilla: true,
                process: false
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            processOpen(click){
                this.grilla = false
                this.process = click
            },
            listOpen(click){
                this.grilla = click
                this.process = false
            },
        },
        created() {
        },
    }
</script>