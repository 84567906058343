<template lang="pug">
    div
        .cont-title
            .row
                .col-lg-6
                    span Process
                .col-lg-6
                    button.btn.class-button-red(@click="returnList") #[i.fas.fa-caret-left(style="margin-right:5px")] RETURN
        .cont-search-paginate
            .row
                .col-lg-6
                    .row
                        .col-lg-8
                            .form-group                                
                                input.input-form(type="text" disabled ref="file" name="file" placeholder="#Account" style="margin-bottom: 5px;" v-model="account")
                        .col-lg-4
                .col-lg-12
                    .row
                        .col-lg-4
                            .form-group
                                div
                                    label Client
                                    input.input-form(type="text" disabled v-model="client")
                        .col-lg-4
                            .form-group
                                div
                                    label Program
                                    input.input-form(type="text" disabled v-model="program")
                        .col-lg-4
                            .form-group
                                div
                                    label Advisor
                                    input.input-form(type="text" disabled v-model="advisor")                        
                        .col-lg-4
                            .form-group
                                div
                                    label Ammount
                                    input.input-form(type="text" placeholder="$" v-model="ammount")
                        .col-lg-4
                            .form-group
                                label Type
                                select.input-form(v-model="types")
                                    option(value="2") Cash
                                    option(value="3") Check 
                                    option(value="4") Money Order
                        .col-lg-4
                            .form-group
                                label Number of Series
                                input.input-form(type="text" placeholder="#" v-model="transaction")
                        .col-lg-4
                            .form-group
                                div
                                    label Merchant
                                    .row
                                        .col-md-3
                                            input(type="radio" value="2" name="merchant" v-model="merchant" id="wwce")
                                            label(for="wwce") WWCE
                                        .col-md-3
                                            input(type="radio" value="1" name="merchant" v-model="merchant" id="amg")
                                            label(for="amg") AMG                                            
                        .col-lg-4
                            .form-group
                                label date
                                kendo-datepicker(
                                    v-model="date"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date"
                                    id="date"
                                    style="background:white !important;width:150px;height: auto;display: inherit;")
                        .col-lg-12
                            .form-group
                                button.btn.class-button-submit(@click="processImport" style="float: left") #[i.fas.fa-save(style="margin-right:5px")] SUBMIT
                                button.btn.class-button-red(@click="deleteOther" style="margin-left: 10px;float:left") #[i.fas.fa-trash-alt(style="margin-right:5px")] DELETE

</template>



<style lang="stylus">
    .color-yellow
        background : #FF9800 !important
        color: black
    .color-green-text
        color: green
    .color-red-text
        color: red
</style>


<script>
    export default {
        props:['global','type','edit'],
        data() {
            return {
                id: this.edit.id,
                merchant: this.edit.merchant,
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                account:this.edit.account,
                client:this.edit.client_name,
                program:this.edit.program,
                advisor:this.edit.advisor_name,
                ammount:this.edit.amount,
                transaction:this.edit.transaction_id,
                date:this.edit.settlement_date,
                types: this.edit.types
            }
        },
        mounted() {
            console.log(this.edit)
        },
        methods: {
            returnList(){
                this.$emit('clickList',true)
            },
            deleteOther(){
                axios.post('/api/deleteOther',{
                    id: this.id
                })
                .then(response => {
                    if(response.status == 200){
                       this.$emit('clickList',true) 
                    }
                })
                .catch(err => {
                    console.error(error); 
                })
            },
            processImport(){
                axios.post('/api/updateOther',{
                    id: this.id,
                    account : this.account,
                    ammount : this.ammount,
                    transaction: this.transaction,
                    date: this.date,
                    merchant: this.merchant,
                    idsession: this.global.layout.id,
                })
                .then(response => {
                    if(response.status == 200){
                        this.$emit('clickList',true)
                    }
                })
            },
            getcard(){
                /*var boton = document.getElementById('app');
                boton.classList.add('preloader');*/
                axios.post('/api/getcard',{
                    account: this.account
                }).then(response => {
                    this.cards = response.data
                    if (this.cards.length > 0) {
                        this.client = this.cards[0].client_name
                        this.program = this.cards[0].program
                        this.advisor = this.cards[0].advisor_name
                    }else{
                        this.client = ''
                        this.program = ''
                        this.advisor = ''
                    }
                    /*var boton = document.getElementById('app');
                    boton.classList.remove('preloader');*/
                })
            },            
        },
        created() {            
        },
    }
</script>