<template>
    <div>
        <div v-if="sold == '1'" id="campoprogramsold" style="height: 180px;">
            <div class="form-group" style=" border: 4px solid rgb(154, 154, 154);
                                            padding: 12px;
                                            transform: translate(-50%, -50%);
                                            position: absolute;
                                            left: 50%;
                                            top: 56%;
                                            color: rgb(154, 154, 154);
                                            font-weight: bold;
                                            font-size: 30px;
                                            text-align: center;">
                <span>PROGRAM SOLD</span>
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-lg-6">
                    <div v-if="id == 3" class="form-group">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr  style="background: #cccccc ; color: #666666;text-transform:uppercase">
                                    <th style="font-weight: 100;">Description</th>
                                    <th style="font-weight: 100;">Price</th>
                                    <th style="font-weight: 100;">Quantity</th>
                                    <th style="font-weight: 100;">SubTotal</th>
                                </tr>
                                <tr v-for="(prices, index) in pricesProgram" :key="prices.id" v-if="index < 17">
                                    <td :class="'items'+program.id" style="font-size: 11px;color: #666666;text-transform: uppercase"><span :attr="prices.id">{{prices.description}}</span></td>
                                    <td style="color: #666666">$ <span :id="'price'+prices.id">{{prices.price}}</span>.00</td>
                                    <td :class="'program'+program.id" style="">
                                        <input :disabled="inputDisable" style="text-align: center;background:white;border-radius:5px" class="input-form-cant" @mouseup="formul(prices.id , program.id)" type="number" min="1" max="99" :id="'campo'+prices.id" @keyup="formul(prices.id , program.id)">
                                    </td>
                                    <td :class="'results'+program.id" style="color: #BAA345">
                                        $ <input class="campo-input-color-palceholder" style="text-align: center;width: 80px;color: #BAA345;border-radius:5px" placeholder="$ 0.00" disabled type="text" name="" :id="'resultado'+prices.id">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div v-if="id == 3" class="form-group">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr style="background: #cccccc ; color: #666666;text-transform:uppercase">
                                    <th style="font-weight: 100;">Description</th>
                                    <th style="font-weight: 100;">Price</th>
                                    <th style="font-weight: 100;">Quantity</th>
                                    <th style="font-weight: 100;">SubTotal</th>
                                </tr>
                                <tr v-for="(pdata2, i) in pricesProgram" :key="pdata2.id" v-if="i > 16">                                
                                    <td :class="'items'+program.id" style="font-size: 12px;color: #666666;text-transform: uppercase"><span :attr="pdata2.id">{{pdata2.description}}</span></td>
                                    <td style="color: #666666">$ <span :id="'price'+pdata2.id">{{pdata2.price}}</span>.00</td>
                                    <td :class="'program'+program.id" style="">
                                        <input :disabled="inputDisable" style="text-align: center;background:white;border-radius:5px" class="input-form-cant" type="number" min="1" max="99" :id="'campo'+pdata2.id" @mouseup="formul(pdata2.id , program.id)" @keyup="formul(pdata2.id , program.id)">
                                    </td>
                                    <td :class="'results'+program.id" style="color: #BAA345">
                                        $ <input class="campo-input-color-palceholder" style="text-align: center;width: 80px;color: #BAA345;border-radius:5px" placeholder="$ 0.00" disabled type="text" name="" :id="'resultado'+pdata2.id">
                                    </td>
                                </tr>
                                <tr style="background: #999999 ; color: #ffffff">
                                    <td colspan="4" style=";text-align:center">TOTAL $ <input class="input-form-cant-total" style="text-align: center" placeholder="$ 0.00" disabled type="text" name="" :id="'pricetotal'+program.id" ></td>                                    
                                </tr>                                                                    
                            </tbody>
                        </table>
                    </div>
                    <div v-if="id == 3" class="col-lg-12 form-group" style="margin-top: 35px;">
                        <div class="row">
                            <div class="col-lg-6">
                                <table class="table table-striped">
                                    <tbody style="border: 1px solid #ccc;">
                                        <tr style="color:#B59200;border-bottom: 1px solid #cccccc;">
                                            <td colspan="1" style="text-align: center;font-weight: bold;background: white;">FEE</td>
                                        </tr>
                                        <tr style="text-align: center;">
                                            <td style="text-align: center;background: #999999;">
                                                <div style="font-size:19px; color: white">
                                                    $ <input v-if=" id != 2 " :disabled="inputDisable" @blur="validFee(id)" style="text-align: center;border: 2px solid #ffffff;width: 100px;border-radius: 5px;outline: none;background: white;font-size: 14px;color: black;" placeholder="$ 0.00" :id="'feescri'+id" type="text" name="" v-model="fee" @keyup="feeExpre()">
                                                    <select v-if="id == 2" name="" id="" v-model="fee" style="text-align: center;border: 2px solid #ffffff;width: 100px;border-radius: 5px;outline: none;background: white;font-size: 14px;color: black;">
                                                        <option disabled></option>
                                                        <option value="24.99">24.99</option>
                                                        <option value="29.99">29.99</option>
                                                        <option value="34.99">34.99</option>
                                                        <option value="39.99">39.99</option>
                                                    </select>
                                                    <span v-if="errorFee" style="color: red ; font-size : 12px">Fee Required</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <table class="table table-striped">
                                    <tbody style="border: 1px solid #ccc;">
                                        <tr style="color:#B59200;border-bottom: 1px solid #cccccc;">
                                            <td colspan="1" style="text-align: center;font-weight: bold;background: white;">Sale Made</td>
                                        </tr>
                                        <tr style="text-align: center;">
                                            <td style="text-align: center;">
                                                <div>
                                                    <div class="custom-control custom-switch" style="padding:0;margin-left:0">
                                                        <label style="margin-right: 35px;">NO</label>
                                                        <input type="checkbox" class="custom-control-input" :name="'sale'+id" :id="'yes'+id" :disabled="inputDisable" checked v-model="sale">                                                            
                                                        <label class="custom-control-label" :for="'yes'+id">YES</label>
                                                    </div>
                                                    <span v-if="errorFee" style="color: red ; font-size : 12px">Fee Required</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12" style="margin-top: 30px;">                                    
                                <div>
                                    <textarea placeholder="Observations" :disabled="inputDisable" name="" id="" class="input-form" v-model="observation" style="height: 90px;margin-top: -2px;background: white;border: 1px solid #cccccc;" @keyup="observationExpre"></textarea>
                                    <span v-if="errorObservation" style="color: red ; font-size : 12px">Observation Required</span>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div v-if="id == 1" class="col-lg-7">
                            <div class="form-group">
                                <table class="table table-striped">
                                    <tbody style="border: 1px solid #ccc">
                                        <tr style="color:#B59200">
                                            <td colspan="5" style="text-align: center;font-weight: bold;background: white;    font-size: 1.1em;">
                                                <span>SINGLE PAYMENT</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[0].id" type="radio" name="options" id="singles1" @click="optionOther(pricesProgram['0'].id ,id)"> 
                                                    <label style="margin-right: 5px;" for="singles1" >{{ pricesProgram[0].description }} - </label> 
                                                    $ <label :id="'prices'+pricesProgram[0].id" for="singles">{{ pricesProgram[0].price }}</label>
                                                </div>
                                            </td>
                                            <td style="background:#f1f1f1;text-align: center;font-family: 'Raleway' !important;">
                                                <div style="display: inline-flex;font-size:12px">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[2].id" type="radio" id="singleg1" @click="optionOther(pricesProgram['2'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singleg1" >{{ pricesProgram[2].description }} - </label>
                                                    $ <label :id="'prices'+pricesProgram[2].id" for="singleg" >{{ pricesProgram[2].price }}</label>
                                                </div>
                                            </td>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input sqtyle="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[4].id" type="radio" id="singlep1" @click="optionOther(pricesProgram['4'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singlep1" >{{ pricesProgram[4].description }} - </label>
                                                    $ <label :id="'prices'+pricesProgram[4].id" for="singlep" >{{ pricesProgram[4].price }}</label>
                                                </div>
                                            </td>
                                            <td style="background:#f1f1f1;text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" type="radio" :value="pricesProgram[6].id" id="singleo1" @click="optionOther(pricesProgram['6'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singleo1" >{{ pricesProgram[6].description }}</label>                                                
                                                </div>
                                            </td>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    $ <input style="width: 65px !important;background: white;height: 20px;border: 1px solid #666666;margin-left: 5px;" v-model="otherOption1" type="text" :id="'other'+pricesProgram['6'].id" class="input-form" disabled @keyup="cloneFee(pricesProgram['6'].id ,id)" @blur="validOther1(34 , id)">
                                                </div>
                                            </td>
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-group">
                                <table class="table table-striped">
                                    <tbody style="border: 1px solid #ccc;">
                                        <tr style="color:#B59200">
                                            <td colspan="5" style="text-align: center;font-weight: bold;background: white;    font-size: 1.1em;">
                                                <span>MONTHLY PAYMENT</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[1].id" type="radio" name="options" id="singles2" @click="optionOther(pricesProgram['1'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singles2" >{{ pricesProgram[1].description }} - </label>
                                                    $ <label :id="'prices'+pricesProgram[1].id" for="singles" >{{ pricesProgram[1].price }}</label>
                                                </div>
                                            </td>
                                            <td style="background:#f1f1f1;text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[3].id" type="radio" id="singleg2" @click="optionOther(pricesProgram['3'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singleg2" >{{ pricesProgram[3].description }} - </label>
                                                    $ <label :id="'prices'+pricesProgram[3].id" for="singleg" >{{ pricesProgram[3].price }}</label>
                                                </div>
                                            </td>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" :value="pricesProgram[5].id" type="radio" id="singlep2" @click="optionOther(pricesProgram['5'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singlep2" >{{ pricesProgram[5].description }} - </label>
                                                    $ <label :id="'prices'+pricesProgram[5].id" for="singlep" >{{ pricesProgram[5].price }}</label>
                                                </div>
                                            </td>
                                            <td style="background:#f1f1f1;text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    <input style="margin-right: 5px;margin-top: 4px;" :disabled="inputDisable" v-model="option" type="radio" :value="pricesProgram[7].id" id="singleo2" @click="optionOther(pricesProgram['7'].id ,id)">
                                                    <label style="margin-right: 5px;" for="singleo2" >{{ pricesProgram[7].description }}</label>
                                                </div>
                                            </td>
                                            <td style="text-align: center;">
                                                <div style="display: inline-flex;font-size:12px;font-family: 'Raleway' !important;">
                                                    $ <input style="width: 65px !important;background: white;height: 20px;border: 1px solid #666666;margin-left: 5px;" v-model="otherOption2" type="text" :id="'other'+pricesProgram['7'].id" class="input-form" disabled @keyup="cloneFee(pricesProgram['7'].id ,id)" @blur="validOther2(35 ,id)">
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-if="id != 3" :class=" id == 1 ? 'col-lg-5 form-group' : 'col-lg-12 form-group'">
                            <div class="row">
                                <div :class=" id == 1 ? 'col-lg-6' : 'col-lg-3'">
                                    <table class="table table-striped">
                                        <tbody style="border: 1px solid #ccc;">
                                            <tr style="color:#B59200;border-bottom: 1px solid #cccccc;">
                                                <td colspan="1" style="text-align: center;font-weight: bold;background: white;">FEE</td>
                                            </tr>
                                            <tr style="text-align: center;">
                                                <td style="text-align: center;background: #999999;">
                                                    <div style="font-size:19px; color: white">
                                                        $ <input v-if=" id != 2 " :disabled="inputDisable" @blur="validFee(id)" style="text-align: center;border: 2px solid #ffffff;width: 100px;border-radius: 5px;outline: none;background: white;font-size: 14px;color: black;" placeholder="$ 0.00" :id="'feescri'+id" type="text" name="" v-model="fee" @keyup="feeExpre()">
                                                        <select @change="deleteError" v-if="id == 2" name="" id="" v-model="fee" style="text-align: center;border: 2px solid #ffffff;width: 100px;border-radius: 5px;outline: none;background: white;font-size: 14px;color: black;">
                                                            <option disabled></option>
                                                            <option value="24.99">24.99</option>
                                                            <option value="29.99">29.99</option>
                                                            <option value="34.99">34.99</option>
                                                            <option value="39.99">39.99</option>
                                                        </select>
                                                        <span v-if="errorFee" style="color: red ; font-size : 12px">Fee Required</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div :class=" id == 1 ? 'col-lg-6' : 'col-lg-3'">
                                    <table class="table table-striped">
                                        <tbody style="border: 1px solid #ccc;">
                                            <tr style="color:#B59200;border-bottom: 1px solid #cccccc;">
                                                <td colspan="1" style="text-align: center;font-weight: bold;background: white;">SALE MADE</td>
                                            </tr>
                                            <tr style="text-align: center;">
                                                <td style="text-align: center;">
                                                    <div>
                                                        <div class="custom-control custom-switch" style="padding:0;margin-left:0">
                                                            <label style="margin-right: 35px;">NO</label>
                                                            <input type="checkbox" class="custom-control-input" :name="'sale'+id" :id="'yes'+id" :disabled="inputDisable" checked v-model="sale">                                                            
                                                            <label class="custom-control-label" :for="'yes'+id">YES</label>
                                                        </div>                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div :class=" id == 1 ? 'col-lg-12' : 'col-lg-6'">                                    
                                    <div>
                                        <textarea placeholder="Observations" :disabled="inputDisable" name="" id="" class="input-form" v-model="observation" style="height: 90px;margin-top: -2px;background: white;border: 1px solid #cccccc;" @keyup="observationExpre"></textarea>
                                        <span v-if="errorObservation" style="color: red ; font-size : 12px">Observation Required</span>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div style="text-align: right;">
                        <div>
                            <button v-if="blocking" class="btn btn-color-blue" style="background: #0066FF;text-transform:uppercase" @click="save(id)"><i class="fas fa-save"></i> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['program','idevent','status','validateRoute','eventBoy'],
    data() {
        return {
            attend: this.status,
            sold: this.program.sold,
            id : this.program.id,
            pricesProgram: JSON.parse(this.program.rate),
            fee : this.program.fee,
            observation : this.program.observations,
            sale : '',
            oneclick: true,
            event : this.idevent,
            option:'',
            otherOption1: '',
            otherOption2: '',
            blocking: true,
            inputDisable: false,
            errorObservation: false,
            errorFee: false,
        }
    },

    mounted() {
        if(this.program.sale == null){
            this.sale = false
        }else if(this.program.sale == 1){
            this.sale = true
        }else if(this.program.sale == 0){
            this.sale = false
        }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        if(this.fee != null && this.fee != '' && this.id != 2 ){
            var data = formatter.format(this.fee)
            this.fee = data.replace('$', "")
        } 
        if (this.id == 1) {
            if(JSON.parse(this.program.rate_selected) != null){
                this.option=JSON.parse(this.program.rate_selected)[0].rate_id;
                if(this.option == 34){
                    this.otherOption1 = this.fee
                }else if(this.option == 35){
                    this.otherOption2 = this.fee
                }  
            }
            if(this.pricesProgram != null && this.program.sold != 1){
                console.log(this.pricesProgram)
                this.pricesProgram.map( map => {
                    if( map.id != 34 && map.id != 35){
                        var sear = document.getElementById('prices'+map.id).innerHTML

                        var data = formatter.format(sear)
                        document.getElementById('prices'+map.id).innerHTML = data.replace('$', "")
                    }
                })
            }
        }else if(this.id == 3 && this.sold != 1){
            if(JSON.parse(this.program.rate_selected) != null){
                var data = JSON.parse(this.program.rate_selected);
                    data.map( amd => {
                        document.getElementById('campo'+amd.rate_id).value = amd.quantity;
                        this.formul(amd.rate_id,this.id)    
                    })
            }
        }

        if(this.attend == 2 || this.validateRoute == false) {
            this.blocking = false
            this.inputDisable = true
        }else{
            this.blocking = true
            this.inputDisable = false
        }
        if(this.eventBoy == true){

        }               
    },

    methods: {
        deleteError(){
            if(this.fee != '')
                this.errorFee = false
        },
        validFee(id){
            this.fee = this.globalFunction(id, 'feescri')
        },
        validOther1(id ,dato){
            this.otherOption1 = this.globalFunction(id ,'other')
            var y = document.getElementById('feescri'+dato);
            y.value = this.otherOption1;
            this.fee = this.otherOption1
        },
        validOther2(id ,dato){
            this.otherOption2 = this.globalFunction(id ,'other')            
            var y = document.getElementById('feescri'+dato);
            y.value = this.otherOption2;
            this.fee = this.otherOption2
        },
        globalFunction(id , dato){
            var x = document.getElementById(dato+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        feeExpre(){
            if(this.fee != null){
                this.errorFee = false
            }
        },
        observationExpre(){
            if(this.observation != null){
                this.errorObservation = false
            }
        },
        cloneFee(id , dato){
            if(id == 34){                
                var y = document.getElementById('feescri'+dato);
                y.value = this.otherOption1;
                this.fee = this.otherOption1;
            }else{                
                var y = document.getElementById('feescri'+dato);
                y.value = this.otherOption2;
                this.fee = this.otherOption2;
            }                    
        },
        optionOther(id , dato){
            this.option = id
            if(id == 34){
                var x = document.getElementById('other'+id);
                    x.removeAttribute('disabled');
                var y = document.getElementById('other35');
                    y.setAttribute("disabled", "disabled");
                this.otherOption2 = '';
                this.fee = '';
            }else if(id == 35){
                var x = document.getElementById('other'+id);
                    x.removeAttribute('disabled');
                var y = document.getElementById('other34');
                    y.setAttribute("disabled", "disabled");
                this.otherOption1 = '';
                this.fee = '';
            }else{
                var x = document.getElementById('other34');                
                    x.setAttribute("disabled", "disabled");
                    this.otherOption2 = '';
                var y = document.getElementById('other35');
                    y.setAttribute("disabled", "disabled");
                    this.otherOption1 = '';
                var z = document.getElementById('feescri'+dato);
                    z.value='';
                this.fee = document.getElementById('prices'+id).innerHTML;          
            }
        },
        formul(id, program){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            })

            var campo = document.getElementById('campo'+id).value;
            var resultado = document.getElementById('resultado'+id);
            var price = document.getElementById('price'+id).innerHTML;        
            var data = formatter.format(campo*price)
            resultado.value = data.replace('$', "")
            
            var x = document.getElementsByClassName('results'+program);
            var numero = 0;
            for (let i = 0; i < x.length; i++) {
                    var nev = x[i].getElementsByTagName('input')[0].value
                    numero += +(nev.replace(/,/gi,""));                   
            }            
            var total = document.getElementById('pricetotal'+program)
            var data = formatter.format(numero)
            total.value = data.replace('$', "")                       
            if(this.fee == ''){
                this.fee = data.replace('$', "")
            }else{
                this.fee = this.fee
            }
        },
        feescri(){
            var flag = true;
            var fee = document.getElementById('feescri').value
            if(fee.length == 1 && flag){
                document.getElementById('feescri').value = "$ "+fee;
                flag = false;
            }
        },
        save(id){
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
            this.oneclick = false;
            var nev = [];
            var total = 0;
            if(id == 3){
                var fee = document.getElementsByClassName('program'+id)
                var idpro = document.getElementsByClassName('items'+id)
                var info = document.getElementById('pricetotal'+id).value
                if(info != ''){
                    var total = info; 
                }else{
                    var total = 0
                }
                for (let i = 0; i < fee.length; i++) {
                    if (fee[i].getElementsByTagName('input')[0].value != '') {
                        nev.push({item : idpro[i].getElementsByTagName('span')[0].getAttribute('attr'), cant : parseInt(fee[i].getElementsByTagName('input')[0].value,10) })
                    }else{
                        nev.push({item : '0', cant : 0 })
                    }
                }
            }else if(id == 1){
                if(this.option){
                    if(this.otherOption1 == '' && this.otherOption2 == ''){
                        nev.push({item : String(this.option), cant : 1 })
                        var total = document.getElementById('prices'+this.option).innerHTML
                    }else if (this.otherOption1 != '' && this.otherOption2 == ''){                    
                        nev.push({item : String(this.option), cant : 1 })                    
                        var total = document.getElementById('other34').value
                    }else if (this.otherOption1 == '' && this.otherOption2 != ''){                    
                        nev.push({item : String(this.option), cant : 1 })
                        var total = document.getElementById('other35').value
                    }
                }else{
                    nev.push({item : '0', cant : 0 })
                    var total = 0
                }                
                
            }else{
                console.log(this.fee)
                console.log(this.sale)
                console.log(this.observation)
            }
            
            if(this.sale == false){
                var salenew = 0
            }else{
                var salenew = 1
            }

            const param = {
                prices : nev,
                observation : this.observation,
                contract : salenew,
                program : id,                
                fee : this.fee,
                event : this.event,
                suggested: total
            }

            if(this.observation == null && this.fee == null){
                this.errorObservation = true
                this.errorFee = true
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else if(this.observation != null && this.fee == null){
                this.errorObservation = false
                this.errorFee = true
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else if(this.observation == null && this.fee != null){
                this.errorObservation = true
                this.errorFee = false
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else if(this.observation == '' && this.fee != ''){
                this.errorObservation = true
                this.errorFee = false
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else if(this.observation != '' && this.fee == ''){
                this.errorObservation = false
                this.errorFee = true
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else if(this.observation == '' && this.fee == ''){
                this.errorObservation = true
                this.errorFee = true
                setTimeout(() => {
                    var boton = document.getElementById('app');
                    boton.classList.remove('preloader')
                }, 50); 
            }else{
                this.errorObservation = false
                this.errorFee = false
                axios.post('/api/attendend',param)
                .then(response => {
                    if(response.status == 200){
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50);
                        console.log(response.data)
                        this.$emit('sale',{'id': this.id , 'state' : salenew }) 
                        this.$emit('click',true);
                    }
                })
            }

        },
    },
}
</script>