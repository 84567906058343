<template>
    <div class="row " style="margin-left: -9px;margin-right: -9px;">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>history imports</span>
                    </div>
                    <div class="col-lg-6">
                        <button @click="returnList" class="btn class-button-red" style="margin-right: 5px;text-transform:uppercase"> <i class="fas fa-caret-left" style="margin-right:5px"></i> return</button>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-3">
                        <div style="display: inline-flex">
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>
                            <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div style="float:right">                            
                            <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                            
                            <button class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-angle-double-left" style="margin-top: 4px;"></i>  BASIC SEARCH</button>
                        </div>
                        <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                            <div class="form-group" style="margin: 0px 5px;">                                
                                <kendo-datepicker
                                    v-model="from"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date"
                                    id="date"
                                    placeholder="From"
                                    style="background:white !important;width:150px;height: auto;display: inherit;">
                                </kendo-datepicker>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">                                
                                <kendo-datepicker
                                    placeholder="To"
                                    v-model="to"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date" 
                                    id="date"
                                    style="background:white !important;width:150px;height: auto;display: inherit;">
                                </kendo-datepicker>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">                                
                                <select placeholder="User" v-model="user" class="input-form" name="" id="" style="background:white !important;color: black !important;height: 30px;">                                                                    
                                    <option value="null" selected disabled>Users</option>
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in users" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin-top: 0px;">                                        
                                <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                            </div>
                            <div class="form-group" style="margin-top:0px">
                                <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Merchant</th>
                                <th>Transaction Successfully</th>
                                <th>Transaction Error</th>
                                <th>User</th>
                                <th>Date</th>
                                <th>Action</th>                            
                            </tr>
                            <tr v-for="(history , index) in historys" :key="index">
                                <td>{{history.merchant}}</td>
                                <td style="color:#00CC00">{{history.transactions_succesfully}}</td>
                                <td style="color:#FF0000">{{history.transactions_error}}</td>
                                <td>{{history.user_name}}</td>
                                <td>{{history.created_at}}</td>
                                <td><a class="btn class-button-green" style="color: white;width: 15rem;margin-left: 5px;float:left;text-transform:uppercase" @click="detailImports(history.id)"> Detail Import <i class="fas fa-caret-right" style="margin-left:5px"></i></a></td>
                            </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-3">
                        <div>
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>                                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:['global','type'],
        data() {
            return {
                historys: [],
                advance : false,
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                searchadvance: true,
                resetadvance: false,
                from: '',
                to: '',
                user:null,                
                users:[],
                iduser: this.global.layout.id,
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totalclients:'',
                orderDate: true,
                oneEventClient: true,
                orderClientAsc:false,
                orderClientDesc:false,
                oneEventDate: true,
                orderDateAsc:false,
                orderDateDesc:false,
                someData:'',
                details:[]
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            detailImports(idImport){
                //aca mando el id de la importacion
                axios.post('/api/detailimport',{
                    id: idImport
                })
                .then( response => {
                    this.details = response.data
                    this.$emit('openDetail',this.details)
                    this.$emit('clickDetail',true)
                })
            },
            returnList(){
                this.$emit('clickList',true)
            },
            advanceSearch(){
                this.advance = false
                this.searchadvance = false
                this.resetadvance =  true
                var x = document.getElementById('btnsearch')
                x.classList.add('margin-top-advance')
            },
            resertSearch(){
                this.advance = false
                this.searchadvance = true
                this.resetadvance =  false
                this.user = null
                this.from = ''
                this.to = ''
                this.search()
                var x = document.getElementById('btnsearch')
                x.classList.remove('margin-top-advance')
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/historyimports?page='+newval,{
                        user: this.user,
                        from: this.from,
                        to: this.to,                        
                }).then(response => {
                    if(response.status == 200){
                        this.historys = response.data.data                
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;                    
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/historyimports?page='+next,{
                        user: this.user,
                        from: this.from,
                        to: this.to,
                }).then(response => {
                    if(response.status == 200){
                        this.historys = response.data.data                                     
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                  
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },
            search(){                
                axios.post('/api/historyimports',{
                    user: this.user,
                    from: this.from,
                    to: this.to,
                })
                .then(response => {
                    if(response.status == 200){                    
                        this.historys = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;                        
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
            allUsers(){
                axios.post('/api/usermodule/'+4,{
                    roles : '[1,2]',
                    type: '1'
                }).then(response => {
                    this.users = response.data;
                })
            }
        },
        created() {
            this.search();
            this.allUsers();
        },
    }
</script>