<template>
    <div class="row" :style="type == 0 ? '' :'margin-left: -9px;margin-right: -9px;' ">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Others</span>
                    </div>
                    <div class="col-lg-6" v-if="type == 0">
                        <button @click="processFunction" class="btn class-button-green" style="text-transform:uppercase"><i class="far fa-arrow-alt-circle-down" style="margin-right:5px"></i> REGISTER</button>                        
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-3">
                        <div style="display: inline-flex">
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>
                            <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div style="float:right;display:inline-flex">
                            <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                            <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                            <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                            <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                        </div>
                        <br>
                        <br>
                        <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">From</label>
                                <kendo-datepicker
                                    v-model="from"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date"
                                    id="date"
                                    style="background:white !important;width:150px;height: auto;display: inherit;">
                                </kendo-datepicker>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">To</label>
                                <kendo-datepicker
                                    v-model="to"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date" 
                                    id="date"
                                    style="background:white !important;width:150px;height: auto;display: inherit;">
                                </kendo-datepicker>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Programs</label>
                                <select v-model="program" class="input-form" name="" id="" style="background:white !important;color: black !important;height: 30px;" @change="programAdvisor">
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Advisor</label>
                                <select v-model="advisor" class="input-form" name="" id="" style="background:white !important;color: black !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in advisors" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin-top: 25px;">                                        
                                <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                            </div>
                            <div class="form-group" style="margin-top:25px">
                                <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Client</th>                         
                                <th>Account</th>
                                <th>Program</th>
                                <th>Advisor</th>
                                <th>Amount</th>
                                <th>#Check</th>
                                <th>Date</th>
                                <th>Submit Date/Time/User</th>
                                <th v-if="type == 0">Action</th>
                            </tr>
                            <tr v-for="(other , index) in others" :key="index">                            
                                <td>{{other.client_name}}</td>
                                <td>{{other.account}}</td>
                                <td>{{other.program}}</td>
                                <td>{{other.advisor_name}}</td>
                                <td>{{'$ '+other.amount}}</td>
                                <td>{{other.transaction_id}}</td>
                                <td>{{other.settlement_date | myGlobalDay}}</td>
                                <td>{{other.created_at | myGlobalDay}} / {{other.user_name}}</td>
                                <td v-if="type == 0"><a style="cursor:pointer" @click="clickEdit(other.id)"><img :src="'/images/edit.png'"></a></td>
                            </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-3">
                        <div>
                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-left"></i>
                            </button>
                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                <i class="fas fa-caret-right"></i>
                            </button>                                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                others: [],
                campo1: '',
                advance : true,
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                searchadvance: true,
                resetadvance: false,
                from: '',
                to: '',
                program:null,
                advisor:null,
                user:null,
                programs:[],
                advisors: [],
                iduser: this.global.layout.id,
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totalclients:'',
                orderDate: true,
                oneEventClient: true,
                orderClientAsc:false,
                orderClientDesc:false,
                oneEventDate: true,
                orderDateAsc:false,
                orderDateDesc:false,
                someData:'',
                dataedit:[]
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            processFunction(){
                this.$emit('clickRegister', true);   
            },
            clickEdit(id){
                axios.post('/api/editOther',{
                    id: id
                })
                .then(response => {
                    if(response.status == 200) {
                        this.$emit('clickEdit',response.data[0]);
                    }
                    //console.log(response)                    
                })
                .catch(error => {
                    console.error(error); 
                })
            },
            returnList(){
                this.grilla = true
                this.excel = false
            },
            advanceSearch(){
                this.advance = false
                this.searchadvance = false
                this.resetadvance =  true
                this.campo1 = ''
            },
            resertSearch(){
                this.advance = true
                this.searchadvance = true
                this.resetadvance =  false            
                this.advisor = null
                this.from = ''
                this.to = ''
                this.program = null
                this.search()
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/paymentothers?page='+newval,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        program: this.program,
                        advisor: this.advisor
                }).then(response => {
                    if(response.status == 200){
                        this.others = response.data.data                
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;                    
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/paymentothers?page='+next,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        program: this.program,
                        advisor: this.advisor
                }).then(response => {
                    if(response.status == 200){
                        this.others = response.data.data                                     
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                  
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },
            search(){
                axios.post('/api/paymentothers',{
                    text: this.campo1,
                    from: this.from,
                    to: this.to,
                    program: this.program,
                    advisor: this.advisor
                })
                .then(response => {
                    if(response.status == 200){                    
                        this.others = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;                        
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
            allPrograms(){
                axios.get('/api/programs')
                    .then(response => {
                        this.programs =  response.data 
                    });
            },
            programAdvisor(){
                axios.post('/api/usermodule/2',{
                    roles : '[3]',
                    type: 0
                }).then(response => {
                    this.advisors = response.data;
                })
            },
        },
        created() {
            this.search();
            this.allPrograms();
            this.programAdvisor()
        },
    }
</script>