<template>
    <div id="loading">
        <div class="container">
            <div class="row ">
                <nav>
                    <div class="nav nav-tabs nav-program" id="nav-tab" role="tablist" style="height: 35px;">
                        <a style="text-align: center;margin: 0px 3px;border-radius: 5px 5px 0px 0px;" :href="'/administration/payment'" class="nav-item nav-link " id="nav-home-tab" >Automatic</a>
                        <a style="text-align: center;margin: 0px 3px;border-radius: 5px 5px 0px 0px;" :href="'/administration/payment/manual'" class="nav-item nav-link " id="nav-home-tab"  aria-controls="profile" aria-selected="false">Manual</a>
                        <a style="text-align: center;margin: 0px 3px;border-radius: 5px 5px 0px 0px;" :href="'/administration/payment/others'"  class="nav-item nav-link active" id="nav-home-tab"  aria-controls="contact" aria-selected="false">Others</a>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent" style="margin-top: -2px;width: 100%;">
                    <!--<div class="tab-pane fade show active" id="automatic" role="tabpanel" aria-labelledby="home-tab" style="border: 2px solid #afafaf;background:#ffffff">
                        <form-automatic :global="this.global"></form-automatic>
                    </div>
                    <div class="tab-pane fade show active" id="manual" role="tabpanel" aria-labelledby="profile-tab" style="border: 2px solid #afafaf;background:#ffffff">
                        <form-manual :global="this.global"></form-manual>
                    </div>-->
                    <div class="tab-pane fade show active" id="others" role="tabpanel" aria-labelledby="contact-tab" style="border: 2px solid #afafaf;background:#ffffff">
                        <form-others :global="this.global" :type="this.type"></form-others>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {        
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            
        },
        created() {
        },
    }
</script>