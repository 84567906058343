<template lang="pug">
    div(style="margin: 0px 6px;")
        .cont-title
            .row
                .col-lg-6
                    span Process
                .col-lg-6
                    button.btn.class-button-red(@click="returnList") #[i.fas.fa-caret-left(style="margin-right:5px")] RETURN
        .cont-search-paginate
            .row
                .col-lg-5
                    .row
                        .col-lg-8
                            .form-group
                                input.input-form( type="text" v-model="account" ref="file" name="file" placeholder="#Account" style="margin-bottom: 5px;")
                        .col-lg-4
                            .form-group
                                button.btn.class-button-green(@click="getcard") CONTINUE #[i.fas.fa-caret-right(style="margin-left: 10px;")] 
                    .row(v-if="firstaccount")
                        .col-lg-6
                            .form-group
                                div
                                    label Client
                                    input.input-form(type="text" v-model="client" disabled)
                        .col-lg-6
                            .form-group
                                div
                                    label Program
                                    input.input-form(type="text" v-model="program" disabled)
                        .col-lg-6
                            .form-group
                                div
                                    label Advisor
                                    input.input-form(type="text" v-model="advisor" disabled)
                        .col-lg-6
                            .form-group
                                div
                                    label Merchant #[span(style="color:red" v-if="errorMerchant") Required ]
                                    .row                                        
                                        .col-md-3
                                            input(type="radio" value="1" name="merchant" v-model="merchant" id="amg")
                                            label(for="amg") AMG
                        .col-lg-6
                            .form-group
                                div
                                    label Amount #[span(style="color:red" v-if="errorAmmount") Required ]  
                                    input.input-form(type="text" placeholder="$" v-model="amount" :id="'campo'+2" v-on:blur="validMounthly(2)" @keypress="justNumbers" )
                        .col-lg-12
                            .form-group
                                div
                                    button.btn.btn-color-green(@click="submitAutorize" style="font-size: 13px;padding: 6px;height: 35px;") SUBMIT
                            .col-lg-12.col-md-12(v-if="errosList")
                                .form-group
                                    span(v-if="responseCode == 1") Approved
                                    span(v-if="responseCode == 2") Declined
                                    span(v-if="responseCode == 3") Error
                                    span(v-if="responseCode == 4") Held for Review
                                    ul
                                        li(v-for="(items , index) in errosAutorize" :key="index") {{items.errorText}}
                            .col-lg-12.col-md-12(v-if="messageList")
                                .form-group
                                    span(v-if="responseCode == 1") Approved
                                    span(v-if="responseCode == 2") Declined
                                    span(v-if="responseCode == 3") Error
                                    span(v-if="responseCode == 4") Held for Review
                                    ul
                                        li(v-for="(items , index) in messageAutorize" :key="index") {{items.description}}
                .col-lg-7(v-if="firstaccount")                    
                    .col-lg-12.col-md-12
                        span(style="color:red" v-if="errorCard") Required
                        table.table.table-hover.table-striped
                            tbody(style="border: 1px solid #ccc;")
                                tr(style="background: #e7e7e7 !important;color: #908615 !important;")
                                    th 
                                    th Card Holder Name
                                    th Card Number
                                    th MM
                                    th YY
                                    th CVC
                                tr(v-for="card in cards" :key="card.id" v-show="card.cardnumber != null")
                                    td 
                                        input(type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id")
                                    td(style="color: #9b9b9b;text-transform: capitalize") {{card.cardholdername}}
                                    td(style="color: #9b9b9b;") {{'XXXX-XXXX-XXXX-'+card.cardnumber}}
                                    td(style="color: #9b9b9b;") {{card.card_expi_month}}
                                    td(style="color: #9b9b9b;") {{card.card_expi_year}}
                                    td(style="color: #9b9b9b;") {{ card.cardsecuritycode != null ?  card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3) : ''}}</td>
                    .col-lg-12.col-md-12(style="text-align: right" )
                        a(@click="createcard" class="btn class-button-green" style="width:80px;color:white" ) #[img(:src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;" ) ] ADD

        modal-card-create(v-if="createmodal" :idlead="this.lead_id" @new="addCard" @click="closedModalCar")
</template>

<style lang="stylus">
    .color-yellow
        background : #FF9800 !important
        color: black
    .color-green-text
        color: green
    .color-red-text
        color: red
</style>


<script>
    export default {
        props:['global','type'],
        data() {
            return {
                lead_id: '',
                dataExcel:[],
                merchant: '',
                cards:[],
                createmodal: false,
                account : '',
                client : '',
                program : '',
                advisor : '',
                card_id : '',
                amount: '',
                errosAutorize: [],
                messageAutorize: [],
                errosList: false,
                messageList: false,
                responseCode: '',
                errorMerchant: false,
                errorAmmount: false,
                errorCard: false,
                firstaccount: false
            }
        },
        mounted() {
            //console.log(this.global)
        },
        methods: {
            validMounthly(id){
                this.amount = this.globalFunction(id)        
            },
            globalFunction(id){
                var x = document.getElementById('campo'+id).value
                if(x.length>0){
                    if(x.indexOf('.') != -1){ 
                        var numw = x.replace(/,/gi, "");
                        var dec = numw.split('.')
                        var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                        num = num+'.'+dec[1]
                        
                    }else{
                        var numw = x.replace(/,/gi, "");
                        var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                        num = num+'.00'
                    }
                    return num
                }else{
                    return x
                }
            },
            justNumbers: function (event) {
                var charCode = window.event ? event.which : event.keyCode;
                var RE = /^\d*(\.\d{1})?\d{0,1}$/;
                if (charCode != 46 && charCode > 31 
                && (charCode < 48 || charCode > 57)) {
                    //Usando la definición del DOM level 2, "return" NO funciona.
                    event.preventDefault();
                }
            },
            returnList(){
                this.$emit('clickList',true)
            },
            addCard(cards) {
                this.cards = cards;
            },
            createcard(){
                this.createmodal = true;
                var boton = document.getElementById('app');
                boton.classList.add('preloader-task');
            },
            closedModalCar(click){
                this.createmodal = false
                var boton = document.getElementById('app');
                boton.classList.remove('preloader-task');
            },
            processImport(){
                this.formData = new FormData();
                this.formData.append('file', this.$refs.file.files[0]);
                this.formData.append('merchant',this.merchant);
                this.dataExcel = []
                var boton = document.getElementById('app');
                boton.classList.add('preloader');
                axios.post('/importar', this.formData ,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.status == 200){
                        axios.post('/api/exceldetail',{
                            id: this.global.layout.id
                        }).then(response => {
                            this.dataExcel = response.data                            
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        })
                    }
                })
            },
            getcard(){
                var boton = document.getElementById('app');
                boton.classList.add('preloader');
                axios.post('/api/getcard',{
                    account: this.account
                }).then(response => {
                    if(response.status == 200 ){
                        this.firstaccount = true
                        this.cards = response.data
                        if (this.cards.length > 0) {
                            this.client = this.cards[0].client_name
                            this.program = this.cards[0].program
                            this.advisor = this.cards[0].advisor_name
                            this.lead_id = this.cards[0].lead_id
                        }else{
                            this.client = ''
                            this.program = ''
                            this.advisor = ''
                            this.lead_id = ''
                        }
                    }                                        
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                })
            },
            submitAutorize(){
                if(this.merchant == '' && this.amount == '' && this.card_id == ''){
                    this.errorMerchant = true
                    this.errorAmmount =  true
                    this.errorCard = true
                }else if(this.merchant == '' && this.amount == '' && this.card_id != ''){
                    this.errorMerchant = true
                    this.errorAmmount =  true
                    this.errorCard = false
                }else if(this.merchant == '' && this.amount != '' && this.card_id == ''){
                    this.errorMerchant = true
                    this.errorAmmount =  false
                    this.errorCard = true
                }else if(this.merchant == '' && this.amount != '' && this.card_id != ''){
                    this.errorMerchant = true
                    this.errorAmmount =  false
                    this.errorCard = false
                }else if(this.merchant != '' && this.amount == '' && this.card_id == ''){
                    this.errorMerchant = false
                    this.errorAmmount =  true
                    this.errorCard = true
                }else if(this.merchant != '' && this.amount != '' && this.card_id == ''){
                    this.errorMerchant = false
                    this.errorAmmount =  false
                    this.errorCard = true
                }else if(this.merchant != '' && this.amount == '' && this.card_id != ''){
                    this.errorMerchant = false
                    this.errorAmmount =  true
                    this.errorCard = false
                }else if(this.merchant != '' && this.amount != '' && this.card_id != ''){
                    this.errorMerchant = false
                    this.errorAmmount =  false
                    this.errorCard = false                
                    this.axiosEnv()
                }
            },
            axiosEnv(){            
            swal.fire({
                title: 'ARE YOU SURE CONTINUE?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/checkout',{
                            idcard: this.card_id,
                            account: this.account,
                            amount: this.amount,
                            merchant: this.merchant,
                            idsession : this.global.layout.id
                        })
                        .then(response => {
                            if(response.status == 200 && response.data.status == 200) {
                                this.messageAutorize = response.data.transaction.messages.message
                                this.responseCode = response.data.transaction.responseCode 
                                this.messageList = true
                                this.errosList = false
                                var datanew = ''
                                response.data.transaction.messages.message.map(index => {
                                    datanew = datanew + index.description+'<br>'
                                }),
                                swal.fire({
                                    type: 'success',
                                    title: response.data.transaction.responseCode == 1 ? 'Approbre' : 'Declined',
                                    html: datanew
                                }).then( (res) => {
                                    if(res){
                                        this.$emit('clickList',true)
                                        }
                                    });
                                /*swal.fire({
                                        type: 'success',
                                        title: 'Register Payment Approve'
                                    }).then( (res) => {
                                        if(res){
                                            this.$emit('clickList',true)                                    
                                            setTimeout(() => {
                                                var boton = document.getElementById('app');
                                                boton.classList.remove('preloader')
                                            }, 50); 
                                        }
                                    });     */                   
                            }else if(response.status == 200 && response.data.status == 500){
                                if(response.data.transaction.errors){
                                    this.errosAutorize = response.data.transaction.errors.error
                                    this.responseCode = response.data.transaction.responseCode
                                    this.messageList = false
                                    this.errosList = true
                                    //this.$emit('clickList',true)
                                    var datanew = ''
                                    response.data.transaction.errors.error.map(index => {
                                        datanew = datanew + index.errorText+'<br>'
                                    }),
                                    swal.fire({
                                        type: 'error',
                                        title: 'Error',
                                        html: datanew
                                    }).then( (res) => {
                                        if(res){
                                            //this.$emit('clickList',true)
                                            var x = document.getElementById('app')
                                            x.classList.remove('preloader')
                                            }
                                        });
                                }else{
                                    this.messageAutorize = response.data.transaction.messages.message
                                    this.responseCode = response.data.transaction.responseCode 
                                    this.messageList = true
                                    this.errosList = false
                                    //this.$emit('clickList',true)
                                    var datanew = ''
                                    response.data.transaction.messages.message.map(index => {
                                        datanew = datanew + index.description+'<br>'
                                    }),
                                    swal.fire({
                                        type: 'success',
                                        title: response.data.transaction.responseCode == 1 ? 'Approbre' : 'Declined',
                                        html: datanew
                                    }).then( (res) => {
                                        if(res){
                                            this.$emit('clickList',true)
                                            }
                                        });
                                }
                            }
                        }).catch(error => {
                            if(error.response.status == 500 ) {
                                this.errosAutorize = response.data.transaction.errors.error
                                this.responseCode = response.data.transaction.responseCode
                                this.errosList = true
                            }
                        })
                    }else{
                        var x = document.getElementById('app')
                        x.classList.remove('preloader')
                    }
                });
            }
        },
        created() {            
        },
    }
</script>