<template>
    <div id="loading">
        <form @submit.prevent="updateUser()">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Edit User</span>
                    </div>
                    <div class="col-lg-6" style="text-align:right">
                        <a href="/users" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;"><i class="far fa-times-circle"></i> CANCEL</a>
                        <button v-show="updateUser" type="submit" class="btn btn-color-green" id="update" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="far fa-save"></i> UPDATE</button>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-12">
                                <div style="border: 2px solid #BAA345;padding: 20px;border-radius: 15px;">
                                    <span class="title-modal">User Information</span>
                                    <div class="row" style="padding: 20px">
                                        <div class="col-lg-4">
                                            <div>
                                                <div v-if="form.image">
                                                    <div v-if="url && form.image">
                                                        <img :src="url"  width="90" style="display:block">
                                                        <img :src="'/'+form.image" width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/'+form.image" width="90">
                                                    </div>
                                                </div>
                                                <div v-else>                                                                                                         
                                                    <div v-if="url">
                                                        <img :src="url"  width="90" style="display:block">
                                                        <img :src="'/images/avatar.png'" width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/images/avatar.png'" width="90">
                                                    </div>
                                                </div>
                                                <div class="vld-parent">
                                                    <loading :active.sync="isLoading"></loading>
                                                </div>
                                                <div class="file-field">
                                                    <div class="btn btn-sm float-left">
                                                        <span></span>
                                                        <input  name="image" type="file" @change="onImageChange" v-if="uploadReady">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">First Name</label>
                                                            <input v-model="form.first_name" type="text" name="first_name" ref="firstname"  
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                                            <has-error :form="form" field="first_name"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Last Name</label>
                                                            <input v-model="form.last_name" type="text" name="last_name"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                                            <has-error :form="form" field="last_name"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Phone Number</label>
                                                            <input v-model="form.phone" name="phone" id="phone"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('phone') }" maxlength="12">
                                                            <has-error :form="form" field="phone"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Cellphone</label>
                                                            <input v-model="form.cellphone" name="cellphone" id="cellphone"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('cellphone') }" maxlength="12">
                                                            <has-error :form="form" field="cellphone"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label>Date of Birth</label>
                                                            <kendo-datepicker
                                                                :format="'MM/dd/yyyy'"
                                                                v-model="form.date_of_birth"
                                                                class="input-form"
                                                                :class="{ 'is-invalid': form.errors.has('date_of_birth') }"
                                                                name="date_of_birth" id="date_of_birth">
                                                            </kendo-datepicker>
                                                            <has-error :form="form" field="date_of_birth"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label>Country</label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <input type="radio" name="country" id="PE" value="PE" v-model="form.country">
                                                                    <label for="PE">PERU</label>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <input type="radio" name="country" id="US" value="US" v-model="form.country">
                                                                    <label for="US">UNITED STATES</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-8">
                                <div >
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div style="border: 2px solid #BAA345;padding: 20px;border-radius: 15px;">
                                                <span class="title-modal">User Access</span>
                                                <div class="row" style="padding: 20px">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="form-group col-lg-6">
                                                                <label>Email (User Name)</label>
                                                                <input disabled v-model="form.email" type="email" name="email"
                                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('email') }">
                                                                <has-error :form="form" field="email"></has-error>
                                                            </div>
                                                            <div class="form-group col-lg-6">
                                                                <label>Password (Must have at least one capital letter and a number) </label>
                                                                <input v-model="form.password" type="password" name="password" id="password"
                                                                class="input-form" :class="{ 'is-invalid': form.errors.has('password') }"
                                                                v-on:keyup="keymonitor">
                                                                <p v-if="validate == true" style="color:green">correct password</p>
                                                                <p v-else style="color:red">Unmodified Password or Incorrect password</p>
                                                                <has-error :form="form" field="password"></has-error>
                                                                <em v-show="editmode">(leave empty if not changing)</em>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3">
                                                                <label>
                                                                    Modules
                                                                </label>
                                                                <ul style="list-style:circle">
                                                                    <li v-for="(module, index) in modulesUser" :key="index"> {{module.name}} </li>
                                                                </ul>
                                                            </div>
                                                            <div class="col-lg-3" v-if="this.form.super == 2 || this.form.super == 1  ? agregados=false : agregados=true">
                                                                <div>
                                                                    <input value ="1" name="admin" v-model="form.admin" class="form-check-input" type="radio" id="admin">
                                                                    <label class="form-check-label" for="admin" @click="adminrols">
                                                                        Administrador
                                                                    </label>                                                                    
                                                                </div>
                                                                <div>
                                                                    <input value="1" name="admin" v-model="form.others" class="form-check-input" type="radio" id="others">
                                                                    <label class="form-check-label" for="others" @click="showrols">
                                                                        Others
                                                                    </label>                                                                  
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8" v-if="form.rolenew != 1">
                                                                <div class="row">
                                                                    <div class="col-lg-6" >
                                                                        <label>Select Rol</label>  
                                                                        <select name="role" v-model="form.rolenew" @change="handleChangeRol" id="role" class="input-form" :class="{ 'is-invalid': form.errors.has('role') }">
                                                                            <option :value="null" disabled>Select User Role</option>
                                                                            <option :selected="rol.id == form.rolenew" v-show="rol.id != 1" v-for="rol in rolesnews" :value="rol.id" :key="rol.id">{{ rol.name }}</option>
                                                                        </select>
                                                                        <has-error :form="form" field="role"></has-error>
                                                                        <div class="row" v-if="form.rolenew == 5" style="padding-top: 10px;">
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="junior" value="0" v-model="form.typesenior">
                                                                                <label for="junior">Junior</label>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="senior" value="1" v-model="form.typesenior">
                                                                                <label for="senior">Senior</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div  style="height: 100%;">
                                    <div class="row" style="height: 100%">
                                        <div class="col-lg-12">
                                            <div style="border: 2px solid #BAA345;padding: 20px;height: 100%;border-radius: 15px;">
                                                <span class="title-modal" >Others</span>
                                                <div class="row" style="padding: 20px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <input name="restriction_by_ip" v-model="form.restriction_by_ip" class="form-check-input" type="checkbox" id="restriction_by_ip">
                                                                <label class="form-check-label" for="restriction_by_ip">
                                                                    This user having restriction ip
                                                                </label>                                                              
                                                            </div>
                                                            <div class="col-lg-12">                                                                
                                                                <input name="status" v-model="form.status" class="form-check-input" type="checkbox" id="status">
                                                                <label class="form-check-label" for="status">
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
    // Import component
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props:['global'],
        data() {
            return {
                viewrols:false,
                validate : false,
                notvalidate : true,   
                isLoading: false,           
                uploadReady: true,
                editmode: false,
                users : {},
                roles: {},
                rolesnews: {},
                modules : {},
                url:null,
                form: new Form({
                    id:this.global.user.id,
                    first_name : this.global.user.first_name,
                    last_name: this.global.user.last_name,
                    email: this.global.user.email,
                    country: this.global.user.country,
                    password: this.global.user.password,
                    phone: this.global.user.phone,
                    cellphone: this.global.user.cellphone,
                    address: this.global.user.address,
                    date_of_birth: this.global.user.date_of_birth,
                    restriction_by_ip: this.global.user.restriction_by_ip == 0 ? false : true,
                    status: this.global.user.status == 0 ? false : true,
                    module_role:this.global.user.visualmodule,
                    file:this.global.user.file,
                    image:this.global.user.image,
                    super:this.global.layout.role_id,
                    superid:this.global.layout.id,
                    rolenew:this.global.user.role_id,
                    nrestriction_by_ip:'',
                    nstatus:'',
                    typesenior: this.global.user.typesenior,
                }),
                modulesUser:this.global.user.modules,
                dataccess: true,
                visualmodule: [],
                name_module:'',
                name_role:'',
                role: '',
                module: '',
                arrayeditRol: [],
                arrayeditModule: [],
                arrayidRol: [],
                arrayidModule: [],
                search:''
            }
        },
        mounted() {
            setTimeout(() => {
                var boton = document.getElementById('app')
                boton.classList.remove('preloader')
            }, 50); 
        },
        components: {
            Loading
        },
        methods: {
            keymonitor: function() {
                var x = document.getElementById("password").value;
                var passw=  /^(?=.*[0-9])[A-Za-z]\w{6,15}$/;
                if(x.match(passw)){
                    //console.log('igual')
                    this.validate = true
                    this.notvalidate = false
                }else{
                    //console.log('no es igual')
                    this.validate = false
                    this.notvalidate = false
                }
            },
            clear () {
                this.uploadReady = false
                this.url = false
                this.$nextTick(() => {
                    this.uploadReady = true
                    this.url = false
                })
            },
            onImageChange(e){
                //console.log(e.target.files[0])
                this.$Progress.start();
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                fileReader.onload = (e) => {
                    this.form.file = e.target.result
                }
                const file = e.target.files[0];
               /* this.url = URL.createObjectURL(file);*/
                this.url=URL.createObjectURL(file);

                this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false
                },50)

                //console.log(this.url);
            },
            getResults(page = 1) {
                axios.get('api/user?page=' + page)
                    .then(response => {
                        this.users = response.data;
                    });
                },
            updateUser(){

                if(this.form.restriction_by_ip == false){
                    this.form.nrestriction_by_ip = '0';
                }else{
                    this.form.nrestriction_by_ip = '1';
                }

                if(this.form.status == false){
                    this.form.nstatus = '0';
                }else{
                    this.form.nstatus = '1';
                }
                
                this.$Progress.start();
                let button = $('.update');
                var boton = document.getElementById('app');
                var error = boton.getElementsByClassName('is-invalid')

                setTimeout(function(){                    
                    if(error.length > 0){                    
                        button.attr('disabled',false);
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                },1000)

                if (error.length == 0){
                    button.attr('disabled',true);
                    boton.classList.add('preloader')
                }  
                
                this.form.post('/api/userupdate',this.form)
                .then( function(response) {
                    if(response.status == 200){
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        button.attr('disabled',true);
                        Fire.$emit('AfterCreate');
                        swal.fire({
                            type: 'success',
                            title: 'User edit in successfully'
                            }).then( (res) => {
                                if(res){
                                    setTimeout(() => {
                                        var boton = document.getElementById('app');
                                        boton.classList.remove('preloader')
                                    }, 50); 
                                    window.location.href = '/users';
                                    }
                                });
                    }
                })
                .catch(() => {
                });
            },
            editModal(user){
                this.editmode = true;
                this.form.reset();  
                $('#addNew').modal('show');
                this.form.fill(user);
                this.dataccess = true;
                 this.arrayeditModule = [];
                 this.arrayeditRol = [];
                 this.visualmodule = [];
                 this.form.module_role = [];

                    if(user.roles.length > 0)
                    {
                        for(let a=0; a<user.module.length; a++) {
                            this.arrayeditModule.push( user.module[a].name);
                             this.arrayidModule.push( user.module[a].id);
                        }

                          for(let b=0; b<user.roles.length; b++) {
                                    this.arrayeditRol.push( user.roles[b].name);
                                         this.arrayidRol.push( user.roles[b].id);
                                }

                                for(let c=0;c<this.arrayeditRol.length;c++)
                                {
                                    this.visualmodule.push({'module' : this.arrayeditModule[c], 'role' :  this.arrayeditRol[c]});
                                     this.form.module_role.push({'module' : this.arrayidModule[c], 'namerol' :  this.arrayidRol[c]})
                                }
                    }else{
                         this.dataccess = false;
                    }

            },
            loadRoles() {
                axios.get("/api/role").then( ({data}) => (this.roles = data));
            },
            loadModules() {
                axios.get("/api/module").then( ({data}) => (this.modules = data));
            },
            loadNewrols() {
                if(this.modulesUser.length == 1){
                    var module = this.modulesUser[0].id
                }else{
                    var module = this.modulesUser[1].id
                }
                axios.post("/api/rolesnews",
                { 
                    id : module
                }).then( ({data}) => (this.rolesnews = data));
            },
            addItem() {
             
               if(this.form.role != undefined && this.form.module != undefined){
                    this.form.module_role.push({'module' : this.form.module, 'namerol': this.form.role});
                    this.visualmodule.push({'module' : this.name_module, 'role' :  this.name_role});                   
                    this.dataccess = true;                    
                    this.module = '';
                    this.role = '';
               }
           
            },
             handleChange(e) {
                if(e.target.options.selectedIndex > -1) {
                    //console.log(e.target.options[e.target.options.selectedIndex].dataset.namemod);
                    this.name_module = e.target.options[e.target.options.selectedIndex].dataset.namemod;
                     
                }
            },
            handleChangeRol(e) {
                if(e.target.options.selectedIndex > -1) {
                  this.name_role = e.target.options[e.target.options.selectedIndex].dataset.namerol;
                }
            },
             removeItem(index) {
                this.form.module_role.splice(index, 1);
                this.visualmodule.splice(index, 1);
                this.dataccess = true;
                this.module = '';
                this.role = '';
                if(this.visualmodule.length == 0){
                        this.dataccess = false;
                    }else{
                        this.dataccess = true;
                    }
              //console.log(this.$el.target.options[this.$el.target.options.selectedIndex].da);
            },
        },
        created() {
           this.loadRoles();
           this.loadModules();
           this.loadNewrols();
          
          Fire.$on('AfterCreate',() => {
                this.loadRoles();
                this.loadModules();
                this.loadNewrols();
           });
        //    setInterval(() => this.loadUsers(), 3000);
        },
        
    }
</script>


<style>
    
   
</style>
