<template>
    <div id="loading">
        <div v-if="grilla">
            <form-migration-grilla :type="this.type" :global="this.global" @createClient="createOpen"></form-migration-grilla>
        </div>
        <div v-if="create">
            <form-migration-create :global="this.global" @clickgrilla="opengrilla"></form-migration-create>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                grilla: true,
                create: false,
                account: false
            }
        },
        methods: {
            createOpen(dato){
                this.grilla = false
                this.create = true
            },
            opengrilla(dato){
                this.grilla = true
                this.create = false
            }
        },
        created() {
            
        },
    }
</script>