<template lang="pug">
    .modal-event.estilo-modal.box-login(style="width:1000px")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 TRACKING
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            br
                            br
                            .col-lg-12.col-md-12
                                .form-group(:style="JSON.parse(trackings) != null ? JSON.parse(trackings).length >= 4 ? 'height: 370px;overflow: auto;' : '' : ''")
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th Date
                                                th User
                                                th Action
                                                th Observation
                                            tr(v-for="(tracking,index) in JSON.parse(trackings)" :key="index")
                                                td {{tracking.date | myGlobalDay}}                                                
                                                td {{tracking.user_name}}
                                                td #[span( :style=" tracking.type == 'send' ? 'text-transform: capitalize;color:#00a3ff' : tracking.type == 'approved' ? 'text-transform: capitalize;color:#00cc00' : 'text-transform: capitalize;color:#ff0000'") {{tracking.type}}]
                                                td {{tracking.comments}}
            .modal-footer        

</template>


<script>
export default {
    props:['trackings' ,'nameProgram' ,'nameClient'],
    data() {
        return {
            program: this.nameProgram,
            client_name:this.nameClient,
        }
    },
    mounted() {        

    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        }
    },
    created() {
        
    },
}
</script>