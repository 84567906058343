<template>
    <div>
        <div id="loading">
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Sales Made</span>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalleads : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalleads }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right;display:inline-flex">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                                    <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Captured By</label>
                                        <select v-model="captured" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in captureds" :key="index" :value="item.id">{{ item.user_name }}</option>
                                        </select>
                                    </div>
                                    <div v-if="this.global.layout.role_id != 5" class="form-group" style="margin: 0px 5px;">
                                        <label for="">Seller</label>
                                        <select v-model="seller" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in sellers" :key="index" :value="item.id">{{ item.user_name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Status</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="1" v-if="type == 0">Pending</option>
                                            <option value="2">Revision</option>
                                            <option value="3">Desaprove</option>
                                            <option value="4">Approved</option>                                                    
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Programs</label>
                                        <select v-model="program" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Initial Payment</label>
                                        <select v-model="statusip" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="1">YES</option>
                                            <option value="2">NO</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th style="text-decoration: underline;">
                                            <span v-if="oneEventLead" @click="search('asc',1)" style=" cursor: pointer;">Client </span>
                                            <span v-if="orderLeadAsc" @click="search('desc',1)" style=" cursor: pointer;">Client <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderLeadDesc" @click="search('asc',1)" style=" cursor: pointer;">Client <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Captured By</th>
                                        <th>Seller</th>
                                        <th>Program</th>
                                        <th>Fee ($)</th>
                                        <th title="Notes">NT</th>
                                        <th title="Initial Payment">IP</th>
                                        <!-- <th></th> -->
                                        <th title="Contract Fee">CF</th>
                                        <th title="Agreement">AG</th>
                                        <th title="Traking">TK</th>
                                        <th title="Comissions Captured">CMC</th>
                                        <th title="Comissions Seller">CMS</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                        <th style="text-decoration: underline;">
                                            <span v-if="oneDateLead" @click="search('asc',11)" style=" cursor: pointer;">Created </span>
                                            <span v-if="orderDateAsc" @click="search('desc',11)" style=" cursor: pointer;">Created <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderDateDesc" @click="search('asc',11)" style=" cursor: pointer;">Created <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th style="text-decoration: underline;">
                                            <span v-if="onderApprovedLead" @click="search('asc',12)" style=" cursor: pointer;">Approved </span>
                                            <span v-if="orderApprovedAsc" @click="search('desc',12)" style=" cursor: pointer;">Approved <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderApprovedDesc" @click="search('asc',12)" style=" cursor: pointer;">Approved <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                    </tr>
                                    <tr v-for="(sales , index) in salemades" :key="index" v-show="type == 1 ? sales.status != 1 : sales.status">
                                       <td class="blue-color" style="text-transform: capitalize">
                                           <a v-if="type == 0" :href="'/crm/leads/edit/'+ sales.lead_id" >{{sales.client}}</a>
                                           <a v-else :href="'/administration/clients/edit/'+ sales.lead_id" >{{sales.client}}</a>
                                        </td> 
                                       <td class="gris-color">{{sales.captured}}</td> 
                                       <td class="gris-color">{{sales.seller}}</td> 
                                       <td class="gris-color">{{sales.program}}</td> 
                                       <td class="gris-color color-grees1" style="background:#f1f1f1">$ {{sales.fee}}</td> 
                                       <td>
                                           <img title="Notes" v-if="sales.notes_status == 0" :src="'/images/icons/CRM--notes-OFF.ico'" alt=""  style="cursor:pointer" @click="modalNotas(sales.id,index,sales.user_id,sales.status,sales.sourcesname,sales.pack)">
                                           <img title="Notes" v-else :src="'/images/icons/CRM--notes-ON.ico'" alt=""  style="cursor:pointer" @click="modalNotas(sales.id,index,sales.user_id,sales.status,sales.sourcesname,sales.pack)">
                                        </td>
                                        <td>
                                           <img title="Initial Payment" v-if="sales.initial_payment_status == 1" :src="'/images/icons/CRM--initialpayment-OFF.ico'" alt=""  style="cursor:pointer" @click="modalPayment(sales.id,index,sales.user_id,sales.status,sales.initial_payment_status)">
                                           <img title="Initial Payment" v-else :src="'/images/icons/CRM--initialpayment-ON.ico'" alt=""  style="cursor:pointer" @click="modalPayment(sales.id,index,sales.user_id,sales.status,sales.initial_payment_status)">
                                        </td>
                                        <!-- <td>{{sales.initial_amount?'$ '+sales.initial_amount:'' }}</td> -->
                                        <td>
                                           <img title="Contract Fee" v-if="sales.contract_fee_status == 0" :src="'/images/icons/CRM--contractfee-OFF.ico'" alt=""  style="cursor:pointer" @click="modalContract(sales.id,index,sales.user_id,sales.status,sales.initial_payment_status)">
                                           <img title="Contract Fee" v-if="sales.contract_fee_status == 1" :src="'/images/icons/CRM--contractfee-ON.ico'" alt=""  style="cursor:pointer" @click="modalContract(sales.id,index,sales.user_id,sales.status,sales.initial_payment_status)">
                                           <img title="Contract Fee" v-if="sales.contract_fee_status == 2" :src="'/images/icons/CRM--contractfee-red.ico'" alt=""  style="cursor:pointer" @click="modalContract(sales.id,index,sales.user_id,sales.status,sales.initial_payment_status)">
                                        </td>
                                        <td>
                                            <a title="Agreement" v-if="sales.program_id == 3" :href="'/imprimir/?id='+sales.id"><img :src="'/images/icons/CRM--agreement.ico'" alt=""  style="cursor:pointer"></a>
                                        </td>
                                       <td>
                                           <img title="Traking" :src="'/images/icons/CRM--tracking.ico'" alt=""  style="cursor:pointer" @click="modalTraking(index)">
                                        </td> 
                                       <td class="gris-color color-grees1" style="background:#f1f1f1">                                           
                                           <span v-for="(item, index) in JSON.parse(sales.commission)" :key="index">{{ item.type == 'capture' ? '$ '+item.commission  :  '' }}</span>
                                        </td>
                                        <td class="gris-color color-grees1" style="background:#f1f1f1">
                                           <span v-for="(item, index) in JSON.parse(sales.commission)" :key="index">{{ item.type == 'sale' ? '$ '+item.commission : '' }}</span>
                                        </td>
                                       <td style="text-align: left;">
                                           <span v-if="sales.status == 1 && type == 0" style="color: #E09900;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">Pending </span> 
                                           <span v-if="sales.status == 2" style="color:#00A3FF;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">Underview </span>
                                           <span v-if="sales.status == 3" style="color:#FF0000;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">Disaproved </span>
                                           <span v-if="sales.status == 4" style="color:#00CC00;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;">Approved</span>
                                        </td>
                                        <td>
                                            <button v-if="sales.status == 1 && type == 0 && sessionId == sales.user_id && sales.contract_fee_status == 1 && sales.notes_status == 1 && sales.initial_payment_status == 2" class="btn btn-color-green" @click="revisionSale(index,2,sales.initial_payment_status,sales.account)" style="width: 130px;text-transform:uppercase">Revission <i class="fas fa-angle-double-right"></i></button>
                                            <button v-if="type == 1 && sales.status == 2 " class="btn btn-color-blue" @click="revisionSale(index,4,sales.initial_payment_status,sales.account)" style="margin-bottom: 3px;text-transform:uppercase">Approve</button><br v-if="type == 1 && sales.status == 2"><button v-if="type == 1 && sales.status == 2" class="btn btn-color-red" @click="revisionSale(index,3,sales.initial_payment_status,sales.account)" style="text-transform:uppercase">Disaproved</button>  
                                            <button v-if="sales.status == 3 && type == 0 && sessionId == sales.user_id && sales.contract_fee_status == 1 && sales.notes_status == 1 && sales.initial_payment_status == 2" class="btn btn-color-red" @click="revisionSale(index,2,sales.initial_payment_status,sales.account)" style="width: 130px;text-transform:uppercase">Revission</button>
                                        </td> 
                                       <td class="gris-color">{{sales.creates | myGlobal}}</td>
                                       <td style="font-size: 10px;" v-if="sales.status == 4" class="text-no" > <span class="color-grees1" style=" background: #FFFF35; padding:8px 6px;">{{sales.approved | myGlobal}}</span></td>
                                       <td v-else> - </td>
                                    </tr>
                                    <tr style="background:#f1f1f1">
                                        <td colspan="3" style="padding:5px">
                                            <div class="cont-search-paginate" style="padding:0px">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div>
                                                            <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalleads : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalleads }} </span>
                                                            <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                                                <i class="fas fa-caret-left"></i>
                                                            </button>
                                                            <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                                                <i class="fas fa-caret-right"></i>
                                                            </button>                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td colspan="2" style="background: #666666;color: #fff;padding:5px">
                                            <div class="row" style="margin-top:7px">
                                                <div class="col-lg-6">
                                                    <span>Total Fee</span>
                                                </div>
                                                <div class="col-lg-6" style="padding-right: 40px">
                                                    <span style="color: #666666;background: #fff;padding: 5px 10px;border-radius: 5px;">${{ salemades[0] != null ? salemades[0].tfee : '-'}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colspan="1" style="background: #666666;color: #fff;padding:5px"></td>
                                        <td colspan="1">
                                            <!--<div class="row" style="margin-top:7px">
                                                <div class="col-lg-6">
                                                    <span>Total IP</span>
                                                </div>
                                                <div class="col-lg-6" style="padding-right: 40px">
                                                    <span style="color: #666666;background: #fff;padding: 5px 10px;border-radius: 5px;">${{ salemades[0] != null ? salemades[0].tip : '-'}}</span>
                                                </div>
                                            </div> -->
                                        </td>                                        
                                        <td colspan="5" style="background: #666666;color: #fff;padding:5px">
                                            <div class="row" style="margin-top:5px">
                                                <div class="col-lg-6">
                                                    <span>Total Cm.</span>
                                                </div>
                                                <div class="col-lg-6" style="display:inline-flex;padding-left: 0;padding-right: 45px;">
                                                    <span style="color: #666666;background: #fff;padding: 2px 10px;border-radius: 5px;margin-right: 5px;">${{ salemades[0] != null ? salemades[0].tcmc : '-'}}</span>
                                                    <span style="color: #666666;background: #fff;padding: 2px 10px;border-radius: 5px;">${{ salemades[0] != null ? salemades[0].tcms : '-'}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td colspan="1"  style="background: #666666;color: #fff;padding:5px"></td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        <modal-comissions v-if="openComissions" @click="closeComissions" v-bind:comissions="this.comissions"       :nameProgram="this.nameProgram" :nameClient="this.nameClient" :type="this.type" :editmodal="this.editmodal" :statusSale="this.statusSale"></modal-comissions>
        <modal-contract   v-if="openContract"   @click="closeContract"   v-bind:contractsales="this.contractsales" :nameProgram="this.nameProgram" :nameClient="this.nameClient" :type="this.type" :editmodal="this.editmodal" :statusSale="this.statusSale" :valorInitalPaymetn="this.valorInitalPaymetn"></modal-contract>
        <modal-notas      v-if="openNotas"      @click="closeNotas"      v-bind:notesprogram="this.notesprogram"   :nameProgram="this.nameProgram" :nameClient="this.nameClient" :type="this.type" :editmodal="this.editmodal" :statusSale="this.statusSale" :sourcesname="this.sourcesname" :pack="this.pack"></modal-notas>
        <modal-tracking   v-if="openTracking"   @click="closeTraking"    v-bind:trackings="this.trackings"         :nameProgram="this.nameProgram" :nameClient="this.nameClient" :type="this.type" :editmodal="this.editmodal" :statusSale="this.statusSale"></modal-tracking>
        <modal-payment    v-if="openPayment"    @click="closePayment"    v-bind:payments="this.payments"           :nameProgram="this.nameProgram" :nameClient="this.nameClient" :type="this.type" :editmodal="this.editmodal" :statusSale="this.statusSale" :sessionId="this.sessionId" :valorInitalPaymetn="this.valorInitalPaymetn"></modal-payment>
        <modal-revission  v-if="openRevisiion" :idProgram="this.idProgram" :nameProgram="this.nameProgram" :nameClient="this.nameClient" :typeRevission="this.typeRevission" @click="closeRevission" :idsales="this.idsales" @response="actulityRevis" :sessionId="this.sessionId" :sessioName="this.sessioName" :valorInitalPaymetn="this.valorInitalPaymetn" :numberAccount="this.account"></modal-revission>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:['global','type'],
    data() {
        return {
            statusip:null,
            editmodal: false,
            notesprogram: [],
            contractsales: [],
            payments:[],
            comissions:[],
            trackings:[],
            nameProgram:'',
            idProgram:'',
            nameClient:'',
            typeRevission:'',
            feeClient: '',
            idsales:'',
            statusSale:'',
            sessionRol : this.global.layout.role_id,
            sessionId : this.global.layout.id,
            sessioName: this.global.layout.first_name+' '+this.global.layout.last_name,
            salemades:[],
            dateActualy: moment().format('YYYY-MM-DD'),
            advance : true,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),            
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: true,
            resetadvance: false,                      
            start_page: '',
            next_page: '',
            ultima_pagina :'',
            totalleads:'',
            captureds: [],
            sellers: [],
            programs:[],
            leadstatus:[],
            oneDateLead:true,
            orderDateAsc:false,
            orderDateDesc:false,
            oneEventLead: true,
            orderLeadAsc:false,
            orderLeadDesc:false,
            onderApprovedLead:true,
            orderApprovedAsc:false,
            orderApprovedDesc:false,
            campo1: '',
            status:null,
            captured:null,
            seller: this.global.layout.role_id == 5 ? this.global.layout.id : null,
            program:null,
            from: '',
            to: '',
            dato1: 'desc',
            dato2: 11,        
            openComissions: false,
            openContract: false,
            openNotas: false,
            openTracking: false,
            openRevisiion: false,
            openPayment: false,
            perpage:'',
            valorInitalPaymetn:'',
            account: '',
            sourcesname:'',
            pack:''
        }
    },    
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader')        
    },
    methods: {
        imprimir(){
            axios.get('/imprimir')
            .then(response => {
                //console.log('imprimio')
            })
        },
        advanceSearch(){
            this.advance = false
            this.searchadvance = false
            this.resetadvance =  true
            this.campo1 = ''

            var x = document.getElementById('btnsearch')
            x.classList.add('margin-top-advance')
          
        },
        resertSearch(){
            this.advance = true
            this.searchadvance = true
            this.resetadvance =  false
            this.from = ''
            this.to = ''
            this.campo1 = null
            this.cr = null
            this.status = null
            this.captured = null
            this.seller = this.global.layout.role_id == 1 || this.global.layout.role_id == 2 ?  null : this.seller
            this.program = null
            this.statusip = null
            this.search('desc',11)
            var x = document.getElementById('btnsearch')
            x.classList.remove('margin-top-advance')
        },
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/salemade?page='+newval, {
                text: this.campo1,
                status: this.status,
                cr: this.cr,
                program: this.program,
                from: this.from ,
                to: this.to ,
                orderby: this.dato2,
                order: this.dato1,
                captured : this.captured,
                seller : this.seller ,
                salemade : this.type,
                rolsession: this.global.layout.role_id,
                statusip: this.statusip
            }).then(response => {
                if(response.status == 200){
                    this.salemades = response.data.data                
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;                    
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/salemade?page='+next, {
                text: this.campo1,
                status: this.status,
                cr: this.cr,
                program: this.program,
                from: this.from ,
                to: this.to ,
                orderby: this.dato2,
                order: this.dato1,
                captured : this.captured,
                seller : this.seller,
                salemade : this.type,
                rolsession: this.global.layout.role_id,
                statusip: this.statusip
            }).then(response => {
                if(response.status == 200){
                    this.salemades = response.data.data                    
                    this.start_page =  response.data.current_page;                    
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                }
            });
        },
        search(dato1 , dato2){
            this.dato1 = dato1
            this.dato2 = dato2
            if(dato2 == 11){
                if(dato1 == "desc"){
                    this.oneDateLead = false
                    this.orderDateDesc = true
                    this.orderDateAsc = false
                }else{
                    this.orderDateAsc = true
                    this.orderDateDesc = false                    
                    this.oneDateLead = false
                }
            }else if(dato2 == 1){
                if(dato1 == "desc"){
                    this.oneEventLead = false
                    this.orderLeadDesc = true
                    this.orderLeadAsc = false
                }else{
                    this.orderLeadAsc = true
                    this.orderLeadDesc = false
                    this.oneEventLead = false
                }
            }else if(dato2 == 12){
                if(dato1 == "desc"){
                    this.onderApprovedLead = false
                    this.orderApprovedDesc = true
                    this.orderApprovedAsc = false
                }else{
                    this.orderApprovedAsc = true
                    this.orderApprovedDesc = false
                    this.onderApprovedLead = false
                }
            }
            axios.post('/api/salemade',{
                text: this.campo1,
                status: this.status,
                cr: this.cr,
                program: this.program,
                from: this.from == '' ? null : this.from,
                to: this.to == '' ? null : this.to,
                orderby: this.dato2 == null ? 11 : this.dato2,
                order: this.dato1 == null ? 'desc' : this.dato1,
                captured : this.captured == null ? null : this.captured,
                seller : this.seller == null ? null : this.seller,
                salemade : this.type,
                rolsession: this.global.layout.role_id,
                statusip: this.statusip
            })
            .then(response => {
                if(response.status == 200){
                    this.salemades = response.data.data
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    this.next_page = this.start_page+1;
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                }
            })
        },
        modalNotas(id,index,session,status,sourcesname,pack){            
            if(session == this.global.layout.id){
                this.editmodal = true
            }else{
                this.editmodal = false
            }
            this.statusSale = status
            this.sourcesname = sourcesname
            this.pack = pack
            var x = this.salemades[index]
            this.trackings = x.trackings
            this.nameProgram = x.program
            this.nameClient = x.client
            axios.post('/api/notessales',{
                id:id
            }).then(response => {
                if(response.status == 200){
                    this.notesprogram = response.data
                    this.openNotas = true;
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closeNotas(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openNotas = false
            this.search('desc',11);
        },
        modalContract(id , index , session ,status , initial){            
            if(session == this.global.layout.id){
                this.editmodal = true
            }else{
                this.editmodal = false
            }
            this.statusSale = status
            var x = this.salemades[index]
            this.trackings = x.trackings
            this.nameProgram = x.program
            this.nameClient = x.client
            this.valorInitalPaymetn = initial
            axios.post('/api/contractsales',{
                id:id
            }).then(response => {
                if(response.status == 200){
                    this.contractsales = response.data[0]
                    this.openContract = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })            
        },
        closeContract(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openContract = false
            this.search('desc',11);
        },
        modalTraking(id){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            var x = this.salemades[id]
            this.trackings = x.trackings
            this.nameProgram = x.program
            this.nameClient = x.client
            this.openTracking = true
        },
        closeTraking(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openTracking = false
        },
        modalComissions(id){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            var x = this.salemades[id]
            this.comissions = x.commission
            this.nameProgram = x.program
            this.nameClient = x.client
            this.openComissions = true
        },
        closeComissions(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openComissions = false
        },

        revisionSale(index , state , initial ,account){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            var x = this.salemades[index]
            this.nameProgram = x.program
            this.idProgram = x.program_id
            this.nameClient = x.client
            this.openRevisiion = true
            this.typeRevission = state
            this.idsales = x.id
            this.valorInitalPaymetn = initial
            this.account = account
        },
        closeRevission(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openRevisiion = false
        },
        modalPayment(id,index,session,status,initial){
            if(session == this.global.layout.id){
                this.editmodal = true
            }else{
                this.editmodal = false
            }
            this.statusSale = status
            var x = this.salemades[index]
            this.comissions = x.commission
            this.nameProgram = x.program
            this.nameClient = x.client
            this.valorInitalPaymetn = initial
            axios.post('/api/paymentsales',{
                id:id
            }).then(response => {
                if(response.status == 200){
                    this.payments = response.data[0]
                    this.openPayment = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closePayment(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openPayment = false
            this.search('desc',11);
        },
        actulityRevis(response){
            if(response == true){
                this.search('desc',11)
            }
        },
        allPrograms(){
            axios.get('/api/programs')
                .then(response => {
                    this.programs =  response.data 
                });
        },
        capturedAll(){
            axios.post('/api/capturedall/2',{ 
                roles : '[]',
                type : '0'
            }).then(response => {
                this.captureds = response.data;                
            })
        },
        sellerAll(){
            axios.post('/api/sellerall/2',{ 
                roles : '[1,5,2]',
                type : '0'
            }).then(response => {
                this.sellers = response.data;
            })
        },
        nuevo(){
            this.search('desc',11);
        }
    },
    created() {
        this.search('desc',11);
        this.capturedAll();
        this.allPrograms();
        this.sellerAll();
    },
}
</script>