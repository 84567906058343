<template>
    <div class="modal-event" style="margin-top:0px">
        <div class="row title-modal-edit">
            <div class="col-lg-12">
                <h3 > Create Credit Card</h3>
                <i class="fas fa-times icon-close-target"  @click="close"></i>
            </div>
        </div>
        <div>
            <form @submit.prevent="createCard()">       
                <div id="modalBody" class="modal-body">                
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for=""> Card Holder Name</label>
                                    <input type="text" name="cardholdername" id="cardholdername" class="input-form"  v-model="form.cardholdername" :class="{ 'is-invalid': form.errors.has('cardholdername') }" v-on:keyup="cardname" style="text-transform:capitalize">
                                    <has-error :form="form" field="cardholdername"></has-error>
                                </div>                        
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for=""> Card number</label>
                                    <input type="text" name="" id="CN" class="input-form" v-model="form.cardnumber" maxlength="19"  v-on:keyup="tarjeta" @paste="cotarjet" :class="{ 'is-invalid': form.errors.has('cardnumber') }">
                                    <has-error :form="form" field="cardnumber"></has-error>
                                </div>                        
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label for="">MM</label>
                                            <input v-on:keyup="cardexpi" type="text" name="" id="card_expi_month" class="input-form" v-model="form.card_expi_month"  maxlength="2" :class="{ 'is-invalid': form.errors.has('card_expi_month') }">
                                            <has-error :form="form" field="card_expi_month"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label for="">YY</label>                                    
                                            <input v-on:keyup="cardyear" type="text" name="" id="card_expi_year" class="input-form" v-model="form.card_expi_year" maxlength="2" :class="{ 'is-invalid': form.errors.has('card_expi_year') }">
                                            <has-error :form="form" field="card_expi_year"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="form-group">
                                            <label for="">CVC</label>                                    
                                            <input v-on:keyup="cardsecu" type="text" name="" id="cardsecuritycode" class="input-form" v-model="form.cardsecuritycode" maxlength="4" :class="{ 'is-invalid': form.errors.has('cardsecuritycode') }">
                                            <has-error :form="form" field="cardsecuritycode"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="">Billing Address is the same the Mailling Address ?</label>
                                            <br>
                                            <div style="display: inline-flex;width: 100%;margin-left: 20px;">
                                                <div>
                                                    <input value ="1" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="admin" @click="noaddress">
                                                    <label class="form-check-label" for="admin" @click="noaddress" style="margin-right: 25px;">
                                                        Yes
                                                    </label>                                                                    
                                                </div>
                                                <div>
                                                    <input value="0" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="others" @click="yesaddress">
                                                    <label class="form-check-label" for="others" @click="yesaddress">
                                                        No
                                                    </label>                                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="otherinfo">
                                <div class="form-group">
                                    <label for="">Mailing address</label>                                    
                                    <vue-google-autocomplete
                                        ref="address_create_card_modal"
                                        id="address_create_card_modal"
                                        classname="form-control input-form fond-white"
                                        placeholder="Please type your address"
                                        v-on:placechanged="getAddressData"
                                        country="us"
                                        v-model="form.address"
                                        style="height:30px !important;background:#f1f1f1 !important"
                                    >
                                    </vue-google-autocomplete>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="otherinfo">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="">City</label>
                                            <input class="border-input input-form"  type="text" id="title" name="title" v-model="form.city">                                        
                                        </div>
                                        <div class="form-group">
                                            <label for="">Zip Code</label>
                                            <input class="border-input  input-form" type="text" id="title" name="title" v-model="form.zipcode">                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="">State</label>
                                            <select class="input-form" name="state" id="state" v-model='form.state' >
                                                <option :value="state.slug" v-for="state in states" :key="state.id">{{state.state}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Country</label>
                                            <input disabled class="border-input input-form" type="text" id="title" name="title" v-model="form.country">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="modal-footer">                                    
                    <button type="submit" class="btn btn-danger btn-color-green" > <i class="fas fa-check"></i> Save</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default {
    components: { VueGoogleAutocomplete },
    props:['idlead'],
    data() {
        return {
            otherinfo:false,
            address_create_card_modal: '',
            verTarjet: true,
            editMode: false,
            states:[],
            cardnumberocul:'',
            cards:[],
            otherinfoclick:'1',
            form: new Form({
                idlead:this.idlead,
                card_expi_month:'',
                card_expi_year:'',
                cardnumber:'',
                cardsecuritycode:'',
                city:'',
                state: '',
                country:'United Stated',
                zipcode:'',
                address:'',
                cardholdername:'',
                street:''
            })
        }   
    },
    mounted() {
        axios.get('/api/stateseeuu')
            .then((response) => {
                this.states = response.data
            })
    },
    methods: {
        cardexpi(){
            var x = document.getElementById('card_expi_month').value;
            var id = document.getElementById('card_expi_month');
            if(x.length > 0){
                id.classList.remove('is-invalid')
            }
        },        
        cardyear(){
            var x = document.getElementById('card_expi_year').value;
            var id = document.getElementById('card_expi_year');
            if(x.length > 0){
                id.classList.remove('is-invalid')
            }
        },        
        cardsecu(){
            var x = document.getElementById('cardsecuritycode').value;
            var id = document.getElementById('cardsecuritycode');
            if(x.length > 0){
                id.classList.remove('is-invalid')
            }
        },        
        cardname(){
            var x = document.getElementById('cardholdername').value;
            var id = document.getElementById('cardholdername');
            if(x.length > 0){
                id.classList.remove('is-invalid')
            }
        },
        yesaddress(){
            this.otherinfo = true;            
        },
        noaddress(){
            this.otherinfo = false;
        },
        getAddressData: function (address_create_card_modal, placeResultData, id) {
                this.direccion = address_create_card_modal;
                this.address_create_card_modal = this.direccion.street_number+' '+this.direccion.route;                
                var x = document.getElementById('address_create_card_modal');
                x.value = this.address_create_card_modal;
                this.form.street = this.address_create_card_modal;
                this.form.address = this.address_create_card_modal
                this.form.state = this.direccion.administrative_area_level_1;
                this.form.city = this.direccion.locality;
                this.form.zipcode = this.direccion.postal_code;
        },
        tarjeta(){            
            var flag = true;
            var x = document.getElementById('CN').value;
            var id = document.getElementById('CN');
            if(x.length == 4 && flag){                
                document.getElementById('CN').value = x+"-";
                id.classList.remove('is-invalid');
                flag = true;
            }else if(x.length == 9 && flag){
                document.getElementById('CN').value = x+'-';
                flag = false;
            }else if(x.length == 14  && flag){
                document.getElementById('CN').value = x+'-';
                flag = false;
            }
        },
        cotarjet(e){
            var x3 = e.clipboardData.getData('text');
            var y3 = x3.slice(0,4)+"-"+x3.slice(4,8)+'-'+x3.slice(8,12)+'-'+x3.slice(12,16);    
            if(x3.length == 16){
                document.getElementById("CN").value = y3           
            }else{
                document.getElementById("CN").value = x3
            }
        },
        eyeTarjet(){
            this.verTarjet= false;
        },
        createCard(){
            var x = document.getElementById('address_create_card_modal');
            if(x)
                if(x.value != null && x.value != ''){
                    this.form.street = x.value;
                }else{
                    this.form.street = ''
                }
            this.form.post('/api/createcard' , this.form).
                then( (response) => {
                    Fire.$emit('AfterCreate');
                    swal.fire({
                    type: 'success',
                    title: 'Card Created Successfully'
                    }).then( (res) => {
                        if(res){
                                this.cards = response.data;
                                this.$emit('new', this.cards);
                                this.editMode = false;
                                this.$emit('click',false)
                            }
                        });
                    
                })
        },
        close(){
            this.editMode = false;
            this.$emit('click',false)
        },
        created() {
            Fire.$on('AfterCreate',() => {
                this.loadLeads();              
           });
        }
    },
    
}
</script>