<template>
        <div class="camp-feed borde-post-feed">
            <div>
                <div class="post-feed">
                    <p class="post-coment borde-post-coment">{{feed.updatedby}} posted a comment</p>
                    <div class="text-img">
                        <img v-bind:src="'/'+feed.image" width="50px"/>
                        <p class="text-princi texto-nota" style="color#999999"><span class="name-nota" style="color:#666666">{{feed.updatedby}}</span> updated a Lead <a :href="'/crm/leads/show/'+feed.id" target="_blanck"><span class="texto-bold"> {{feed.lead_name}}</span></a></p>
                        <p class="text-date nota-date">{{feed.updated_at | myDateGlobal}}</p>
                    </div>
                </div>
                <div class="cont-notes">
                    <div class="bor-notes">
                        <div class="cont-coment-m cont-coment-m-borde" >
                            <span class="btn-coment-borde"   v-on:click="onClickUpdate(feed.id)" v-if="cantfeed">Show all {{feed.cantnotes - 3 }} comments</span>
                        </div>

                        <div v-for="(note , index) in JSON.parse(feed.notes)" :key="index" class="notes"><!--//notas-->                        
                            <div class="content-user">
                                <div class="content-name-user-img">  
                                    <img v-bind:src="'/'+note.image" width="40px"/>
                                    
                                </div>
                                <div class="content-user-name">
                                    <p class="name-user-note" style="color:#666666">{{note.user_name}}</p> 
                                    <p class="text-note">{{note.text}}</p>
                                    <p class="date-note">{{note.created_at | myDateGlobal}}</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cont-notes form-note-cont">
                    <div class="form-note">
                        <input type="text" style="border: 1px solid #ccc;width: 100%" @click="openbutton" v-model="textnote" placeholder="Write a comment">
                        <div v-if="buttonform" class="cont-button">                            
                            <button class="btn btn-color-red" @click="cancelnote" style="display: inline-block"> <i class="fas fa-times fa-times-new"></i> Cancel</button>
                            <button class="btn btn-color-green" @click="savenote" style="display: inline-block"><i class="fas fa-save"></i> Submit</button>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
</template>


<script>
    export default {
        props: ['feed','sessionuser'],
        data() {
            return {
                cantfeed: this.feed.cantnotes > 3 ? true: false,
                modalopen: false,
                buttonform: false,
                buttonformmodal: false,
                textnote: '',
                textnotemodal: '',
                feeds: [],
                notemodal: [],
                
            }
        },
        methods: {
            onClickUpdate(id){
                this.$emit('id',id)                
            },
            openbutton(){
                this.buttonform = true;
            },
            cancelnote(){
                this.buttonform = false;
            },
            savenote(){
                const param = {
                    idlead: this.feed.id,
                    user_id: this.sessionuser,
                    text: this.textnote,
                };
                axios.post('/api/newnotelist',param)
                .then(response => {
                    this.feeds = response.data;
                    //console.log(this.feeds)
                    this.$emit('new', this.feeds);
                    this.textnote = '';
                    this.buttonform= false
                })
            },
        },
        created() {
            
        },
    }
</script>