<template>
    <div :style="dataLead == false ? 'margin-bottom: 10px;background: white;height: 462px;' : 'margin-bottom: 10px;background: white'">
        <div class="cont-title">
            <div class="row">
                <div class="col-lg-6">
                    <span>Process</span>
                </div>
                <div class="col-lg-6">
                    <button @click="returnList" class="btn class-button-red"><i class="fas fa-caret-left" style="margin-right:5px"></i> RETURN</button>
                </div>
            </div>
        </div>
        <div class="cont-search-paginate">
            <div class="row">
                <div class="col-lg-5">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="form-group">
                                <input id="camposearch" autocomplete="off"  class="input-form" type="text" @keyup="searchlead" ref="file" name="file" placeholder="Search Leads | Clients" style="margin-bottom: 5px;">
                                <div id="contusers" class="cont-users">
                                    <span class="span-hover" v-for="(user , index) in users" :key="index" style="display: block;cursor:pointer" @click="searchuser(user.id,user.first_name,user.last_name,user.mobile)"> {{user.first_name}} {{user.last_name}} | {{user.mobile}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <button class="btn class-button-green" @click="getcard"> CONTINUE <i class="fas fa-caret-right" style="margin-left: 10px;"></i> </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="dataLead">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <div >
                                    <label for="">Lead</label>
                                    <input type="text" v-model="lead" class="input-form">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <div>
                                    <label for="">Mobile</label>
                                    <input type="text" v-model="mobile" class="input-form">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" v-if="dataLead">
                            <div class="form-group" style="display: inherit">
                                <label> Ammount </label> <span v-if="Amerror" style="color:red"> Required </span> <br>
                                $ <input style="width: 80%;margin-left: 10px;" id="campo2" class="input-form" type="text" v-model="amount" v-on:blur="validMounthly(2)" @keypress="justNumbers">
                            </div>
                        </div>
                        <div class="col-lg-6" v-if="dataLead">
                            <div class="form-group">
                                <label> Type of Payment </label> <span v-if="MOPerror" style="color:red"> Required </span>
                                <div class="row" style=" display: flex;background: #f1f1f1;border-radius: 5px;margin: 0px;">
                                    <div class="col-md-4" style="display:inline-flex;margin-right: -20px;">
                                        <input type="radio" value="1" name="merchant" v-model="payment" id="wwce" style="margin-top: 5px;">
                                        <label for="wwce" > Realtor </label>
                                    </div>
                                    <div class="col-md-4" style="display:inline-flex;margin-right: 12px;">
                                        <input type="radio" value="2" name="merchant" v-model="payment" id="amg" style="margin-top: 5px;">
                                        <label for="amg" > Appointment </label>
                                    </div>
                                    <div class="col-md-4" style="display:inline-flex">
                                        <input type="radio" value="3" name="merchant" v-model="payment" id="amgss" style="margin-top: 5px;">
                                        <label for="amgss"> Others </label>
                                    </div>
                                </div>
                                <input maxlength="20" @keyup="obserCont" placeholder="Specific" v-if="payment == 3" type="text" name="" id="campoobs" style="margin-top: 5px;" v-model="observationOther" class="input-form">
                                <span v-if="errorObs" style="color:red"> Required </span>
                            </div>
                        </div>                    
                        <div class="col-lg-12" v-if="dataLead">
                            <div class="form-group">
                                <button @click="submitAutorize" class="btn btn-color-green" style="font-size: 13px;padding: 6px;height: 35px;width: 20%;"> <i class="fas fa-save" style="margin-right: 4px;"></i> SUBMIT </button>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="errosList">
                            <div class="form-group">
                                <span v-if="responseCode == 1" > Approved </span>
                                <span v-if="responseCode == 2" > Declined </span>
                                <span v-if="responseCode == 3" > Error </span>
                                <span v-if="responseCode == 4" > Held for Review </span>
                                <ul>
                                    <li v-for="(items , index) in errosAutorize" :key="index" > {{items.errorText}} </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="messageList">
                            <div class="form-group">
                                <span v-if="responseCode == 1" > Approved </span>
                                <span v-if="responseCode == 2" > Declined </span>
                                <span v-if="responseCode == 3" > Error </span>
                                <span v-if="responseCode == 4" > Held for Review </span>
                                <ul>
                                    <li v-for="(items , index) in messageAutorize" :key="index" > {{items.description}} </li>
                                </ul>
                            </div>
                        </div>      
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="row" >
                        <div class="col-lg-6" v-if="dataLead">
                            <div class="form-group">
                                <label for="">Method of Payment</label> <span v-if="Meterror" style="color:red"> Required </span>
                                <div class="row">
                                    <div class="col-md-6" style="display:inline-flex">
                                        <input type="radio" value="1" name="method" v-model="methodpayment" id="method1" style="margin-top: 5px;">
                                        <label for="method1" > Credit Card </label>
                                    </div>
                                    <div class="col-md-6" style="display:inline-flex">
                                        <input type="radio" value="2" name="method" v-model="methodpayment" id="method2" style="margin-top: 5px;">
                                        <label for="method2" > Others </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12" v-if="methodpayment == 2">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="">Type</label> <span v-if="Tyerror" style="color:red"> Required </span>
                                        <select name="" id="" v-model="types" class="input-form">
                                            <option value="1">Cash</option>
                                            <option value="2">Check</option>
                                            <option value="3">Money Order</option>
                                        </select>  
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="">Serial Number</label> <span v-if="Sperror" style="color:red"> Required </span>
                                        <input type="text" name="" id="" v-model="specify" class="input-form">
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="col-lg-12" v-if="methodpayment == 1">
                            <span v-if="CAerror" style="color:red"> Required </span>
                            <table class="table">
                                <tbody style="border: 1px solid #ccc;">
                                    <tr style="background: #f1f1f1;color: #999999;">
                                        <th></th>
                                        <th>Card Holder Name</th>
                                        <th>Card Number</th>
                                        <th>MM</th>
                                        <th>YY</th>
                                        <th>CVC</th>
                                    </tr>
                                    <tr v-for="card in cards" :key="card.id" v-show="card.cardnumber != null">
                                        <td>
                                            <input :disabled="card.count_declined > 1" :title="'Blocked for intents number.'" type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id">
                                        </td>
                                        <td style="color: #9b9b9b;text-transform: capitalize;">{{card.cardholdername}}</td>
                                        <td style="color: #9b9b9b;">{{'XXXX-XXXX-XXXX-'+card.cardnumber}}</td>
                                        <td style="color: #9b9b9b;">{{card.card_expi_month}}</td>
                                        <td style="color: #9b9b9b;">{{card.card_expi_year}}</td>
                                        <td style="color: #9b9b9b;">{{ card.cardsecuritycode != null ?  card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3) : ''}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12" style="text-align: right" v-if="methodpayment == 1">                                                            
                            <a @click="createcard" class="btn class-button-green" style="width:80px;color:white"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;"> ADD</a>
                        </div>                        
                    </div>
                </div> 
                <modal-card-create v-if="createmodal" :idlead="this.user_id" @new="addCard" @click="closedModalCar" > </modal-card-create>
            </div>
        </div>
    </div>
</template>

<style lang="stylus">
    .color-yellow
        background : #FF9800 !important
        color: black
    .color-green-text
        color: green
    .color-red-text
        color: red
</style>


<script>
    export default {
        props:['global'],
        data() {
            return {
                lead_id: '',
                dataExcel:[],
                payment: '',
                cards:[],
                createmodal: false,                
                lead : '',
                mobile : '',
                card_id : '',
                amount: '',
                merchant: 1,
                errosAutorize: [],
                messageAutorize: [],
                errosList: false,
                messageList: false,
                responseCode: '',
                users: [],
                resultsearch: false,
                user_id: '',
                user_name: '',
                dataLead: false,
                CAerror: false,
                Amerror: false,
                MOPerror: false,
                Meterror: false,
                optionspayments: false,
                types:'',
                specify:'',
                methodpayment:'',
                Tyerror:'',
                Sperror:'',
                errorObs:false,
                observationOther:''
            }
        },
        mounted() {
            //console.log(this.global)
        },
        methods: {
            obserCont(){
                if(this.observationOther != ''){
                    this.errorObs = false
                }
            },
            validMounthly(id){
                this.amount = this.globalFunction(id)        
            },
            globalFunction(id){
                var x = document.getElementById('campo'+id).value            
                if(x.indexOf('.') != -1){ 
                    var numw = x.replace(/,/gi, "");
                    var dec = numw.split('.')
                    var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.'+dec[1]
                    
                }else{
                    var numw = x.replace(/,/gi, "");
                    var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.00'
                }
                return num
            },
            justNumbers: function (event) {
                var charCode = window.event ? event.which : event.keyCode;
                var RE = /^\d*(\.\d{1})?\d{0,1}$/;
                if (charCode != 46 && charCode > 31 
                && (charCode < 48 || charCode > 57)) {
                    //Usando la definición del DOM level 2, "return" NO funciona.
                    event.preventDefault();
                }
            },
            searchlead(){
                var x = document.getElementById('camposearch')
                var dato = document.getElementById('contusers')
                var cant = x.value
                axios.post('/api/searchlead',{
                    q : cant
                })
                .then(response => {
                    if(response.status == 200) {                        
                        if(cant.length > 0){
                            dato.classList.add('all-he')
                            this.users = response.data
                            if(this.users.length == 0){
                                dato.classList.remove('scroll-all')
                                dato.classList.remove('all-he')
                            }else if(this.users.length < 15){                                
                                dato.classList.add('all-he')
                                dato.classList.remove('scroll-all')
                            }else if(this.users.length > 15){
                                dato.classList.add('scroll-all')
                            }
                        }else{
                            dato.classList.remove('scroll-all')
                            dato.classList.remove('all-he')
                            this.users = []
                        }
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            searchuser(id,first,last,mobile){
                this.user_id = id
                this.user_name = first+' '+last+' | '+mobile
                var x = document.getElementById('camposearch')
                var dato = document.getElementById('contusers')
                x.value = this.user_name
                dato.classList.remove('scroll-all')
                dato.classList.remove('all-he')
                this.users= []
            },
            returnList(){
                this.$emit('clickList',true)
            },
            addCard(cards) {
                this.cards = cards;
            },
            createcard(){
                this.createmodal = true;
                var boton = document.getElementById('app');
                boton.classList.add('preloader-task');
            },
            closedModalCar(click){
                this.createmodal = false
                var boton = document.getElementById('app');
                boton.classList.remove('preloader-task');
            },
            getcard(){
                var boton = document.getElementById('app');
                boton.classList.add('preloader');
                axios.post('/api/searchleadpayment',{
                    id: this.user_id
                }).then(response => {
                    if(response.status == 200){
                        this.cards = response.data
                        this.dataLead = true
                        if (this.cards.length > 0) {
                            this.lead = this.cards[0].lead_name
                            this.mobile = this.cards[0].mobile
                        }else{
                            this.lead = ''
                            this.mobile = ''
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
            submitAutorize(){
                if(this.amount == '' && this.payment == ''){                    
                    this.Amerror = true
                    this.MOPerror = true
                }else if(this.amount != '' && this.payment == ''){
                    this.Amerror = false
                    this.MOPerror = true
                }else if(this.amount == '' && this.payment != ''){
                    this.Amerror = true
                    this.MOPerror = false
                }else if(this.amount != '' && this.payment != ''){
                    this.Amerror = false
                    this.MOPerror = false
                    if(this.payment == 1 || this.payment == 2 ){
                        if(this.methodpayment == ''){
                            this.Meterror = true
                        }else{
                            this.Meterror = false
                            if(this.methodpayment == 1 && this.card_id == ''){
                                this.CAerror = true
                            }else if(this.methodpayment == 1 && this.card_id != ''){
                                this.CAerror = false
                                this.subtAutorize()
                            }else if(this.methodpayment == 2 && this.types == '' && this.specify == ''){
                                this.Tyerror = true
                                this.Sperror = true
                            }else if(this.methodpayment == 2 && this.types != '' && this.specify == ''){
                                this.Tyerror = false
                                this.Sperror = true
                            }else if(this.methodpayment == 2 && this.types == '' && this.specify != ''){
                                this.Tyerror = true
                                this.Sperror = false
                            }else if(this.methodpayment == 2 && this.types != '' && this.specify != ''){
                                this.Tyerror = false
                                this.Sperror = false
                                this.subtAutorize()
                            }
                        }
                    }else if(this.payment == 3){
                        if(this.observationOther == ''){
                            this.errorObs = true
                        }else{
                            this.errorObs = false
                            if(this.methodpayment == ''){
                                this.Meterror = true
                            }else{
                                this.Meterror = false
                                if(this.methodpayment == 1 && this.card_id == ''){
                                    this.CAerror = true
                                }else if(this.methodpayment == 1 && this.card_id != ''){
                                    this.CAerror = false
                                    this.subtAutorize()
                                }else if(this.methodpayment == 2 && this.types == '' && this.specify == ''){
                                    this.Tyerror = true
                                    this.Sperror = true
                                }else if(this.methodpayment == 2 && this.types != '' && this.specify == ''){
                                    this.Tyerror = false
                                    this.Sperror = true
                                }else if(this.methodpayment == 2 && this.types == '' && this.specify != ''){
                                    this.Tyerror = true
                                    this.Sperror = false
                                }else if(this.methodpayment == 2 && this.types != '' && this.specify != ''){
                                    this.Tyerror = false
                                    this.Sperror = false
                                    this.subtAutorize()
                                }
                            }
                        }
                    }
                }
            },
            subtAutorize(){
                
                swal.fire({
                    title: 'Process Payment',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.sendMessage = true
                            var boton = document.getElementById('app');
                            boton.classList.add('preloader');
                            axios.post('/checkoutpayment',{
                                idcard: this.card_id,                    
                                amount: this.amount,
                                merchant: this.merchant,
                                idsession : this.global.layout.id,
                                payment:this.payment,
                                lead_id: this.user_id,
                                types:this.types,
                                specify:this.specify,
                                methodpayment:this.methodpayment,
                                observationOther: this.observationOther
                            })
                            .then(response => {
                                if(response.status == 200 && response.data.status == 200) {
                                    var boton = document.getElementById('app');
                                    boton.classList.remove('preloader');                                      
                                    if(this.methodpayment == 1){
                                        var datanew = ''
                                        response.data.transaction.messages.message.map(index => {
                                            datanew = datanew + index.description+'<br>'
                                        }),
                                        swal.fire({
                                            type: 'success',
                                            title: response.data.transaction.responseCode == 1 ? 'Approbre' : 'Declined',
                                            html: datanew
                                        }).then( (res) => {
                                            if(res){
                                                this.$emit('clickList',true)
                                                }
                                            });
                                    }else{
                                        swal.fire({
                                        type: 'success',
                                        title: 'Transaction Aproved'
                                        }).then( (res) => {
                                            if(res){
                                                this.$emit('clickList',true)
                                                }
                                            });
                                    }
                                }else if(response.status == 200 && response.data.status == 500){
                                    var boton = document.getElementById('app');
                                    boton.classList.remove('preloader');
                                    if(response.data.transaction.errors){
                                        this.errosAutorize = response.data.transaction.errors.error // arrays errores -> errorText
                                        this.responseCode = response.data.transaction.responseCode
                                        this.messageList = false
                                        this.errosList = true
                                        //this.$emit('clickList',true)
                                        if(this.methodpayment == 1){
                                            var datanew = ''
                                            response.data.transaction.errors.error.map(index => {
                                                datanew = datanew + index.errorText+'<br>'
                                            }),
                                            swal.fire({
                                                type: 'error',
                                                title: 'Error',
                                                html: datanew
                                            }).then( (res) => {
                                                if(res){
                                                    //this.$emit('clickList',true)
                                                    }
                                                });
                                        }
                                    }else{
                                        var datanew = ''
                                        response.data.transaction.messages.message.map(index => {
                                            datanew = datanew + index.description+'<br>'
                                        }),
                                        swal.fire({
                                            type: 'success',
                                            title: 'Held for Review',
                                            html: datanew
                                        }).then( (res) => {
                                            if(res){
                                                this.$emit('clickList',true)
                                                }
                                            });
                                        
                                    }
                                }
                            }).catch(error => {
                                /*if(error.response.status == 500 ) {
                                    if(this.methodpayment == 1){
                                            var datanew = ''
                                            response.data.transaction.errors.error.map(index => {
                                                datanew = datanew + index.errorText+'<br>'
                                            }),
                                            swal.fire({
                                                type: 'error',
                                                title: 'Error',
                                                html: datanew
                                            }).then( (res) => {
                                                if(res){
                                                    //this.$emit('clickList',true)
                                                    }
                                                });
                                    }
                                }*/
                            })
                        }else{
                            var x = document.getElementById('modal_event')
                            x.classList.remove('preloader-new')
                        }
                    });
                
            }
        },
        created() {            
        },
    }
</script>

<style lang="stylus">
    .cont-users
        color #fff    
        position: absolute;
        width: 92%;
        background: #666666;
        z-index: 1;
        border-radius: 8px;
        overflow-y: scroll
        top: 58px;
    .cont-users.all-he
        height auto
        border: 1px solid #ccc;
        padding: 5px;
    .cont-users.scroll-all
        height: 310px !important
        overflow-y: scroll
    .span-hover:hover 
        background #e4e4e4;
</style>
