<template>
    <div>
        <div id="loading">
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Attend Appointment</span>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="cont-user-info">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="border-campo-user">
                                        <span class="title-modal">Appointment Information</span>
                                        <div class="row" style="padding: 20px">
                                            <div class="col-lg-3">
                                                <div style="color:#B59200">
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon col-md-4">
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <span name="start" id="date"> {{ campos.date | myDate }} </span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon col-md-4">
                                                            <i class="fas fa-map-marker-alt"></i>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <span name="start" id="date"> {{ campos.location }} </span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon col-md-4">
                                                            <i class="fas fa-clock"></i>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <span name="start" id="date"> {{ campos.from | myTime }} - {{ campos.to | myTime}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div style="" class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span >Seller</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8" style="border-left: 6px solid #999999;">
                                                                <span name="start" id="date"> {{ campos.seller_name }} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Created By</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{ campos.usercreate_name }} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-2">
                                                                <span>Description</span>
                                                            </div>
                                                            <div class="class-campo-text add-class-campo-text col-md-10">
                                                                <span name="start" id="date"> {{ campos.description }} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-2">
                                                                <span>Comment</span>
                                                            </div>
                                                            <div class="class-campo-text add-class-campo-text col-md-10">
                                                                <span name="start" id="date"> {{ campos.comment }} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div style="color:#B59200">
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                            <span>Lead</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <a target="_blank" class="lead-style" :href="'/crm/leads/edit/'+ campos.lead_id">{{ campos.lead_name }}</a>
                                                        </div>
                                                    </div>
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                            <span>Modified By</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <span name="description" id="description" v-if="campos.userupdated_name != null">{{ campos.userupdated_name }} {{ campos.updated_at }} </span>
                                                            <span name="description" id="description" v-else> - </span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                            <span>Status</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-8">
                                                            <span v-if="campos.attend == 1">Process</span>
                                                            <span v-if="campos.attend == 2">Finish</span>                                                            
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cont-user-info">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="border-campo-user">
                                        <span class="title-modal">Programs</span>
                                        <div class="row" style="padding: 5px">
                                            <div class="col-lg-12">
                                                <div>
                                                    <div class="form-group">
                                                        <nav>
                                                            <div class="nav nav-tabs nav-program" id="nav-tab" role="tablist" style="height: 35px;">
                                                                <a style="text-align: center;margin: 0px 3px;border-radius: 5px 5px 0px 0px;"  v-for="(program, index) in programs" :key="program.id" :class="{ 'active': index === 0 }" class="nav-item nav-link" id="nav-home-tab" data-toggle="tab" :href="'#class'+program.id" role="tab" aria-controls="nav-home" aria-selected="true">
                                                                    {{program.value}}
                                                                    <i v-if="programs[index].sold == 1 || programs[index].sale == 1 " class="fas fa-certificate"></i>
                                                                    <span v-if="programs[index].sold == 1 || programs[index].sale == 1 " class="text-sold">SOLD</span>
                                                                </a>
                                                            </div>
                                                        </nav>
                                                        <div class="tab-content" id="nav-tabContent" style="margin-top: -2px;">
                                                            <cont-programs
                                                                v-for="(program, index) in programs" 
                                                                :key="program.id"
                                                                :program="program" 
                                                                :index="index"
                                                                :idevent = "campos.id"
                                                                :status = "campos.attend"
                                                                class="tab-pane fade show" 
                                                                :id="'class'+program.id" 
                                                                :class="{ 'active': index === 0 }" 
                                                                role="tabpanel" 
                                                                aria-labelledby="nav-home-tab" 
                                                                style="border: 1px solid #afafaf;padding: 15px;"
                                                                @click="finish"
                                                                :validateRoute = "validateRoute"
                                                                @sale="actualsale"
                                                            ></cont-programs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: center;width: 100%;">
                                    <button class="btn " style="font-family:'RalewayBold' !important;height: 30px;margin-top: 10px;padding: 0px 10px;width: 200px;background: #00CC00;color: white;text-transform:uppercase" @click="finishEvent" v-if="campos.attend == 1 && validateRoute == true ? true : false"><i class="far fa-check-circle" style="margin-right: 5px"></i> FINISH APPOINTMENT</button>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['global'],
    data() {
        return {
            campos: this.global.newevent,
            programs: this.global.newevent.programs,
            precios : [],
            observation: '',
            contract: '',
            fee: '',
            oneclick: true,
            comment : 'prueba',
            btnFinish: false,
            validateRoute : this.global.layout.id == this.global.newevent.seller_id ? true : false,
        }
    },
    mounted() {
        console.log(this.global.newevent)
        var info = []
        this.programs.map(data => {
            info.push(data.sale)            
        })
        setTimeout(() => {
            var boton = document.getElementById('app')
            boton.classList.remove('preloader')
        }, 50); 
        /*if(this.campos.attend == 2 || this.validateRoute == false){
            this.btnFinish = false
        }else if(info.includes(1) || info.includes(0)){
            this.btnFinish = true
        }else{
            this.btnFinish = false
        }*/
    },
    methods: {
        actualsale(data){
            this.programs.map(index => {
                if(index.id == data.id){
                    index.sale = data.state
                }
            })
            console.log(this.programs)
        },
        finishEvent(){
            swal.fire({
                title: 'Finish Appointment?',
                text: "Before finalizing you must save.",
                type: 'warning',
                html:'<textarea id="swal-input1" style="height: 90px;font-size: 14px;" v-model="comment" class="swal2-input" placeholder="Enter the comment." required></textarea>',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes, Finish !'
                }).then((result) => {
                    if (result.value) {
                        var boton = document.getElementById('app');
                        boton.classList.add('preloader')                        
                        this.comment = document.getElementById('swal-input1').value
                        axios.post('/api/finishevent', {id:this.campos.id,comment:this.comment})
                            .then(response => {
                                if(response.status == 201) {
                                    setTimeout(() => {
                                        var boton = document.getElementById('app');
                                        boton.classList.remove('preloader')
                                    }, 50);                         
                                    window.location.href = '/crm/appointmentmade';
                                }
                            });
                    }
                });
        },
        finish(click){
            this.btnFinish = click;
        },
    },
    
}
</script>