<template>
    <div v-bind:id="type == 'task' ? 'taskvista' : ''" class="cont-user-info">
        <div class="row">
            <div class="col-lg-12">
                <div class="border-campo-user">
                    <span class="title-modal">Calls</span>
                    <div class="row" style="padding: 20px">
                        <div class="col-lg-12">
                            <div>
                                <div class="form-group">
                                    <div >
                                        <table class="table ">                                                
                                            <tbody  style="border: 1px solid #ccc;">
                                                <tr style="background: #f1f1f1;color: #999999;">
                                                    <th >Title</th>
                                                    <th v-if="type=='task'">Host</th>
                                                    <th v-if="type=='event'">Location</th>
                                                    <th>Date event</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Action</th>
                                                </tr>
                                                <tr v-for="ev in events.data" :key="ev.id">
                                                    <td><a class="open-modal-click" @click="modal(ev.id)">{{ ev.title }}</a></td>
                                                    <td v-if="type=='task'" style="color: #9b9b9b;">{{ ev.user.first_name }} {{ ev.user.last_name }}</td>
                                                    <td v-if="type=='event'" style="color: #9b9b9b;">{{ ev.location }}</td>
                                                    <td style="color: #9b9b9b;">{{ ev.date | myDate }}</td>
                                                    <td style="color: #9b9b9b;">{{ ev.from | myTime }}</td>
                                                    <td style="color: #9b9b9b;">{{ ev.to | myTime }}</td>
                                                    <td>
                                                        <a  @click="deleteEvent(ev.id)" style="cursor:pointer;" ><img :src="'/images/delete.png'"></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                                <a @click="createdEvent(form.id)" v-bind:data-target="type == 'event' ? '#createevent' : '#createtask'" class="btn class-button-green" style="width:80px;color:white"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;"> ADD</a>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-event-event" v-if="modalOpen">
            <div class="event-inter">
                <div class="row title-modal-edit" style="margin: 0px">
                    <div class="col-lg-12">                        
                        <h3>Created {{ type | FirstCapitalLetter }}</h3>
                        <i class="fas fa-times icon-close-target"  @click="closeModal"></i>
                    </div>
                </div>
                <div >            
                    <div id="modalBody" class="modal-body">
                        <form>
                            <div>
                                <div class="campos-detail-modal row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label for="">Title:</label>
                                            <div>
                                                <input  type="text" v-model="event.title" name="title" id="title"
                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('title') }" @keyup="titleText">
                                                <p v-if="errorTitle " style="color:red">Required Title</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div>
                                                <label for="">Host:</label>
                                                <select name="user_id" v-model="event.user_id" id="seller"  class="input-form user_id" 
                                                :class="{ 'is-invalid': event.errors.has('user_id') }" @click="selectSeller">
                                                    <option :value="null" selected disabled>Select host</option>
                                                    <option :value="ls.id" v-for="ls in sellers" :key="ls.id">{{ ls.user_name }}</option>
                                                </select>
                                                <p v-if="errorSeller" style="color:red">Required Seller</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label for="">Description:</label>
                                            <textarea v-model="event.description" style="background: #f1f1f1 !important" name="description" class="input-form description textarea-modal" id="" cols="30" rows="5" ></textarea>
                                            <has-error :form="form" field="description"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div id="vueapp" class="vue-app">
                                                <!--<h5>Selected date: {{event.from}}</h5>-->
                                                <label for="">Day event:</label>
                                                <kendo-datepicker
                                                    :min="minDate"
                                                    :max="maxDate"
                                                    :value="currentDate"
                                                    :format="'MM/dd/yyyy'"
                                                    style="background: #f1f1f1 !important" v-model="event.date"  
                                                    name="date" id="date"
                                                    class="input-form"
                                                    :class="{ 'is-invalid': form.errors.has('from') }">
                                                </kendo-datepicker>
                                                <has-error :form="form" field="from" class="form-control"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <div id="vueapp" class="vue-app">
                                                <!--<h5>Selected date: {{event.from}}</h5>-->
                                                <label for="">From:</label><br>
                                                <kendo-timepicker 
                                                    :min="min"
                                                    :max="max"
                                                    :value="value"   
                                                    style="background: #f1f1f1 !important"
                                                    v-model="event.from" 
                                                    name="from" id="from" 
                                                    class="input-form"
                                                    :format = "'HH:mm'"
                                                    :class="{ 'is-invalid': form.errors.has('from') }">
                                                </kendo-timepicker>                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <div id="vueapp" class="vue-app">
                                                <!--<h5>Selected date: {{event.from}}</h5>-->
                                                <label for="">To:</label><br>
                                                <kendo-timepicker 
                                                    :min="min"
                                                    :max="max"
                                                    :value="value"    
                                                    class="input-form"
                                                    :format = "'HH:mm'"
                                                    style="background: #f1f1f1 !important"
                                                    v-model="event.to" 
                                                    name="to" 
                                                    id="to" 
                                                    :class="{ 'is-invalid': form.errors.has('from') }">
                                                </kendo-timepicker>
                                                <p v-if="errorTo" style="color:red">Error Time</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">                    
                        <button type="button" class="btn btn-color-green" @click="SaveEvent()" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="fas fa-save"></i> SAVE</button>                        
                    </div>
                </div>
            </div>
        </div>
        <modal-event-edit v-if="open" v-bind:campos="this.campos" @update="updateEvent" @click="closed" @delete="deleteEvent"></modal-event-edit>
    </div>
</template>


<script>
export default {
    props:['global','type','state'],
    data(){
        return{
            open: false,
            errorTitle : false,
            errorSeller : false,
            errorTo : false,
            modalOpen: false,
            taskimdex: this.type == 'task',
            campos:[],
            errors: [],
            validate : false,
            sellers: [],
            events:{},
            lead_id:{id:''},
            options:[],
            editmode: false,
            editmodeE: false,
            editmodeT: false,
            date: null,
            value: "00:00 AM",
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(),
            maxDate: new Date(2050,9,1),
            currentDate: new Date(2017, 9, 15, 10, 30),
            form: new Form({
                id:'',
                user_id:null,
            }),
            event: new Form({
                user_id:null,
                title:'',
                location:'',
                lead_id:this.global.editleads[0].id,
                all_day:'',
                date:'',
                from:'',
                to:'',
                description:'',
                created_users:this.global.layout.id,
                type:this.type,
                state: this.state,
            }),
        }
    },
    methods: {
        titleText(){
            if(this.event.title == null ){
                this.errorTitle = true
            }else{
                this.errorTitle = false
            }
        },
        selectSeller(){
            if(this.event.user_id == null ){
                this.errorSeller = true
            }else{
                this.errorSeller = false
            }
        },
        modal(id){
            var boton = document.getElementById('taskvista');
            boton.classList.add('preloader-task')
            axios.post('/api/eventcalendar',{ 
                id : id
            }).then(response => {
                    this.campos = {"valor": response.data[0] , "idlayout" :this.global.layout.id };
                    this.open = true;
                });
        },
        closed(click){
            var boton = document.getElementById('taskvista');
            boton.classList.remove('preloader-task')
            this.open = click;
        },
        updateEvent(eventos) {
            this.eventos = eventos;
        },
        deleteEvent(eventos) {
            this.eventos = eventos;
        },
        toggleModal(){
            this.modal = !this.modal
        },
        onChange: function(ev) {
        this.date = kendo.toString(ev.sender.value(), 'd');
        },
        loadEvents(lead){
            axios.get("/api/crm/crm-events/"+ this.global.editleads[0].id + "/" + this.type ).then( ({data}) => (this.events = data));
        },
        getData(){
            axios.post('/api/sellerall/2',{ 
                roles : '[]',
                type : '1'
            }).then(response => {
                    this.sellers =  response.data 
                });
        },
        createdEvent(){
            this.modalOpen = true;
            var x = document.getElementById('taskvista');
            x.classList.remove('task-index-opa');
            this.errorTitle = false
            this.errorSeller = false
        },
        closeModal(){
            this.modalOpen = false;
            this.form.reset();
            var x = document.getElementById('taskvista');
            x.classList.remove('task-index-opa');
            this.errorTitle = false
            this.errorSeller = false
        },
        SaveEvent(){
            if(this.event.title == '' && this.event.user_id == null){
                this.errorTitle = true
                this.errorSeller = true
            }else if(this.event.from >= this.event.to){
                toast.fire({
                    type: 'error',
                    title: 'Enter correct time!'
                    });
                this.errorTo = true
            }else{
                this.errorTo = false  
                this.$Progress.start();
                this.event.post('/api/crm/crm-events').then( () => {
                Fire.$emit('AfterCreate');
                this.event.reset();
                toast.fire({
                    type: 'success',
                    title: 'Event Created in successfully'
                    });
                        this.loadEvents(this.lead_id);
                        this.form.reset();
                        this.dataccess = false;
                        this.$Progress.finish();
                        this.modalOpen = false;
                })
                .catch( (errors) => {
                    // console.log(errors);
                })
            }
        },
        deleteEvent(id){
            swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                if (result.value) {
                    this.form.post('/api/crm/crm-events/'+id).then(()=>{
                            swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                            )
                                this.loadEvents(this.lead_id);
                        Fire.$emit('AfterCreate');
                        
                    }).catch(()=> {
                        swal("Failed!", "There was something wronge.", "warning");
                    });
                }
            })
        },
    },
    created() {
        this.loadEvents();
        this.getData();
        Fire.$on('AfterCreate',() => {
            this.loadEvents();
            
        });
    //    setInterval(() => this.loadUsers(), 3000);
    },
}
</script>
 