<template lang="pug">
    .modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3(v-if="this.type == 2") SEND TO REVISSION
                h3(v-if="this.type == 3") DISAPPROVE
                h3(v-if="this.type == 4") APPROVE
                i#iconbloqu.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program{{numeroAcco}}
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            .col-lg-6.col-md-6(v-if="this.type == 4")
                                .form-group 
                                    label Advisor
                                    select(v-model="advisor_id" @click="selectAdvisor" style="width: 100%; height: 28px; border-radius: 5px;background:#f1f1f1")
                                            option(:value="ls.id" v-for="ls in advisor" :key="ls.id") {{ ls.user_name }}
                                    span(v-if="ADerror" style="color:red") Required
                            .col-lg-12.col-md-12
                                .form-group
                                    label Observation
                                    textarea.textarea-style(v-model="observation" @keyup="textObser")
                                    span(v-if="OBerror" style="color:red") Required
                            .col.lg-12.col-md-12
                                div(v-if="sendMessage")
                                    .progress
                                        .progress-bar.progress-bar-striped(role="progressbar" :style="'width:'+numeroAcco+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100") {{numeroAcco+'%'}}                                    
                            .col.lg-12.col-md-12
                                div(v-if="sendMessageOk")
                                    span(style="color: green") Message sent
                            .col.lg-12.col-md-12
                                div(v-if="messageAutorize")
                                    ul
                                        li(v-for="(item, index) in dataAutorize" :key="index")
                                            span(style="color: green") {{item.text}}

            .modal-footer
                button#btnsucessblo.btn.btn-success.btn-color-green(:disabled="disabledButton"  type="button" style="width: 110px;font-size: 13px;padding: 6px;height: 35px;" :class="classColor" @click="revision")
                    span(v-if="this.type == 2") SEND 
                    span(v-if="this.type == 3") DISAPPROVE
                    span(v-if="this.type == 4") APPROVE                

</template>

<style lang="stylus">
    .textarea-style
        border: 1px solid #ccc;
        width: 100%;
        height: 70px;
        background #f1f1f1 !important
        border-radius 5px
    .input-card
        border: 1px solid #ccc;
        text-align: center;        
        width: 100%;
        height : 28px;
    .backg-red
        background : red !important
        border-color: red;
    .bloq-bton
        pointer-events none !important
</style>

<script>
export default {
    props:['idProgram','nameProgram','nameClient','typeRevission','idsales','sessionId','sessioName','valorInitalPaymetn','numberAccount'],
    data() {
        return {
            classColor: this.typeRevission == 3 ? 'backg-red' : '',
            program: this.nameProgram,
            advisor:[],
            advisor_id:null,
            client_name:this.nameClient,
            type: this.typeRevission,
            observation: '',
            account:this.numberAccount,
            sendMessage: false,
            sendMessageOk: false,
            OBerror: false,
            ACerror:false,
            ADerror:false,
            ACDerror:false,
            messageAutorize: false,
            dataAutorize: '',
            disabledButton: this.type == 4 && this.valorInitalPaymetn == 1 ? true : false,
            numeroAcco:0
        }
    },
    mounted() {
        //console.log(this.numberAccount)
        var module=0;
        switch (this.idProgram) {
            case 1: module=3;break;
            case 2: module=7;break;
            case 3: module=6;break;
            case 4: module=5;break;
            case 5: module=8;break;
        }
        axios.post('/api/usermodule/'+module,{
                    roles : '[2,3]',
                    type: '1'
                }).then(response => {
                    this.advisor = response.data;
                })
    },
    methods: {
        textAcount(){
            if(this.account != ''){
                this.ACerror = false
                this.ACDerror = false
            }
        },
        selectAdvisor(){
            if(this.advisor_id != null){
                this.ADerror = false
            }
        },
        textObser(){
            if(this.observation != ''){
                this.OBerror = false
            }
        },
        closeModal(){
            this.$emit('click',false)
        },
        revision(){
            if(this.type == 2 && this.observation == ''){
                this.OBerror = true
            }else if(this.type == 2 && this.observation != ''){
                this.send()
            }else if(this.type == 3 && this.observation == ''){
                this.OBerror = true
            }else if(this.type == 3 && this.observation != ''){
                this.send()
            }else if(this.type == 4 && this.observation == '' && this.advisor_id == null  ){
                this.OBerror = true
                this.ADerror = true
                this.ACerror = true
            }else if(this.type == 4 && this.observation == '' && this.advisor_id == null  ){                
                this.OBerror = true                
                this.ADerror = true
            }else if(this.type == 4 && this.observation == '' && this.advisor_id != null  ){
                this.OBerror = true
                this.ACerror = true
            }else if(this.type == 4 && this.observation == '' && this.advisor_id != null  ){
                this.OBerror = true
            }else if(this.type == 4 && this.observation != '' && this.advisor_id == null  ){
                this.ADerror = true
            }else if(this.type == 4 && this.observation != '' && this.advisor_id == null  ){
                this.ADerror = true
                this.ACerror = true
            }else if(this.type == 4 && this.observation != '' && this.advisor_id != null  ){
                this.sendAutorize()
            }
        },
        send(){                        
            var refresh = setInterval(this.nuevo, 1000);
            var btn = document.getElementById('iconbloqu')
            var btn2 = document.getElementById('btnsucessblo')
            btn.classList.add('bloq-bton')
            btn2.classList.add('bloq-bton')
            this.disabledButton = true
            this.sendMessage = true
                axios.post('/api/revisionsale',{
                    sale_id : this.idsales,
                    state: this.type,
                    observation : this.observation,
                    user_id: this.sessionId,
                    client : this.client_name,
                    advisor_id : this.advisor_id,
                    namesession: this.sessioName,
                }).then(response => {
                    if(response.status == 200) {
                        this.numeroAcco = 100
                        clearInterval(refresh)
                        //this.sendMessage = false
                        this.sendMessageOk = true
                        if(this.numeroAcco == 100){
                            setTimeout(() => {
                                this.$emit('response',true)
                                this.$emit('click',false)                            
                            }, 1000);
                        }
                    }            
                }).catch( (errors) => {
                    if(errors.response.status == 500) {
                        this.sendMessage = false
                        this.ACDerror = true
                        this.disabledButton = false
                        var btn2 = document.getElementById('btnsucessblo')
                        btn2.classList.remove('bloq-bton')
                        var btn = document.getElementById('iconbloqu')
                        btn.classList.remove('bloq-bton')
                    }                   
                })
        },
        nuevo(){
            this.numeroAcco = this.numeroAcco + 5
        },
        sendAutorize(){
            this.numero = this.numero + 5
            var btn = document.getElementById('iconbloqu')
            var btn2 = document.getElementById('btnsucessblo')
            btn.classList.add('bloq-bton')
            btn2.classList.add('bloq-bton')
            this.disabledButton = true
            this.sendMessage = true
                axios.post('/createSubscription',{
                    sale_id : this.idsales,
                    state: this.type,
                    observation : this.observation,
                    account: this.account,
                    user_id: this.sessionId,
                    client : this.client_name,
                    advisor_id : this.advisor_id,
                    namesession: this.sessioName,
                }).then(response => {
                    if(response.status == 200) {
                        this.numero = 100
                        this.sendMessage = false
                        this.sendMessageOk = true
                        this.messageAutorize = true
                        this.dataAutorize = response.data
                        setTimeout(() => {
                            this.$emit('response',true)
                            this.$emit('click',false)
                        }, 50);
                    }            
                }).catch( (errors) => {
                    if(errors.response.status == 500) {
                        this.numero = 0
                        this.sendMessage = false
                        this.ACDerror = true
                        this.disabledButton = false
                        var btn = document.getElementById('iconbloqu')
                        btn.classList.remove('bloq-bton')
                        var btn2 = document.getElementById('btnsucessblo')
                        btn2.classList.remove('bloq-bton')
                    }                   
                })
        }


    },    
}
</script>

