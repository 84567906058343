<template lang="pug"> 
    .row
        .col-md-12.my-2
            ul.list-unstyled.my-2
                li.media.list-unstyled.my-2.borde-nota(:class="{editmode: note.editmode, completed: !note.editmode}")
                    img.mr-3.img-responsive(:src="'/'+note.users.image" alt="" style="border-radius:50%;height: 50px;")
                    .media-body
                        h5.mt-0.mb-1(style="color:#DBCA00") {{ note.users.first_name }} {{ note.users.last_name }}
                            span(style="font-size: 10px; color: #ccc") - {{ note.created_at | myDate }}
                        textarea.input-edit-note(v-if="editmode" type="text" name="note" v-model="note.text")
                        p(style="color: #666666;" v-else) {{note.text}}
                    div(style="text-align: right")
                        span Lead -
                            span(style="font-size: 13px; color: #DBCA00") {{ note.lead.first_name }} {{ note.lead.last_name }}
                        br
                        button.bnt.btn-danger.btn-margin-new(v-if="editmode" @click.prevent="onClickCancel()")
                            i.fas.fa-ban
                        button.btn.btn-success.btn-margin-new(v-if="editmode" @click="onClickUpdate()")
                            note-icon(img="save")
                        button.btn-margin(v-else-if="iduser == note.user_id || idroles == 1 || idroles == 2" @click="onClickEdit()")
                            img(:src="'/images/edit.png'")
                        button.btn-margin(v-if="deletemode" @click="onClickDelete()")
                            img(:src="'/images/delete.png'")
                        br

</template>

<style lang="stylus">
    .btn-margin-new
        padding: 4px 10px;
        height: 31px;
        display: inline-flex;
        border-radius: 5px;
        margin: 5px;
</style>

<script>
    export default {
        props: ['note','iduser','idroles'],
        data() {
            return {
                editmode: false,
                deletemode: true,
            };
        },
        mounted() {
            //console.log(this.note)
        },
        methods: {
            onClickCancel(){
                this.editmode = false;
                this.deletemode = true;
            },
            onClickDelete() {
                const params = {
                id : this.note.id,
                deleted_by : this.iduser,
            };
            //console.log(params);
            swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ab9220',
                        cancelButtonColor: '#8f9194',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                            // Send request to the server
                    if (result.value) {
                        axios.post('/api/notesdelete',params).then(()=>{
                                swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                                )
                            this.$emit('delete');
                            //       this.loadEvents(this.lead_id);
                            Fire.$emit('AfterCreate');
                            
                        }).catch(()=> {
                            swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })
            },
            onClickEdit() {
                this.editmode = true;
                this.deletemode = false;
            },
            onClickUpdate() {
                const params = {
                    id : this.note.id,
                    note :this.note.text,
                    updated_by: this.iduser,
                };
                axios.put('/api/updatenotes', params)
                    .then((response) => {
                        swal.fire({
                            type: 'success',
                            title: 'Note Updated in successfully'
                            });
                    const note = response.data;
                    this.$emit('update', note);
                    this.$Progress.finish();
                    Fire.$emit('AfterCreate');
                    this.editmode=false;
                    this.deletemode = true;
                })
                .catch( (errors) => {
                    // console.log(errors);
                })
            }
        }
    }
</script>