<template>
    <div id="loading">
        <div v-if="grilla">
            <grilla-report-payment @clickDetail="clickDetail"></grilla-report-payment>
        </div>
        <div v-if="detail">
            <detail-report-payment :content="this.content" @clickList="clickList"></detail-report-payment>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                grilla: true,
                detail: false,
                content: []
            }
        },
        methods: {
            clickList(dato){
                this.detail = false
                this.grilla = dato                
            },
            clickDetail(dato){                
                this.detail = true
                this.grilla = false
                this.content = dato
            }
        },
    }
</script>