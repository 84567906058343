<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 YEAR PAYMENT
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Program
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ program }}
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ client_name }}
                        br
                        br
                        .col-lg-4.col-md-4
                            .form-group.row
                                .col-md-4
                                    label Ammount #[span(v-if="errorAmmount" style="color:red") Required]
                                .col-md-8(style="display:inherit") $
                                    input.input-card.input-form(:id="'campo'+1" type="text" v-model="ammount" v-on:blur="validInitial(1)" @keypress="justNumbers" step="any")                                    
                        .col-lg-8.col-md-8
                            .form-group.row
                                .col-md-4(style="margin-right: -15px")
                                    label Select Year #[span(v-if="errorYear" style="color:red") Required]
                                .col-md-8(style="background: #f1f1f1;padding: 5px 15px;border-radius: 5px;")
                                    select.input-form(v-model="year")
                                        option(value="2014") 2014
                                        option(value="2015") 2015
                                        option(value="2016") 2016
                                        option(value="2017") 2017
                                        option(value="2018") 2018
                                    
            .modal-footer
                .form-group
                    button.btn.btn-success.btn-color-green(type="button" @click="savePayment"  style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT    

</template>

<style lang="stylus">
    .text-style
        border: 1px solid #ccc;
        text-align: center;
        margin-left: 5px;
        width: 100%;
        height: 70px;
        border-radius: 10px;
</style>


<script>
export default {
    props:['payments','nameProgram','nameClient','type','editmodal','statusSale','sessionId','valorInitalPaymetn','account'],
    data() {
        return {
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            sendMessage: false,
            sendMessageOk: false,
            program: this.nameProgram,
            client_name:this.nameClient,
            ammount: '',
            valorEdit : false,
            method_payment: null,
            credit: false,
            cash: false,
            others: false,            
            card_id:'',            
            createmodal:false,
            specify : '',
            errosAutorize: [],
            messageAutorize: [],
            errosList: false,
            messageList: false,
            responseCode: '',
            errorAmmount:false,
            errorYear:false,
            types:'',
            merchant:'',
            transaction:'',
            date:'',
            newType:'',
            year:''
        }
    },
    mounted() {
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        validInitial(id){
            this.ammount = this.globalFunction(id)
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        cardEvent(id){            
            if(id == 0){
                this.credit = true
                this.others = false
            }else if (id == 1){
                this.credit = false
                this.others = true
            }
            this.method_payment = id
        },
        createcard(){
            this.createmodal = true;
            var x = document.getElementById('modal_event')
            x.classList.add('preloader-new')
        },
        closedModalCar(click){
            this.createmodal = false
            var x = document.getElementById('modal_event')
            x.classList.remove('preloader-new')
        },
        addCard(cards) {
            this.cards = cards;
        },
        savePayment(){
            if(this.ammount == ''){
                this.errorAmmount = true
            }else{
                this.errorAmmount = false
                if(this.year == ''){
                    this.errorYear = true
                }else{
                    this.errorYear = false
                    this.sendValidatePaynent(this.method_payment)
                }
            }            
        },
        sendValidatePaynent(type){
            var x = document.getElementById('modal_event')
            x.classList.add('preloader-new')
            swal.fire({
                title: 'Add Year Payment ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        this.sendMessage = true
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/api/saveyearpaymentmigrate',{
                            amount: this.ammount,
                            user_id: this.sessionId,
                            account: this.account,
                            year: this.year
                        }).then(response => {
                            if(response.status == 200) {
                                this.sendMessage = false
                                swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                    }).then( (res) => {
                                        if(res){
                                            this.$emit('click',false)
                                            }
                                        });
                            }
                        }).catch(error => {                    
                        })
                    }else{
                        var x = document.getElementById('modal_event')
                        x.classList.remove('preloader-new')
                    }
                });  
        },
    },
    created() {
        
    },
}
</script>