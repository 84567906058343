<template lang="pug">
    div
        .cont-title
            .row
                .col-lg-6
                    span Process
                .col-lg-6
                    button.btn.class-button-red(@click="returnList") #[i.fas.fa-caret-left(style="margin-right:5px")] RETURN
        .cont-search-paginate
            .row
                .col-lg-6
                    .row
                        .col-lg-8
                            .form-group                                
                                input.input-form(type="text" ref="file" name="file" placeholder="#Account" style="margin-bottom: 5px;" v-model="account")
                        .col-lg-4
                            .form-group
                                button.btn.class-button-green(@click="getcard") CONTINUE #[i.fas.fa-caret-right(style="margin-left: 10px;")] 
                .col-lg-12(v-if="campoexist")
                    span(style="color:#FF0000") The account number does not exist.
                .col-lg-12(v-if="campoocul")
                    .row
                        .col-lg-4
                            .form-group
                                div
                                    label Client
                                    input.input-form(type="text" disabled v-model="client")
                        .col-lg-4
                            .form-group
                                div
                                    label Program
                                    input.input-form(type="text" disabled v-model="program")
                        .col-lg-4
                            .form-group
                                div
                                    label Advisor
                                    input.input-form(type="text" disabled v-model="advisor")                        
                        .col-lg-4
                            .form-group
                                div(style="display:inherit")
                                    label Ammount #[span(style="color:red" v-if="errorAll") Required ] 
                                    br
                                    span $ 
                                    input(style="width: 80%;margin-left: 10px;" id="campo2" class="input-form" type="text" v-model="ammount" v-on:blur="validMounthly(2)" @keypress="justNumbers")
                        .col-lg-4
                            .form-group
                                label Type #[span(style="color:red" v-if="errorAll") Required ]
                                select.input-form(v-model="types")
                                    option(value="2") Cash
                                    option(value="3") Check 
                                    option(value="4") Money Order
                        .col-lg-4
                            .form-group
                                label Serial Number #[span(style="color:red" v-if="errorAll") Required ]
                                input.input-form(type="text" placeholder="#" v-model="transaction")                                           
                        .col-lg-4
                            .form-group
                                label Date #[span(style="color:red" v-if="errorAll") Required ]
                                kendo-datepicker(
                                    v-model="date"
                                    :min="minDate"
                                    :max="maxDate"
                                    :format="'MM/dd/yyyy'"
                                    class="input-form"
                                    name="date"
                                    id="date"
                                    style="background:white !important;width:150px;height: auto;display: inherit;")
                        .col-lg-12
                            .form-group
                                button.btn.btn-success(@click="processImport" style="font-size: 13px;padding: 6px;height: 35px;width: 15%;") SUBMIT

</template>

<style lang="stylus">
    .color-yellow
        background : #FF9800 !important
        color: black
    .color-green-text
        color: green
    .color-red-text
        color: red
</style>


<script>
    export default {
        props:['global','type'],
        data() {
            return {
                lead_id: 1,
                merchant: '',
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                account:'',
                client:'',
                program:'',
                advisor:'',
                ammount:'',
                transaction:'',
                date:'',
                types:'',
                campoocul: false,
                errorAll: false,
                campoexist: false,
            }
        },
        mounted() {
        },
        methods: {
            validMounthly(id){
                this.ammount = this.globalFunction(id)        
            },
            globalFunction(id){
                var x = document.getElementById('campo'+id).value            
                if(x.indexOf('.') != -1){ 
                    var numw = x.replace(/,/gi, "");
                    var dec = numw.split('.')
                    var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.'+dec[1]
                    
                }else{
                    var numw = x.replace(/,/gi, "");
                    var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                    num = num+'.00'
                }
                return num
            },
            justNumbers: function (event) {
                var charCode = window.event ? event.which : event.keyCode;
                var RE = /^\d*(\.\d{1})?\d{0,1}$/;
                if (charCode != 46 && charCode > 31 
                && (charCode < 48 || charCode > 57)) {
                    //Usando la definición del DOM level 2, "return" NO funciona.
                    event.preventDefault();
                }
            },
            returnList(){
                this.$emit('clickList',true)
            },
            processImport(){
                if(this.ammount == '' && this.types == '' && this.transaction == '' && this.date == ''){
                    this.errorAll = true
                }else if(this.ammount != '' && this.types != '' && this.transaction != '' && this.date != ''){
                    this.errorAll = false
                    this.axiosEnv()
                }                
            },
            getcard(){
                axios.post('/api/getcard',{
                    account: this.account
                }).then(response => {
                    if(response.status == 200){
                        if(response.data != null && response.data != ''){
                            this.campoocul = true
                            this.campoexist = false
                            this.cards = response.data
                            if (this.cards.length > 0) {
                                this.client = this.cards[0].client_name
                                this.program = this.cards[0].program
                                this.advisor = this.cards[0].advisor_name
                            }else{
                                this.client = ''
                                this.program = ''
                                this.advisor = ''
                            }
                        }else{
                            this.campoocul = false
                            this.campoexist = true
                        }
                    }
                })
            },
            axiosEnv(){
                axios.post('/api/saveOther',{
                    account : this.account,
                    ammount : this.ammount.replace(',',""),
                    transaction: this.transaction,
                    date: this.date,
                    merchant: 1,
                    idsession: this.global.layout.id,
                    type: this.types
                })
                .then(response => {
                    if(response.status == 200 ){                        
                        swal.fire({
                                type: 'success',
                                title: 'Register Payment Approve'
                            }).then( (res) => {
                                if(res){
                                    this.$emit('clickList',true)
                                    }
                                });
                    }
                })
            }
        },
        created() {            
        },
    }
</script>