<template>
<div>
    <div id="loading">
        <div class="cont-title">
            <div class="row">
                <div class="col-lg-6">
                    <span>Feeds</span>
                </div>
            </div>
        </div>
        <div class="cont-search-paginate">
            <div>
                <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                    <i class="fas fa-caret-left"></i>
                </button>
                <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                    <i class="fas fa-caret-right"></i>
                </button>
                
                <for-notes
                    v-for="(feed, index) in feeds" 
                    :key="index"
                    :feed = "feed"
                    :sessionuser = "global.layout.id"
                    @id="filtroid"
                ></for-notes>
                
                <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                    <i class="fas fa-caret-left"></i>
                </button>
                <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                    <i class="fas fa-caret-right"></i>
                </button>
            </div>
        </div> 
    </div>
    <div class="camp-feed borde-post-feed">
        <div class="modal-notas" v-if="modalopen">
            <div style="height:618px;">
                <div class="post-feed">
                    <div class="text-img">
                        <img v-bind:src="'/'+notemodal.image" width="50px"/>
                        <p class="text-princi texto-nota"><span class="name-nota">{{notemodal.updatedby}} </span> updated a Lead <span class="texto-bold"> {{notemodal.lead_name}}</span></p>
                        <p class="text-date nota-date">{{notemodal.updated_at | myDateGlobal }}</p>
                    </div>
                </div>
                <div class="cont-notes">
                    <h4>{{notemodal.lead_name}}</h4>
                    <div class="content-borde-total">

                    <span class="btn-borde-total">Total : ( {{notemodal.cantnotes}} )</span>

                    </div>
                    <div class="bor-notes" style="height: 340px;overflow: auto;">
                        <div v-for="(note , index) in JSON.parse(notemodal.notes)" :key="index" class="notes"><!--//notas-->   

                                <div class="content-user">
                                    <div class="content-name-user-img">
                                        <img v-bind:src="'/'+note.image" width="30px"/>
                                    </div>
                                    <div class="content-name-user">
                                        <p class="name-user-note" style="position:relative !important">{{note.user_name}}</p> 
                                        <p class="text-note">{{note.text}}</p>
                                        <p class="date-note">{{note.created_at | myDateGlobal}}</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cont-notes form-note-cont">
                    <div class="form-note">
                        <input type="text" style="border: 1px solid #ccc;width: 100%" @click="openbuttonmodal" v-model="textnotemodal" placeholder="Write a comment">
                        <div v-if="buttonformmodal" class="cont-button">
                            <button class="btn btn-success btn-notes bg-verde-light" @click="savenotemodal" style="display: inline-block"> <i class="fas fa-save"></i> Submit</button>
                            <button class="btn btn-danger btn-notes bg-red" @click="cancelnotemodal" style="display: inline-block"> <i class="fas fa-times fa-times-new"></i> Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="cont-btn-close">
                    <button @click="closemodal" class="btn btn-danger btn-notes bg-red" style="display: inline-block"><i class="fas fa-times fa-times-new"></i> Close</button>
                </div>
            </div> 
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props:['global'],
        data() {
            return {                
                feeds: [],
                NUM_RESULTS: 19,
                start_page: '',
                next_page: '',
                ultima_pagina :'',
                paginas:[],
                modalopen: false,
                notemodal:[],
                buttonformmodal: false,
                textnotemodal: '',
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
        },
        methods: {
            filtroid(id){
                const param = {
                    id: id,
                };
                axios.post('/api/allnotes',param)
                .then(response => {
                    if(response.status == 200){
                        this.notemodal = response.data;
                        var boton = document.getElementById('loading')
                        boton.classList.add('preloader')
                        this.modalopen = true
                    }
                })
            },
            openbuttonmodal(){
                this.buttonformmodal = true;                
            },
            savenotemodal(){
                const param = {
                    idlead: this.notemodal.id,
                    user_id: this.global.layout.id,
                    text: this.textnotemodal,
                };
                axios.post('/api/newnotelistmodal',param)
                .then(response => {                    
                    this.notemodal = response.data[0];
                    this.textnotemodal = ''
                    this.listfeeds();
                })
            },
            cancelnotemodal(){
                this.buttonformmodal = false;
                this.textnotemodal = ''
            },
            closemodal(){
                this.modalopen = false;
                var boton = document.getElementById('loading')
                        boton.classList.remove('preloader')
            },
            prev(start_p){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                var newval = start_p - 1;
                axios({
                    method: 'Get',
                    responseType:'json',
                    url: "/api/listfeeds?page="+newval+""
                }).then(response => {
                    if(response.status == 200){
                        this.feeds = response.data.data
                        this.start_page =  response.data.current_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios({
                   method: 'Get',
                   responseType:'json',
                   url: "/api/listfeeds?page="+next+""
                }).then(response => {
                    if(response.status == 200) {
                        this.feeds = response.data.data
                        this.start_page =  response.data.current_page;                    
                        this.ultima_pagina = response.data.last_page;

                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },            
            listfeeds(){
                axios.get('/api/listfeeds')
                .then(response => {
                    if(response.status == 200){
                        this.feeds = response.data.data;
                        this.start_page =  response.data.current_page;                        
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
        },
        created() {
            this.listfeeds()
        },     
    }
</script>