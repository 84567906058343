<template>
    <div>
        <div id="loading">
            <div class="row ">
                <form @submit.prevent="createLead()">
                    <div class="col-md-12">
                        <div class="cont-title" id="menu-nav-fixed">
                            <div class="row">
                                <div class="col-lg-6">
                                    <span>Create Lead</span>
                                </div>
                                <div class="col-lg-6" style="text-align:right">
                                        <a href="/crm/leads" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;"><i class="far fa-times-circle"></i> CANCEL</a>
                                        <button v-show="createLead" type="submit" class="btn btn-color-green create" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="far fa-save"></i>  SAVE</button>
                                        <a id="buttonsaveedit" @click="saveEdit"  class="btn btn-color-blue create" style="width:130px;font-size: 13px;padding: 6px;height: 35px;color:white"> <i class="far fa-save"></i>  SAVE AND EDIT</a>
                                </div>
                            </div>
                        </div>
                        <div class="cont-search-paginate">
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">User Information creator</span>
                                            <div class="row" >
                                                <div class="col-lg-3">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Creator user</label>
                                                            <select name="user_id" id="user_id" v-model="form.usercreator"  class="input-form" 
                                                                :class="{ 'is-invalid': form.errors.has('usercreator') }" @click="useroption">
                                                                <option  :value="null" disabled>Select user</option>
                                                                <option :value="ls.id" v-for="ls in users" :key="ls.id">{{ ls.user_name}}</option>
                                                            </select>
                                                            <has-error :form="form" field="usercreator"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Creation date</label>
                                                            <kendo-datepicker
                                                                :format="'MM/dd/yyyy'"
                                                                v-model="form.datecreator"
                                                                class="input-form"
                                                                name="date" id="date"
                                                                style="background: rgb(241, 241, 241) !important; width: 100%; height: auto; display: inherit;"
                                                                :class="{ 'is-invalid': form.errors.has('datecreator') }">
                                                            </kendo-datepicker>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Personal Information</span>
                                            <div class="row">
                                                <div class="col-lg-5" style="padding: 0px 50px 0 30px">
                                                    <div class="row" style="padding: 0px 5px 20px;background: #f1f1f1;border-radius: 5px;">
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">First name</label>
                                                                <input id="inputs" v-model="form.first_name" type="text" name="first_name"
                                                                class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('first_name') }" v-on:keyup="keymonitor" style="text-transform:capitalize">
                                                                <has-error :form="form" field="first_name"></has-error>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Middle name</label>
                                                                <input v-model="form.middle_name" type="text" name="last_name"
                                                                class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('middle_name') }" style="text-transform:capitalize">
                                                                <has-error :form="form" field="last_name"></has-error> 
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Last name</label>
                                                                <input v-model="form.last_name" type="text" name="last_name"
                                                                class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('last_name') }" style="text-transform:capitalize">
                                                                <has-error :form="form" field="last_name"></has-error> 
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Email</label>
                                                                <input name="email" :class="{ 'is-invalid': form.errors.has('email') }" id="email" 
                                                                v-model="form.email" type="email" class="input-form fond-white">
                                                                <has-error :form="form" field="email"></has-error> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <div class="row" style="padding: 0px 5px 20px;background: #f1f1f1;border-radius: 5px;">
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label for="">SSN</label>                                                    
                                                                <input name="ssn" :class="{ 'is-invalid': form.errors.has('ssn') }" id="SSN" 
                                                                v-model="form.ssn" type="text" maxlength="11" class="input-form fond-white" v-on:keyup="security">
                                                                <has-error :form="form" field="ssn"></has-error> 
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label for="">DOB</label>
                                                                <kendo-datepicker
                                                                    :max="maxDate"
                                                                    :value="currentDate"
                                                                    :format="'MM/dd/yyyy'"
                                                                    v-model="form.dob"
                                                                    class="input-form fond-white"
                                                                    style="background: #fff !important; width: 100%; height: auto; display: inherit;"
                                                                    name="date" id="date">
                                                                </kendo-datepicker>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5" style="padding: 0px 30px 0px 50px">
                                                    <div class="row" style="padding: 0px 5px 20px;background: #f1f1f1;border-radius: 5px;">
                                                        <div class="col-lg-6" >
                                                            <div>        
                                                                <div class="form-group">
                                                                    <label for="">Phone(H)</label>
                                                                    <input id="phone" v-model="form.phone" type="text" name="phone" maxlength="14" value=""
                                                                    class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('phone') }" v-on:keyup="phone" @paste="coppho">
                                                                    <has-error :form="form" field="phone"></has-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="">Phone(M)</label>
                                                                    <input id="mobile" v-model="form.mobile" type="text" name="mobile" maxlength="14" value=""
                                                                    class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('mobile') }" v-on:keyup="mobile" @paste="copmo">
                                                                    <has-error id="mobile-error" :form="form" field="mobile"></has-error>
                                                                    <span style="color:red" v-if="errorFormatMobile">Format Invalid</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div>
                                                                <div class="form-group">
                                                                    <label for="">Phone(W)</label>
                                                                    <input id="work" v-model="form.work" type="text" name="work" maxlength="14" value=""
                                                                    class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('work') }" v-on:keyup="work" @paste="cowor">
                                                                    <has-error :form="form" field="work"></has-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="border-campo-user">
                                            <span class="title-modal title-menor">Mailing Address Information</span>
                                            <div class="row" >
                                                <div class="col-lg-12" >
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Mailing address</label>                                                
                                                            <vue-google-autocomplete
                                                                ref="addressprincipal"
                                                                id="address_principal"
                                                                classname="form-control input-form fond-white"
                                                                placeholder="Please type your address"
                                                                v-on:placechanged="getAddressData"
                                                                country="us"
                                                                v-model="form.street"
                                                                style="height:30px !important;background:#f1f1f1 !important"
                                                            >
                                                            </vue-google-autocomplete>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">City</label>
                                                            <input type="text"  name="city" id="city"  class="input-form" v-model="form.city">
                                                            <has-error :form="form" field="city"></has-error>
                                                        </div>                                               
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">State</label>
                                                            <select class="input-form" name="state" id="state" v-model='form.state' >
                                                                <option :value="state.slug" v-for="state in states" :key="state.id">{{state.state}}</option>
                                                            </select>
                                                            <has-error :form="form" field="state"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Zip Code</label>
                                                            <input type="text"  name="zipcode" id="" class="input-form" v-model="form.zipcode" >  
                                                            <has-error :form="form" field="zipcode"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Country</label>
                                                            <input type="text" name="country" id="" disabled placeholder="EE.UU." class="input-form" v-model="form.country" > 
                                                            <has-error :form="form" field="country"></has-error>
                                                        </div>  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Lead Information</span>
                                            <div class="row" style="margin:0px">
                                                <div class="col-lg-9" style="padding-left:0px">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Lead Owner</label>
                                                                <select name="user_id" id="" v-model="form.user_id"  class="input-form" 
                                                                    :class="{ 'is-invalid': form.errors.has('user_id') }">
                                                                    <option  :value="null" disabled>Select Lead Owner</option>
                                                                    <option :value="ls.id" v-for="ls in usersowner" :key="ls.id">{{ ls.user_name}}</option>
                                                                </select>
                                                                <has-error :form="form" field="user_id"></has-error>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Lead status </label>               
                                                                <select name="leadstatus_id" id="leadstatus_id" class="input-form"
                                                                    v-model="form.leadstatus_id" 
                                                                    :class="{ 'is-invalid': form.errors.has('leadstatus_id') }" @click="leadstatu">
                                                                    <option :value="null" disabled>Select Lead Status</option>
                                                                    <option :disabled="global.layout.role_id == 3 && ls.id == 7" :value="ls.id" v-for="ls in leadstatus" :key="ls.id" v-show="ls.id != 7">{{ ls.value }}</option>
                                                                </select>
                                                                <has-error :form="form" field="leadstatus_id"></has-error> 
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Lead Source</label>
                                                                <select name="source_id" id="source_id"  class="input-form"
                                                                    v-model="form.source_id" 
                                                                    :class="{ 'is-invalid': form.errors.has('source_id') }" @click="source">
                                                                    <option  :value="null" disabled>Select Lead Source</option>
                                                                    <option :value="ls.id" v-for="ls in leadsource" :key="ls.id">{{ ls.value }}</option>
                                                                </select>
                                                                <has-error :form="form" field="source_id"></has-error>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Source name</label>
                                                                    <select name="sourcesname_id" id="sourcesname_id"  class="input-form"
                                                                    v-model="form.sourcesname_id" 
                                                                    :class="{ 'is-invalid': form.errors.has('sourcesname_id') }" @click="sourcesname">
                                                                    <option  :value="null" disabled>Select Source name</option>
                                                                    <option :selected="ls.id == form.sourcesname_id" :value="ls.id" v-for="ls in leadsourcename" :key="ls.id" v-show="ls.active=='Y'">{{ ls.name }}</option>
                                                                </select>
                                                                <has-error :form="form" field="sourcesname_id"></has-error>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label for="">Payment </label>
                                                                <br>
                                                                <input type="radio" name="payment" id="nopay" value="0" v-model="form.payment" >
                                                                <label for="nopay" style="margin-right:15px">NO</label>                                                                
                                                                <input type="radio" name="payment" id="yespay" value="1" v-model="form.payment" >
                                                                <label for="yespay">YES</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="form.payment == '1'">
                                                            <div class="form-group">
                                                                <label for="">Ammount </label>
                                                                <br>
                                                                $<input v-model="form.ammount" type="text" step="any" class="input-form" id="ammount" @blur="ammountType" @keypress="justNumbers" style="width: 50%;text-align: right;margin-left: 5px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div>
                                                        <div class="form-group">
                                                            <label for="">Programs</label>
                                                            <div class="row" style="background: #f1f1f1;border-radius:5px">                                                    
                                                                <div v-for="pro in program" :key="pro.id" class="col-lg-12">
                                                                    <input type="checkbox" :id="pro.id" v-model="form.program" :value="pro.id" >
                                                                    <label :for="pro.id">{{pro.value}}</label>
                                                                </div>
                                                            </div>
                                                        </div>                                              
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3" style="display: inline-grid;">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Credit Report</span>
                                            <div class="row" >
                                                <div :class="form.credit_report == true ? 'col-lg-6' : 'col-lg-12'" style="text-align: center">
                                                    <div class="form-group" style="margin-top: 6px;">
                                                        <div class="custom-control custom-switch" style="padding:0;margin-left:0">
                                                            <label style="margin-right: 35px;">NO</label>
                                                            <input type="checkbox" class="custom-control-input" name="credit" id="credit" checked v-model="form.credit_report">                                                            
                                                            <label class="custom-control-label" for="credit">YES</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6" v-if="form.credit_report == true">                                                    
                                                    <div class="form-group" style="display: inline-flex;background: #f1f1f1;border-radius: 5px;padding: 2px 4px;margin-top: 6px;">
                                                        <input type="radio" name="type_credit" id="online" value="1" v-model="form.type_credit" style="margin-top: 4px;">
                                                        <label for="online" style="margin-right: 12px;">Online</label>
                                                        <br>
                                                        <input type="radio" name="type_credit" id="realtor" value="2" v-model="form.type_credit" style="margin-top: 4px;">
                                                        <label for="realtor">Realtor</label>
                                                    </div>
                                                </div>
                                                <div class="row" style="margin:0px">
                                                    <div class="col-lg-12" v-if="form.type_credit == '1' && form.credit_report == true ">
                                                        <div>
                                                            <label for="">Plataforms</label>
                                                            <select name="plataform" id="plataform" class="input-form" v-model="form.plataform">
                                                                <option :value="null" disabled>Select Plataform</option>
                                                                <option :value="platafomr.id" v-for="platafomr in plataforms" :key="platafomr.id">{{ platafomr.name }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                        <div>
                                                            <label for="">Date</label>
                                                            <kendo-datepicker
                                                                :max="maxDate"
                                                                :value="currentDate"
                                                                :format="'MM/dd/yyyy'"
                                                                v-model="form.dateonline"
                                                                class="input-form"
                                                                style="background: rgb(241, 241, 241) !important; width: 100%; height: auto; display: inherit;"
                                                                name="date" id="date">
                                                            </kendo-datepicker>
                                                        </div>
                                                    </div>                                            
                                                    <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                        <div>
                                                            <label for="">Username</label>
                                                            <input v-model="form.usernameonline" type="text" class="input-form">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                        <div>
                                                            <label for="">Password</label>
                                                            <input v-model="form.passwordonline" type="text" class="input-form">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                        <div>
                                                            <label for="">Member number</label>
                                                            <input v-model="form.membernumberonline" type="text" class="input-form">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-6" style="display: inline-grid;">
                                        <div class="border-campo-user" >
                                            <span class="title-modal">Description</span>
                                            <div class="row" >
                                                <div class="col-lg-12">
                                                    <div class="form-group">                                                        
                                                        <textarea name="description" class="form-control description input-form" style="height: auto;color: #666666 !important;font-size: 16px !important;background: #f1f1f1 !important;" id="" cols="30" rows="5" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                                                        <has-error :form="form" field="description"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Credit Cards</span>
                                            <div class="row" >
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-12" v-if="cardoptions">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for=""> Card Holder Name</label>
                                                                        <input type="text" name=""  class="input-form" v-model="form.cardholdername" style="text-transform:capitalize">
                                                                    </div>                                                                                                                                                     
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for=""> Card number</label>
                                                                        <input type="text" name="" id="CN" class="input-form" v-model="form.cardnumber" maxlength="19" v-on:keyup="tarjeta" @paste="cotarjet" >
                                                                    </div>                                                                                                                                                     
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <div class="form-group">
                                                                        <label for="">MM</label>
                                                                        <input type="text" name="" id="" class="input-form" v-model="form.card_expi_month" maxlength="2">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <div class="form-group">
                                                                        <label for="">YY</label>
                                                                        <input type="text" name="" id="" class="input-form" v-model="form.card_expi_year" maxlength="2">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <div class="form-group">
                                                                        <label for="">CVC</label>
                                                                        <input type="text" name="" id="" class="input-form" v-model="form.cardsecuritycode" maxlength="4">
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for="">Billing Address is the same the Mailling Address ?</label>
                                                                        <br>
                                                                        <div style="display: inline-flex;width: 100%;margin-left: 20px;">
                                                                            <div>
                                                                                <input value ="1" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="admin" @click="noaddress">
                                                                                <label class="form-check-label" for="admin" @click="noaddress" style="margin-right: 25px;">
                                                                                    Yes
                                                                                </label>                                                                    
                                                                            </div>
                                                                            <div>
                                                                                <input value="0" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="others" @click="yesaddress">
                                                                                <label class="form-check-label" for="others" @click="yesaddress">
                                                                                    No
                                                                                </label>                                                                   
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                                    
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12" v-if="otherinfo">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="form-group">
                                                                        <label>Mailing address</label>
                                                                        <vue-google-autocomplete
                                                                            ref="addresscard"
                                                                            id="address_card"
                                                                            classname="form-control input-form fond-white"
                                                                            placeholder="Please type your address"
                                                                            v-on:placechanged="getAddressDataCard"
                                                                            country="us"
                                                                            v-model="form.streetcard"
                                                                            style="height:30px !important;background:#f1f1f1 !important"
                                                                        >
                                                                        </vue-google-autocomplete>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for="">City</label>
                                                                        <input type="text"  name="city" id="city"  class="input-form" v-model="form.citycard">
                                                                        <has-error :form="form" field="city"></has-error>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for="">State</label>
                                                                        <select class="input-form" name="state" id="state" v-model='form.statecard' >
                                                                            <option :value="state.slug" v-for="state in states" :key="state.id">{{state.state}}</option>
                                                                        </select>
                                                                        <has-error :form="form" field="state"></has-error>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for="">Zip Code</label>
                                                                        <input type="text"  name="zipcode" id="" class="input-form" v-model="form.zipcodecard" >  
                                                                        <has-error :form="form" field="zipcode"></has-error>
                                                                    </div>
                                                                </div>                                                                    
                                                                <div class="col-lg-6">
                                                                    <div class="form-group">
                                                                        <label for="">Country</label>
                                                                        <input type="text" name="country" id="" disabled class="input-form" v-model="form.countrycard" > 
                                                                        <has-error :form="form" field="country"></has-error>
                                                                    </div>                                                                        
                                                                </div>                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
export default{
    components: { VueGoogleAutocomplete },
    props:['global'],
    data(){
        return{
            errorFormatMobile:false,
            address_card: '',
            address_principal: '',
            cardoptions:true,
            ocultbtn: true,
            otherinfo: false,
            otherinfoclick:'1',
            newarray:{},
            states:[],
            leads:{},
            users: [],
            usersowner:[],
            items:[],
            options:[],
            leadsource: [],
            leadstatus:[],
            assigneduser:[],
            leadsourcename:[],
            assigned_user_id:[],
            date_assignment:[],
            plataforms:[],
            activities:true,
            program: [],
            priority:[],
            status:[],
            lead_id:{id:''},
            value: "8:00 AM",
            min: new Date(1000, 0, 1, 8, 0, 0),
            max: new Date(3000, 11, 31, 18, 0, 0),
            minDate: new Date(1000, 1, 1),
            maxDate: new Date(3000,1,1),
            currentDate: new Date(),
            form: new Form({
                id:'',
                email:'',
                user_id:this.global.layout.id,
                first_name:'',
                last_name:'',
                middle_name:'',
                source_id:null,
                sourcesname_id:null,
                program_id:null,
                phone:'',
                mobile:'',
                work:'',
                credit_report:false,
                payment:'0',
                ammount:'',
                program:[],
                leadstatus_id:null,                    
                street:'',
                city:'',
                state: 'CA',
                country:'United States',
                zipcode:'',
                description:'',
                card_expi_month:'',
                card_expi_year:'',
                ssn:'',
                cardholdername:'',
                cardnumber:'',
                cardsecuritycode:'',
                dob:'',
                super: this.global.layout.role_id,
                created_by: this.global.layout.id,
                usercreator:null,
                datecreator: moment().format('YYYY-MM-DD'),
                streetcard:'',
                citycard:'',
                zipcodecard:'',
                statecard:'',
                countrycard:'United States',
                type_credit: '0',
                dateonline: '',
                plataform: null,
                usernameonline: '',
                passwordonline: '',
                membernumberonline: '',
            }),
            dataccess: false,
        }
    },
    mounted() {
        window.addEventListener("keypress", function(event){
            if (event.keyCode == 13){
                event.preventDefault();
            }
        }, false);
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        if(this.form.ammount != null && this.form.ammount != ''){
            var data = formatter.format(this.form.ammount)
            this.form.ammount = data.replace('$', "")
        }
        setTimeout(() => {
            var boton = document.getElementById('app')
            boton.classList.remove('preloader')
        }, 50);
    },
    methods: {
        ammountType(){
            this.form.ammount = this.globalFunction()        
        },
        globalFunction(){
            var x = document.getElementById('ammount').value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        useroption(){
            var id = document.getElementById("user_id");
            if(this.form.usercreator != null){
                id.classList.remove('is-invalid')
            }
        },
        leadstatu(){
            var id = document.getElementById("leadstatus_id");
            if(this.form.leadstatus_id != null){
                id.classList.remove('is-invalid')
            }
        },
        source(){
            var id = document.getElementById("source_id");
            if(this.form.source_id != null){
                id.classList.remove('is-invalid')
            }
        },
        sourcesname(){
            var id = document.getElementById("sourcesname_id");
            if(this.form.sourcesname_id != null){
                id.classList.remove('is-invalid')
            }
        },
        crdtrpr(){
            this.form.type_credit = 0;
        },  
        validarSiNumero(numero){
            if (!/^([0-9])*$/.test(numero))
            alert("El valor " + numero + " no es un número");
        },
        
        getAddressData: function (address_principal, placeResultData, id) {
                this.direccion = address_principal;
                this.address_principal = this.direccion.street_number+' '+this.direccion.route;                
                var x = document.getElementById('address_principal');
                x.value = this.address_principal;
                this.form.street = this.address_principal;
                this.form.state = this.direccion.administrative_area_level_1;
                this.form.city = this.direccion.locality;
                this.form.zipcode = this.direccion.postal_code;
        },
        getAddressDataCard: function (address_card, placeResultData, id) {
                this.direccion = address_card;
                this.address_card = this.direccion.street_number+' '+this.direccion.route;
                var x = document.getElementById('address_card');
                x.value = this.address_card;
                this.form.streetcard = this.address_card;
                this.form.statecard = this.direccion.administrative_area_level_1;
                this.form.citycard = this.direccion.locality;
                this.form.zipcodecard = this.direccion.postal_code;
        },
        phone(){
            var flag = true;
            var z = document.getElementById("phone").value;
            if(z.length == 3 && flag){
                document.getElementById('phone').value = "("+z+")"+" ";
                flag = true;
            }else if(z.length == 9 && flag){
                document.getElementById('phone').value = z+'-';
                flag = false;
            }
        },
        coppho(e){
            var x1 = e.clipboardData.getData('text');
            var y1 = "("+x1.slice(0,3)+")"+" "+x1.slice(4,7)+'-'+x1.slice(8,12);    
            if(x1.length == 12){
                document.getElementById("phone").value = y1            
            }else{
                document.getElementById("phone").value = x1
            }
        },
        mobile(){
            var flag = true;
            var z = document.getElementById('mobile').value;
            var y = document.getElementById('mobile');
            if(y.getElementsByClassName('is-invalid')){
                if( z.length > 0 ){
                    y.classList.remove('is-invalid')
                }
            }
            if(z.length == 3 && flag){
                document.getElementById('mobile').value = "("+z+")"+" ";
                flag = true;
            }else if(z.length == 9 && flag){
                document.getElementById('mobile').value = z+'-';
                flag = true;
            }else if(z.length == 14 && flag){
                this.errorFormatMobile = false
                flag = false
                var x = document.getElementById('mobile')
                x.classList.remove('border-red')
            }
        },
        copmo(e){
            var x2 = e.clipboardData.getData('text');
            var y2 = "("+x2.slice(0,3)+")"+" "+x2.slice(4,7)+'-'+x2.slice(8,12);    
            if(x2.length == 12){
                document.getElementById("mobile").value = y2        
            }else{
                document.getElementById("mobile").value = x2
            }
        },
        work(){
            var flag = true;
            var w = document.getElementById('work').value;
            if(w.length == 3 && flag){
                document.getElementById('work').value = "("+w+")"+" ";
                flag = true;
            }else if(w.length == 9 && flag){
                document.getElementById('work').value = w+'-';
                flag = false;
            }
        },
        cowor(e){
            var x3 = e.clipboardData.getData('text');
            var y3 = "("+x3.slice(0,3)+")"+" "+x3.slice(4,7)+'-'+x3.slice(8,12);    
            if(x3.length == 12){
                document.getElementById("work").value = y3           
            }else{
                document.getElementById("work").value = x3
            }
        },
        opencard(){
            this.cardoptions = true;
            this.ocultbtn = false;
        },
        yesaddress(){
            this.otherinfo = true;
        },
        noaddress(){
            this.otherinfo = false;
        },
        tarjeta(){
            var flag = true;
            var x = document.getElementById('CN').value;

            if(x.length == 4 && flag){
                document.getElementById('CN').value = x+"-";
                flag = true;
            }else if(x.length == 9 && flag){
                document.getElementById('CN').value = x+'-';
                flag = false;
            }else if(x.length == 14  && flag){
                document.getElementById('CN').value = x+'-';
                flag = false;
            }
        },
        cotarjet(e){
            var x3 = e.clipboardData.getData('text');
            var y3 = x3.slice(0,4)+"-"+x3.slice(4,8)+'-'+x3.slice(8,12)+'-'+x3.slice(12,16);    
            if(x3.length == 16){
                document.getElementById("CN").value = y3           
            }else{
                document.getElementById("CN").value = x3
            }
        },
        security(){            
            var flag = true;
            var x = document.getElementById('SSN').value;

            if(x.length == 3 && flag){
                document.getElementById('SSN').value = x+"-";
                flag = true;
            }else if(x.length == 6 && flag){
                document.getElementById('SSN').value = x+'-';
                flag = false;
            }
        },        
        keymonitor(){
            var global = document.getElementById("inputs");
            var x = document.getElementById("inputs").value;
            if(x.length > 0){
                global.classList.remove('is-invalid')
            }
        },
        cancel(){            
            window.location="/crm/leads/";
        },
        saveEdit(){
            var bt = document.getElementById('buttonsaveedit')
            bt.classList.add("disabled");

            var x = document.getElementById('address_principal');
            this.form.street = x.value;

            let button = $('.create');
            var boton = document.getElementById('app');
            boton.classList.add('preloader')

            this.form.post('/api/leadscreate',this.form)
            .then( function(response) {                
                if(response.status == 201){
                    //console.log(response)
                    button.attr('disabled',true);
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    Fire.$emit('AfterCreate');
                    swal.fire({
                        type: 'success',
                        title: 'Lead Created in successfully'
                        }).then( (res) => {
                            if(res){                                
                                setTimeout(() => {
                                    var boton = document.getElementById('app');
                                    boton.classList.remove('preloader')
                                }, 50); 
                                window.location.href = '/crm/leads/edit/'+response.data.id;
                                }
                            });
                }
            })
            .catch( (errors) => {
                 if(errors.response.status == 500 || errors.response.status == 422){
                        var bt = document.getElementById('buttonsaveedit')
                        bt.classList.remove("disabled");
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
            })
        },
        createLead() {
            var x = document.getElementById('address_principal');
            this.form.street = x.value;

            let button = $('.create');
            var boton = document.getElementById('app');
            boton.classList.add('preloader')    
            if(this.form.credit_report == false){
                this.form.credit_report = 1
            }else if(this.form.credit_report == true){
                this.form.credit_report = 2
            }
 
            this.form.post('/api/leadscreate',this.form)
            .then( function(response) {                
                if(response.status == 201){
                    button.attr('disabled',true);
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    Fire.$emit('AfterCreate');
                    swal.fire({
                        type: 'success',
                        title: 'Lead Created in successfully'
                        }).then( (res) => {
                            if(res){
                                //console.log(res)
                                setTimeout(() => {
                                    var boton = document.getElementById('app');
                                    boton.classList.remove('preloader')
                                }, 50); 
                                window.location.href = '/crm/leads';
                                }
                            });
                }
            })
            .catch( (errors) => {
                if(errors.response.status == 500 || errors.response.status == 422){
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    this.form.credit_report = false
                }
            })
        },
        stateseeuu(){
            axios.get('/api/stateseeuu')
                .then((response) => {
                    this.states = response.data
                })
        },
        plataformall(){
            axios.get('/api/plataformall')
                .then((response) => {
                    this.plataforms = response.data
                })
        },
        userCreator(){
            axios.post('/api/sellerall/2',{ 
                roles : '[]',
                type : '0'
            }).then(response => {
                this.users = response.data;
            })
        },
        userCreatorOwner(){
            axios.post('/api/sellerall/2',{ 
                roles : '[]',
                type : '1'
            }).then(response => {
                this.usersowner = response.data;
            })
        },
        leadSources(){
            axios.get('/api/leadsource')
                .then(response => {
                    this.leadsource =  response.data 
                });
        },
        programsAll(){
            axios.get('/api/programs')
                .then(response => {
                    this.program =  response.data 
                });
        },
        leadStatus(){
            axios.get('/api/leadstatus')
                .then(response => {
                    this.leadstatus =  response.data 
                });
        },
        leadSourcename(){
            axios.get('/api/sourcesnames')
                .then(response => {
                    this.leadsourcename =  response.data 
                });
        },

    },
    created() {        
        this.stateseeuu();
        this.plataformall();
        this.userCreator();
        this.leadSources();
        this.programsAll();
        this.leadStatus();
        this.leadSourcename();
        this.userCreatorOwner();
        Fire.$on('AfterCreate',() => {
            
        });
    //    setInterval(() => this.loadUsers(), 3000);
    },
}

</script>


<style lang="stylus" scoped>
    .border-red
        border: 1px solid red
    .disable
        pointer-events: none
</style>
