<template>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Leads</span>
                            </div>
                            <div class="col-lg-6" v-if="type != 1">
                                <button @click="createLeadRoute" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px;"> CREATE</button>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalleads : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalleads }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right;diplay:inline-flex">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                                    <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Status Lead</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in leadstatus" :key="index" :value="item.id" v-show="index != 6">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Owner</label>
                                        <select v-model="owner" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in owners" :key="index" :value="item.id">{{ item.user_name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">CR</label>
                                        <select v-model="cr" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="2">YES</option>
                                            <option value="1">NO</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Programs</label>
                                        <select v-model="program" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Source Name</label>
                                        <select v-model="sourcename" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option :value="ls.id" v-for="ls in leadsourcename" :key="ls.id" v-show="ls.active=='Y'">{{ ls.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th></th>
                                        <th>
                                            <span v-if="oneEventLead" @click="search('asc',2)" style=" cursor: pointer;text-decoration: underline;">Name </span>
                                            <span v-if="orderLeadAsc" @click="search('desc',2)" style=" cursor: pointer;text-decoration: underline;">Name <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderLeadDesc" @click="search('asc',2)" style=" cursor: pointer;text-decoration: underline;">Name <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Status</th>
                                        <th>Mobile</th>
                                        <th>Owner</th>
                                        <th>CR</th>
                                        <th>Programs</th>
                                        <th>
                                            <span v-if="oneDateLead" @click="search('asc',10)" style=" cursor: pointer;text-decoration: underline;">Creation Date </span>
                                            <span v-if="orderDateAsc" @click="search('desc',10)" style=" cursor: pointer;text-decoration: underline;">Creation Date <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderDateDesc" @click="search('asc',10)" style=" cursor: pointer;text-decoration: underline;">Creation Date <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Modification Date</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr v-for="(lead , index) in leads" :key="index">
                                        <td style="text-align: center">
                                            <div class="btn-lead-green" v-if="lead.date_even > dateActualy && lead.type_event == 'event'">
                                                <i class="fas fa-calendar-check icon-lead"></i>
                                                <div class="cont-green" >
                                                    {{lead.date_even}}
                                                </div>
                                            </div>
                                            <div class="btn-lead-green" v-if="lead.date_even > dateActualy && lead.type_event == 'task'">
                                                <i class="fas fa-phone icon-lead"></i>
                                                <div class="cont-green" >
                                                    {{lead.date_even}}
                                                </div>
                                            </div>
                                            <div class="btn-lead-red" v-if="lead.date_even < dateActualy && lead.type_event == 'event'">
                                                <i class="fas fa-calendar-check icon-lead"></i>
                                                <div class="cont-red" >
                                                    {{lead.date_even}}
                                                </div>
                                            </div>
                                            <div class="btn-lead-red" v-if="lead.date_even < dateActualy && lead.type_event == 'task'">
                                                <i class="fas fa-phone icon-lead"></i>
                                                <div class="cont-red" >
                                                    {{lead.date_even}}
                                                </div>
                                            </div>
                                            <div class="btn-lead-blue" v-if="lead.date_even == dateActualy && lead.type_event == 'event'">
                                                <i class="fas fa-calendar-check icon-lead"></i>
                                                <div class="cont-blue" >
                                                    TODAY
                                                </div>
                                            </div>
                                            <div class="btn-lead-blue" v-if="lead.date_even == dateActualy && lead.type_event == 'task'">
                                                <i class="fas fa-phone icon-lead"></i>
                                                <div class="cont-blue" >
                                                    TODAY
                                                </div>
                                            </div>
                                            <div v-if="lead.date_even == null && lead.type_event == null">
                                                -
                                            </div>
                                        </td>
                                        <td class="blue-color">
                                            <a v-if="type == 0" :href="'/crm/leads/show/'+ lead.id" >{{lead.lead_name}}</a>
                                            <a v-if="type == 1" :href="'/administration/leads/edit/'+ lead.id" >{{lead.lead_name}}</a>
                                        </td>
                                        <td class="gris-color">{{ lead.status}}</td>
                                        <td class="gris-color">{{ lead.mobile}}</td>
                                        <td class="gris-color">{{ lead.owner}}</td>
                                        <td>                                            
                                            <span v-if="lead.credit_report == '1'" class="text-yes">NO</span>                                            
                                            <span v-else class="text-no">YES</span>                                             
                                        </td>
                                        <td>
                                            <ul style="margin-bottom: 0px;padding:0">                                                
                                                <li v-for="(program , index) in JSON.parse(lead.programs)" :key="index" style="list-style: none;">{{ program}}</li>                                                
                                            </ul>
                                        </td>
                                        <td class="gris-color">{{lead.created_at | myDate }}</td>
                                        <td class="gris-color">{{lead.updated_at | myDate }}</td>
                                        <td>
                                            <a v-if="type == 0" :href="'/crm/leads/edit/'+ lead.id" ><img :src="'/images/edit.png'"></a>
                                            <a v-if="type == 1" :href="'/administration/leads/edit/'+ lead.id" ><img :src="'/images/edit.png'"></a>
                                            <a v-if="global.layout.role_id == 1 || (global.layout.role_id == 2 && type == 0)" @click="deleteUser(lead.id)" style="cursor:pointer" title="Delete">
                                                <img :src="'/images/delete.png'">
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalleads : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalleads }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:['global', 'type'],
    data() {
        return {
            dateActualy: moment().format('YYYY-MM-DD'),
            advance : true,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),            
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: true,
            resetadvance: false,                      
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totalleads:'',
            leads:[],
            owners:[],
            programs:[],
            leadstatus:[],
            leadsourcename:[],
            oneDateLead:true,
            orderDateAsc:false,
            orderDateDesc:false,
            oneEventLead: true,
            orderLeadAsc:false,
            orderLeadDesc:false,
            campo1: '',
            status:null,
            owner:null,
            cr:null,
            program:null,
            from: '',
            to: '',
            dato1: 'desc',
            dato2: 10,
            sourcename:null
        }
    },
    mounted() {
        
    },
    methods: {
        advanceSearch(){
            this.advance = false
            this.searchadvance = false
            this.resetadvance =  true
            this.campo1 = ''
          
        },
        resertSearch(){
            this.advance = true
            this.searchadvance = true
            this.resetadvance =  false
            this.from = ''
            this.to = ''
            this.campo1 = null
            this.cr = null
            this.status = null
            this.owner = null
            this.program = null
            this.sourcename = null
            this.search(null,null)
        },
        
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/searchleads?page='+newval, {
                name_text: this.campo1,
                lead_status: this.status,
                cr: this.cr,
                program: this.program,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                orderby: this.dato2 == null ? 10 : this.dato2,
                order: this.dato1 == null ? 'desc' : this.dato1,
                user_owner : this.owner,
                sourcename: this.sourcename,
            }).then(response => {
                if(response.status == 200){
                    this.leads = response.data.data                
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;                    
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/searchleads?page='+next, {
                name_text: this.campo1,
                lead_status: this.status,
                cr: this.cr,
                program: this.program,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                orderby: this.dato2 == null ? 10 : this.dato2,
                order: this.dato1 == null ? 'desc' : this.dato1,
                user_owner : this.owner,
                sourcename: this.sourcename,
            }).then(response => {
                if(response.status == 200){
                    this.leads = response.data.data                                     
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;                   
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            });
        },
        search(dato1 , dato2){
            this.dato1 = dato1
            this.dato2 = dato2
            if(dato2 == 10){
                if(dato1 == "desc"){
                    this.oneDateLead = false
                    this.orderDateDesc = true
                    this.orderDateAsc = false
                }else{
                    this.orderDateAsc = true
                    this.orderDateDesc = false                    
                    this.oneDateLead = false
                }
            }else if(dato2 == 2){
                if(dato1 == "desc"){
                    this.oneEventLead = false
                    this.orderLeadDesc = true
                    this.orderLeadAsc = false
                }else{
                    this.orderLeadAsc = true
                    this.orderLeadDesc = false
                    this.oneEventLead = false
                }
            }
            axios.post('/api/searchleads',{
                name_text: this.campo1,
                lead_status: this.status,
                cr: this.cr,
                program: this.program,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                orderby: this.dato2 == null ? 10 : this.dato2,
                order: this.dato1 == null ? 'desc' : this.dato1,
                user_owner : this.owner,
                sourcename: this.sourcename,
            })
            .then(response => {
                if(response.status == 200){
                    this.leads = response.data.data
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    this.next_page = this.start_page+1;
                    this.ultima_pagina = response.data.last_page;
                    this.totalleads = response.data.total;
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50);                    
                }
            })
        },
        deleteUser(id){
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ab9220',
            cancelButtonColor: '#8f9194',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                    if (result.value) {
                    axios.post('/api/leaddelete',{
                        leadid : id,
                        idsession : this.global.layout.id
                        }).then(response=>{
                            swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success',
                            ).then( (res) => {
                                if(res){
                                        this.search('desc',10);
                                    }
                                });
                        Fire.$emit('AfterCreate');
                    }).catch(()=> {
                        swal("Failed!", "There was something wronge.", "warning");
                    });
                    }
            });
        },
        createLeadRoute(){
            window.location.href = '/crm/leads/create';
        },
        allOwners(){
            axios.post('/api/usermodule/2',{ 
                roles : '[]',
                type : '0'
            }).then(response => {
                    this.owners =  response.data 
                });
        },
        allPrograms(){
            axios.get('/api/programs')
                .then(response => {
                    this.programs =  response.data 
                });
        },
        allLeadStatus(){
            axios.get('/api/leadstatus')
                .then(response => {
                    this.leadstatus =  response.data 
                });
        },
        leadSourcename(){
            axios.get('/api/sourcesnames')
                .then(response => {
                    this.leadsourcename =  response.data 
                });
        },
    },
    created() {
        this.search('desc',10);
        this.allOwners();
        this.allPrograms();
        this.allLeadStatus();
        this.leadSourcename();
    },
}
</script>