<template>
    <div class="row ">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Payment Report</span>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-12">
                        <div style="float:right">                                    
                            <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="report()" > <img :src="'/images/icon-search.png'" ></button>
                        </div>
                        <div v-if="advance == false" style="display: inline-flex;float:right">  
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Years</label>
                                <select v-model="year" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                    <option value="2013">2013</option>
                                    <option value="2014">2014</option>
                                    <option value="2015">2015</option>
                                    <option value="2016">2016</option>
                                    <option value="2017">2017</option>
                                    <option value="2018">2018</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                </select>
                            </div>                                 
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Programs</label>
                                <select v-model="program" @change="condadvisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin: 0px 5px;">
                                <label for="">Advisors</label>
                                <select v-model="advisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in advisors" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                            <div class="form-group" style="margin-top: 25px;">                                        
                                <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="report()" > <img :src="'/images/icon-search.png'" ></button>                                        
                            </div>
                            <div class="form-group" style="margin-top:25px">
                                <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th></th>
                                <th>JAN</th>
                                <th>FEB</th>
                                <th>MAR</th>
                                <th>APR</th>
                                <th>MAY</th>
                                <th>JUN</th>
                                <th>JUL</th>
                                <th>AUG</th>
                                <th>SEP</th>
                                <th>OCT</th>
                                <th>NOV</th>
                                <th>DEC</th>
                            </tr>
                            <tr v-for="(item, index) in reports" :key="index" :style="index == 1 ? 'background: #ffff63' : index == 2 ? 'background: #bfff59' : index == 3 ? 'background : #a0d9ff' : index == 4 ? 'background: #f1f1f1' : index == 6 ? 'background: #999999' : '' ">
                                <td :style="index == 0 ? 'color: black' : index == 1 ? 'color:#BAA345 ; font-weight: bold;' : index == 2 ? 'color: #00CC00 ;font-weight: bold;' : index == 3 ? 'color:#0066FF ;font-weight: bold;' : 'font-weight: bold;'">{{item.col1}}</td>
                                <td :style="item.value_jan == '0.00%' || item.value_jan == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_jan}}</span></td>
                                <td :style="item.value_feb == '0.00%' || item.value_feb == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_feb}}</span></td>
                                <td :style="item.value_mar == '0.00%' || item.value_mar == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_mar}}</span></td>
                                <td :style="item.value_apr == '0.00%' || item.value_apr == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_apr}}</span></td>
                                <td :style="item.value_may == '0.00%' || item.value_may == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_may}}</span></td>
                                <td :style="item.value_jun == '0.00%' || item.value_jun == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_jun}}</span></td>
                                <td :style="item.value_jul == '0.00%' || item.value_jul == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_jul}}</span></td>
                                <td :style="item.value_aug == '0.00%' || item.value_aug == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_aug}}</span></td>
                                <td :style="item.value_sep == '0.00%' || item.value_sep == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_sep}}</span></td>
                                <td :style="item.value_oct == '0.00%' || item.value_oct == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_oct}}</span></td>
                                <td :style="item.value_nov == '0.00%' || item.value_nov == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_nov}}</span></td>
                                <td :style="item.value_dec == '0.00%' || item.value_dec == 0  ? 'color:#666666' : 'color: black'"><span :style="item.type_row == 9 ? 'background : white; color: black ; padding: 1px 10px;border-radius: 5px;' : ''">{{item.type_row == 9 ?'$ ': '' }}{{item.value_dec}}</span></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><a @click="envio(year,program,advisor,1,reports[0].value_jan,reports[1].value_jan,reports[2].value_jan,reports[3].value_jan,reports[4].value_jan,reports[5].value_jan,reports[6].value_jan)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,2,reports[0].value_feb,reports[1].value_feb,reports[2].value_feb,reports[3].value_feb,reports[4].value_feb,reports[5].value_feb,reports[6].value_feb)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,3,reports[0].value_mar,reports[1].value_mar,reports[2].value_mar,reports[3].value_mar,reports[4].value_mar,reports[5].value_mar,reports[6].value_mar)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,4,reports[0].value_apr,reports[1].value_apr,reports[2].value_apr,reports[3].value_apr,reports[4].value_apr,reports[5].value_apr,reports[6].value_apr)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,5,reports[0].value_may,reports[1].value_may,reports[2].value_may,reports[3].value_may,reports[4].value_may,reports[5].value_may,reports[6].value_may)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,6,reports[0].value_jun,reports[1].value_jun,reports[2].value_jun,reports[3].value_jun,reports[4].value_jun,reports[5].value_jun,reports[6].value_jun)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,7,reports[0].value_jul,reports[1].value_jul,reports[2].value_jul,reports[3].value_jul,reports[4].value_jul,reports[5].value_jul,reports[6].value_jul)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,8,reports[0].value_aug,reports[1].value_aug,reports[2].value_aug,reports[3].value_aug,reports[4].value_aug,reports[5].value_aug,reports[6].value_aug)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,9,reports[0].value_sep,reports[1].value_sep,reports[2].value_sep,reports[3].value_sep,reports[4].value_sep,reports[5].value_sep,reports[6].value_sep)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,10,reports[0].value_oct,reports[1].value_oct,reports[2].value_oct,reports[3].value_oct,reports[4].value_oct,reports[5].value_oct,reports[6].value_oct)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,11,reports[0].value_nov,reports[1].value_nov,reports[2].value_nov,reports[3].value_nov,reports[4].value_nov,reports[5].value_nov,reports[6].value_nov)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                                <td><a @click="envio(year,program,advisor,12,reports[0].value_dec,reports[1].value_dec,reports[2].value_dec,reports[3].value_dec,reports[4].value_dec,reports[5].value_dec,reports[6].value_dec)" style="cursor:pointer;color: blue;border: 1px solid blue;border-radius: 5px;padding: 4px 10px;">DETAILS</a></td>
                            </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['global'],
    data() {
        return {            
            advance : false,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),            
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: false,
            resetadvance: true,                      
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totalleads:'',            
            programs:[],
            advisors:[],            
            program:null,
            advisor:null,
            reports:[],
            year:2019,
            newarr: []
        }
    },
    mounted() {
        
    },
    methods: {
        condadvisor(){
            var module = -1
            if(this.program == 0){
                module = 0
            }else{
                switch (this.program) {                
                    case 1: module = 3; break;
                    case 2: module = 7; break;
                    case 3: module = 6; break;
                    case 4: module = 5; break;
                    case 5: module = 8; break;
                }
            }
            this.advisor = null
            axios.post('/api/usersprograms',{id:module})
                .then(response => {
                    this.advisors =  response.data 
                });
        },
        envio(year,program,advisor,month,percent,accounta,payment,automatic,manual,other){
            var newarr = {'year': year , 'month' : month , 'program': program , 'advisor': advisor , 'percent':percent,'accounta':accounta,'payment':payment,'automatic':automatic,'manual':manual,'other':other }
            console.log(newarr)
            this.$emit('clickDetail', newarr)
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
        },
        advanceSearch(){
            this.advance = true
            this.searchadvance = false
            this.resetadvance =  true          
        },
        resertSearch(){
            this.program = null
            this.advisor = null
            this.report()
        },
        allPrograms(){
            axios.get('/api/programs')
                .then(response => {
                    this.programs =  response.data 
                });
        },
        report(){
            axios.post('/api/reportpayment',{
                year: this.year,
                program: this.program,
                advisor: this.advisor
            })
            .then(response => {
                if(response.status == 200){
                    this.reports = response.data
                    console.log(this.reports)
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            })
            .catch(err => {
                console.error(err); 
            })
        }
    },
    created() {
        this.allPrograms()
        this.condadvisor()
        this.report()
    },
}
</script>