<template>
    <div>
        <div class="cont-noti">
            <i id="clickmodalnotifi" class="fas fa-bell icon-cam"  :style="this.validstyle == 0 ? 'color: #ccc;border: 2px solid #ccc;' : ''"></i>
            <div class="cant-noti" v-if="this.cant != 0" >{{ this.validstyle = true ? this.cant : '0'}}</div>
            <div class="cant-noti cant-all" v-if="this.cant > 99" >+99</div>
            <div id="mcaponotifications" class="cont-noti-list">
                <i class="fas fa-sort-up"></i>
                <ul style="margin:0; padding:0">
                    <li :style="notification.status == 0 ? 'background: #ffffff' : 'background: #dfdfdf;'" @click="changeStatus(notification.id)" class="li-noti" v-for="(notification, index) in notifications" :key="index">
                        <img v-if="notification.status == 0 && notification.type == 0" :src="'/images/icons/CRM---notifications-new-appointment.png'" alt="">
                        <img v-if="notification.status == 1 && notification.type == 0" :src="'/images/icons/CRM---notifications-new-appointment-gray.png'" alt="">                    
                        <img v-if="notification.status == 0 && notification.type == 1" :src="'/images/icons/CRM---notifications-new-call.png'" alt="">
                        <img v-if="notification.status == 1 && notification.type == 1" :src="'/images/icons/CRM---notifications-new-call-gray.png'" alt="">
                        <img v-if="notification.status == 0 && notification.type == 2" :src="'/images/icons/CRM---notifications-new-sale.png'" alt="">
                        <img v-if="notification.status == 1 && notification.type == 2" :src="'/images/icons/CRM---notifications-new-sale-gray.png'" alt="">
                        <img v-if="notification.status == 0 && notification.type == 3" :src="'/images/icons/CRM---notifications-new-list.png'" alt="">
                        <img v-if="notification.status == 1 && notification.type == 3" :src="'/images/icons/CRM---notifications-new-list-gray.png'" alt="">
                        <a :style="notification.status == 0 ? 'color: #B59200;' : 'color: #afafaf;'" :href="notification.link">{{notification.notification}} <span>{{notification.created_at | myDateGlobal}}</span></a>
                    </li>
                </ul>
                <button class="btn btn-seall" @click="notificationsAll">SEE ALL</button>
            </div>
        </div>
        <modal-notifications v-if="closemodal" @click="closeNotifications" :notifications="this.allnotifications" :start_page="this.start_page"
                            :next_page="this.next_page" :ultima_pagina="this.ultima_pagina" :totalnotifications="this.totalnotifications" :perpage="this.perpage" :global="this.global"></modal-notifications>
    </div>
</template>

<script>
/*notifications 
    type = 0 => appointment
    type = 1 => call
    type = 2 => list
    type = 3 => sale
*/
    export default {
        props:['global'],
        data() {
            return {
                prueba: 0,
                contList: false,
                notifications: [],
                closemodal: false,
                allnotifications: [],
                start_page: '',
                next_page: '',
                ultima_pagina :'',
                totalnotifications:'',
                perpage: '',
                validstyle: '',
                cant: ''
            }
        },
        mounted() {
           // console.log(this.validstyle)
            //console.log(this.cant)
        },
        methods: {
            /*recarga(){
                setInterval(this.suma, 3600000);             
            },*/
            all(){                
                this.cant = 0
                this.validstyle = 1
                axios.post('/api/getnotifications',{
                    id: this.global
                })
                .then(response => {
                    if(response.status == 200){
                        this.notifications = response.data
                        this.cant = this.notifications.length > 0 ?this.notifications[0].cn : 0
                        if(this.cant == 0 ){
                            this.validstyle = 0
                            //console.log(this.validstyle)
                        }
                    }                    
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            changeStatus(id){
                axios.post('/api/updatenotif',{
                    id: id
                })
                .then(res => {
                    //console.log(res)
                })
            },
            notificationsAll(){
                var boton = document.getElementById('content');
                boton.classList.add('preloader-event')
                axios.post('/api/allnotifications',{
                    id: this.global
                })
                .then(response => {
                    if(response.status == 200){
                        this.allnotifications = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalnotifications = response.data.total;
                        this.closemodal = true
                        this.contList = false
                        var elemento = document.getElementById('mcaponotifications')
                        elemento.classList.remove('open-noti')
                    }
                })
                .catch(err => {
                    console.error(err); 
                })                
            },            
            closeNotifications(click){
                this.closemodal = click
                var boton = document.getElementById('content');
                boton.classList.remove('preloader-event')
            }
        },
        created() {
            //this.recarga();
            this.all();
        },
    }
</script>

<style lang="stylus">
    .cont-noti
        position: absolute;
        right: 80px;
        top: 15px;
        font-size: 18px;
        z-index : 5
        text-align: right;
    .cant-noti
        font-size: 11px;
        position: absolute;
        top: -3px;
        right: -9px;
        color: white;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        text-align: center;
    .cant-noti.cant-all
        font-size: 11px;
        position: absolute;
        top: -6px;
        right: -13px;
        color: #fff;
        background: #f00;
        border-radius: 50%;
        width: 25px;
        height: 19px;
        text-align: center;
        padding-top: 1px;
    .icon-cam
        border: 2px solid white;
        border-radius: 50%;
        padding: 3px;
        color: white;
        cursor pointer
    .cont-noti-list
        background: #f5f5f5;
        width: 350px;
        margin-top: 8px;
        padding: 5px;
        display none
    .cont-noti-list.open-noti
        display block
    .cont-noti-list .li-noti
        color: #f00;
        display: block !important;
        margin: 4px 0px;
        background: #f0f0f0;
        text-align: left;
        font-size: 15px;
        height 50px
        padding-left 15px
    .cont-noti-list .li-noti a
        color: #dbc900;
        width: 100%;
        display: block;
        padding: 5px;
        font-weight bold
        display inline
    .cont-noti-list .li-noti a:hover
        text-decoration none
    .cont-noti-list i
        position: absolute;
        right: 8px;
        color: white;
        top: 30px;
    .cont-noti-list ul .li-noti img
        width: auto !important;
        padding: 0;
        box-sizing: border-box;
        margin-left: 0;
        display inline
    .cont-noti-list .li-noti a span
        display: flex;
        font-size: 12px;
        padding-left: 32px;
        color: #afafaf !important
    .btn-seall
        width: 100%;
        background: #B59200;
        color: white;
        font-weight: bold;
        height: 30px;
        padding: 5px;
    .btn-seall:hover
        color white
</style>
