<template>
    <div>
        <div style="background:white;padding-top:10px">
            <form>
                <div class="modal-body">
                    <div class="container cont-info" style="background:#f6f5f5" id="menu-nav-fixed">
                        <div class="row">
                            <div class="col-10">
                                <h2>{{ form.first_name }} {{ form.last_name }}</h2>
                            </div>
                            <div class="col-2">
                                <a href="/users" type="submit" class="btn btn-danger create">Back</a>
                            </div>
                        </div>  
                    </div>
                        <br>
                    <div class="campo/1">
                        <p class="name-campo">User Information</p>
                        <div class="container cont-info">
                            <div class="row">
                                <div class="col-12">
                                    <label>Avatar</label>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div v-if="form.image">
                                                <div v-if="url && form.image">
                                                    <img :src="url" class="img-responsive" height="70" width="90" style="display:block">
                                                    <img :src="'/'+form.image"  class="img-responsive" height="70" width="90" style="display:none">
                                                </div>
                                                <div v-else>
                                                    <img :src="'/'+form.image"  class="img-responsive" height="70" width="90">
                                                </div>
                                            </div>
                                            <div v-else>                                                                                                         
                                                <div v-if="url">
                                                    <img :src="url" class="img-responsive" height="70" width="90" style="display:block">
                                                    <img :src="'/images/avatar.png'" class="img-responsive" height="70" width="90" style="display:none">
                                                </div>
                                                <div v-else>
                                                    <img :src="'/images/avatar.png'" class="img-responsive" height="70" width="90">
                                                </div>
                                            </div>
                                            <div class="vld-parent">
                                                <loading :active.sync="isLoading"></loading>
                                            </div>
                                        </div>
                                    </div>
                                        <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>First Name</label>
                                                <input disabled v-model="form.first_name" type="text" name="first_name" ref="firstname"
                                                    class="form-control first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                                <has-error :form="form" field="first_name"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Last Name</label>
                                                <input disabled v-model="form.last_name" type="text" name="last_name"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                                <has-error :form="form" field="last_name"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Phone Number</label>
                                                <input disabled v-model="form.phone" name="phone" id="phone"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }" maxlength="12">
                                                <has-error :form="form" field="phone"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cellphone</label>
                                                <input disabled v-model="form.cellphone" name="cellphone" id="cellphone"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('cellphone') }" maxlength="12">
                                                <has-error :form="form" field="cellphone"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Date of Birth</label>
                                                <input disabled v-model="form.date_of_birth" name="date_of_birth" id="date_of_birth" type="date"
                                                class="form-control" :class="{ 'is-invalid': form.errors.has('date_of_birth') }">
                                                <has-error :form="form" field="date_of_birth"></has-error>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>       
                    </div>
                        <br>
                    <div class="campo/3">
                        <p class="name-campo">User Access</p>
                        <div class="container cont-info">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">                                        
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email (User Name)</label>
                                                <input disabled v-model="form.email" type="email" name="email"
                                                    class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                                <has-error :form="form" field="email"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Select Rol</label>  
                                                <select disabled name="role" v-model="form.rolenew" id="role" class="form-control" :class="{ 'is-invalid': form.errors.has('role') }">
                                                    <option :value="null" disabled>Select User Role</option>
                                                    <option :selected="rol.id == form.rolenew" v-if="rol.id != 1" v-for="rol in roles" :value="rol.id" :key="rol.id">{{ rol.name }}</option>
                                                </select>
                                                <has-error :form="form" field="role"></has-error>
                                            </div>
                                        </div>  
                                    </div>
                                    
                                </div>
                            </div>
                        </div>       
                    </div>
                        <br>
                    <div class="campo/2">
                        <p class="name-campo">Others</p>
                        <div class="container cont-info">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input  name="restriction_by_ip" v-model="form.restriction_by_ip" class="form-check-input" type="checkbox" id="restriction_by_ip">
                                            <label class="form-check-label" for="restriction_by_ip">
                                                This user having restriction ip
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input name="status" v-model="form.status" class="form-check-input" type="checkbox" id="status">
                                            <label class="form-check-label" for="status">
                                                Active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>       
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
    // Import component
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props:['global'],
        data() {
            return {
                viewrols:false,
                validate : false,
                notvalidate : true,   
                isLoading: false,           
                uploadReady: true,
                editmode: false,
                users : {},
                roles: {},
                rolesnews: {},
                modules : {},
                url:null,
                form: new Form({
                    id:this.global.user.id,
                    first_name : this.global.user.first_name,
                    last_name: this.global.user.last_name,
                    email: this.global.user.email,
                    password: this.global.user.password,
                    phone: this.global.user.phone,
                    cellphone: this.global.user.cellphone,
                    address: this.global.user.address,
                    date_of_birth: this.global.user.date_of_birth,
                    restriction_by_ip: this.global.user.restriction_by_ip,
                    status: this.global.user.status,
                    module_role:this.global.user.visualmodule,
                    file:this.global.user.file,
                    image:this.global.user.image,
                    super:this.global.layout.role_id,
                    superid:this.global.layout.id,
                    rolenew:this.global.user.role_id,
                }),
                dataccess: true,
                visualmodule: [],
                name_module:'',
                name_role:'',
                role: '',
                module: '',
                arrayeditRol: [],
                arrayeditModule: [],
                arrayidRol: [],
                arrayidModule: [],
                search:''
            }
        },
        components: {
            Loading
        },
        methods: {
            loadRoles() {
                axios.get("/api/role").then( ({data}) => (this.roles = data));
            },
            loadModules() {
                axios.get("/api/module").then( ({data}) => (this.modules = data));
            },
            loadNewrols() {
                axios.post("/api/rolesnews",
                { 
                    id : this.form.superid                    
                }).then( ({data}) => (this.rolesnews = data));
            },
        },
        created() {
           this.loadRoles();
           this.loadModules();
           this.loadNewrols();
          
          Fire.$on('AfterCreate',() => {
                this.loadRoles();
                this.loadModules();
                this.loadNewrols();
           });
        //    setInterval(() => this.loadUsers(), 3000);
        },
        
    }
</script>


<style>
    
   
</style>
