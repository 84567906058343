<template>
    <div>
        <div>
            <div id="loading">
                <div class="row ">
                    <form @submit.prevent="updateLead()">
                        <div class="col-md-12">
                            <div class="cont-title" id="menu-nav-fixed">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <span v-if="type == 0" >Edit Lead</span>
                                        <span v-else>Edit Client</span>
                                    </div>
                                    <div class="col-lg-6" style="text-align:right">
                                            <a v-if="type == 0" href="/crm/leads" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;" ><i class="far fa-times-circle"></i> CANCEL</a>
                                            <a v-if="type == 1" href="/administration/clients" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;"><i class="far fa-times-circle"></i> CANCEL</a>
                                            <a v-if="type == 2" href="/crm/clients" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;"><i class="far fa-times-circle"></i> CANCEL</a>                                        
                                            <button type="submit" class="btn btn-color-green create" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="far fa-save"></i>  SAVE</button>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="cont-search-paginate">
                                <div class="cont-user-info" v-if="type != 1">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="border-campo-user">
                                                <span class="title-modal">User Information creator</span>
                                                <div class="row" >
                                                    <div class="col-lg-3">
                                                        <div>
                                                            <div class="form-group">
                                                                <div class="form-group">
                                                                    <label for="">Creator user</label>
                                                                    <select name="user_id" id="" v-model="form.usercreator"  class="input-form" 
                                                                        :class="{ 'is-invalid': form.errors.has('user_id') }">
                                                                        <option  :value="null" disabled>Select user</option>
                                                                        <option :value="ls.id" v-for="ls in users" :key="ls.id">{{ ls.user_name }}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">Creation date</label>
                                                                <kendo-datepicker
                                                                    :min="minDate"
                                                                    :max="maxDate"
                                                                    :format="'MM/dd/yyyy'"
                                                                    v-model="form.datecreator"
                                                                    class="input-form"
                                                                    name="date" id="date"
                                                                    style="background: rgb(241, 241, 241) !important; width: 100%; height: auto; display: inherit;"
                                                                    >
                                                                </kendo-datepicker>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cont-user-info">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="border-campo-user">
                                                <span class="title-modal">Personal Information</span>
                                                <div class="row">
                                                    <div class="col-lg-5" style="padding: 0px 50px 0 30px">
                                                        <div class="row" style="padding: 0px 5px 20px;background: #f1f1f1;border-radius: 5px;">
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">First name</label>
                                                                    <input id="inputs" v-model="form.first_name" type="text" name="first_name"
                                                                    class="input-form fond-white" v-bind:class="{ 'is-invalid' : form.errors.has('first_name') }" >
                                                                    <has-error :form="form" field="first_name"></has-error>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Middle name</label>
                                                                    <input v-model="form.middle_name" type="text" name="last_name"
                                                                    class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('middle_name') }" style="text-transform:capitalize">
                                                                    <has-error :form="form" field="last_name"></has-error> 
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Last name</label>
                                                                    <input v-model="form.last_name" type="text" name="last_name"
                                                                    class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('last_name') }" style="text-transform:capitalize">
                                                                    <has-error :form="form" field="last_name"></has-error> 
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Email</label>
                                                                    <input name="email" :class="{ 'is-invalid': form.errors.has('email') }" id="email" 
                                                                    v-model="form.email" type="email" class="input-form fond-white">
                                                                    <has-error :form="form" field="email"></has-error> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <div class="row" style="padding: 0px 5px 15px;background: #f1f1f1;border-radius: 5px;">
                                                            <div class="col-lg-12">
                                                                <div class="form-group" style="position: relative">
                                                                    <label for="">SSN</label><br>
                                                                    <div v-if="form.ssn != '' && form.ssn != null">
                                                                        <span v-if="ocultSsn">{{'XXX-XX-'+form.ssn.substr(7)}}</span>
                                                                    </div>
                                                                    <input v-if="viewSsn" name="ssn" :class="{ 'is-invalid': form.errors.has('ssn') }" id="SSN" 
                                                                    v-model="form.ssn" type="text" maxlength="11" class="input-form fond-white" v-on:keyup="securityedit">
                                                                    <i v-if="ocultSsnEye" class="fas fa-eye" @click="openSsn" style="position: absolute;right: 0;top: 32px;"></i>
                                                                    <has-error :form="form" field="ssn"></has-error>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="form-group">
                                                                    <label for="">DOB</label>
                                                                    <kendo-datepicker
                                                                        :max="maxDate"
                                                                        :format="'MM/dd/yyyy'"
                                                                        v-model="form.dob"
                                                                        class="input-form fond-white"
                                                                        style="background: #fff !important; width: 100%; height: auto; display: inherit;"
                                                                        name="date" id="date">
                                                                    </kendo-datepicker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5" style="padding: 0px 30px 0px 50px">
                                                        <div class="row" style="padding: 0px 5px 20px;background: #f1f1f1;border-radius: 5px;">
                                                            <div class="col-lg-6" >
                                                                <div>        
                                                                    <div class="form-group">
                                                                        <label for="">Phone(H)</label>
                                                                        <input id="phone" v-model="form.phone" type="text" name="phone" maxlength="14" value=""
                                                                        class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('phone') }" v-on:keyup="phone" @paste="coppho">
                                                                        <has-error :form="form" field="phone"></has-error>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label for="">Phone(M)</label>
                                                                        <input id="mobile" v-model="form.mobile" type="text" name="mobile" maxlength="14"
                                                                        class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('mobile') }" @keyup="mobile" @paste="copmo">
                                                                        <has-error :form="form" field="mobile"></has-error>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div>
                                                                    <div class="form-group">
                                                                        <label for="">Phone(W)</label>
                                                                        <input id="work" v-model="form.work" type="text" name="work" maxlength="14" value=""
                                                                        class="input-form fond-white" :class="{ 'is-invalid': form.errors.has('work') }" v-on:keyup="work" @paste="cowor">
                                                                        <has-error :form="form" field="work"></has-error>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                       
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cont-user-info">
                                    <div class="row">
                                        <div :class=" type != 1 ? 'col-lg-3' :'col-lg-9'">
                                            <div class="border-campo-user">
                                                <span :class="type != 1 ? 'title-modal title-menor' : 'title-modal'">Mailing Address Information</span>
                                                <div class="row" >
                                                    <div class="col-lg-12" >
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">Mailing address</label>                                                
                                                                <vue-google-autocomplete
                                                                    ref="addressprincipal"
                                                                    id="address_principal"
                                                                    classname="form-control input-form fond-white"
                                                                    placeholder="Please type your address"
                                                                    v-on:placechanged="getAddressData"
                                                                    country="us"
                                                                    v-model="form.street"
                                                                    style="height:30px !important;background:#f1f1f1 !important"
                                                                >
                                                                </vue-google-autocomplete>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">City</label>
                                                                <input type="text"  name="city" id="city"  class="input-form" v-model="form.city">
                                                                <has-error :form="form" field="city"></has-error>
                                                            </div>                                               
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">State</label>
                                                                <select class="input-form" name="state" id="state" v-model='form.state' >
                                                                    <option :value="state.slug" v-for="state in states" :key="state.id">{{state.state}}</option>
                                                                </select>
                                                                <has-error :form="form" field="state"></has-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">Zip Code</label>
                                                                <input type="text"  name="zipcode" id="" class="input-form" v-model="form.zipcode" >  
                                                                <has-error :form="form" field="zipcode"></has-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">Country</label>
                                                                <input type="text" name="country" id="" disabled placeholder="EE.UU." class="input-form" v-model="form.country" > 
                                                                <has-error :form="form" field="country"></has-error>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6" v-if="type != 1">
                                            <div class="border-campo-user">
                                                <span class="title-modal">Lead Information</span>
                                                <div class="row" style="margin:0px">
                                                    <div class="col-lg-9" style="padding-left:0px">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Lead Owner</label>
                                                                    <select name="user_id" id="" v-model="form.user_id"  class="input-form" 
                                                                        :class="{ 'is-invalid': form.errors.has('user_id') }">
                                                                        <option  :value="null" disabled>Select Lead Owner</option>
                                                                        <option :value="ls.id" v-for="ls in usersowner" :key="ls.id">{{ ls.user_name}}</option>
                                                                    </select>
                                                                    <has-error :form="form" field="user_id"></has-error>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Lead status </label>               
                                                                    <select name="leadstatus_id" :disabled="this.type == 2" id="" class="input-form"
                                                                        v-model="form.leadstatus_id" 
                                                                        :class="{ 'is-invalid': form.errors.has('leadstatus_id') }">
                                                                        <option :value="null" disabled>Select Lead Status</option>
                                                                        <option :disabled="global.layout.role_id == 3 && ls.id == 7" :value="ls.id" v-for="ls in leadstatus" :key="ls.id" v-show="ls.id != 7">{{ ls.value }}</option>
                                                                    </select>
                                                                    <has-error :form="form" field="leadstatus_id"></has-error> 
                                                                </div> 
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Lead Source</label>
                                                                    <select name="source_id" id=""  class="input-form"
                                                                        v-model="form.source_id" 
                                                                        :class="{ 'is-invalid': form.errors.has('source_id') }">
                                                                        <option  :value="null" disabled>Select Lead Source</option>
                                                                        <option :value="ls.id" v-for="ls in leadsource" :key="ls.id">{{ ls.value }}</option>
                                                                    </select>
                                                                    <has-error :form="form" field="source_id"></has-error>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Source name</label>
                                                                        <select name="sourcesname_id" id=""  class="input-form"
                                                                        v-model="form.sourcesname_id" 
                                                                        :class="{ 'is-invalid': form.errors.has('sourcesname_id') }">
                                                                        <option  :value="null" disabled>Select Source name</option>
                                                                        <option :selected="ls.id == form.sourcesname_id" :value="ls.id" v-for="ls in leadsourcename" :key="ls.id" v-show="ls.active=='Y'">{{ ls.name }}</option>
                                                                    </select>
                                                                    <has-error :form="form" field="sourcesname_id"></has-error>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Payment </label>
                                                                    <br>
                                                                    <input type="radio" name="payment" id="nopay" value="0" v-model="form.payment" >
                                                                    <label for="nopay" style="margin-right:15px">NO</label>                                                                
                                                                    <input type="radio" name="payment" id="yespay" value="1" v-model="form.payment" >
                                                                    <label for="yespay">YES</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6" v-if="form.payment == '1'">
                                                                <div class="form-group">
                                                                    <label for="">Ammount </label>
                                                                    <br>
                                                                    $<input v-model="form.ammount" type="text" step="any" class="input-form" id="ammount" @blur="ammountType" @keypress="justNumbers" style="width: 50%;text-align: right;margin-left: 5px;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <div>
                                                            <div class="form-group">
                                                                <label for="">Programs</label>
                                                                <div class="row" style="background: #f1f1f1;border-radius:5px">                                                    
                                                                    <div v-for="( pro ,index ) in programs" :key="index" class="col-lg-12">
                                                                        <input type="checkbox" :id="'pro'+pro.id" v-model="form.programs" :value="pro.id" >
                                                                        <label :for="'pro'+pro.id">{{pro.value}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>                                              
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class=" type != 1 ? 'col-lg-3' :'col-lg-3'" style="display: inline-grid;">
                                            <div class="border-campo-user">
                                                <span class="title-modal">Credit Report</span>
                                                <div class="row" >
                                                    <div :class="form.credit_report == true ? 'col-lg-6' : 'col-lg-12'" style="text-align: center">
                                                        <div class="form-group" style="margin-top: 6px;">
                                                            <div class="custom-control custom-switch" style="padding:0;margin-left:0">
                                                                <label style="margin-right: 35px;">NO</label>
                                                                <input type="checkbox" class="custom-control-input" name="credit" id="credit" checked v-model="form.credit_report">                                                            
                                                                <label class="custom-control-label" for="credit">YES</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6" v-if="form.credit_report == true">                                                    
                                                        <div class="form-group" style="display: inline-flex;background: #f1f1f1;border-radius: 5px;padding: 2px 4px;margin-top: 6px;">
                                                            <input type="radio" name="type_credit" id="online" value="1" v-model="form.type_credit" style="margin-top: 4px;">
                                                            <label for="online" style="margin-right: 12px;">Online</label>
                                                            <br>
                                                            <input type="radio" name="type_credit" id="realtor" value="2" v-model="form.type_credit" style="margin-top: 4px;">
                                                            <label for="realtor">Realtor</label>
                                                        </div>
                                                    </div>
                                                    <div class="row" style="margin:0px">
                                                        <div class="col-lg-12" v-if="form.type_credit == '1' && form.credit_report == true ">
                                                            <div>
                                                                <label for="">Plataforms</label>
                                                                <select name="plataform" id="plataform" class="input-form" v-model="form.plataform">
                                                                    <option :value="null" disabled>Select Plataform</option>
                                                                    <option :value="platafomr.id" v-for="platafomr in plataforms" :key="platafomr.id">{{ platafomr.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                            <div>
                                                                <label for="">Date</label>
                                                                <kendo-datepicker
                                                                    :max="maxDate"
                                                                    :value="currentDate"
                                                                    :format="'MM/dd/yyyy'"
                                                                    v-model="form.dateonline"
                                                                    class="input-form"
                                                                    style="background: rgb(241, 241, 241) !important; width: 100%; height: auto; display: inherit;"
                                                                    name="date" id="date">
                                                                </kendo-datepicker>
                                                            </div>
                                                        </div>                                            
                                                        <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                            <div>
                                                                <label for="">Username</label>
                                                                <input v-model="form.usernameonline" type="text" class="input-form">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                            <div>
                                                                <label for="">Password</label>
                                                                <input v-model="form.passwordonline" type="text" class="input-form">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6" v-if="form.type_credit == '1' && form.credit_report == true">
                                                            <div>
                                                                <label for="">Member number</label>
                                                                <input v-model="form.membernumberonline" type="text" class="input-form">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cont-user-info">
                                    <div class="row">
                                        <div class="col-lg-6" v-if="type != 1" style="display: inline-grid;">
                                            <div class="border-campo-user" >
                                                <span class="title-modal">Description</span>
                                                <div class="row" >
                                                    <div class="col-lg-12">
                                                        <div class="form-group">                                                        
                                                            <textarea name="description" class="form-control description input-form" style="height: auto;color: #666666 !important;font-size: 16px !important;background: #f1f1f1 !important;" id="" cols="30" rows="5" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                                                            <has-error :form="form" field="description"></has-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class=" type != 1 ? 'col-lg-6' : 'col-lg-12'" style="display: inline-grid;">
                                            <div class="border-campo-user">
                                                <span class="title-modal">Credit Cards</span>
                                                <div class="row" >
                                                    <div class="col-lg-12">
                                                        <div class="row">                                                        
                                                            <div class="col-lg-12">
                                                                <table class="table">
                                                                    <tbody style="border: 1px solid #ccc;">
                                                                        <tr style="background: #f1f1f1;color: #999999;">
                                                                            <th>Card Holder Name</th>
                                                                            <th>Card Number</th>
                                                                            <th>MM</th>
                                                                            <th>YY</th>
                                                                            <th>CVC</th>
                                                                            <th>Actions</th>
                                                                        </tr>
                                                                        <tr v-for="card in form.cards" :key="card.id">
                                                                            <td style="color: #9b9b9b;text-transform: capitalize;">{{card.cardholdername}}</td>
                                                                            <td style="color: #9b9b9b;">{{'XXXX-XXXX-XXXX-'+card.cardnumber}}</td>
                                                                            <td style="color: #9b9b9b;">{{card.card_expi_month}}</td>
                                                                            <td style="color: #9b9b9b;">{{card.card_expi_year}}</td>
                                                                            <td style="color: #9b9b9b;">{{ card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3)}}</td>
                                                                            <td>
                                                                                <a @click="modalcard(card.id)" style="cursor: pointer"><i style="color: green" class="fas fa-eye"></i></a>
                                                                                <a v-if="form.super == 1 || form.super == 2" @click="deletecard(card.id)" style="cursor: pointer"><i style="color: red" class="fas fa-times-circle"></i></a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-lg-12" style="text-align: right" v-if="ocultbtn">                                                            
                                                                <a @click="createcard" class="btn class-button-green" style="width:80px;color:white"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;"> ADD</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </form>
                    <div class="col-lg-12">
                        <div class="row" style="margin:0">
                            <div class="col-lg-12">
                                <form-lead-note :global="global" v-if="type != 1"></form-lead-note>
                            </div>
                            <div class="col-lg-6" style="display: inline-grid;padding-right:0">
                                <form-lead-event :type="'event'" :global="global" v-if="type != 1" :state="this.type"></form-lead-event>
                            </div>
                            <div class="col-lg-6" style="display: inline-grid;padding-left:0">
                                <form-lead-task :type="'task'" :global="global" v-if="type != 1" :state="this.type"></form-lead-task>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal-card-edit v-if="open" :session="form.super" v-bind:cards="this.cards" @click="closed"></modal-card-edit>
            <modal-card-create v-if="createmodal" @click="closedmodal" :idlead="form.id" @new="addCard"></modal-card-create>
        </div>
    </div>
</template>



<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import moment from 'moment'
export default{
    components: { VueGoogleAutocomplete },
    props:['global','type'],
    data(){
        return{
            ocultSsn: true,
            ocultSsnEye: this.global.layout.role_id == 1 || this.global.layout.role_id == 2 ? true : false,
            viewSsn: false,
            plataforms:[],
            address_card: '',
            address_principal: '',
            open: false,
            createmodal:false,
            cards:[],
            cardoptions:false,
            ocultbtn: true,
            otherinfo: false,
            otherinfoclick:'0',
            selected: ['John'],
            newarray:{},
            states:[],
            leads:{},
            users: [],
            usersowner:[],
            items:[],
            options:[],
            leadsourcename:[],
            leadsource: [],
            leadstatus:[],
            assigneduser:[],
            date_assignment:null,
            activities:true,
            programs: [],
            priority:[],
            status:[],
            tasks:{},
            notes:{},
            lead_id:{id:''},
            editmode: false,
            editmodeE: false,
            editmodeT: false,
            date: null,
            value: "8:00 AM",
            min: new Date(1950, 0, 1, 8, 0, 0),
            max: new Date(2049, 11, 31, 18, 0, 0),
            minDate: new Date(1900, 1, 1),
            maxDate: new Date(2050,1,1),
            currentDate: new Date(),
            form: new Form({
                email:this.global.editleads[0].email,
                id:this.global.editleads[0].id,
                user_id:this.global.editleads[0].user.id,
                first_name:this.global.editleads[0].first_name,
                last_name:this.global.editleads[0].last_name,
                middle_name:this.global.editleads[0].middle_name,
                source_id:this.global.editleads[0].source.id,
                sourcesname_id:this.global.editleads[0].sourcesname_id,
                phone:this.global.editleads[0].phone,
                mobile:this.global.editleads[0].mobile,
                work:this.global.editleads[0].work,
                credit_report:false,
                payment:this.global.editleads[0].payment,
                ammount:this.global.editleads[0].ammount,
                leadstatus_id:this.global.editleads[0].status.id,
                street:this.global.editleads[0].street,
                city:this.global.editleads[0].city,
                state:this.global.editleads[0].states_eeuu_slug,
                country:this.global.editleads[0].country,
                zipcode:this.global.editleads[0].zipcode,
                description:this.global.editleads[0].description,
                ssn:this.global.editleads[0].ssn,
                dob: this.global.editleads[0].dob,
                updated_by: this.global.layout.id,
                usercreator:this.global.editleads[0].created_by,
                datecreator: moment(this.global.editleads[0].created_at).format('YYYY-MM-DD'),
                super: this.global.layout.role_id,
                cards: this.global.editleads[0].cards,
                programs : this.global.editleads[0].program,
                type_credit: this.global.editleads[0].type_credit_report,
                dateonline: this.global.editleads[0].credit ? this.global.editleads[0].credit.date : '' ,
                plataform: this.global.editleads[0].credit ? this.global.editleads[0].credit.plataform_id : '',
                usernameonline: this.global.editleads[0].credit ? this.global.editleads[0].credit.username : '',
                passwordonline: this.global.editleads[0].credit ? this.global.editleads[0].credit.password : '',
                membernumberonline: this.global.editleads[0].credit ? this.global.editleads[0].credit.member_number : '',
            }),
            dataccess: false,
            task: new Form({
                id:'',
                user_id:null,
                subject:'',
                date_created:'',
                lead_id:'',
                taskstatuses_id:null,
                taskpriorities_id:null,
                description:'',
                
            }),
            note: new Form({
                user_id:'',
                lead_id:'',
                text:'',
            }),
            pagex: 1,

        }
    },
    mounted() {
        if(this.global.editleads[0].ssn == null){
            this.viewSsn = true
            this.ocultSsnEye = false
        }else{
            this.viewSsn = false            
        }

        if(this.global.editleads[0].credit_report == 1){
            this.form.credit_report = false
        }else if(this.global.editleads[0].credit_report == 2){
            this.form.credit_report = true
        }

        window.addEventListener("keypress", function(event){
            if (event.keyCode == 13){
                event.preventDefault();
            }
        }, false);

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        if(this.form.ammount != null && this.form.ammount != '' ){
            var data = formatter.format(this.form.ammount)
            this.form.ammount = data.replace('$', "")
        }
        if(this.form.ammount == '0.00')
            this.form.ammount = ''
        setTimeout(() => {
            var boton = document.getElementById('app')
            boton.classList.remove('preloader')
        }, 50);
    },
    methods: {
        openSsn(){
            this.ocultSsn = false
            this.ocultSsnEye = false
            this.viewSsn = true
        },
        ammountType(){
            this.form.ammount = this.globalFunction()        
        },
        globalFunction(){
            var x = document.getElementById('ammount').value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        crdtrpr(){
            this.form.type_credit = 0;
            this.form.dateonline = '';
            this.form.plataform = '';
            this.form.usernameonline= '';
            this.form.passwordonline= '';
            this.form.membernumberonline= '';
        },

        phone(){
            var flag = true;
            var x = document.getElementById('phone').value;
            //console.log(x)

            if(x.length == 3 && flag){
                document.getElementById('phone').value = "("+x+")"+" ";
                flag = true;
            }else if(x.length == 9 && flag){
                document.getElementById('phone').value = x+'-';
                flag = false;
            }
        },
        coppho(e){
            var x1 = e.clipboardData.getData('text');
            var y1 = "("+x1.slice(0,3)+")"+" "+x1.slice(4,7)+'-'+x1.slice(8,12);    
            if(x1.length == 12){
                document.getElementById("phone").value = y1            
            }else{
                document.getElementById("phone").value = x1
            }
        },
        mobile(){
            var flag = true;
            var x = document.getElementById('mobile').value;
            //console.log(x)

            if(x.length == 3 && flag){
                document.getElementById('mobile').value = "("+x+")"+" ";
                flag = true;
            }else if(x.length == 9 && flag){
                document.getElementById('mobile').value = x+'-';
                flag = false;
            }
        },
        copmo(e){
            var x2 = e.clipboardData.getData('text');
            var y2 = "("+x2.slice(0,3)+")"+" "+x2.slice(4,7)+'-'+x2.slice(8,12);    
            if(x2.length == 12){
                document.getElementById("mobile").value = y2        
            }else{
                document.getElementById("mobile").value = x2
            }
        },
        work(){
            var flag = true;
            var x = document.getElementById('work').value;
            //console.log(x)

            if(x.length == 3 && flag){
                document.getElementById('work').value = "("+x+")"+" ";
                flag = true;
            }else if(x.length == 9 && flag){
                document.getElementById('work').value = x+'-';
                flag = false;
            }
        },
        cowor(e){
            var x3 = e.clipboardData.getData('text');
            var y3 = "("+x3.slice(0,3)+")"+" "+x3.slice(4,7)+'-'+x3.slice(8,12);    
            if(x3.length == 12){
                document.getElementById("work").value = y3           
            }else{
                document.getElementById("work").value = x3
            }
        },
        getAddressData: function (address_principal, placeResultData, id) {            
                this.direccion = address_principal;
                this.address_principal = this.direccion.street_number+' '+this.direccion.route;                
                var x = document.getElementById('address_principal');
                x.value = this.address_principal;
                this.form.street = this.address_principal;
                this.form.state = this.direccion.administrative_area_level_1;
                this.form.city = this.direccion.locality;
                this.form.zipcode = this.direccion.postal_code;
        },
        getAddressDataCard: function (address_card, placeResultData, id) {
                this.direccion = address_card;
                this.address_card = this.direccion.street_number+' '+this.direccion.route;
                var x = document.getElementById('address_card');
                x.value = this.address_card;
                this.form.streetcard = this.address_card;
                this.form.statecard = this.direccion.administrative_area_level_1;
                this.form.citycard = this.direccion.locality;
                this.form.zipcodecard = this.direccion.postal_code;
        },
        addCard(cards) {
            this.form.cards = cards;
        },
        closed(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.open = click;
        },
        closedmodal(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.createmodal = click;
        },
        createcard(){
            this.createmodal = true;
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
        },
        modalcard(id){            
            axios.post('/api/carddata', {
                id:id
                }).then((response) => {
                    if(response.status == 200){
                        var boton = document.getElementById('loading');
                        boton.classList.add('preloader-modal')
                        //console.log(response.data[0])
                        this.cards = {"valor": response.data[0] , "role" :this.global.layout.role_id };
                        this.open = true;
                    }                    
                })
        },
        deletecard(id){
            swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                const params = {
                                    cardid: id,                   
                                    leadid: this.form.id,
                                };
                                axios.post('/api/deletecard', params).then((response)=>{
                                        swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success',
                                        ).then( (res) => {
                                            if(res){
                                                    this.form.cards = response.data;
                                                }
                                            });
                                    Fire.$emit('AfterCreate');
                                }).catch(()=> {
                                    swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    });
        },
        opencard(){
            this.cardoptions = true;
            this.ocultbtn = false;
        },
        yesaddress(){
            this.otherinfo = true;
        },
        noaddress(){
            this.otherinfo = false;
        },
        securityedit(){
            var flag = true;
            var x = document.getElementById('SSN').value;
            //console.log(x)

            if(x.length == 3 && flag){
                document.getElementById('SSN').value = x+"-";
                flag = true;
            }else if(x.length == 6 && flag){
                document.getElementById('SSN').value = x+'-';
                flag = false;
            }
            
        },
            clieCarga(){
                //console.log(this.global.layout.id);
                this.form.assigned_user_id = this.global.editleads[0].assigned_user_id;
                this.form.date_assignment = this.global.editleads[0].date_assignment;
                this.currentDate = new Date();
            },
            reset(){
                this.form.assigned_user_id = '';
                this.form.date_assignment = null;
 
            },
            updateLead(){                
                this.$Progress.start();
                var x = document.getElementById('address_principal');
                this.form.street = x.value;

                let button = $('.create');
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                
                if(this.form.credit_report == false){
                    this.form.credit_report = 1
                }else{
                    this.form.credit_report = 2
                }
               
                if(this.type == 0){
                    this.form.post('/api/leads/update/'+this.form.id)
                    .then( function(response) {
                        if(response.status == 200){
                            setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                            button.attr('disabled',true);
                            //this.activities = true;
                            Fire.$emit('AfterCreate');
                            swal.fire({
                                type:'success', 
                                title:'Information has been updated.'
                                }).then((res)=>{
                                    if(res){
                                        setTimeout(() => {
                                            var boton = document.getElementById('app');
                                            boton.classList.remove('preloader')
                                        }, 50); 
                                        window.location.href='/crm/leads';
                                    }
                                });                            
                        }                
                    })
                    .catch((errors) => {
                        this.$Progress.fail();
                        if(errors.response.status == 500 || errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        }
                    });
               }else if(this.type == 1){
                   this.form.post('/api/leads/update/'+this.form.id)
                    .then( function(response) {
                        if(response.status == 200){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                            button.attr('disabled',true);
                            //this.activities = true;
                            Fire.$emit('AfterCreate');
                            swal.fire({
                                type:'success', 
                                title:'Information has been updated.'
                                }).then((res)=>{
                                    if(res){
                                        setTimeout(() => {
                                            var boton = document.getElementById('app');
                                            boton.classList.remove('preloader')
                                        }, 50); 
                                        window.location.href='/administration/clients';
                                    }
                                });                            
                        }                
                    })
                    .catch((errors) => {
                        this.$Progress.fail();
                        if(errors.response.status == 500 || errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        }
                    });
               }else if(this.type == 2){
                   this.form.post('/api/leads/update/'+this.form.id)
                    .then( function(response) {
                        if(response.status == 200){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                            button.attr('disabled',true);
                            //this.activities = true;
                            Fire.$emit('AfterCreate');
                            swal.fire({
                                type:'success', 
                                title:'Information has been updated.'
                                }).then((res)=>{
                                    if(res){
                                        setTimeout(() => {
                                            var boton = document.getElementById('app');
                                            boton.classList.remove('preloader')
                                        }, 50); 
                                        window.location.href='/administration/leads';
                                    }
                                });                            
                        }                
                    })
                    .catch((errors) => {
                        this.$Progress.fail();
                        if(errors.response.status == 500 || errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        }
                    });
               }else{
                   this.form.post('/api/leads/update/'+this.form.id)
                    .then( function(response) {
                        if(response.status == 200){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                            button.attr('disabled',true);
                            //this.activities = true;
                            Fire.$emit('AfterCreate');
                            swal.fire({
                                type:'success', 
                                title:'Information has been updated.'
                                }).then((res)=>{
                                    if(res){
                                        setTimeout(() => {
                                            var boton = document.getElementById('app');
                                            boton.classList.remove('preloader')
                                        }, 50); 
                                        window.location.href='/crm/clients';
                                    }
                                });                            
                        }                
                    })
                    .catch((errors) => {
                        this.$Progress.fail();
                        if(errors.response.status == 500 || errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        }
                    });
               }
            
            },
            getResultsTask(page = 1){
                axios.get('crm-tasks/'+this.lead_id.id+'?page=' + page)
                    .then(response => {
                        this.tasks = response.data;
                        //console.log(this.notes);
                    });
            },
            cancel(){
                //console.log('cerrar');
                window.location="/crm/leads/";
            },
            stateseeuu(){
                axios.get('/api/stateseeuu')
                    .then((response) => {
                        this.states = response.data
                    })
            },
            plataformall(){
            axios.get('/api/plataformall')
                .then((response) => {
                    this.plataforms = response.data
                })
            },
            userCreator(){
                axios.post('/api/sellerall/2',{ 
                    roles : '[]',
                    type : '0'
                }).then(response => {
                    this.users = response.data;
                })
            },
            userCreatorOwner(){
                axios.post('/api/sellerall/2',{ 
                    roles : '[]',
                    type : '1'
                }).then(response => {
                    this.usersowner = response.data;
                })
            },
            leadSources(){
                axios.get('/api/leadsource')
                    .then(response => {
                        this.leadsource =  response.data 
                    });
            },
            programsAll(){
                axios.get('/api/programs')
                    .then(response => {
                        this.programs =  response.data 
                    });
            },
            leadStatus(){
                axios.get('/api/leadstatus')
                    .then(response => {
                        this.leadstatus =  response.data 
                    });
            },
            leadSourcename(){
                axios.get('/api/sourcesnames')
                    .then(response => {
                        this.leadsourcename =  response.data 
                    });
            },
        },
        created() {
            this.stateseeuu();
            this.plataformall();
            this.userCreator();
            this.leadSources();
            this.programsAll();
            this.leadStatus();
            this.leadSourcename();
            this.userCreatorOwner();
          Fire.$on('AfterCreate',() => {
              
           });
        },
}

</script>
