<template>
    <div>
        <div id="loading">
            <div>
                <div class="row ">
                    <div class="col-md-12">
                        <div class="cont-title">
                            <div class="row">
                                <div class="col-lg-6">
                                    <span>Clients</span>
                                </div>
                                <div class="col-lg-6">
                                    <button v-if="client.migrating == 1 && client.cf == 1" @click="finishmigrate" class="btn class-button-submit" style="margin-right: 5px;text-transform:uppercase"> Finish</button>
                                </div>
                            </div>
                        </div>
                        <div class="cont-search-paginate">
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Client Information</span>
                                            <div class="row" style="padding: 20px">
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Client name</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.client_name}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Account</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <span name="start" id="date"> {{client.account}} </span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Advisor</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <select style="width:100%" v-if="advisorstate  == true" name="" id="" v-model="advisor" class="input-form">
                                                                    <option :value="item.id" v-for="(item, index) in advisors" :key="index">{{item.user_name}}</option>
                                                                </select>                                                                
                                                                <button v-if="advisorstate == true" class="btn class-button-green-payment" @click="saveAdvisor">Save</button>
                                                                <button v-if="advisorstate == true" class="btn class-button-red-payment" @click="changeAdvisor">Cancel</button>
                                                                <span v-if="advisorstate == false" name="start" id="date"> {{client.advisor_name}} </span>                                                                
                                                                <i v-if="client.migrating == 1" title="Edit" @click="changeAdvisor" class="fas fa-pen" :style="advisorstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="" class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span >Status</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">
                                                                    <div v-if="statusstate  == true">
                                                                        <select style="width:90%;margin-bottom:5px"  name="" id="" v-model="status" class="input-form">
                                                                            <option value="1">Active</option>
                                                                            <option value="0">Inactive</option>
                                                                            <option value="2">Hold</option>
                                                                        </select>
                                                                    </div>
                                                                    <button v-if="statusstate == true" class="btn class-button-green-payment" @click="saveStatus">Save</button>
                                                                    <button v-if="statusstate == true" class="btn class-button-red-payment" @click="changeStatus">Cancel</button>
                                                                    <span v-if="statusstate == false" name="start" id="date"> {{client.status == 1 ? 'Active' : 'Inactive'}} </span>
                                                                    <i v-if="client.migrating == 1" title="Edit" @click="changeStatus" class="fas fa-pen" :style="statusstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Start date</span>
                                                                </div>
                                                                <div class="class-campo-text col-md-8">
                                                                    <div v-if="datestate == true">
                                                                        <kendo-datepicker
                                                                            v-model="date"
                                                                            :min="minDate"
                                                                            :max="maxDate"
                                                                            :format="'MM/dd/yyyy'"
                                                                            class="input-form"
                                                                            name="date"
                                                                            id="date"
                                                                            style="background:#fff !important;width:150px;height: auto;display: inherit;">
                                                                        </kendo-datepicker>
                                                                    </div>
                                                                    <button v-if="datestate == true" class="btn class-button-green-payment" @click="saveDate">Save</button>
                                                                    <button v-if="datestate == true" class="btn class-button-red-payment" @click="changeDate">Cancel</button>
                                                                    <span v-if="datestate == false" name="start" id="date">{{ date }} </span>
                                                                    <i v-if="client.migrating == 1" title="Edit" @click="changeDate" class="fas fa-pen" :style="datestate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Captured by</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <select style="width:100%" v-if="capturedstate  == true" name="" id="" v-model="captured" class="input-form">
                                                                        <option :value="item.id" v-for="(item, index) in captureds" :key="index">{{item.user_name}}</option>
                                                                    </select>                                                                
                                                                    <button v-if="capturedstate == true" class="btn class-button-green-payment" @click="saveCaptured">Save</button>
                                                                    <button v-if="capturedstate == true" class="btn class-button-red-payment" @click="changeCaptured">Cancel</button>
                                                                    <span v-if="capturedstate == false" name="start" id="date"> {{client.capture_name}} </span>                                                                
                                                                    <i v-if="client.migrating == 1" title="Edit" @click="changeCaptured" class="fas fa-pen" :style="capturedstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group class-inline row">
                                                                <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                    <span>Seller</span>
                                                                </div>
                                                                <div class="class-campo-text add-class-campo-text col-md-8">
                                                                    <select style="width:100%" v-if="sellerstate  == true" name="" id="" v-model="seller" class="input-form">
                                                                        <option :value="item.id" v-for="(item, index) in sellers" :key="index">{{item.user_name}}</option>
                                                                    </select>                                                                
                                                                    <button v-if="sellerstate == true" class="btn class-button-green-payment" @click="saveSeller">Save</button>
                                                                    <button v-if="sellerstate == true" class="btn class-button-red-payment" @click="changeSeller">Cancel</button>
                                                                    <span v-if="sellerstate == false" name="start" id="date"> {{client.seller_name}} </span>                                                                
                                                                    <i v-if="client.migrating == 1" title="Edit" @click="changeSeller" class="fas fa-pen" :style="sellerstate == false ? 'position: absolute;right: 25px;top: 10px;':'position: absolute;right: 25px;bottom: 10px;opacity:1 !important'"></i>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div style="color:#B59200">
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Initial payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img v-if="client.ip == 1" title="Initial Payment" :src="'/images/icons/CRM--initialpayment-ON.ico'" alt=""  style="cursor:pointer" @click="modalPayment()">
                                                                <img v-else title="Initial Payment" :src="'/images/icons/CRM--initialpayment-OFF.ico'" alt=""  style="cursor:pointer" @click="modalPayment()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Contract fee</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8">
                                                                <img v-if="client.cf == 1 && client.ip == 1" title="Contract Fee" :src="'/images/icons/CRM--contractfee-ON.ico'" alt="" style="cursor:pointer" @click="modalContract()">
                                                                <img v-if="client.cf == 0 && client.ip == 1" title="Contract Fee" :src="'/images/icons/CRM--contractfee-OFF.ico'" alt="" style="cursor:pointer" @click="modalContract()">
                                                            </div>
                                                        </div>
                                                        <div class="form-group class-inline row">
                                                            <div class="class-campo-icon add-class-campo-icon col-md-4">
                                                                <span>Monthly payment</span>
                                                            </div>
                                                            <div class="class-campo-text col-md-8" >
                                                                <div v-if="client.cf == 1 && client.ip == 1">
                                                                    <span >$ {{client.monthly_amount}} </span> <span :style="client.rbp == 0 ? 'color:#FF0000;font-weight: bold' : 'color:#00CC00;font-weight: bold'"> {{client.type_payment}}</span>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cont-user-info">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="border-campo-user">
                                            <span class="title-modal">Payment Report</span>
                                            <div class="row" style="padding: 20px">
                                                <div class="col-lg-5">                                                    
                                                    <div class="form-group">
                                                        <table class="table">
                                                            <tbody id="busq-user">
                                                                <tr class="th-bg">
                                                                    <th>Date</th>
                                                                    <th>Fee or Charge</th>
                                                                    <th>Ammount</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                                <tr v-for="(item, index) in listcharges" :key="index">
                                                                    <td>{{item.created_at | myGlobal}}</td>
                                                                    <td>{{item.fee}}</td>
                                                                    <td>$ {{item.amount}}</td>
                                                                    <td><i v-if="item.id != null" @click="deletecharges(item.id)" style="color: red" class="fas fa-times-circle"></i></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="form-group">                                            
                                                        <button v-if="client.migrating == 1" style="float:left" @click="changeCharge" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px;"> ADD CHARGE</button>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="form-group">
                                                        <table class="table">
                                                            <tbody id="busq-user">
                                                                <tr class="th-bg">
                                                                    <th>Date</th>
                                                                    <th>Type</th>
                                                                    <th>Id Transaction</th>
                                                                    <th>Ammount</th>
                                                                    <th>Method</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                                <tr v-for="(item, index) in listpayments" :key="index">
                                                                    <td>{{item.settlement_date | myGlobal}}</td>
                                                                    <td>{{item.type}}</td>
                                                                    <td>{{item.transaction_id}}</td>
                                                                    <td>$ {{item.amount}}</td>
                                                                    <td>{{item.method_payment}}</td>
                                                                    <td><i v-if="item.id != null && client.migrating == 1" @click="deletepayment(item.id)" style="color: red" class="fas fa-times-circle"></i></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div v-if="client.migrating == 1" class="form-group">                                            
                                                        <button @click="addCredit()" style="float:left;width: 140px" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px"> ADD CREDIT</button>
                                                        <button @click="addYearPayment()" style="float:right;width: 190px;" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px"> ADD YEAR PAYMENT</button>
                                                        <button @click="addMmonthlyPayment()" style="float:right;width: 190px;margin-right: 5px" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px"> ADD MONTHLY PAYMENT</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style="margin:0">
                                                <div class="col-lg-4">
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Total Fees an Charges</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.total_charge}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">                                                    
                                                    <div class="form-group class-inline row">
                                                        <div class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Total Payments</span>
                                                        </div>
                                                        <div class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.total_payments}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group class-inline row">
                                                        <div style="background:#908035" class="class-campo-icon add-class-campo-icon col-md-6">
                                                            <span>Account Balance</span>
                                                        </div>
                                                        <div style="border-color: #908035;" class="class-campo-text col-md-6">
                                                            <span name="start" id="date">$ {{client.balance}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-migration-payment v-if="openPayment" @click="closePayment" v-bind:payments="this.payments" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="client.migrating == 1?true:false" :account="this.global.id" :statusSale="4" :sessionId="this.global.layout.id" :valorInitalPaymetn="0"></modal-migration-payment>
        <modal-migration-contract v-if="openContract" @click="closeContract" v-bind:contractsales="this.contractsales" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="client.migrating == 1?true:false" :statusSale="4" :valorInitalPaymetn="0" :account="this.global.id" :sessionId="this.global.layout.id"></modal-migration-contract>
        <modal-migration-monthly-edit  v-if="openMonthly" @click="closeMonthly" :lead_id="this.client.lead_id" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.client.id" :session="this.global.layout.id" @actuals="actualityMonthly"></modal-migration-monthly-edit>
        <modal-migration-add-charge v-if="openCharge" @click="closeCharge" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :lead_id="this.client.lead_id" :id="this.client.id" :account="this.client.account" :session="this.global.layout.id" @dato="globalfunction" :cantcards="this.client.cards"></modal-migration-add-charge>
        <modal-history-advisor v-if="openHistoryAdvisor" @click="closeAdvisor" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-advisor>
        <modal-history-status v-if="openHistoryStatus" @click="closeStatus" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-status>
        <modal-history-monthly v-if="openHistoryMonthly" @click="closeMonthlyHistory" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :id="this.global.id"></modal-history-monthly>
        <modal-migration-monthly-payment v-if="openMonthlyPayment" v-bind:payments="this.payments" @click="closeMonthlyPayment" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :account="this.global.id" :statusSale="4" :sessionId="this.global.layout.id" :valorInitalPaymetn="0"></modal-migration-monthly-payment>
        <modal-migration-year-payment v-if="openYearPayment" @click="closeYearPayment" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :account="this.global.id" :statusSale="4" :sessionId="this.global.layout.id" :valorInitalPaymetn="0"></modal-migration-year-payment>
        <modal-migration-credit v-if="openCredit" @click="closeCredit" :nameProgram="this.client.program_name" :nameClient="this.client.client_name" :type="1" :editmodal="false" :account="this.global.id" :statusSale="4" :sessionId="this.global.layout.id" :valorInitalPaymetn="0"></modal-migration-credit>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:['global'],
    data() {
        return {
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            openContract: false,
            openPayment: false,
            openMonthly: false,
            openMonthlyPayment: false,
            openYearPayment:false,
            openCharge: false,
            openHistoryAdvisor:false,
            openHistoryStatus:false,
            openHistoryMonthly:false,
            openCredit:false,
            payments:[],
            client:[],
            advisors: [],
            captureds:[],
            sellers:[],
            advisor: '',
            advisorstate: false,
            status:'',
            date:'',
            captured:'',
            seller:'',
            statusstate: false,
            capturedstate:false,
            sellerstate:false,
            datestate:false,
            listpayments: [],
            listcharges: [],
        }
    },
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader');
        
    },
    methods: {
        finishmigrate(){
            swal.fire({
                title: 'Finish Migrate',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/finishmigrate',{
                            id: this.global.id,
                            user_id: this.global.layout.id                            
                        })
                        .then(response => {
                            if(response.status == 200){
                                x.classList.remove('preloader')
                                var datanew = ''
                                response.data.message.map(index => {
                                    datanew = datanew + index.text+'<br>'
                                }),
                                swal.fire({
                                    type: response.data.resultCode == 'Error' ? 'error' : 'success',
                                    title: response.data.resultCode == 'Error' ? 'Migration not Completed' : 'Operation Finish',
                                    html: datanew
                                }).then( (res) => {
                                    if(res){
                                        this.listCharges()
                                        this.listPayments()
                                        this.search()
                                        if(response.data.resultCode != 'Error'){
                                            window.location.href='/administration/migrations';
                                        }
                                        }
                                    });
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }
                });
        },
        addCredit(){
            this.openCredit = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
        },
        closeCredit(){
            this.openCredit = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
            var boton = document.getElementById('app')
            boton.classList.remove('preloader')
            this.listPayments()
            this.search()
        },
        deletecharges(id){
            swal.fire({
                title: 'Delete Charge ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/api/deletecharges',{
                            id: id,                            
                        })
                        .then(response => {
                            if(response.status == 200){
                                x.classList.remove('preloader')
                                swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                }).then( (res) => {
                                    if(res){
                                        this.listCharges()
                                        this.listPayments()
                                        this.search()
                                        }
                                    });                    
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }
                });            
        },
        deletepayment(id){
            swal.fire({
                title: 'Delete Payment ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/api/deletepayment',{
                            id: id,                            
                        })
                        .then(response => {
                            if(response.status == 200){
                                x.classList.remove('preloader')
                                swal.fire({
                                    type: 'success',
                                    title: 'Operation Finish'
                                }).then( (res) => {
                                    if(res){
                                        this.listPayments()
                                        this.listCharges()
                                        this.search()
                                        }
                                    });                    
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }
                });            
        },
        actualityMonthly(dato){
            if(dato == true){
                this.search()
            }
        },
        globalfunction(dato){
            if(dato = true){
                this.search();
                this.listPayments();
                this.listCharges()
            }
        },
        addMmonthlyPayment(){            
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')

            axios.post('/api/paymentsalesmigrations',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.payments = response.data[0]                    
                    this.openMonthlyPayment = true                 
                }
            })
        },
        closeMonthlyPayment(){
            this.openMonthlyPayment = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
            var boton = document.getElementById('app');
            boton.classList.remove('preloader');   
            this.listPayments()
            this.search()
        },
        addYearPayment(){
            this.openYearPayment = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
        },
        closeYearPayment(){
            this.openYearPayment = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
            var boton = document.getElementById('app');
            boton.classList.remove('preloader');   
            this.listPayments()
            this.search()
        },
        changeCharge(){
            this.openCharge = true
            var boton = document.getElementById('loading')
            boton.classList.add('preloader-modal')
        },
        closeCharge(){
            this.openCharge = false
            var boton = document.getElementById('loading')
            boton.classList.remove('preloader-modal')
        },
        changeMonthly(){            
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openMonthly = true
        },
        historyMonthly(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryMonthly = true
        },
        closeMonthlyHistory(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryMonthly = false
        },
        closeMonthly(){
            this.openMonthly = false
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
        },
        closeAdvisor(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryAdvisor = false
        },
        closeStatus(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openHistoryStatus = false
        },
        saveAdvisor(){
            axios.post('/api/changeadvisormigrate',{
                advisor_id: this.advisor,
                id: this.global.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.advisorstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        saveStatus(){
            axios.post('/api/changestatusmigrate',{
                status: this.status,                
                id: this.global.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.statusstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        saveDate(){
            axios.post('/api/changedatemigrate',{
                status: this.date,                
                id: this.global.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.datestate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        changeDate(){
            this.datestate = !this.datestate
        },
        saveCaptured(){
            axios.post('/api/changecapturedmigrate',{
                status: this.captured,                
                id: this.global.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.capturedstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        changeCaptured(){
            this.capturedstate = !this.capturedstate
        },
        saveSeller(){
            axios.post('/api/changesellermigrate',{
                status: this.seller,                
                id: this.global.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.search()
                    this.sellerstate = false
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        changeSeller(){
            this.sellerstate = !this.sellerstate
        },
        changeAdvisor(){
            this.advisorstate = !this.advisorstate
        },
        historyAdvisor(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryAdvisor = true
        },
        changeStatus(){
            this.statusstate = !this.statusstate
        },
        historyStatus(){
            var boton = document.getElementById('loading');
            boton.classList.add('preloader-modal')
            this.openHistoryStatus = true
        },
        search(){
            axios.post('/api/searchaccountmigrate',{
                id: this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.client = response.data[0]
                    console.log(this.client)
                    this.advisor = this.client.advisor_id
                    this.status = this.client.status
                    this.seller = this.client.seller_id
                    this.captured = this.client.capture_id
                    this.date = moment(this.client.start_date).format('MM/DD/YYYY')
                    var module=0;
                    switch (this.client.program_id) {
                        case 1: module=3;break;
                        case 2: module=7;break;
                        case 3: module=6;break;
                        case 4: module=5;break;
                        case 5: module=8;break;
                    }
                    axios.post('/api/usermodule/'+module,{ 
                        roles : '[2,3]',
                        type : '1'
                    }).then(response => {
                            this.advisors =  response.data 
                        });
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2
                    })
                    if(this.client.monthly_amount != null){
                        var data = formatter.format(this.client.monthly_amount)
                        this.client.monthly_amount = data.replace('$', "")
                    }
                    if(this.client.total_charge != null){
                        var data = formatter.format(this.client.total_charge)
                        this.client.total_charge = data.replace('$', "")
                    }
                    if(this.client.total_payments != null){
                        var data = formatter.format(this.client.total_payments)
                        this.client.total_payments = data.replace('$', "")
                    }
                    if(this.client.balance != null){
                        var data = formatter.format(this.client.balance)
                        this.client.balance = data.replace('$', "")
                    }
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        modalContract(id , index , session ,status , initial){
            axios.post('/api/contractsalesmigrate',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.contractsales = response.data[0]
                    this.openContract = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })          
        },
        modalPayment(){
            axios.post('/api/paymentsalesmigrations',{
                id:this.client.sale_id
            }).then(response => {
                if(response.status == 200){
                    this.payments = response.data[0]                    
                    this.openPayment = true
                    var boton = document.getElementById('loading');
                    boton.classList.add('preloader-modal')
                }
            })
        },
        closePayment(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            var boton = document.getElementById('app');
            boton.classList.remove('preloader')
            this.openPayment = false
            this.search();
            this.listPayments()
            this.listCharges()
        },
        closeContract(click){
            var boton = document.getElementById('loading');
            boton.classList.remove('preloader-modal')
            this.openContract = false
            this.search('desc',11);
            this.listPayments()
            this.listCharges()
        },
        listPayments(){
            axios.post('/api/listpayment',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listpayments = response.data
                    this.listpayments.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        listCharges(){
            axios.post('/api/listcharges',{
                id:this.global.id
            })
            .then(response => {
                if(response.status == 200){
                    this.listcharges = response.data
                    this.listcharges.map(index => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2
                        })
                        if(index.amount != null){
                            var data = formatter.format(index.amount)
                            index.amount = data.replace('$', "")
                        }
                    })
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        getSeller(){
            axios.post('/api/usermodule/2',{ 
                roles : '[1,2,5]',
                type : '0'
            }).then(response => {
                this.sellers =  response.data 
            });
        },
        getCapture(){
            axios.post('/api/usermodule/2',{ 
                roles : '[1,2,3,5]',
                type : '0'
            }).then(response => {
                this.captureds =  response.data 
            });
        },

    },
    created() {
        this.search();
        this.listPayments();
        this.listCharges()
        this.getSeller()
        this.getCapture()
    },
}
</script>