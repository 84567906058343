<template>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Users</span>
                            </div>
                            <div class="col-lg-6">
                                <button @click="createLeadRoute" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 10%;margin-top: -3px;"> CREATE</button>
                                <button @click="sessionUsers" class="btn class-button-red" style="margin-right: 5px;"><img :src="'/images/icon-history.png'" style="margin-right: 5px;">History Access</button>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalusers : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalusers }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right;display:inline-flex">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-button-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> ADVANCED SEARCH <i class="fas fa-caret-right" style="margin-left:5px"></i></button>
                                    <button class="btn class-button-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-caret-left" style="margin-right:5px"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>                                    
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">State</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option disabled selected>All</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Module</label>
                                        <select v-model="module" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in modules" :key="index" :value="item.id" v-show="item.id != 1">{{ item.name }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Status</th>                    
                                        <th>Roles</th>
                                        <th>Module</th>
                                        <th>Creator</th>
                                        <th>Registered at</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr v-for="(user , index) in users" :key="index">
                                        <td class="blue-color">
                                            <a :href="'/users/show/'+ user.id">{{user.first_name}}</a>
                                        </td>
                                        <td class="blue-color">
                                            <a :href="'/users/show/'+ user.id">{{user.last_name}}</a>
                                        </td>
                                        <td class="gris-color">{{user.email}}</td>
                                        <td>
                                            <span style="color:#00CC00;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;" v-if="user.status == 1">{{user.status == 1 ? 'Active' : 'Inactive' }}</span>
                                            <span style="color:#FF0000;text-transform: uppercase;padding: 8px;background: #f1f1f1;font-weight: bold;" v-else>{{user.status == 1 ? 'Active' : 'Inactive' }}</span>
                                        </td>
                                        <td class="blue-color">{{user.rol}}</td>
                                        <td class="blue-color">{{user.module}}</td>
                                        <td class="gris-color">{{user.creator_name}}</td>
                                        <td class="gris-color">{{user.created_at}}</td>
                                        <td>
                                            <a :href="'/users/edit/'+ user.id" ><img :src="'/images/edit.png'"></a>
                                            
                                            <a :href="'/users/session/'+user.id" title="Sessions">
                                                <i class="fas fa-user" style="color:#00E500"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalusers : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalusers }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props:['global'],
    data() {
        return {
            users:[],
            modules:[],
            dateActualy: moment().format('YYYY-MM-DD'),
            advance : true,
            min: new Date(1950, 0, 1, 0, 0, 0),
            max: new Date(2049, 11, 31, 24, 0, 0),            
            minDate: new Date(1000,1,1),
            maxDate: new Date(2050,9,1),
            searchadvance: true,
            resetadvance: false,                      
            start_page: '',
            next_page: '',
            perpage:'',
            ultima_pagina :'',
            totalusers:'',
            campo1: '',
            status:null,
            module:null,
            from: '',
            to: '',
        }
    },
    mounted() {
        var boton = document.getElementById('app');
        boton.classList.add('preloader')
    },
    methods: {
        advanceSearch(){
            this.advance = false
            this.searchadvance = false
            this.resetadvance =  true
            this.campo1 = ''

            var x = document.getElementById('btnsearch')
            x.classList.add('margin-top-advance')
          
        },
        resertSearch(){
            this.advance = true
            this.searchadvance = true
            this.resetadvance =  false
            this.from = ''
            this.to = ''
            this.campo1 = null
            this.cr = null
            this.status = null            
            this.module = null
            this.search(null,null)
            var x = document.getElementById('btnsearch')
            x.classList.remove('margin-top-advance')
        },
        
        prev(start_p){
            var newval = start_p - 1;
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/usersearch?page='+newval, {
                name_text: this.campo1,
                status: this.status,
                module: this.module,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                user_id : this.global.layout.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.users = response.data.data
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    } 
                    this.ultima_pagina = response.data.last_page;
                    this.totalusers = response.data.total;
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                }
            });
        },  
        next(next){
            var boton = document.getElementById('app');
            boton.classList.add('preloader')
            axios.post('/api/usersearch?page='+next, {
                name_text: this.campo1,
                status: this.status,
                module: this.module,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                user_id : this.global.layout.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.users = response.data.data
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;                   
                    this.ultima_pagina = response.data.last_page;
                    this.totalusers = response.data.total;
                    if(this.start_page == this.ultima_pagina){
                        this.next_page = this.start_page;
                    }else{
                        this.next_page = this.start_page+1;
                    }
                    setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                }
            });
        },
        search(){
            axios.post('/api/usersearch',{
                name_text: this.campo1,
                status: this.status,
                module: this.module,
                date_from: this.from == '' ? null : this.from,
                date_to: this.to == '' ? null : this.to,
                user_id : this.global.layout.id,
            })
            .then(response => {
                if(response.status == 200){
                    this.users = response.data.data
                    this.start_page =  response.data.current_page;
                    this.perpage = response.data.per_page;
                    this.next_page = this.start_page+1;
                    this.ultima_pagina = response.data.last_page;
                    this.totalusers = response.data.total;
                    setTimeout(() => {
                        var boton = document.getElementById('app');
                        boton.classList.remove('preloader')
                    }, 50); 
                }
            })
        },
        deleteUser(id){
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ab9220',
            cancelButtonColor: '#8f9194',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                    if (result.value) {
                        axios.post('/api/userdelete',{
                            userid:id
                        }).then(()=>{
                                swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success',
                                ).then( (res) => {
                                    if(res){
                                            this.search()
                                        }
                                    });
                            Fire.$emit('AfterCreate');
                        }).catch(()=> {
                            swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
            });
        },
        createLeadRoute(){
            window.location.href = '/users/create';
        },
        sessionUsers(){
            window.location.href = 'users/session/0';
        },
        allModules(){
            axios.get('/api/module')
            .then(response => {
                    this.modules =  response.data 
                });
        },
    },
    created() {
        this.allModules();
        this.search();
    },
}
</script>