require('./bootstrap');

window.Vue = require('vue');

import { Form, HasError, AlertError } from 'vform';
import moment from 'moment';
import VueProgressBar from 'vue-progressbar';
import Swal from 'sweetalert2';
import Push from 'push.js';

Vue.use(Push)

//kendo-ui
import $ from 'jquery';
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';

import { Calendar,DateInput,DatePicker,DateRangePicker,DateTimePicker,MultiViewCalendar,TimePicker,DateinputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper';

Vue.use(DateinputsInstaller);
//kendo-ui

import Gate from "./Gate";


import Vue from 'vue'
import Vuetify from 'vuetify'


Vue.use(Vuetify)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect);


Vue.prototype.$gate = new Gate(window.user);


window.swal = Swal;

const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
  });

window.toast = toast;
window.Form = Form;

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component('not-found', require('./components/NotFound.vue').default);

//dashboard
Vue.component('cont-dashboard', require('./components/crm/Dashboard.vue').default);

//Components
Vue.component(HasError.name, HasError).default
Vue.component(AlertError.name, AlertError).default
Vue.component('pagination', require('laravel-vue-pagination'));

//componenrs para my profile
Vue.component('my-profile' , require('./components/profile/Profile.vue').default);
//components para laravel
Vue.component('form-user', require('./components/user/User.vue').default);
Vue.component('form-user-create', require('./components/user/CreateUser.vue').default);
Vue.component('form-user-edit', require('./components/user/EditUser.vue').default);
Vue.component('form-user-show', require('./components/user/ShowUser.vue').default);
Vue.component('form-user-session', require('./components/user/Sessions.vue').default);
//crm
Vue.component('form-leads', require('./components/lead/Lead.vue').default);
Vue.component('form-leads-create', require('./components/lead/CreatedLeads.vue').default);
Vue.component('form-lead-edit', require('./components/lead/EditLead.vue').default);
Vue.component('form-lead-show', require('./components/lead/ShowLead.vue').default);
Vue.component('form-lead-note', require('./components/notes/NoteComponent.vue').default);
Vue.component('form-lead-event', require('./components/event/EventComponent.vue').default);
Vue.component('form-lead-task', require('./components/event/TaskComponent.vue').default);
Vue.component('modal-event-edit', require('./components/modal/ModaEventEdit.vue').default);
Vue.component('form-note-component', require('./components/notes/NoteEditComponent.vue').default);
Vue.component('form-attend', require('./components/lead/AttendLead.vue').default);
Vue.component('save-prices', require('./components/lead/SavePrices.vue').default);

Vue.component('modal-card-edit', require('./components/lead/ModalCard.vue').default);
Vue.component('modal-card-create', require('./components/lead/CreateCardModal.vue').default);

Vue.component('cont-programs', require('./components/lead/ContPrograms.vue').default);

Vue.component('note-icon', {
    template:'<span aria-hidden="true"><i :class="cssClasses"></i></span>',
    props:['img'],
    computed: {
        cssClasses: function(){
            return 'far fa-'+this.img;
        }
    },
});

Vue.component('calendar-appoin', require('./components/calendar/Calendar.vue').default );
Vue.component('calendar-task', require('./components/calendar/CalendarTask.vue').default );
Vue.component('calendar-task-all', require('./components/calendar/CalendarTaskAll.vue').default );


// component list

Vue.component('list-group',require('./components/list/List.vue').default);

// component feeds

Vue.component('feeds-list',require('./components/feed/Feed.vue').default);
Vue.component('for-notes',require('./components/feed/FormFeedsNotes.vue').default);


// component appointemtmade

Vue.component('form-appointmentmade',require('./components/appointmentmade/AppointmentMade.vue').default);

// component commissions

Vue.component('form-commissions',require('./components/commissions/Commissions.vue').default);

// component salesmade

Vue.component('form-salesmade',require('./components/salesmade/SalesMade.vue').default);
Vue.component('modal-comissions',require('./components/salesmade/ModalComissions.vue').default);
Vue.component('modal-contract',require('./components/salesmade/ModalContract.vue').default);
Vue.component('modal-notas',require('./components/salesmade/ModalNotas.vue').default);
Vue.component('modal-tracking',require('./components/salesmade/ModalTracking.vue').default);
Vue.component('modal-revission',require('./components/salesmade/ModalRevision.vue').default);
Vue.component('modal-payment',require('./components/salesmade/ModalPayment.vue').default);

// component client

Vue.component('form-clients',require('./components/clients/Client.vue').default);
Vue.component('form-clients-account',require('./components/clients/ClientAccount.vue').default);
Vue.component('modal-monthly-edit',require('./components/clients/ModalEditMonthly.vue').default);
Vue.component('modal-add-charge',require('./components/clients/ModalAddCharge.vue').default);
Vue.component('modal-history-advisor',require('./components/clients/ModalHistoryAdvisor.vue').default);
Vue.component('modal-history-status',require('./components/clients/ModalHistoryStatus.vue').default);
Vue.component('modal-history-monthly',require('./components/clients/ModalHistoryMonthly.vue').default);
Vue.component('modal-credit',require('./components/clients/ModalCredit.vue').default);

// component payment

Vue.component('form-payment',require('./components/payment/Payment.vue').default);
Vue.component('form-payment-manual',require('./components/payment/PaymentManual.vue').default);
Vue.component('form-payment-others',require('./components/payment/PaymentOthers.vue').default);

//todo Payment Automatic
Vue.component('form-automatic',require('./components/payment/Automatic.vue').default);
Vue.component('form-automatic-grilla',require('./components/payment/Automatic/Grilla.vue').default);
Vue.component('form-automatic-import',require('./components/payment/Automatic/Import.vue').default);
Vue.component('form-automatic-history',require('./components/payment/Automatic/History.vue').default);
Vue.component('form-automatic-detail',require('./components/payment/Automatic/DetailImport.vue').default);

// todo payment Manual
Vue.component('form-manual',require('./components/payment/Manual.vue').default);
Vue.component('form-manual-grilla',require('./components/payment/Manual/Grilla.vue').default);
Vue.component('form-manual-process',require('./components/payment/Manual/Process.vue').default);

// todo payment Others
Vue.component('form-others',require('./components/payment/Others.vue').default);
Vue.component('form-others-grilla',require('./components/payment/Others/Grilla.vue').default);
Vue.component('form-others-register',require('./components/payment/Others/Register.vue').default);
Vue.component('form-others-edit',require('./components/payment/Others/Edit.vue').default);

//todo payment crm
Vue.component('form-crm',require('./components/payment/PaymentCRM.vue').default);
Vue.component('form-crm-grilla',require('./components/payment/CRM/Grilla.vue').default);
Vue.component('form-crm-process',require('./components/payment/CRM/Process.vue').default);

//notifications
Vue.component('form-notifications',require('./components/notifications/Notification.vue').default);
Vue.component('modal-notifications',require('./components/notifications/ModalNotifications.vue').default);

//reports
Vue.component('report-payment',require('./components/reports/ReportPayment.vue').default);
Vue.component('grilla-report-payment',require('./components/reports/payment/Grilla.vue').default);
Vue.component('detail-report-payment',require('./components/reports/payment/Detail.vue').default);

//creditexperts
Vue.component('client-account-creditexperts',require('./components/creditexperts/Account.vue').default);
Vue.component('cont-task-notes-credit',require('./components/creditexperts/TaskNotes.vue').default);
Vue.component('cont-payment-credit',require('./components/creditexperts/Payment.vue').default);
Vue.component('modal-add-task' ,require('./components/creditexperts/ModalTask.vue').default)
Vue.component('modal-add-alltask' ,require('./components/creditexperts/ModalAllTask.vue').default)
Vue.component('modal-add-note' ,require('./components/creditexperts/ModalNote.vue').default)
Vue.component('modal-add-allnote' ,require('./components/creditexperts/ModalAllNote.vue').default)

//debtsolution
Vue.component('client-account-debtsolution',require('./components/debtsolution/Account.vue').default);

//bussiness
Vue.component('client-account-bussiness',require('./components/bussiness/Account.vue').default);

//taxresearch
Vue.component('client-account-taxresearch',require('./components/taxresearch/Account.vue').default);

//boostcredit
Vue.component('client-account-boostcredit',require('./components/boostcredit/Account.vue').default);


//migrations
Vue.component('form-migration',require('./components/migrations/Migration.vue').default);
Vue.component('form-migration-grilla',require('./components/migrations/Grilla.vue').default);
Vue.component('form-migration-create',require('./components/migrations/Create.vue').default);
Vue.component('form-migration-account',require('./components/migrations/Account.vue').default);
Vue.component('modal-migration-monthly-edit',require('./components/migrations/ModalEditMonthly.vue').default);
Vue.component('modal-migration-add-charge',require('./components/migrations/ModalAddCharge.vue').default);
Vue.component('modal-migration-payment',require('./components/migrations/ModalPayment.vue').default);
Vue.component('modal-migration-contract',require('./components/migrations/ModalContract.vue').default);
Vue.component('modal-migration-monthly-payment',require('./components/migrations/ModalAddMonthly.vue').default);
Vue.component('modal-migration-credit',require('./components/migrations/ModalCredit.vue').default);
Vue.component('modal-migration-year-payment',require('./components/migrations/ModalAddYear.vue').default);



// filters
Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('myYear',function(created){
    return moment(created).format('YYYY');
});

Vue.filter('myTime',function(created){
    return moment(created, 'h:mm:ss').format('LT');
});

Vue.filter('myDateGlobal',function(created){
    return moment(created).format('ddd, D MMM YYYY hh:mm A');
});

Vue.filter('myHourTime',function(created){
    return moment(created).format('hh:mm');
});

Vue.filter('myTimePicker',function(created){
    return moment(created).format('YYYY-MMM-D');
});

Vue.filter('myGlobal',function(created){
    return moment(created).format('MM/DD/YYYY');
});

Vue.filter('myGlobalDay',function(created){
    return moment(created).format('MM/DD/YYYY hh:mm A');
});



/*Vue.filter('FirstCapitalLetter',function(created){
    return created.charAt(0).toUpperCase() + created.slice(1);
});*/
Vue.filter('pluralize', (type) => type == 'event' ? 'Appointments' : 'Calls');
Vue.filter('FirstCapitalLetter', (type) => type == 'event' ? 'Appointment' : 'Call');

window.Fire =  new Vue();
Vue.component('btn-delete-user', require('./components/btn-delete/deleteuser.vue').default);
Vue.component('construct-new', require('./components/ExampleComponent.vue').default);


const app = new Vue({
    el: '#app',
    data:{
        search: '',
        theDate: moment().format('YYYY-MM-DD'),
        unixDate: moment().unix(),
    },
    methods:{
        searchit: _.debounce(() => {
            Fire.$emit('searching');
        },1000),

        printme() {
            window.print();
        },
    },
    
});
