<template>
    <div>
        <div v-if="grilla">
            <form-automatic-grilla :global="this.global" @clickImport="importOpen" @clickHistory="historyOpen"></form-automatic-grilla>
        </div>
        <div v-if="excel">
            <form-automatic-import :global="this.global" @clickList="listOpen"></form-automatic-import>
        </div>
        <div v-if="history">
            <form-automatic-history :global="this.global" @clickList="listOpen" @openDetail="information" @clickDetail="detailOpen"></form-automatic-history>
        </div>
        <div v-if="detail">
            <form-automatic-detail :details="this.details" @clickHistory="historyOpen"></form-automatic-detail> 
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                grilla: true,
                excel: false,
                history:false,
                details: [],
                detail: false,
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            importOpen(click){
                this.grilla = false
                this.excel = click
                this.history = false
                this.detail = false
            },
            historyOpen(click){
                this.grilla = false
                this.excel = false
                this.history = click
                this.detail = false
            },
            listOpen(click){
                this.grilla = click
                this.excel = false
                this.history = false
                this.detail = false
            },
            detailOpen(click){
                this.detail = click
                this.history = false
                
            },
            information(information){
                this.details = information
            }
        },
        created() {
        },
    }
</script>