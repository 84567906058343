<template lang="pug">
    .modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 history status
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            br
                            br
                            .col-lg-12.col-md-12
                                .form-group
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th Status
                                                th User Creator 
                                                th User Updater
                                                th Date Creator
                                                th Date Updater
                                            tr(v-for="(history,index) in historys" :key="index")
                                                td {{history.status == 1 ? 'Active' : 'Inactive'}}
                                                td {{history.creator_name}}
                                                td {{history.updater_name}}
                                                td {{history.created_at}}
                                                td {{history.updated_at}}
            .modal-footer                

</template>


<script>
export default {
    props:['id' ,'nameProgram' ,'nameClient'],
    data() {
        return {
            program: this.nameProgram,
            client_name:this.nameClient,
            historys: []
        }
    },
    mounted() {        
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        all(){
            axios.post('/api/historystatus',{
                id: this.id
            })
            .then(response => {
                if(response.status == 200){
                    this.historys = response.data
                }
            })
            .catch(err => {
                console.error(err); 
            })
        }
    },
    created() {
        this.all()
    },
}
</script>