import { render, staticRenderFns } from "./ModalComissions.vue?vue&type=template&id=a8bba118&lang=pug&"
import script from "./ModalComissions.vue?vue&type=script&lang=js&"
export * from "./ModalComissions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports