<template lang="pug">
    .modal-event.estilo-modal.box-login(style="width: 550px !important;z-index: 7;margin-top: 5px;background: #f5f5f5;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 NOTIFICATIONS
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body(style="padding:0")
                div 
                    div.campos-detail-modal.row(style="margin:0:padding:0;margin-left: 5px;")
                        .col-lg-12.col-md-12
                            .form-group                                    
                                ul(style="margin:0; padding:0")
                                    li.li-noti(:style="notification.status == 0 ? 'background: #ffffff' : 'background: #dfdfdf;'" @click="changeStatus(notification.id)" v-for="(notification, index) in allnotifications" :key="index")
                                        img(v-if="notification.status == 0 && notification.type == 0" :src="'/images/icons/CRM---notifications-new-appointment.png'" alt="")
                                        img(v-if="notification.status == 1 && notification.type == 0" :src="'/images/icons/CRM---notifications-new-appointment-gray.png'" alt="")
                                        img(v-if="notification.status == 0 && notification.type == 1" :src="'/images/icons/CRM---notifications-new-call.png'" alt="")
                                        img(v-if="notification.status == 1 && notification.type == 1" :src="'/images/icons/CRM---notifications-new-call-gray.png'" alt="")
                                        img(v-if="notification.status == 0 && notification.type == 2" :src="'/images/icons/CRM---notifications-new-sale.png'" alt="")
                                        img(v-if="notification.status == 1 && notification.type == 2" :src="'/images/icons/CRM---notifications-new-sale-gray.png'" alt="")
                                        img(v-if="notification.status == 0 && notification.type == 3" :src="'/images/icons/CRM---notifications-new-list.png'" alt="")
                                        img(v-if="notification.status == 1 && notification.type == 3" :src="'/images/icons/CRM---notifications-new-list-gray.png'" alt="")
                                        a(:style="notification.status == 0 ? 'color: #B59200;' : 'color: #afafaf;'" :href="notification.link" ) {{notification.notification}} #[span(style="position: absolute;right: 20px;") {{notification.created_at | myDateGlobal}}]
                        .col-lg-12
                            div.cont-search-paginate(style="display: inline-flex")
                                span.class-span-1(style="color:black") {{ ((start_page1 - 1)*perpage1 +1)+'-'+( start_page1 == ultima_pagina1  ? totalnotifications1 : start_page1*perpage1 ) }}
                                span(style="padding-top: 7px;color:black") of
                                span.class-span-2(style="color:black") {{ totalnotifications1 }}
                                button.btn.class-btn-next-prev(aria-label="Previous" @click="prev(start_page1)" :style="start_page1 != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                    i.fas.fa-caret-left                                
                                button.btn.class-btn-next-prev(aria-label="Next" @click="next(next_page1)"  :style="this.start_page1 != this.ultima_pagina1 ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                    i.fas.fa-caret-right


                                
            .modal-footer                

</template>


<script>
export default {
    props:['notifications','start_page','next_page','ultima_pagina','totalnotifications','perpage','global'],
    data() {
        return {            
            start_page1: this.start_page,
            next_page1: this.next_page,
            ultima_pagina1: this.ultima_pagina,
            totalnotifications1: this.totalnotifications,
            allnotifications: this.notifications,
            perpage1: this.perpage
        }
    },
    mounted() {
        console.log(this.notifications)
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        changeStatus(id){
            axios.post('/api/updatenotif',{
                id: id
            })
            .then(res => {
                //console.log(res)
            })
        },
        prev(start_p){
            var newval = start_p - 1;
            axios.post('/api/allnotifications?page='+newval, {
                id: this.global
            }).then(response => {
                if(response.status == 200){
                    this.allnotifications = response.data.data                
                    this.start_page1 =  response.data.current_page;
                    this.perpage1 = response.data.per_page;
                    if(this.start_page1 == this.ultima_pagina1){
                        this.next_page1 = this.start_page1;
                    }else{
                        this.next_page1 = this.start_page1+1;
                    } 
                    this.ultima_pagina1 = response.data.last_page;
                    this.totalnotifications1 = response.data.total;
                }
            });
        },  
        next(next){
            axios.post('/api/allnotifications?page='+next, {
                id: this.global
            }).then(response => {
                if(response.status == 200){
                    this.allnotifications = response.data.data                    
                    this.start_page1 =  response.data.current_page;                    
                    this.ultima_pagina1 = response.data.last_page;
                    this.totalnotifications1 = response.data.total;
                    this.perpage1 = response.data.per_page;
                    if(this.start_page1 == this.ultima_pagina1){
                        this.next_page1 = this.start_page1;
                    }else{
                        this.next_page1 = this.start_page1+1;
                    }
                }
            });
        },
    },
}
</script>

<style lang="stylus">
    .cont-noti
        position: absolute;
        right: 80px;
        top: 15px;
        font-size: 18px;
        z-index : 9
        text-align: right;
    .cant-noti
        font-size: 11px;
        position: absolute;
        top: -3px;
        right: -9px;
        color: white;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        text-align: center;
    .icon-cam
        border: 2px solid white;
        border-radius: 50%;
        padding: 3px;
        color: white;
        cursor pointer
    .cont-noti-list
        background: #f5f5f5;
        width: 350px;
        margin-top: 8px;
        padding: 5px;
    .li-noti
        color: #f00;
        display: block !important;
        margin: 4px 0px;
        background: #f0f0f0;
        text-align: left;
        font-size: 15px;
        height 40px
        padding-left 15px
        padding-top: 7px;
    .li-noti a
        color: #dbc900;
        width: 100%;
        display: block;
        padding: 5px;
        font-weight bold
        display inline
    .li-noti a:hover
        text-decoration none
    .cont-noti-list i
        position: absolute;
        right: 8px;
        color: white;
        top: 30px;
    #modalBody ul .li-noti img
        width: auto !important;
        padding: 0;
        box-sizing: border-box;
        margin-left: 0;
        display inline
    .li-noti a span
        display: inline;
        font-size: 12px;
        padding-left: 32px;
        color: #afafaf !important
</style>