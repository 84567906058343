<template>
    <div :style="dataLead == false ? 'margin-bottom: 10px;background: white;height: 462px;' : 'margin-bottom: 10px;background: white'">
        <div class="cont-title">
            <div class="row">
                <div class="col-lg-6">
                    <span>Migration - process</span>
                </div>
                <div class="col-lg-6">
                    <button @click="returnList" class="btn class-button-red"><i class="fas fa-caret-left" style="margin-right:5px"></i> RETURN</button>
                </div>
            </div>
        </div>
        <div class="cont-search-paginate">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="form-group">
                                <input id="camposearch" autocomplete="off"  class="input-form" type="text" @keyup="searchlead" ref="file" name="file" placeholder="Search Leads | Clients" style="margin-bottom: 5px;">
                                <div id="contusers" class="cont-users">
                                    <span class="span-hover" v-for="(user , index) in users" :key="index" style="display: block;cursor:pointer" @click="searchuser(user.id,user.lead_name)"> {{user.lead_name}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <button class="btn class-button-green" @click="getcard"> CONTINUE <i class="fas fa-caret-right" style="margin-left: 10px;"></i> </button>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-lg-12" v-if="validate">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div >
                                    <label for="">Lead</label>
                                    <input type="text" v-model="lead" disabled class="input-form">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>
                                    <label for="">Mobile</label>
                                    <input type="text" v-model="mobile" disabled class="input-form">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>
                                    <label for="">Programs</label>
                                    <ul>
                                        <li v-for="(item, index) in programs" :key="index">{{item.program_name+' || '+item.account}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>
                                    <label for="">Account</label> <span v-if="errorAccount" style="color:red">Required</span>
                                    <input type="text" name="" id="" class="input-form" v-model="account">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="">Programs</label> <span v-if="errorProgram" style="color:red">Required</span>
                                <select v-model="program" @change="condadvisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in programsall" :key="index" :value="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" >
                                <label for="">Advisors</label> <span v-if="errorAdvisor" style="color:red">Required</span>
                                <select v-model="advisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in advisors" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>
                                    <label for="">Status</label> <span v-if="errorStatus" style="color:red">Required</span>
                                    <select name="" id="" v-model="status" class="input-form">
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                        <option value="2">Hold</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>
                                    <label for="">Star Date</label> <span v-if="errorDate" style="color:red">Required</span>
                                    <kendo-datepicker
                                        v-model="date"
                                        :min="minDate"
                                        :max="maxDate"
                                        :format="'MM/dd/yyyy'"
                                        class="input-form"
                                        name="date"
                                        id="date"
                                        style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                    </kendo-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" >
                                <label for="">Seller</label> <span v-if="errorSeller" style="color:red">Required</span>
                                <select v-model="seller" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in sellers" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group" >
                                <label for="">Capture</label> <span v-if="errorCapture" style="color:red">Required</span>
                                <select v-model="capture" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                    <option value="0">All</option>
                                    <option v-for="(item, index) in captures" :key="index" :value="item.id">{{ item.user_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <div>                                    
                                    <button class="btn class-button-submit" @click="createaccount" > CONTINUE <i class="fas fa-caret-right" style="margin-left: 10px;"></i> </button>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global'],
        data() {
            return {
                validate:false,
                users:[],
                dataLead: false,
                user_id: '',
                user_name: '',
                programs:[],
                lead:'',
                mobile:'',
                account:'',
                program:'',
                programsall:[],
                advisors:[],
                sellers:[],
                captures:[],
                advisor:'',
                seller:'',
                capture:'',
                status:'',
                date:'',
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                errorAccount: false,
                errorProgram: false,
                errorAdvisor: false,
                errorStatus: false,
                errorDate: false,
                errorSeller: false,
                errorCapture: false,
            }
        },
        methods: {
            condadvisor(){
                var module=0;
                switch (this.program) {
                    case 1: module=3;break;
                    case 2: module=7;break;
                    case 3: module=6;break;
                    case 4: module=5;break;
                    case 5: module=8;break;                
                }
                this.advisors = []
                this.advisor = null
                axios.post('/api/usersprograms',{
                    id: module
                }).then(response => {
                    this.advisors =  response.data 
                });
            },
            searchlead(){
                this.validate=false
                this.dataLead = false
                var x = document.getElementById('camposearch')
                var dato = document.getElementById('contusers')
                var cant = x.value
                axios.post('/api/searchclient',{
                    q : cant
                })
                .then(response => {
                    if(response.status == 200) {                        
                        if(cant.length > 0){
                            dato.classList.add('all-he')
                            this.users = response.data
                            if(this.users.length == 0){
                                dato.classList.remove('scroll-all')
                                dato.classList.remove('all-he')
                            }else if(this.users.length < 15){                                
                                dato.classList.add('all-he')
                                dato.classList.remove('scroll-all')
                            }else if(this.users.length > 15){
                                dato.classList.add('scroll-all')
                            }
                        }else{
                            dato.classList.remove('scroll-all')
                            dato.classList.remove('all-he')
                            this.users = []
                        }
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            searchuser(id,first){
                this.user_id = id
                this.user_name = first
                var x = document.getElementById('camposearch')
                var dato = document.getElementById('contusers')
                x.value = this.user_name
                dato.classList.remove('scroll-all')
                dato.classList.remove('all-he')
                this.users= []
            },
            getcard(){
                var boton = document.getElementById('app');
                boton.classList.add('preloader');
                axios.post('/api/searchclientpayment',{
                    id: this.user_id
                }).then(response => {
                    if(response.status == 200){
                        this.validate = true
                        this.dataLead = true
                        this.programs = response.data
                        if (this.programs.length > 0) {
                            this.lead = this.programs[0].lead_name
                            this.mobile = this.programs[0].mobile
                        }else{
                            this.lead = ''
                            this.mobile = ''
                        }
                        axios.get('/api/programs')
                        .then(response => {
                            this.programsall =  response.data
                            this.programs.sort(function(a, b) {
                                return a.program_id-b.program_id;
                            });
                            for( var i = 0; i < this.programsall.length ; i++){
                                for( var y = 0 ; y < this.programs.length; y++){                                    
                                    if ( this.programsall[i].id == this.programs[y].program_id) {
                                        this.programsall.splice(i,1)
                                    }
                                }
                            }
                        });
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
            },
            createaccount(){
                if(this.account == ''){
                    this.errorAccount = true 
                }else{
                    this.errorAccount = false
                    if(this.program == ''){
                        this.errorProgram = true
                    }else{
                        this.errorProgram = false
                        if(this.advisor == null){
                            this.errorAdvisor = true
                        }else{
                            this.errorAdvisor = false
                            if(this.status == ''){
                                this.errorStatus = true
                            }else{
                                this.errorStatus = false
                                if(this.date == ''){
                                    this.errorDate = true
                                }else{
                                    this.errorDate = false
                                    if(this.seller == null || this.seller == ''){
                                        this.errorSeller = true
                                    }else{
                                        this.errorSeller = false
                                        if(this.capture == null || this.capture == ''){
                                            this.errorCapture = true
                                        }else{
                                            this.errorCapture = false
                                            var btn = document.getElementById('app')
                                            btn.classList.add('preloader')
                                            axios.post('/api/createaccount',{
                                                id_lead : this.user_id,
                                                account: this.account,
                                                id_program: this.program,
                                                id_advisor: this.advisor,
                                                id_status: this.status,
                                                start_date: this.date,
                                                id_user: this.global.layout.id,
                                                seller_id:this.seller,
                                                capture_id:this.capture
                                            })
                                            .then(response => {
                                                if(response.status == 200){
                                                    if (response.data[0].ERROR) {
                                                        swal.fire({
                                                            type: 'error',
                                                            title: response.data[0].ERROR
                                                        }).then( (res) => {
                                                            if(res){
                                                                 btn.classList.remove('preloader')
                                                            }
                                                        });
                                                    }else{
                                                        swal.fire({
                                                            type: 'success',
                                                            title: 'Operation Finish'
                                                        }).then( (res) => {
                                                            if(res){
                                                                window.location.href='/administration/migrations/account/'+response.data[0].id;                                    
                                                            }
                                                        });
                                                    }                  
                                                }
                                            })
                                            .catch(err => {
                                                console.error(err); 
                                            })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }                
            },
            returnList(){
                this.$emit('clickgrilla',true)
            },
            allPrograms(){
                
            },
            getSeller(){
                axios.post('/api/usermodule/2',{ 
                    roles : '[1,2,5]',
                    type : '0'
                }).then(response => {
                    this.sellers =  response.data 
                });
            },
            getCapture(){
                axios.post('/api/usermodule/2',{ 
                    roles : '[1,2,3,5]',
                    type : '0'
                }).then(response => {
                    this.captures =  response.data 
                });
            },
        },
        created() {
            this.allPrograms()
            this.getSeller()
            this.getCapture()
        },
    }
</script>