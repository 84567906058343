<template lang="pug">
    .cont-user-info
        .row
            .col-lg-12
                div.border-campo-user
                    <span class="title-modal">NOTES</span>
                    .row(style="padding:20px")
                        .col-lg-12
                            div
                                div.form-group
                                    div
                                        form
                                            .row
                                                .col-md-12
                                                    .form-group
                                                        textarea#text.form-control.text(style="border-radius: 10px;background:#f1f1f1;margin-bottom: 15px;" name="text" cols="30" rows="3"  v-model="note.text" :class="{ 'is-invalid': note.errors.has('text') }")
                                                            has-error(:form="note" field="text")
                                                .col-md-12
                                                    button.btn.class-button-green(type="button" style="float:left" @click.prevent="saveNote(form.id)") #[i.fa.fa-plus] Add                                                            

                                        form-note-component(v-for="(note, index) in notes" 
                                            :key="note.id"
                                            :note="note" 
                                            :index="index"
                                            :iduser="global.layout.id"
                                            :idroles="global.layout.role_id"
                                            @update="updateNote(index, ...arguments)"
                                            @delete="deleteNote(index)")                                        
                        .col-lg-12
                            div.cont-search-paginate(style="display: inline-flex")
                                span.class-span-1 {{ ((start_page - 1)*5 +1)+'-'+( start_page == ultima_pagina  ? totalnotes : start_page*5 ) }}
                                span(style="padding-top: 7px;") of
                                span.class-span-2 {{ totalnotes }}
                                button.btn.class-btn-next-prev(aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                    i.fas.fa-caret-left                                
                                button.btn.class-btn-next-prev(aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'")
                                    i.fas.fa-caret-right
                                        
</template>
<script>
export default {
    props:['global'],
    data(){
        return{
            editmode:false,
            leads:{},
            notes:[],
            users: [],
            data:null,
            form: new Form({
                id:'',
                user_id:null,

            }),
            dataccess: false,
            note: new Form({
                id:this.global.editleads[0].notes,
                user_id:this.global.layout.id,
                lead_id:this.global.editleads[0].id,
                text:'',
            }),
            pagex: 1,
            editing:false,
            start_page: '',
            next_page: '',
            ultima_pagina :'',
            totalnotes:'',
        }
    },
    methods: {
        prev(start_p){
            var newval = start_p - 1;
            axios({
                method: 'Get',
                responseType:'json',
                url: '/api/noteslead/'+ this.global.editleads[0].id + '?page='+newval,
            }).then(response => {                
                this.notes = response.data.data                
                this.start_page =  response.data.current_page;
                if(this.start_page == this.ultima_pagina){
                    this.next_page = this.start_page;
                }else{
                    this.next_page = this.start_page+1;
                } 
                this.ultima_pagina = response.data.last_page;
                this.totalnotes = response.data.total;
            });
        },  
        next(next){
            axios({
                method: 'Get',
                responseType:'json',
                url: '/api/noteslead/'+ this.global.editleads[0].id + '?page='+next,
            }).then(response => {
                this.notes = response.data.data                                     
                this.start_page =  response.data.current_page;                    
                this.ultima_pagina = response.data.last_page;
                this.totalnotes = response.data.total;
                if(this.start_page == this.ultima_pagina){
                    this.next_page = this.start_page;
                }else{
                    this.next_page = this.start_page+1;
                }
            });
        },
        updateNote(index, note) {
                this.notes[index] = note;
            },
        deleteNote(index) {
                this.notes.data.splice(index, 1);
        },
        saveNote(){
               
            this.$Progress.start();
            let addbutton = $('.addbutton');

            addbutton.attr('disabled', true);
        
            this.note.post('/api/notescreate').then( () => {
            Fire.$emit('AfterCreate');
            //$('#createnote').modal('hide');

            toast.fire({
                type: 'success',
                title: 'Note Created in successfully'
                });
                    this.loadNote(this.lead_id);

                this.note.text = '';
                this.dataccess = false;
                addbutton.attr('disabled', false);
                this.$Progress.finish();
                })
                .catch( (errors) => {
                // console.log(errors);
                    addbutton.attr('disabled', false);
                })     
        },
        loadNote(){
            //console.log(this.global.layout.id);
            axios.get('/api/noteslead/'+this.global.editleads[0].id)
            .then(response => {
                this.notes = response.data.data
                this.start_page =  response.data.current_page;
                this.next_page = this.start_page+1;
                this.ultima_pagina = response.data.last_page;
                this.totalnotes = response.data.total;
            });
        },
        createdNote(){

            this.note.user_id = window.user.id;
            this.note.lead_id = this.global.editleads[0].id;
            //this.editmode= false;

        },
    },
    created(){

        //this.loadLeads();
        this.loadNote();
        this.dataccess = true;
          
          Fire.$on('AfterCreate',() => {
            //this.loadLeads();
            this.loadNote();
           });
        //    setInterval(() => this.loadUsers(), 3000);
    },
}
</script>
