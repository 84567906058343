<template lang="pug">
    .modal-event.estilo-modal.box-login(style="width: 1000px;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 history monthly payment
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            br
                            br
                            .col-lg-12.col-md-12
                                .form-group(:style="historys != null ? historys.length > 7 ? 'height: 370px;overflow: auto;' : '' : ''")
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th Id Autorize
                                                th Number Card
                                                th Day Payment
                                                th Monthly Ammount
                                                th Method Payment
                                                th Type Payment
                                                th User Creator                                                 
                                                th Date Creator
                                                th User Updater
                                                th Date Updater
                                            tr(v-for="(history,index) in historys" :key="index")
                                                td {{history.authorizel_id == null ? '-' : history.authorizel_id}}
                                                td {{history.cardnumber == null ? '-' : history.cardnumber}}
                                                td {{history.day_payment == null ? '-' : history.day_payment}}
                                                td {{'$ '+ history.monthly_amount}}
                                                td {{history.method_payment == 0 ? 'Credit Card' : 'Others'}}
                                                td {{history.type_payment == 1 ? 'Manual' : history.type_payment == 0 ? 'Automatic' : ''}}
                                                td {{history.creator_name}}
                                                td {{history.created_at}}
                                                td {{history.updater_name}}
                                                td {{history.updated_at}}
            .modal-footer                

</template>


<script>
export default {
    props:['id' ,'nameProgram' ,'nameClient'],
    data() {
        return {
            program: this.nameProgram,
            client_name:this.nameClient,
            historys: []
        }
    },
    mounted() {        
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        all(){
            axios.post('/api/historymonthly',{
                id: this.id
            })
            .then(response => {
                if(response.status == 200){
                    this.historys = response.data
                }
            })
            .catch(err => {
                console.error(err); 
            })
        }
    },
    created() {
        this.all()
    },
}
</script>