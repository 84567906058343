<template>
    <div class="modal-event" style="margin-top:0px">
        <div class="row title-modal-edit">
            <div class="col-lg-12">
                <h3 > Credit Card</h3>
                <i class="fas fa-times icon-close-target"  @click="close"></i>
            </div>
        </div>
        <div>            
            <div id="modalBody" class="modal-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for=""> Card Holder Name</label>                            
                            <input disabled type="text" name="cardholdername" id="cardholdername" class="input-form"  v-model="cards.valor.cardholdername" style="text-transform:capitalize">                            
                        </div>                        
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="">Number Card</label>
                            <h5 type="text" id="tarjet" v-if="verTarjet" >{{"XXXX-XXXX-XXXX-"+cards.valor.cardnumber}}</h5>                            
                            <input disabled v-else type="text" name="" id="CN" class="input-form" v-model="originaltarget[0]" maxlength="19" >
                            <i v-on:click="eyeTarjet(cards.valor.id)" class="fas fa-eye" v-if="(cards.role == 1 || cards.role == 2) && verTarjet == true " style="cursor:pointer;position: absolute;right: 30%;bottom: 10px;"></i>
                        </div>                        
                    </div>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="">MM</label>
                                    <input type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.card_expi_month"  maxlength="2" >
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="">YY</label>
                                    <input type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.card_expi_year"  maxlength="2" >
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group">
                                    <label for="">CVC</label>                                    
                                    <h5 v-if="verCvc">{{ cards.valor.cardsecuritycode.length == 3 ? 'XX'+cards.valor.cardsecuritycode.substr(2) : 'XXX'+cards.valor.cardsecuritycode.substr(3) }}</h5>                                    
                                    <input v-else type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.cardsecuritycode"  maxlength="2" >
                                    <i v-on:click="eyecvs()" class="fas fa-eye" v-if="cards.role == 1 && verCvc == true" style="cursor:pointer;position: absolute;right: 40px;bottom: 10px;"></i>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="">Billing Address is the same the Mailling Address ?</label>
                                    <br>
                                    <div style="display: inline-flex;width: 100%;margin-left: 20px;">
                                        <div>
                                            <input disabled value ="1" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="admin">
                                            <label class="form-check-label" for="admin"  style="margin-right: 25px;">
                                                Yes
                                            </label>                                                                    
                                        </div>
                                        <div>
                                            <input disabled value="0" name="admin" v-model="otherinfoclick" class="form-check-input" type="radio" id="others" >
                                            <label class="form-check-label" for="others" >
                                                No
                                            </label>                                                                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" v-if="cards.valor.address != null">
                        <div class="form-group">
                            <label for="">Mailing address</label>
                            <input class="border-input description-modal input-form" v-if="editMode" type="text" id="title" name="title" v-model="cards.valor.address">                            
                            <input v-else type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.address"  maxlength="2" >
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-6" v-if="cards.valor.city != null">
                                <div class="form-group">
                                    <label for="">City</label>
                                    <input class="border-input description-modal input-form" v-if="editMode" type="text" id="title" name="title" v-model="cards.valor.city">                                    
                                    <input v-else type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.city"  maxlength="2" >
                                </div>
                                <div class="form-group" v-if="cards.valor.zipcode != null">
                                    <label for="">Zip Code</label>
                                    <input class="border-input description-modal input-form" v-if="editMode" type="text" id="title" name="title" v-model="cards.valor.zipcode">                                    
                                    <input v-else type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.zipcode"  maxlength="2" >
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group" v-if="cards.valor.states_eeuu_slug != null">
                                    <label for="">State</label>
                                    <select class="input-form" name="state" id="state" disabled v-model='cards.valor.states_eeuu_slug' >
                                        <option :value="state.slug" v-for="state in states" :key="state.id">{{state.state}}</option>
                                    </select>                                                                
                                </div>
                                <div class="form-group" v-if="cards.valor.country != null">
                                    <label for="">Country</label>
                                    <input class="border-input description-modal input-form" v-if="editMode" type="text" id="title" name="title" v-model="cards.valor.country">                                    
                                    <input v-else type="text" name="" id="card_expi_month" class="input-form" disabled v-model="cards.valor.country"  maxlength="2" >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">                                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['cards'],
    data() {
        return {
            verCvc: true,
            verTarjet: true,
            editMode: false,
            states:[],
            cardnumberocul:'',
            originaltarget:'',
            otherinfoclick: this.cards.valor.address != null ? '0': '1'
        }   
    },
    methods: {
        eyecvs(){
            this.verCvc = !this.verCvc
        },
        eyeTarjet(id){
            
            axios.post('/api/originaltarget',{id:id})
                .then((response) => {
                    this.originaltarget = response.data
                    this.verTarjet = !this.verTarjet;
                })
        },
        onClickDelete(){

        },
        onClickCancel(){
            this.editMode = false;
        },
        onClickUpdate(){

        },
        onClickEdit(){
            this.editMode = true;
            axios.get('/api/stateseeuu')
                .then((response) => {
                    this.states = response.data
                })
        },
        close(){
            this.editMode = false;
            this.$emit('click',false)
        }
    },
    
}
</script>