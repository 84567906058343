<template>
    <div id="loading">
        <form id="formcreate" @submit.prevent="createUser()">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>Create Users</span>
                    </div>
                    <div class="col-lg-6" style="text-align:right">
                        <a href="/users" class="btn btn-color-red" style="font-size: 13px;padding: 6px;height: 35px;"><i class="far fa-times-circle"></i> CANCEL</a>
                        <button v-show="createUser" type="submit" class="btn btn-color-green create" style="font-size: 13px;padding: 6px;height: 35px;"> <i class="far fa-save"></i>  SAVE</button>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-12">
                                <div style="border: 2px solid #BAA345;padding: 20px;border-radius: 15px;">
                                    <span class="title-modal">User Information</span>
                                    <div class="row" style="padding: 20px">
                                        <div class="col-lg-4">
                                            <div>
                                                <div v-if="form.image">
                                                    <div v-if="url && form.image">
                                                        <img :src="url" width="90" style="display:block">
                                                        <img :src="'/'+form.image" width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/'+form.image"  width="90">
                                                    </div>
                                                </div>
                                                <div v-else>                                                                                                         
                                                    <div v-if="url">
                                                        <img :src="url"  width="90" style="display:block">
                                                        <img :src="'/images/avatar.png'"  width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/images/avatar.png'"  width="90">
                                                    </div>
                                                </div>
                                                <div class="vld-parent">
                                                    <loading :active.sync="isLoading"></loading>
                                                </div>
                                                <div class="file-field">
                                                    <div class="btn btn-sm float-left">
                                                        <span></span>
                                                        <input  name="image" type="file" @change="onImageChange" v-if="uploadReady">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">First Name</label>
                                                            <input v-model="form.first_name" type="text" name="first_name" ref="firstname"  
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                                            <has-error :form="form" field="first_name"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Last Name</label>
                                                            <input v-model="form.last_name" type="text" name="last_name"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                                            <has-error :form="form" field="last_name"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Phone Number</label>
                                                            <input v-model="form.phone" name="phone" id="phone"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('phone') }" maxlength="12">
                                                            <has-error :form="form" field="phone"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label for="">Cellphone</label>
                                                            <input v-model="form.cellphone" name="cellphone" id="cellphone"
                                                            class="input-form" :class="{ 'is-invalid': form.errors.has('cellphone') }" maxlength="12">
                                                            <has-error :form="form" field="cellphone"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label>Date of Birth</label>
                                                            <kendo-datepicker                                            
                                                                :min="minDate"
                                                                :max="maxDate"
                                                                :value="currentDate"
                                                                :format="'MM/dd/yyyy'"
                                                                v-model="form.date_of_birth"
                                                                class="input-form"
                                                                :class="{ 'is-invalid': form.errors.has('date_of_birth') }"
                                                                name="date_of_birth" id="date_of_birth">
                                                            </kendo-datepicker>
                                                            <has-error :form="form" field="date_of_birth"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label>Country</label>
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <input type="radio" name="country" id="PE" value="PE" v-model="form.country">
                                                                    <label for="PE">PERU</label>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <input type="radio" name="country" id="US" value="US" v-model="form.country">
                                                                    <label for="US">UNITED STATES</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-8">
                                <div >
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div style="border: 2px solid #BAA345;padding: 20px;border-radius: 15px;">
                                                <span class="title-modal">User Access</span>
                                                <div class="row" style="padding: 20px">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="form-group col-lg-6">
                                                                <label>Email (User Name)</label>
                                                                <input v-model="form.email" type="email" name="email"
                                                                class="input-form" :class="{ 'is-invalid': form.errors.has('email') }">
                                                                <has-error :form="form" field="email"></has-error>
                                                            </div>
                                                            <div class="form-group col-lg-6">
                                                                <label>Password (Must have at least one capital letter and a number) </label>
                                                                <input v-model="form.password" type="password" name="password" id="password"
                                                                class="input-form" :class="{ 'is-invalid': form.errors.has('password') }"
                                                                v-on:keyup="keymonitor">
                                                                <p v-if="validate == true" style="color:green" >correct password</p>
                                                                <p v-else style="color:red" :class="'is-invalid'">Unmodified Password or Incorrect password</p>
                                                                <has-error :form="form" field="password"></has-error>
                                                                <em v-show="editmode">(leave empty if not changing)</em>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-1">
                                                                <div>
                                                                    Roles
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3" v-if="this.form.super != 1 ? agregados=false : agregados=true">
                                                                <div>
                                                                    <input value ="1" name="admin" v-model="form.admin" class="form-check-input" type="radio" id="admin" @click="adminrols">
                                                                    <label class="form-check-label" for="admin" @click="adminrols">
                                                                        CEO
                                                                    </label>                                                                    
                                                                </div>
                                                                <div>
                                                                    <input value="1" name="admin" v-model="form.others" class="form-check-input" type="radio" id="others" @click="showrols">
                                                                    <label class="form-check-label" for="others" @click="showrols">
                                                                        Others
                                                                    </label>                                                                   
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8">
                                                                <div class="row">
                                                                    <div class="form-group col-lg-6" v-if="this.form.super != 1 ? nuevorol=true : nuevorol=false">
                                                                        <label>Select Rol</label>  
                                                                        <select name="role" :required="!form.role" v-model="form.role" @change="handleChangeRol" id="role" class="input-form" :class="{ 'is-invalid': form.errors.has('role') }">
                                                                            <option :value="null" disabled>Select User Role</option>
                                                                            <option v-for="rol in rolesnews" :value="rol.id" :key="rol.id" v-show="rol.id != 1">{{ rol.name }}</option>
                                                                        </select>
                                                                        <has-error :form="form" field="role"></has-error>
                                                                        <div class="row" v-if="form.role == 5" style="padding-top: 10px;">
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="junior" value="0" v-model="form.typesenior">
                                                                                <label for="junior">Junior</label>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="senior" value="1" v-model="form.typesenior">
                                                                                <label for="senior">Senior</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group col-lg-6" v-if="viewrols">
                                                                        <label>Select Module</label>
                                                                        <select @click="filter" name="module"  @change="handleChange" v-model="form.module" id="module" class="input-form" :class="{ 'is-invalid': form.errors.has('module') }">
                                                                            <option :value="null" disabled>Select module</option>
                                                                            <option v-for="mod in modules" :value="mod.id" :key="mod.id" v-show="mod.id != 1 && mod.state == 1">{{ mod.name }}</option>
                                                                        </select>
                                                                        <has-error :form="form" field="module"></has-error>
                                                                    </div>
                                                                    <div class="form-group col-lg-6" v-if="viewrols">
                                                                        <label>Select Rol</label>  
                                                                        <select name="role" v-model="form.role" @change="handleChangeRol" id="role" class="input-form" :class="{ 'is-invalid': form.errors.has('role') }">
                                                                            <option :value="null" disabled>Select User Role</option>
                                                                            <option v-for="rol in roles" :value="rol.id" :key="rol.id" v-show="rol.id != 1">{{ rol.name }}</option>
                                                                        </select>
                                                                        <has-error :form="form" field="role"></has-error>
                                                                        <div class="row" v-if="form.role == 5" style="padding-top: 10px;">
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="junior" value="0" v-model="form.typesenior">
                                                                                <label for="junior">Junior</label>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <input type="radio" name="typeSenior" id="senior" value="1" v-model="form.typesenior">
                                                                                <label for="senior">Senior</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                <div  style="height: 100%;">
                                    <div class="row" style="height: 100%">
                                        <div class="col-lg-12">
                                            <div style="border: 2px solid #BAA345;padding: 20px;height: 100%;border-radius: 15px;">
                                                <span class="title-modal">Others</span>
                                                <div class="row" style="padding: 20px;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <input  name="restriction_by_ip" v-model="form.restriction_by_ip" class="form-check-input" type="checkbox" id="restriction_by_ip">
                                                                <label class="form-check-label" for="restriction_by_ip">
                                                                    This user having restriction ip
                                                                </label>                                                               
                                                            </div>
                                                            <div class="col-lg-12">                                                                
                                                                <input name="status" v-model="form.status" class="form-check-input" type="checkbox" id="status">
                                                                <label class="form-check-label" for="status">
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form> 
    </div>
</template>

<script>
    // Import component
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment'

    export default {
        props:['layout'],
        data() {
            return {
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1800,1,1),
                maxDate: new Date(2050,9,1),
                currentDate: new Date(2017, 9, 15, 10, 30),
                nuevorol:false,
                agregados: true,
                viewrols: false,
                validate : false,
                notvalidate : true,   
                isLoading: false,           
                uploadReady: true,
                editmode: false,
                users : {},
                roles: {},
                rolesnews: {},
                modules : {},
                url:null,
                form: new Form({
                    id:'',
                    first_name : '',
                    last_name: '',
                    email: '',
                    country:'PE',
                    password: '',
                    phone: '',
                    cellphone: '',
                    date_of_birth: moment().format('YYYY-MM-DD'),
                    restriction_by_ip: false,
                    status: false,
                    module:null,
                    role:null,
                    typesenior: '',
                    rolenew:null,
                    file:'',
                    image:'',
                    admin:'0',
                    others:'0',
                    super: this.layout.role_id,
                    superid: this.layout.id,
                }),
                modulesUser:this.layout.moduls,
                dataccess: false,
                visualmodule: [],
                name_module:'',
                name_role:'',
                role: '',
                module: '',
                arrayeditRol: [],
                arrayeditModule: [],
                arrayidRol: [],
                arrayidModule: [],
                show :{
                     id:'',
                    first_name : '',
                    last_name: '',
                    email: '',
                    password: '',
                    phone: '',
                    cellphone: '',
                    date_of_birth: '',
                    restriction_by_ip: 0,
                    status: 0,
                    permission: [],
                    mod: [],
                    visualmodule: [],
                    showpermission: false,
                    image:''
                },
                search:''
            }
        },
        components: {
            Loading
        },
        mounted() {
            setTimeout(() => {
                var boton = document.getElementById('app')
                boton.classList.remove('preloader')
            }, 50); 
        },
        methods: {
            filter (){
                //console.log(this.form.module)
                axios.post("/api/modulfilter",
                { 
                    id : this.form.module                    
                })
                .then(({data}) => (this.roles = data))
            },
            showrols (){
                this.viewrols = true
                this.admin = false
                this.form.admin = '0'
            },
            adminrols (){
                this.viewrols = false
                this.others = false
                this.form.module = null
                this.form.role = null
                this.form.others = '0'
            },
            keymonitor: function() {
                var x = document.getElementById("password").value;
                var passw=  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d,@,$,--,%,_]{6,15}$/;
                if(x.match(passw)){
                    this.validate = true
                    this.notvalidate = false
                }else{
                    this.validate = false
                    this.notvalidate = false
                }
            },
            clear () {
                this.uploadReady = false
                this.url = false
                this.$nextTick(() => {
                    this.uploadReady = true
                    this.url = false
                })
            },
            onImageChange(e){
                //console.log(e.target.files[0])
                this.$Progress.start();
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                fileReader.onload = (e) => {
                    this.form.file = e.target.result
                    //console.log(this.form.file)
                }
                const file = e.target.files[0];
               /* this.url = URL.createObjectURL(file);*/
                this.url=URL.createObjectURL(file);

                this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false
                },50)

                //console.log(this.url);
            },
            loadModules() {
                axios.get("/api/module").then( ({data}) => (this.modules = data));
            },
            loadNewrols() {
                if(this.modulesUser.length == 1){
                    var module = this.modulesUser[0].id
                }else{
                    var module = this.modulesUser[1].id
                }
                axios.post("/api/rolesnews",
                { 
                    id : module
                }).then( ({data}) => (this.rolesnews = data));
            },
            createUser() {
                let button = $('.create');
                var boton = document.getElementById('app');
                var error = boton.getElementsByClassName('is-invalid')

                setTimeout(function(){                    
                    if(error.length > 0){                    
                        button.attr('disabled',false);
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                },1000)

                if (error.length == 0){            
                    button.attr('disabled',true);
                    boton.classList.add('preloader')
                } 
                //console.log(this.validate)
                if(this.validate == true){
                    this.form.post('/api/usercreate',this.form)
                    .then( response => {
                        if(response.status == 200){
                        button.attr('disabled',true);
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        Fire.$emit('AfterCreate');
                        swal.fire({
                            type: 'success',
                            title: 'User Created in successfully'
                            }).then( (res) => {
                                if(res){
                                    setTimeout(() => {
                                        var boton = document.getElementById('app');
                                        boton.classList.remove('preloader')
                                    }, 50); 
                                    window.location.href = '/users';
                                    }
                                });
                        }  
                    })
                    .catch( (errors) => {
                        if(errors.response.status == 500 || errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                        }
                    })
                }
            },
            addItem() {
             
               if(this.form.role != undefined && this.form.module != undefined){
                    this.form.module_role.push({'module' : this.form.module, 'namerol': this.form.role});
                    this.visualmodule.push({'module' : this.name_module, 'role' :  this.name_role});
                   
                    this.dataccess = true;
                    
                    this.module = '';
                    this.role = '';
               }
           
            },
             handleChange(e) {
                if(e.target.options.selectedIndex > -1) {
                    //console.log(e.target.options[e.target.options.selectedIndex].dataset.namemod);
                    this.name_module = e.target.options[e.target.options.selectedIndex].dataset.namemod;
                     
                }
            },
            handleChangeRol(e) {
                if(e.target.options.selectedIndex > -1) {
                  this.name_role = e.target.options[e.target.options.selectedIndex].dataset.namerol;
                }
            },
             removeItem(index) {
                this.form.module_role.splice(index, 1);
                this.visualmodule.splice(index, 1);
                this.dataccess = true;
                this.module = '';
                this.role = '';
                if(this.visualmodule.length == 0){
                        this.dataccess = false;
                    }else{
                        this.dataccess = true;
                    }
              //console.log(this.$el.target.options[this.$el.target.options.selectedIndex].da);
            },
            searchit(){
                let query = this.search;
                    axios.get('findUser?q=' + query)
                    .then((data) => {
                        this.users = data.data
                    })
                    .catch( (errors) => {
                    // console.log(errors);
                    })
            }
        },
        created() {
           this.loadModules();
           this.loadNewrols();
          
          Fire.$on('AfterCreate',() => {
                this.loadModules();
                this.loadNewrols();
           });
        //    setInterval(() => this.loadUsers(), 3000);
        },
        
    }
</script>



<style>
    
   
</style>
