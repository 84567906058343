<template>
    <a @click="deleteUser(form.userid)" v-if="form.userid != 1" style="cursor:pointer" title="Delete">
        <img :src="'/images/delete.png'">
    </a>
</template>

<script>
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        props:['user'],
            data() {
                return {
                    form: new Form({
                        userid: this.user,
                    }),
                }   
            },
            
            components: {
                Loading
            },
            
            methods: {
                deleteUser(id){
                    swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                this.form.post('/api/userdelete',id).then(()=>{
                                        swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success',
                                        ).then( (res) => {
                                            if(res){
                                                    window.location.href = '/users';
                                                }
                                            });
                                    Fire.$emit('AfterCreate');
                                }).catch(()=> {
                                    swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    });
                },
                created() {
                    
                    Fire.$on('willDelete',() => {
                    });
                    //    setInterval(() => this.loadUsers(), 3000);
                },
            }
    }
</script>