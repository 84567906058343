<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 INITIAL PAYMENT
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Program
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ nameProgram }}
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ nameClient }}
                        br
                        br
                        .col-lg-4.col-md-4
                            .form-group.row
                                .col-md-4
                                    label Ammount #[span(v-if="errorAmmount" style="color:red") Required]
                                .col-md-8(style="display:inherit") $
                                    input.input-card.input-form(:id="'campo'+1" type="text" v-model="ammount" v-on:blur="validInitial(1)" @keypress="justNumbers" step="any")                                    
                        .col-lg-8.col-md-8
                            .form-group.row
                                .col-md-4
                                    label Specify #[span(v-if="errorSpecify" style="color:red") Required]
                                .col-md-8
                                    input.input-form(type="text" v-model="observation" placeholder="Specify")
            .modal-footer
                .form-group
                    button.btn.btn-success.btn-color-green(type="button" @click="savePayment"  style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT             

</template>

<style lang="stylus">
    .text-style
        border: 1px solid #ccc;
        text-align: center;
        margin-left: 5px;
        width: 100%;
        height: 70px;
        border-radius: 10px;
</style>


<script>
export default {
    props:['payments','nameProgram','nameClient','type','editmodal','statusSale','sessionId','valorInitalPaymetn','account'],
    data() {
        return {
            ammount: '',
            observation : '',
            errorAmmount:false,
            errorSpecify:false,
        }
    },
    mounted() {
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        },
        validInitial(id){
            this.ammount = this.globalFunction(id)
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        savePayment(){
            if(this.ammount == ''){
                this.errorAmmount = true
            }else{
                this.errorAmmount = false
                if(this.observation == ''){
                    this.errorSpecify = true
                }else{
                    this.errorSpecify = false
                    var x = document.getElementById('modal_event')
                    x.classList.add('preloader-new')
                    swal.fire({
                        title: 'Add Credit',
                        text: "You won't be able to revert this!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#ab9220',
                        cancelButtonColor: '#8f9194',
                        confirmButtonText: 'Yes'
                        }).then((result) => {
                            if (result.value) {
                                this.sendMessage = true
                                var x = document.getElementById('app')
                                x.classList.add('preloader')
                                axios.post('/api/creditpayment',{
                                    amount: this.ammount,
                                    user_id: this.sessionId,
                                    account: this.account,
                                    observation: this.observation,
                                }).then(response => {
                                    if(response.status == 200) {
                                        this.sendMessage = false
                                        swal.fire({
                                            type: 'success',
                                            title: 'Transaction Finish'
                                            }).then( (res) => {
                                                if(res){
                                                    this.$emit('click',false)
                                                    }
                                                });
                                    }
                                }).catch(error => {                    
                                })
                            }else{
                                var x = document.getElementById('modal_event')
                                x.classList.remove('preloader-new')
                            }
                        });
                }
            }  
        },
    },
    created() {
        
    },
}
</script>