<template>
    <div class="row " style="margin-left: -9px;margin-right: -9px;">
        <div class="col-md-12">
            <div class="cont-title">
                <div class="row">
                    <div class="col-lg-6">
                        <span>automatic</span>
                    </div>
                    <div class="col-lg-6">
                        <button @click="historyImports" class="btn class-button-red" style="margin-right: 5px;text-transform:uppercase"><i class="fas fa-caret-left" style="margin-right:5px"></i>return</button>
                    </div>
                </div>
            </div>
            <div class="cont-search-paginate">
                <div class="row">
                    <div class="col-lg-12">
                        <div style="display: inline-flex">
                            <span style="background: #BAA345;color: white;padding: 5px 15px;border-radius: 5px;text-transform:capitalize">Merchant : {{details[0].merchant==1?'AMG':'WWCE'}}</span>
                            <span style="margin:5px"> <i class="fas fa-chevron-right"></i> </span>
                            <span style="background: #BAA345;color: white;padding: 5px 15px;border-radius: 5px;text-transform:capitalize">User : {{details[0].user_name}}</span>
                            <span style="margin:5px"> <i class="fas fa-chevron-right"></i> </span>
                            <span style="background: #BAA345;color: white;padding: 5px 15px;border-radius: 5px;text-transform:capitalize">transaction S : {{details[0].transactions_succesfully}}</span>
                            <span style="margin:5px"> <i class="fas fa-chevron-right"></i> </span>
                            <span style="background: #BAA345;color: white;padding: 5px 15px;border-radius: 5px;text-transform:capitalize">transaction E : {{details[0].transactions_error}}</span>
                            <span style="margin:5px"> <i class="fas fa-chevron-right"></i> </span>
                            <span style="background: #BAA345;color: white;padding: 5px 15px;border-radius: 5px;text-transform:capitalize">Date : {{details[0].created_at | myDate}}</span>                            
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div >
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Transaction</th>
                                    <th>Client Excel</th>
                                    <th>Client A.M.G.</th>
                                    <th>Account</th>
                                    <th>Program</th>
                                    <th>Advisor</th>
                                    <th>Amount</th>
                                    <th>Credit Card</th>
                                    <th>Transaction Status</th>
                                    <th>Settlement Date/Time</th>
                                </tr>
                                <tr v-for="(detail , index) in details" :key="index">
                                    <td>{{detail.transaction_id}}</td>
                                    <td>{{detail.name_file}}</td>
                                    <td>{{detail.name_bd}}</td>
                                    <td>{{detail.account}}</td>
                                    <td>{{detail.program}}</td>
                                    <td>{{detail.advisor_name}}</td>
                                    <td>{{detail.amount}}</td>
                                    <td>{{detail.card_number}}</td>
                                    <td>{{detail.status}}</td>
                                    <td>{{detail.settlement_date}}</td>                                   
                                </tr>
                        </tbody>
                    </table>                            
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props:['details'],
        data() {
            return {
                
            }
        },
        mounted() {
            /*var boton = document.getElementById('app');
            boton.classList.add('preloader');*/
            //console.log(this.details)
        },
        methods: {
            historyImports(){
                this.$emit('clickHistory', true);
            },
        },
        created() {
        },
    }
</script>