<template>
    <div id="loading">
        <div>
            <div v-if="grilla">
                <form-crm-grilla :global="this.global" @clickProcess="processOpen"></form-crm-grilla>
            </div>
            <div v-if="process">
                <form-crm-process :global="this.global" @clickList="listOpen"></form-crm-process>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global'],
        data() {
            return {
                grilla: true,
                process: false
            }
        },
        mounted() {
            var boton = document.getElementById('app');
            boton.classList.add('preloader');
        },
        methods: {
            processOpen(click){
                this.grilla = false
                this.process = click
            },
            listOpen(click){
                this.grilla = click
                this.process = false
            },
        },
        created() {
        },
    }
</script>