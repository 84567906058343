<template lang="pug">
div
    #modal_event.modal-event.estilo-modal.box-login(style="width: 800px !important;z-index: 7;")
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 ADD NOTE
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                div 
                    div.campos-detail-modal.row(style="margin: 0;")                        
                        div.col-lg-6
                            div.form-group.class-inline.row
                                div.class-campo-icon.col-md-4 Client
                                div.class-campo-text.col-md-8
                                    span(name="start" id="date") {{ nameClient }}
                        br
                        br
                        form(@submit.prevent="savePayment()" enctype="multipart/form-data" method="POST")
                            .col-lg-12.col-md-12
                                .form-group.row
                                    .col-md-4
                                        label Date #[span(style="color:red" v-if="errorDate") Required ]
                                    .col-md-8(style="display:inherit")
                                        .row
                                            .col-md-6
                                                kendo-datepicker(
                                                    v-model="form.date"
                                                    :min="minDate"
                                                    :max="maxDate"
                                                    :format="'MM/dd/yyyy'"
                                                    class="input-form"
                                                    name="date"
                                                    id="date"
                                                    style="background:white !important;width:150px;height: auto;display: inherit;")
                            .col-lg-12.col-md-12
                                .form-group.row
                                    .col-md-4
                                        label Content #[span(style="color:red" v-if="errorContent") Required ]
                                    .col-md-8
                                        textarea.input-form(style="height:120px" v-model="form.content")
                            .col-lg-12.col-md-12
                                .form-group.row
                                    .col-md-4
                                        label Attach file
                                    .col-md-8
                                        input.input-form(type="file" @change="onImageChange")
                                        button.btn.btn-success.btn-color-green(type="submit"  style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT             
            .modal-footer
                .form-group
                    a.btn.btn-success.btn-color-green(type="button" href="/descargararchivo" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-arrow-up] SUBMIT             
</template>

<script>
    export default {
        props:['nameClient','idaccount','session','addnotemodal'],
        data() {
            return {
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                value: "00:00 AM",
                form: new Form({                
                    date:this.addnotemodal.date,
                    content:this.addnotemodal.content,
                    image:'',
                    namedoc:'',
                }),
                errorSubj:false,
                errorDate:false,
                errorContent:false,                
            }
        },
        mounted() {
            console.log(this.addtaskmodal)
        },
        methods: {
            onImageChange(e){
                //console.log(e.target.files[0])
                this.$Progress.start();
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                fileReader.onload = (e) => {
                    this.form.image = e.target.result
                    console.log(e.target)
                    //console.log(this.form.file)
                }
                const file = e.target.files[0].name
                this.form.namedoc=  file
            },
            closeModal(){
                this.$emit('click',false)
            },
            savePayment(){
                this.axiosEnv()
                    /*if(this.date != ''){
                        this.errorDate = false
                        if(this.content == ''){
                            this.errorContent = true
                        }else{
                            this.errorContent = false
                            this.axiosEnv()
                        }
                    }*/
            },
            axiosEnv(){                
                console.log('sss')
                //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                this.form.post('/addnotecredit',this.form)
                .then(response => {
                    if(response.status == 200){
                        //this.$emit('click',false)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })


            },
            savePaymentdow(){
                axios.get('/descargararchivo')
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err); 
                })
            }
        },
        created() {
            
        },
    }
</script>