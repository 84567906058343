<template>
    <div>        
        <div class="row" style="padding: 20px">
            <div class="col-lg-5">                                                    
                <div class="form-group">
                    <label for="">Task</label>
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Subject</th>
                                <th>Date/Hour</th>
                                <th>Actions</th>
                            </tr>
                            <tr v-for="(item, index) in tasks" :key="index">
                                <th>{{item.subject}}</th>
                                <th>{{item.date | myGlobalDay}}</th>
                                <th style="display: inline-flex">
                                    <input type="checkbox" name="" id="" @click="checkTask(item.id)" style="height: 20px;width: 20px">
                                    <img :src="'/images/edit.png'" @click="editTask(item.id)" style="margin-left:5px">
                                    <img :src="'/images/delete.png'" @click="deleteTask(item.id)" style="margin-left:5px">
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <button class="btn class-button-green" @click="alltasks" style="float:left">View completed tasks</button>
                    <button class="btn class-button-green" @click="addTask" style="float:right">+ Add Task</button>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="form-group">
                    <label for="">Notes</label>
                    <table class="table">
                        <tbody id="busq-user">
                            <tr class="th-bg">
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Abr</th>
                                <th>May</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Agu</th>
                                <th>Set</th>
                                <th>Oct</th>
                                <th>Nov</th>
                                <th>Dec</th>
                            </tr>
                            <tr v-for="(item, index) in notes" :key="index">
                                                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group">
                    <span>First Note of Seller <img title="Notes" :src="'/images/icons/CRM--notes-ON.ico'" alt=""  style="cursor:pointer" @click="notesSale"></span>
                    <button class="btn class-button-green" style="float:right" @click="addNotes">+ Add Note</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['idaccount','session','allTasks','allNotes'],
        data() {
            return {
                //tasks:this.allTasks,
                notes:this.allNotes,
                tasks:[
                    {
                        'id' : 1,
                        'subject' : 'holas',
                        'date' : '2018-12-12 08:25:00',                        
                    }
                ]
            }
        },
        mounted() {
            
        },
        methods: {
            alltasks(){
                this.$emit('alltask',true)
            },
            addTask(){
                this.$emit('addtask',true)
            },
            notesSale(){
                this.$emit('notessale',true)
            },
            addNotes(){
                this.$emit('addnotes',true)
            },
            checkTask(id){
                axios.post('/api/',{
                    id: id
                })
                .then(response => {
                    if(response.status == 200){
                        this.$emit('actultask',true)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            editTask(id){
                axios.post('/api/edittaskcredit',{
                    id: id
                })
                .then(response => {
                    if(response.status == 200){
                        //this.$emit('editTask',response.data)
                        var datos = {'id' : 1 ,'subject' : 'holas' , 'date' : '2018-12-12', 'hour' : '12:30' , 'content' : 'sdnvsonvsonvdosnvsnvlsnlknvlks'}
                        this.$emit('editTask', datos)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            deleteTask(id){
                axios.post('/api/',{
                    id: id
                })
                .then(response => {
                    if(response.status == 200){
                        this.$emit('actultask',true)
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
        },
        created() {
            
        },
    }
</script>