<template>
    <div>
        <div id="loading">
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <h3>dashboard</h3>
                            </div>
                            <div class="col-lg-6" v-if="visualmod">
                                <select style="font-size: 15px;width: 50%;float: right;" class="input-form" id="" v-model="userfilter" @change="filtrocont()">
                                    <option v-bind:value="0">All</option>
                                    <option v-for="value in users" :key="value.id" v-bind:value="value.id">{{ value.user_name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active nav-dashboard" id="home-tab" data-toggle="tab" href="#lead" role="tab" aria-controls="home" aria-selected="true"  @click="change_tab(0)">
                                    <div class="icon-dashboard">
                                        <h4> <i class="fas fa-user-check"></i> Leads</h4>
                                    </div>
                                    <div class="cont-contst borde-box-dashboard" style="background: white">                                
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">TODAY</label>                                                    
                                                    <p class="borde-count" style="color: #BAA345" >{{global.leadday}}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">MONTH</label>                                                    
                                                    <p class="borde-count" >{{global.leadmonth}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link nav-dashboard" id="profile-tab" data-toggle="tab" href="#appointement" role="tab" aria-controls="profile" aria-selected="false"  @click="change_tab(1)">
                                    <div class="cont-contst borde-box-dashboard" style="background: white">
                                        <div class="icon-dashboard">
                                            <h4> <i class="far fa-calendar-alt"></i> Appointments</h4>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">TODAY</label>
                                                    <p class="borde-count" style="color: #BAA345">{{global.eventday}}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="content-info pad-new">                            
                                                    <label for="">MONTH</label>
                                                <p class="borde-count" >{{global.eventmonth}}</p>  
                                                </div>
                                            </div>
                                        </div>                                                
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link nav-dashboard" id="contact-tab" data-toggle="tab" href="#calls" role="tab" aria-controls="contact" aria-selected="false"  @click="change_tab(2)">
                                    <div class="cont-contst borde-box-dashboard" style="background: white">
                                        <div class="icon-dashboard">
                                            <h4> <i class="fas fa-phone-volume"></i> Calls</h4>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">TODAY</label>
                                                    <p class="borde-count" style="color: #BAA345">{{global.taskday}}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">MONTH</label>
                                                    <p class="borde-count">{{global.taskmonth}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link nav-dashboard" id="contact-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="contact" aria-selected="false"  @click="change_tab(3)">
                                    <div class="cont-contst borde-box-dashboard" style="background: white">
                                        <div class="icon-dashboard">
                                            <h4> <i class="fas fa-dollar-sign"></i> Sales</h4>
                                        </div>
                                        <div class="row">                       
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">TODAY</label>
                                                    <p class="borde-count" style="color: #BAA345">{{global.vendioday}}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">MONTH</label>
                                                    <p class="borde-count" >{{global.vendiomonth}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link nav-dashboard" id="contact-tab" data-toggle="tab" href="#captur" role="tab" aria-controls="contact" aria-selected="false" @click="change_tab(4)">
                                    <div class="cont-contst borde-box-dashboard" style="background: white">
                                        <div class="icon-dashboard">
                                            <h4> <i class="fas fa-star"></i> Capturated</h4>
                                        </div>
                                        <div class="row">                       
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">TODAY</label>
                                                    <p class="borde-count" style="color: #BAA345">{{global.clientday}}</p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="content-info pad-new">
                                                    <label for="">MONTH</label>
                                                    <p class="borde-count" >{{global.clientmonth}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div class="div-contenido-tabs">
                            <div class="container" style="padding:0">
                                <div class="box-chart" style="margin-bottom:0px">
                                    <div class="title-chart">
                                        MONTHLY GRAPHICS
                                    </div>
                                    <div class="cont-select cont-select-chart">
                                        <select class="input-form" v-model="year" name="" id="" @change="filtrocont()">
                                            <option value="2015">2015</option>
                                            <option value="2016">2016</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                        </select>                                        
                                    </div>                                                                        
                                    <div class="title-chart" style="margin-left: 15px;">
                                        TOTAL :
                                    </div>
                                    <div class="cont-select cont-select-chart">
                                        <input class="input-form" type="text" name="" id="" v-model="total_year" disabled>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="lead" role="tabpanel" aria-labelledby="home-tab">
                                    <div id="app">
                                        <div id="content">
                                            <canvas id="myChart1" ></canvas>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="appointement" role="tabpanel" aria-labelledby="profile-tab">
                                    <div id="app">
                                        <div id="content">
                                            <canvas id="myChart2" ></canvas>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="calls" role="tabpanel" aria-labelledby="contact-tab">
                                    <div id="app">
                                        <div id="content">
                                            <canvas id="myChart3" ></canvas>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="contact-tab">
                                    <div id="app">
                                        <div id="content">
                                            <canvas id="myChart4" ></canvas>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="captur" role="tabpanel" aria-labelledby="contact-tab">
                                    <div id="app">
                                        <div id="content">
                                            <canvas id="myChart5" ></canvas>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
    import Chart from 'chart.js';
    export default {
        props:['perfil'],
        data() {
            return {
                visualmod: this.perfil.layout.role_id == 1 || this.perfil.layout.role_id == 2 ? true : false,
                global : [],
                leads: [],
                quotes: [],
                calls: [],
                sales: [],
                captur: [],
                userfilter:'',
                year:'2019',
                value:'',
                total_year:0,
                index:0,
                users:[],
            }
        },
        methods: {
            change_tab(index){
                switch(index){
                    case 0: this.total_year=this.global.leads_year; break;
                    case 1: this.total_year=this.global.quotes_year; break;
                    case 2: this.total_year=this.global.calls_year; break;
                    case 3: this.total_year=this.global.sales_year; break;
                    case 4: this.total_year=this.global.clients_year; break;
                };
                this.index=index;
            },
            filtrocont(){
                if(window.char1 && window.char2 && window.char3 && window.char4 && window.char5){
                    window.char1.destroy();
                    window.char2.destroy();
                    window.char3.destroy();
                    window.char4.destroy();
                    window.char5.destroy();
                }                
                axios.post('/api/filtrouserdash',{ 
                    created_id : this.userfilter,anio:this.year
                }).then(response => {
                    if(response.status == 200){
                        this.global = response.data;
                        this.leads = response.data.leads.map(list => {
                        return list;
                        });
                        this.quotes = response.data.quotes.map(list => {
                            return list;
                        });
                        this.calls = response.data.calls.map(list => {
                            return list;
                        });
                        this.sales = response.data.sales.map(list => {
                            return list;
                        });
                        this.captur = response.data.vendio.map(list => {
                            return list;
                        });
                        this.graphics(); 
                        this.change_tab(this.index);
                    }
                });
                
            },
            graphics(){
                var leads = document.getElementById("myChart1");
                    var quotes = document.getElementById("myChart2");
                    var calls = document.getElementById("myChart3");
                    var sales = document.getElementById("myChart4");
                    var captur = document.getElementById("myChart5");

                    window.char1 = new window.Chart(leads, {
                        type: "line",                        
                        data: {
                            labels: ["January", "February", "March", "April", "May", "June","July","August","September","October","November","December"],
                            datasets: [
                                {
                                backgroundColor: "#FF6B00",
                                borderColor: "rgb(54, 162, 235)",
                                fill: false,
                                data: this.leads,
                                label: 'Total Leads ',
                                borderColor: '#BAA345',
                                pointBorderColor: '#FFFF35',
                                pointBackgroundColor: '#FFFF35',
                                }
                            ],
                        },
                        options: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: ''
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month',                                        
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Value',
                                    }
                                }]
                            }
                        }
                    });
                    window.char2 = new window.Chart(quotes, {
                        type: "line",
                        data: {
                            labels: ["January", "February", "March", "April", "May", "June","July","August","September","October","November","December"],
                            datasets: [
                                {
                                backgroundColor: "#FF6B00",
                                borderColor: "rgb(54, 162, 235)",
                                fill: false,
                                data: this.quotes,
                                label: 'Total Appointments',
                                borderColor: '#BAA345',
                                pointBorderColor: '#FFFF35',
                                pointBackgroundColor: '#FFFF35',
                                }
                            ],
                        },
                        options: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: ''
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month',                                        
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Value',
                                    }
                                }]
                            }
                        }
                    });
                    window.char3 = new window.Chart(calls, {
                        type: "line",
                        data: {
                            labels: ["January", "February", "March", "April", "May", "June","July","August","September","October","November","December"],
                            datasets: [
                                {
                                backgroundColor: "#FF6B00",
                                borderColor: "rgb(54, 162, 235)",
                                fill: false,
                                data: this.calls,
                                label: 'Total Calls',
                                borderColor: '#BAA345',
                                pointBorderColor: '#FFFF35',
                                pointBackgroundColor: '#FFFF35',
                                }
                            ],
                        },
                        options: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: ''
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month',                                        
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Value',
                                    }
                                }]
                            }
                        }
                    });
                    window.char4 = new window.Chart(sales, {
                        type: "line",
                        data: {
                            labels: ["January", "February", "March", "April", "May", "June","July","August","September","October","November","December"],
                            datasets: [
                                {
                                backgroundColor: "#FF6B00",
                                borderColor: "rgb(54, 162, 235)",
                                fill: false,
                                data: this.sales,
                                label: 'Total Sales',
                                borderColor: '#BAA345',
                                pointBorderColor: '#FFFF35',
                                pointBackgroundColor: '#FFFF35',
                                }
                            ],
                        },
                        options: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: '',
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month',                                        
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Value',
                                    }
                                }]
                            }
                        }
                    });
                    window.char5 = new window.Chart(captur, {
                        type: "line",
                        data: {
                            labels: ["January", "February", "March", "April", "May", "June","July","August","September","October","November","December"],
                            datasets: [
                                {
                                backgroundColor: "#FF6B00",
                                borderColor: "rgb(54, 162, 235)",
                                fill: false,
                                data: this.captur,
                                label: ' Total Captured',
                                borderColor: '#BAA345',
                                pointBorderColor: '#FFFF35',
                                pointBackgroundColor: '#FFFF35',
                                }
                            ],
                        },
                        options: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: ''
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Month',                                        
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Value',
                                    }
                                }]
                            }
                        }
                    });
            },
            allData(){
                var user_id=this.perfil.layout.id;
                
                if(this.perfil.layout.role_id == 1 || this.perfil.layout.role_id == 2 ){
                    user_id=0;
                }
                 axios.post('/api/filtrouserdash',{
                        created_id : user_id
                        })
                .then(response => {
                    this.userfilter = response.data.usercreate; 
                    this.global = response.data;
                    this.leads = response.data.leads.map(list => {
                        return list;
                    });
                    this.quotes = response.data.quotes.map(list => {
                        return list;
                    });
                    this.calls = response.data.calls.map(list => {
                        return list;
                    });
                    this.sales = response.data.sales.map(list => {
                        return list;
                    });
                    this.captur = response.data.vendio.map(list => {
                        return list;
                    });
                    //console.log(this.global)
                     this.graphics(); 
                     this.total_year = this.global.leads_year;
                     setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                });
              
            },
            userCreator(){
                axios.post('/api/sellerall/2',{ 
                    roles : '[]',
                    type : '0'
                }).then(response => {
                    this.users = response.data;
                })
            },
        },
        created() {
            this.allData();
            this.userCreator();
        },  
    }
</script>
