<template>
    <div id="loading">
        <div v-if="grilla">
            <form-others-grilla :global="this.global" @clickRegister="registerOpen" :type="this.type" @clickEdit="editOther"></form-others-grilla>
        </div>
        <div v-if="register">
            <form-others-register :global="this.global" @clickList="listOpen"></form-others-register>
        </div>
        <div v-if="edit">
            <form-others-edit :global="this.global" @clickList="listOpen" :edit="this.dataedit" ></form-others-edit>
        </div>
    </div>
</template>

<script>
    export default {
        props:['global','type'],
        data() {
            return {
                grilla: true,
                register: false,
                edit: false,
                dataedit: []
            }
        },
        mounted() {
            /*var boton = document.getElementById('app');
            boton.classList.add('preloader');*/
        },
        methods: {
            registerOpen(click){
                this.grilla = false
                this.register = click
                this.edit = false
            },
            listOpen(click){
                this.grilla = click
                this.register = false
                this.edit = false
            },
            editOther(click){
                this.grilla = false
                this.register = false
                this.edit = true
                this.dataedit = click
            }
        },
        created() {
        },
    }
</script>