<template>
    <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>Clients</span>
                            </div>
                            <div class="col-lg-6">
                                <button  @click="createmigrations" class="btn class-button-green"><img :src="'/images/icon-add.png'" style="margin-right: 5px;width: 15%;margin-top: -3px;"> CREATE</button>
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>
                                    <button id="btnsearch2" class="btn class-btn-refresh" title="Refresh" @click="resertSearch"><i class="fas fa-sync-alt"></i></button>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div style="float:right">
                                    <input v-if="advance == true" id="searchText" type="text" class="class-input-search" v-model="campo1" @keyup.enter="search(null,null)" style="outline: none">
                                    <button v-if="advance == true" id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>
                                    <button class="btn class-btn-next-prev class-color-brow" v-if="searchadvance" id="btn-avance" @click="advanceSearch"> <i class="fas fa-angle-double-right" style="margin-top: 4px;"></i> ADVANCED SEARCH</button>
                                    <button class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" id="btn-avance" @click="resertSearch"><i class="fas fa-angle-double-left" style="margin-top: 4px;"></i>  BASIC SEARCH</button>
                                </div>
                                <br>
                                <br>
                                <div v-if="advance == false" style="display: inline-flex;margin-top: 10px;float:right">
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">From</label>
                                        <kendo-datepicker
                                            v-model="from"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date"
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">To</label>
                                        <kendo-datepicker
                                            v-model="to"
                                            :min="minDate"
                                            :max="maxDate"
                                            :format="'MM/dd/yyyy'"
                                            class="input-form"
                                            name="date" 
                                            id="date"
                                            style="background:#f1f1f1 !important;width:150px;height: auto;display: inherit;">
                                        </kendo-datepicker>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Programs</label>
                                        <select v-model="program" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;" @change="allAdvisors()">
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in programs" :key="index" :value="item.id">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Advisor</label>
                                        <select v-model="advisor" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option v-for="(item, index) in advisors" :key="index" :value="item.id">{{ item.user_name }}</option>                                                 
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin: 0px 5px;">
                                        <label for="">Status</label>
                                        <select v-model="status" class="input-form" name="" id="" style="color: #666666 !important;height: 30px;">                                                                    
                                            <option value="0">All</option>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>                                                 
                                        </select>
                                    </div>
                                    <div class="form-group" style="margin-top: 25px;">                                        
                                        <button id="btnsearch" class="btn class-btn-next-prev class-color-brow" type="button"  @click="search(null,null)" > <img :src="'/images/icon-search.png'" ></button>                                        
                                    </div>
                                    <div class="form-group" style="margin-top:25px">
                                        <button id="btnsearch2" class="btn class-btn-next-prev class-color-brow" v-if="resetadvance" title="Clear" @click="resertSearch"><img :src="'/images/icons/CRM-clear.ico'" alt=""></button>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th>
                                            <span v-if="oneEventClient" @click="search('asc',2)" style=" cursor: pointer;text-decoration:underline">Name </span>
                                            <span v-if="orderClientAsc" @click="search('desc',2)" style=" cursor: pointer;text-decoration:underline">Name <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderClientDesc" @click="search('asc',2)" style=" cursor: pointer;text-decoration:underline">Name <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Mobile</th>
                                        <th>
                                            <span v-if="oneEventAccount" @click="search('asc',4)" style=" cursor: pointer;text-decoration:underline">Account </span>
                                            <span v-if="orderAccountAsc" @click="search('desc',4)" style=" cursor: pointer;text-decoration:underline">Account <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderAccountDesc" @click="search('asc',4)" style=" cursor: pointer;text-decoration:underline">Account <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Program</th>
                                        <th>Status</th>
                                        <th>Advisor</th>
                                        <th>
                                            <span v-if="oneEventDate" @click="search('asc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date | User Create </span>
                                            <span v-if="orderDateAsc" @click="search('desc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date | User Create <i class="fas fa-long-arrow-alt-up"></i></span>
                                            <span v-if="orderDateDesc" @click="search('asc',5)" style=" cursor: pointer;text-decoration:underline">Creation Date | User Create <i class="fas fa-long-arrow-alt-down"></i></span>
                                        </th>
                                        <th>Finish</th>
                                        <th>Finish Date | User Finish</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr v-for="(client , index) in clients" :key="index">
                                         <td class="blue-color" >
                                             <a :href="'/administration/clients/edit/'+ client.lead_id">{{client.lead_name}}</a>
                                        </td>
                                         <td class="gris-color">{{client.mobile}}</td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px">                                                
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">
                                                    <a :href="'/administration/migrations/account/'+ account.account_id">{{account.account}}</a>
                                                    
                                                </li>                                                
                                            </ul>
                                         </td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px">                                                
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">{{ account.program}}</li>                                                
                                            </ul>
                                         </td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px">                                                
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">
                                                    <span v-if="account.status == 1" style="color:#00CC00;text-transform: uppercase;font-weight: bold;">Active</span>
                                                    <span v-else style="color:#FF0000;text-transform: uppercase;font-weight: bold;">Inactive</span>
                                                </li>                                                
                                            </ul>
                                         </td>
                                         <td >
                                             <ul style="padding-left: 0px; margin-bottom: 0px">                                                
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;">{{ account.advisor_name }}</li>                                                
                                            </ul>
                                         </td>
                                         <td class="gris-color">
                                             <ul style="padding-left: 0px; margin-bottom: 0px">
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;"> <span v-if="account.created_at != null">{{ account.created_at | myDate }}</span> <span v-else>-</span> | {{ account.user_name != null ? account.user_name : '-' }}</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul style="padding-left: 0px; margin-bottom: 0px">
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;"><span :style="account.migrating == 0 ? 'color:#00CC00' : 'color:#FF0000'">{{ account.migrating == 1 ? 'NO' : 'YES' }}</span></li>
                                            </ul>
                                         </td>
                                         <td>
                                            <ul style="padding-left: 0px; margin-bottom: 0px">
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;"><span v-if="account.updated_at != null">{{ account.updated_at | myDate }}</span> <span v-else>-</span> | {{ account.updater_name !=null ? account.updater_name: '-' }}</li>
                                            </ul>
                                         </td>                                         
                                         <td>
                                             <ul style="padding-left: 0px; margin-bottom: 0px">
                                                <li v-for="(account , index) in JSON.parse(client.accounts)" :key="index" style="list-style: none;"><i v-if="account.migrating == 1" @click="deleteAccount(account.account_id)" style="color: red;cursor:pointer" class="fas fa-times-circle"></i></li>
                                            </ul>
                                         </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalclients : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalclients }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { switchCase } from 'babel-types';
    export default {
        props:['global','type'],
        data() {
            return {
                clients: [],
                campo1: '',
                advance : true,
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1000,1,1),
                maxDate: new Date(2050,9,1),
                searchadvance: true,
                resetadvance: false,
                from: '',
                to: '',
                program:null,
                programs:[],
                status:'',
                advisor:null,
                advisors:[],
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totalclients:'',
                orderDate: true,
                oneEventClient: true,
                orderClientAsc:false,
                orderClientDesc:false,
                oneEventDate: true,
                orderDateAsc:false,
                orderDateDesc:false,
                oneEventAccount:true,
                orderAccountAsc:false,
                orderAccountDesc:false,
            }
        },
        methods: {
            deleteAccount(id){
                console.log(id);
                swal.fire({
                title: 'Delete Account ?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ab9220',
                cancelButtonColor: '#8f9194',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) {
                        var x = document.getElementById('app')
                        x.classList.add('preloader')
                        axios.post('/api/deleteaccount',{
                            id: id,                         
                        })
                        .then(response => {
                            if(response.status == 200){                        
                                swal.fire({
                                    type: 'success',
                                    title: 'Delete Account'
                                }).then( (res) => {
                                    if(res){
                                        this.search(null,null)                                
                                        }
                                    });
                            }
                        })
                        .catch(err => {
                            console.error(err); 
                        })
                    }
                });
            },
            createmigrations(){
                this.$emit('createClient',true)
            },
            advanceSearch(){
                this.advance = false
                this.searchadvance = false
                this.resetadvance =  true
                this.campo1 = ''
                var x = document.getElementById('btnsearch')
                x.classList.add('margin-top-advance')
            },
            resertSearch(){
                this.campo1 = null
                this.advance = true
                this.searchadvance = true
                this.resetadvance =  false
                this.from = ''
                this.to = ''
                this.program = null
                this.advisor = null
                this.search(null,null)
                var x = document.getElementById('btnsearch')
                x.classList.remove('margin-top-advance')
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/clientmigration?page='+newval,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        program: this.program,
                        orderby: this.dato2 == null ? 5 : this.dato2,
                        order: this.dato1 == null ? 'desc' : this.dato1,
                        status: this.status,
                }).then(response => {
                    if(response.status == 200){
                        this.clients = response.data.data                
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;                    
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/clientmigration?page='+next,{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        program: this.program,
                        orderby: this.dato2 == null ? 5 : this.dato2,
                        order: this.dato1 == null ? 'desc' : this.dato1,
                        status: this.status,
                }).then(response => {
                    if(response.status == 200){
                        this.clients = response.data.data                                     
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                  
                        this.ultima_pagina = response.data.last_page;
                        this.totalclients = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },
            search(dato1 , dato2){
                this.dato1 = dato1
                this.dato2 = dato2 
                if(dato2 == 2) {
                    if(dato1 == "desc"){
                        this.oneEventClient = false
                        this.orderClientDesc = true
                        this.orderClientAsc = false
                    }else{
                        this.orderClientAsc = true
                        this.orderClientDesc = false
                        this.oneEventClient = false
                    }
                    this.oneEventDate = true
                    this.orderDateDesc = false
                    this.orderDateAsc = false
                }
                if(dato2 == 5) {
                    if(dato1 == "desc"){
                        this.oneEventDate = false
                        this.orderDateDesc = true
                        this.orderDateAsc = false
                    }else{
                        this.orderDateAsc = true
                        this.orderDateDesc = false
                        this.oneEventDate = false
                    }
                    this.oneEventClient = true
                    this.orderClientAsc = false
                    this.orderClientDesc = false
                }
                if(dato2 == 4) {
                    if(dato1 == "desc"){
                        this.oneEventAccount = false
                        this.orderAccountDesc = true
                        this.orderAccountAsc = false
                    }else{
                        this.orderAccountAsc = true
                        this.orderAccountDesc = false
                        this.oneEventAccount = false
                    }
                    /*this.oneEventAccount = true
                    this.orderClientAsc = false
                    this.orderClientDesc = false*/
                }
                    axios.post('/api/clientmigration',{
                        text: this.campo1,
                        from: this.from,
                        to: this.to,
                        program: this.program,
                        orderby: this.dato2 == null ? 5 : this.dato2,
                        order: this.dato1 == null ? 'desc' : this.dato1,
                        status: this.status,
                        advisor:this.advisor,
                    })
                    .then(response => {
                        if(response.status == 200){                    
                            this.clients = response.data.data
                            this.start_page =  response.data.current_page;
                            this.perpage = response.data.per_page;
                            this.next_page = this.start_page+1;
                            this.ultima_pagina = response.data.last_page;
                            this.totalclients = response.data.total;
                            setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        }
                    })
            },
            allPrograms(){
                axios.get('/api/programs')
                    .then(response => {
                        this.programs =  response.data 
                    });
            },
            allAdvisors(){
                var module = -1
                if(this.program == 0){
                    module = 0
                }else{
                    switch (this.program) {                
                        case 1: module = 3; break;
                        case 2: module = 7; break;
                        case 3: module = 6; break;
                        case 4: module = 5; break;
                        case 5: module = 8; break;
                    }
                }
                this.advisor = null
                axios.post('/api/usersprograms',{id:module})
                    .then(response => {
                        this.advisors =  response.data 
                    });
            },

        },
        created() {
            this.search(null,null);
            this.allPrograms();
            this.allAdvisors();
        },
    }
</script>