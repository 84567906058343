<template lang="pug">
    #modal_event.modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 CONTRACT FEE
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            .col-lg-12.col-md-12
                                .row
                                    .col-lg-6.col-md-6
                                        .form-group(style="background: #f1f1f1;border-radius: 5px;padding: 2px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label(style="margin-top: 2px;") Fee :
                                                .col-md-6(style="border-left: 2px solid #cccccc;display:inherit") $
                                                    input.input-card.input-card-border(type="text" v-model="fee" disabled id="fee")
                                        .form-group(style="background: #f1f1f1;border-radius: 5px;padding: 4px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label(style="margin-top: 2px;") Initial Payment :
                                                .col-md-6(style="border-left: 2px solid #cccccc;display: inherit;") $
                                                    span(style="color:red;margin-left: 50px;" v-if="this.valorInitalPaymetn == 1") Pending
                                                    input.input-card.input-show(v-else :id="'campo'+1" type="text" v-model="initial_amount" v-on:blur="validInitial(1)" @keyup="rodeInitial" @keypress="justNumbers" :disabled="valorEdit" step="any" style="background: #f1f1f1 !important;")
                                                    span(v-if="IPerror" style="color:red") Required
                                        .form-group
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label
                                                .col-md-6(style="background: #f1f1f1;border-radius: 5px;padding: 2px;") 
                                                    span(style="position: absolute;left: 10%;top: 15%;") $
                                                    input.input-card.input-card-border(type="text" v-model="difference" disabled id="difference")                            
                                        .form-group
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Monthly Payment :
                                                .col-md-6(style="display: inherit;") $
                                                    input.input-card( style="border-radius: 5px;" type="text" v-model="monthly_amount" v-on:blur="validMounthly(2)" :class="this.contractsales.program_id == 2?'input-show':''" @keyup="paymentInitial" :disabled="valorEdit" :id="'campo'+2")
                                                    span(v-if="MPerror" style="color:red") Required
                                        .form-group
                                            .row(style="margin:0")
                                                .col-md-12(style="text-align: center;background: #f1f1f1;padding: 3px;border-radius: 5px;")
                                                    div(style="padding: 5px;border-radius: 5px;")
                                                        span Finished in #[input.input-monthly(type="text" v-model="months" disabled)] months                                        
                                    .col-lg-6.col-md-6                                        
                                        .form-group(style="background: #f1f1f1;padding: 6px;border-radius: 5px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Method of Payment :
                                                    span(v-if="MOPerror" style="color:red") Required
                                                .col-md-6
                                                    .row
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="method" id="card" v-model="method_payment" value="0" @click="cardEvent(0)" :disabled="valorEdit")
                                                            label(for="card" style="margin-left: 15px;") Credit Card
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="method" id="cheque" v-model="method_payment" value="1" @click="cardEvent(1)" :disabled="valorEdit")
                                                            label(for="cheque" style="margin-left: 15px;") Others                            
                                        .form-group(v-if="method_payment == 0" style="background: #f1f1f1;padding: 6px;border-radius: 5px;")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Type :
                                                    .row
                                                        .col-md-6
                                                            span(v-if="TPerror" style="color:red") Required
                                                .col-md-6
                                                    .row
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="type" id="automatic" v-model="type_payment" value="0" :disabled="valorEdit")
                                                            label(for="automatic" style="margin-left: 15px;") Automatic
                                                        .col-lg-12(style="padding: 6px;")
                                                            input(type="radio" name="type" id="manul" v-model="type_payment" value="1" :disabled="valorEdit" @click="manualclick")
                                                            label(for="manul" style="margin-left: 15px;") Manual
                                        .form-group(v-if="method_payment == 0 && type_payment == 0")
                                            .row(style="margin:0")
                                                .col-md-6
                                                    label Day of Payment :
                                                    .row
                                                        .col-md-6
                                                            span(v-if="DOPerror" style="color:red") Required
                                                .col-md-6
                                                    .row
                                                        select.input-card(name="days" id="days5" v-model="day_payment" :disabled="valorEdit")
                                                            option(:value="d.day" v-for="d in payment_days" :key="d.id") {{ d.day }}
                            br
                            .col-lg-12.col-md-12(v-if="method_payment == 0 && type_payment == 0") 
                                .col-lg-12.col-md-12( :style=" cards != null ? cards.length > 3 ? 'height: 120px;margin-bottom:15px;margin-top: 15px;overflow-y: auto;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;':'margin-top: 15px;padding-right: 0;padding-left: 0;'")
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th 
                                                th Card Holder Name
                                                th Card Number
                                                th MM
                                                th YY
                                                th CVC
                                            tr(v-for="(card, index) in cards" :key="index" v-show="card.cardnumber != null")
                                                td 
                                                    input(type="radio" :value="card.id" :name="'card'+card.id" v-model="card_id" :disabled="valorEdit")
                                                td(style="color: #9b9b9b;text-transform: capitalize") {{card.cardholdername}}
                                                td(style="color: #9b9b9b;") {{'XXXX-XXXX-XXXX-'+card.cardnumber}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_month}}
                                                td(style="color: #9b9b9b;") {{card.card_expi_year}}
                                                td(style="color: #9b9b9b;") {{card.cardsecuritycode != null ?  card.cardsecuritycode.length == 3 ? 'XX'+card.cardsecuritycode.substr(2) : 'XXX'+card.cardsecuritycode.substr(3) : ''}}
                                .col-lg-12.col-md-12(style="text-align: right" v-show="valorEdit != true")
                                    a(@click="createcard" class="btn class-button-green" style="width:80px;color:white") #[img(:src="'/images/icon-add.png'" style="margin-right: 5px;width: 18%;margin-top: -3px;")] ADD
                                        span(v-if="CAerror" style="color:red;position: absolute;left: 15px;top: 25px;") Required

            .modal-footer(v-if="this.valorInitalPaymetn != 1")
                button.btn.btn-success.btn-color-green(type="button" @click="saveContract" v-if="valorEdit != true" style="font-size: 13px;padding: 6px;height: 35px;") #[i.fas.fa-save] SAVE
        modal-card-create(v-if="createmodal" :idlead="this.lead_id" @new="addCard" @click="closedModalCar" style="z-index:99 !important")

</template>

<style lang="stylus">
    .input-card
        border: 1px solid #ccc;
        text-align: center;
        margin-left: 5px;
        width: 90%;
        height: 28px;
        background: #f1f1f1;
        border-radius: 5px;

    .input-card-border
        border: none !important;

    .input-monthly
        border: 1px solid #fff;
        text-align: center;
        margin-left: 5px;
        width: 30px;
        background: #fff;
        border-radius: 5px;
        margin-right: 5px;

    .input-left
        margin-right: 15px;
</style>


<script>
import { ifStatement } from 'babel-types';
export default {
    props:['contractsales','nameProgram','nameClient','type','editmodal','statusSale','valorInitalPaymetn'],
    data() {
        return {
            lead_id:this.contractsales.lead_id, 
            sale_id: this.contractsales.sale_id,
            client_id:this.contractsales.sale_id,
            program: this.nameProgram,
            client_name:this.nameClient,
            fee:this.contractsales.fee,
            cards:JSON.parse(this.contractsales.cards),
            initial_amount:this.contractsales.initial_amount,
            difference:'',
            monthly_amount: this.contractsales.program_id == 2 ? this.contractsales.fee : this.contractsales.monthly_amount ,
            months: this.contractsales.program_id == 2 ? 0 : this.contractsales.months ,
            day_payment:this.contractsales.day_payment ,
            method_payment:this.contractsales.method_payment,
            type_payment:this.contractsales.type_payment ,
            card_id:this.contractsales.card_id ,
            cardCredit: false,
            createmodal:false,
            campovacio: false,
            valorEdit : false,
            payment_days:[],
            IPerror: false,
            MPerror: false,            
            MOPerror: false,
            TPerror: false,
            DOPerror: false,
            CAerror: false,
        }
    },
    mounted() {
        if(this.contractsales.card_id != null && this.contractsales.card_id != ''){
            var cards = JSON.parse(this.contractsales.cards)
            var newcards = []
            cards.map(index => {
                if(index.id == this.contractsales.card_id){
                    newcards.push(index)
                }
            })
            this.cards = newcards            
        }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        if(this.fee != null){
            var data = formatter.format(this.fee)
            this.fee = data.replace('$', "")
        }
        if(this.initial_amount != null){
            var data1 = formatter.format(this.initial_amount)
            this.initial_amount = data1.replace('$', "")
        }
        if(this.monthly_amount != null){
            var data2 = formatter.format(this.monthly_amount)        
            this.monthly_amount = data2.replace('$', "")
        }
        if(this.contractsales){
            if(this.contractsales.method_payment == 0){
                this.campovacio = true
            }else{
                this.campovacio = false
            }
            if(this.fee != null && this.initial_amount != null){
                var x = this.fee.replace(/,/gi,"") - this.initial_amount.replace(/,/gi,"")
                this.difference = x //+'.00'
                var data = formatter.format(this.difference)
                this.difference = data.replace('$', "")
            }
        }
        if(this.type == 1 || this.editmodal == false || this.statusSale == 2 || this.statusSale == 4){
            this.valorEdit = true
        }else{
            this.valorEdit = false
        }
    },
    methods: {
        manualclick(){
            this.day_payment = null
            this.card_id = ''            
        },
        validMounthly(id){
            this.monthly_amount = this.globalFunction(id)        
        },
        validInitial(id){
            this.initial_amount = this.globalFunction(id)
        },
        globalFunction(id){
            var x = document.getElementById('campo'+id).value            
            if(x.indexOf('.') != -1){ 
                var numw = x.replace(/,/gi, "");
                var dec = numw.split('.')
                var num = dec[0].split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.'+dec[1]
                
            }else{
                var numw = x.replace(/,/gi, "");
                var num = numw.split(/(?=(?:\d{3})+$)/).join(",");
                num = num+'.00'
            }
            return num
        },
        justNumbers: function (event) {
            var charCode = window.event ? event.which : event.keyCode;
            var RE = /^\d*(\.\d{1})?\d{0,1}$/;
            if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57)) {
                //Usando la definición del DOM level 2, "return" NO funciona.
                event.preventDefault();
            }
        },
        closeModal(){
            this.$emit('click',false)
        },
        closedModalCar(click){
            this.createmodal = false
            var x = document.getElementById('modal_event')
            x.classList.remove('preloader-new')
        },
        rodeInitial(){
            var x = this.fee.replace(/,/gi,"") - this.initial_amount.replace(/,/gi,"")
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            })
            this.difference = x
            var data = formatter.format(this.difference)
            this.difference = data.replace('$', "")
            this.paymentInitial()
        },
        paymentInitial(){                        
            if(this.monthly_amount != null){                
                this.MPerror = false            
                if(this.monthly_amount.length == 0){
                    this.monthly_amount = null
                }
            }
            if(this.difference != null && this.monthly_amount != null){
                var x = this.difference.replace(/,/gi,"")/this.monthly_amount.replace(/,/gi,"")
                var arr = Math.ceil(x)
                if(arr == Infinity){
                    this.months = ''
                }else{
                    this.months = arr
                }
            }
        },
        saveContract(){
            if(      this.initial_amount == null && this.monthly_amount == null && this.method_payment == null){
                this.IPerror = true
                this.MPerror = true
                this.MOPerror = true
            }else if(this.initial_amount != null && this.monthly_amount == null && this.method_payment == null){                
                this.MPerror = true
                this.MOPerror = true
            }else if(this.initial_amount == null && this.monthly_amount != null && this.method_payment == null){
                this.IPerror = true
                this.MOPerror = true
            }else if(this.initial_amount != null && this.monthly_amount != null && this.method_payment == null){                
                this.MOPerror = true
            }else if(this.initial_amount != null && this.monthly_amount == null && this.method_payment != null){                
                this.MPerror = true
            }else if(this.initial_amount == null && this.monthly_amount != null && this.method_payment != null){
                this.IPerror = true
            }else if(this.initial_amount == null && this.monthly_amount == null && this.method_payment != null){
                this.IPerror = true
                this.MPerror = true
            }else if( this.initial_amount != null && this.monthly_amount != null && this.method_payment != null){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                boton.classList.add('modal-preloader')
                const param = {
                    initial_amount:this.initial_amount,                
                    monthly_amount:this.monthly_amount,
                    months:this.months,
                    day_payment:this.day_payment,
                    method_payment:this.method_payment,
                    type_payment:this.type_payment,
                    card_id:this.card_id,
                    sale_id: this.sale_id,
                };  
                var boton = document.getElementById('app');
                var error = boton.getElementsByClassName('is-invalid')

                setTimeout(function(){                    
                    if(error.length > 0){                    
                        button.attr('disabled',false);
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                },1000)
                
                axios.post('/api/insertContract',param)
                .then(response => {
                    if(response.status == 200){                    
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                        boton.classList.remove('modal-preloader')
                        this.DOPerror = false
                        this.TPerror = false
                        this.IPerror = false
                        this.MPerror = false
                        this.MOPerror = false
                        this.CAerror = false
                        swal.fire({
                                type: 'success',
                                title: 'Contract Fee Successfull'
                            }).then( (res) => {
                                if(res){
                                    this.$emit('click',false)
                                    }
                                });
                    }
                }).catch( (errors) => {
                    if(errors.response.status == 422){
                            setTimeout(() => {
                                var boton = document.getElementById('app');
                                boton.classList.remove('preloader')
                            }, 50); 
                            if (errors.response.data.errors.type_payment) {
                                this.TPerror = true
                            }else{this.TPerror = false}
                            if (errors.response.data.errors.day_payment) {
                                this.DOPerror = true
                            }else{this.DOPerror = false}
                            if (errors.response.data.errors.card_id) {
                                this.CAerror = true
                            }else{this.CAerror = false}
                        }
                })
            }
        },
        cardEvent(id){            
            if(id == 0){
                this.campovacio = true
            }else{
                this.campovacio = false
                this.type_payment = 2
                this.day_payment = null
                this.card_id = ''
            }            
            this.method_payment = id            

            if(this.method_payment != null){
                this.MOPerror = false
            }
        },
        addCard(cards) {
            this.cards = cards;
        },
        createcard(){
            this.createmodal = true;
            var x = document.getElementById('modal_event')
            console.log(x)
            x.classList.add('preloader-new')
        },
        closedmodal(click){
            this.createmodal = click;            
        },
    },
    created() {
        axios.get('/api/paymentdays')
                .then(response => {
                    this.payment_days =  response.data 
                });        
    },
}
</script>