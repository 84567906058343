<template>
    <div>
        <div class="container">
            <form @submit.prevent="updateUser()">
                <div class="cont-new-form">
                    <div class="cont-btn-user">
                        <div class="row">
                            <div class="col-lg-6">                                
                                <div class="box-count-user">
                                    <span class="title-user-form">Edit My Profile</span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="box-count-btn">
                                    <div class="cont-buttons" style="z-index: 2">
                                        <a href="/users" class="btn btn-danger btn-color-red" ><i class="far fa-times-circle"></i> Cancel</a>
                                        <button v-show="createUser" type="submit" class="btn btn-success btn-color-green create"> <i class="far fa-save"></i>  Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-12">
                                <div style="border: 2px solid #dbca00;padding: 20px;border-radius: 15px;">
                                    <span class="title-modal">User Information</span>
                                    <div class="row" style="padding: 20px">
                                        <div class="col-lg-4">
                                            <div>
                                                <div v-if="form.image">
                                                    <div v-if="url && form.image">
                                                        <img :src="url" width="90" style="display:block">
                                                        <img :src="'/'+form.image" width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/'+form.image"  width="90">
                                                    </div>
                                                </div>
                                                <div v-else>                                                                                                         
                                                    <div v-if="url">
                                                        <img :src="url"  width="90" style="display:block">
                                                        <img :src="'/images/avatar.png'"  width="90" style="display:none">
                                                    </div>
                                                    <div v-else>
                                                        <img :src="'/images/avatar.png'"  width="90">
                                                    </div>
                                                </div>
                                                <div class="vld-parent">
                                                    <loading :active.sync="isLoading"></loading>
                                                </div>
                                                <div class="file-field">
                                                    <div class="btn btn-sm float-left">
                                                        <span></span>
                                                        <input  name="image" type="file" @change="onImageChange" v-if="uploadReady">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div>
                                                <div class="form-group">
                                                    <label for="">First Name</label>
                                                    <input v-model="form.first_name" type="text" name="first_name" ref="firstname"  
                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('first_name') }">
                                                    <has-error :form="form" field="first_name"></has-error>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Phone Number</label>
                                                    <input v-model="form.phone" name="phone" id="phone"
                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('phone') }" maxlength="12">
                                                    <has-error :form="form" field="phone"></has-error>
                                                </div>
                                                <div class="form-group">
                                                    <label>Date of Birth</label>
                                                    <kendo-datepicker                                            
                                                        :min="minDate"
                                                        :max="maxDate"
                                                        :format="'MM/dd/yyyy'"
                                                        v-model="form.date_of_birth"
                                                        class="input-form"
                                                        :class="{ 'is-invalid': form.errors.has('date_of_birth') }"
                                                        name="date_of_birth" id="date_of_birth">
                                                    </kendo-datepicker>
                                                    <has-error :form="form" field="date_of_birth"></has-error>
                                                </div> 
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div>
                                                <div class="form-group">
                                                    <label for="">Last Name</label>
                                                    <input v-model="form.last_name" type="text" name="last_name"
                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('last_name') }">
                                                    <has-error :form="form" field="last_name"></has-error>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Cellphone</label>
                                                    <input v-model="form.cellphone" name="cellphone" id="cellphone"
                                                    class="input-form" :class="{ 'is-invalid': form.errors.has('cellphone') }" maxlength="12">
                                                    <has-error :form="form" field="cellphone"></has-error>
                                                </div>
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <input type="radio" name="country" id="PE" value="PE" v-model="form.country" disabled>
                                                            <label for="PE">PERU</label>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <input type="radio" name="country" id="US" value="US" v-model="form.country" disabled>
                                                            <label for="US">UNITED STATES</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cont-user-access-global">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="cont-user-access">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div style="border: 2px solid #dbca00;padding: 20px;border-radius: 15px;">
                                                <span>User Access</span>
                                                <div class="row" style="padding: 20px">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="form-group col-lg-6">
                                                                <label>Email (User Name)</label>
                                                                <input v-model="form.email" type="email" name="email"
                                                                class="input-form" :class="{ 'is-invalid': form.errors.has('email') }">
                                                                <has-error :form="form" field="email"></has-error>
                                                            </div>
                                                            <div class="form-group col-lg-6">
                                                                <label>Password (Must have at least one capital letter and a number) </label>
                                                                <input v-model="form.password" type="password" name="password" id="password"
                                                                class="input-form" :class="{ 'is-invalid': form.errors.has('password') }"
                                                                v-on:keyup="keymonitor">
                                                                <p v-if="validate == true" style="color:green">correct password</p>
                                                                <p v-else style="color:red">Unmodified Password or Incorrect password</p>
                                                                <has-error :form="form" field="password"></has-error>
                                                                <em v-show="editmode">(leave empty if not changing)</em>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-lg-6">
                                                                <label>Module(s)</label>
                                                                <input v-model="form.module" type="text" name="module" id="module" class="input-form" disabled>                                                                
                                                            </div>
                                                            <div class="form-group col-lg-6">
                                                                <label>Rol</label>  
                                                                <select name="role" v-model="form.role" id="role" class="input-form" :class="{ 'is-invalid': form.errors.has('role') }" disabled>
                                                                    <option :value="null" disabled>Select User Role</option>
                                                                    <option v-for="rol in roles" :value="rol.id" :key="rol.id" >{{ rol.name }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cont-user-info">
                        <div class="row">
                            <div class="col-lg-12">
                                <div style="border: 2px solid #dbca00;padding: 20px;border-radius: 15px;">
                                    <span class="title-modal">Sessions List</span>
                                    <div class="row" style="padding: 20px">
                                        <div class="col-lg-12">
                                            <div>
                                                <nav aria-label="Page navigation" class="text-center" style="position: relative;height: 50px;">
                                                    <ul class="pagination text-center">
                                                        <li>
                                                            <button class="btn btn-warning" aria-label="Previous" @click="prev(start_page)" v-show="start_page != 1">
                                                                <span aria-hidden="true"><i class="fas fa-arrow-left"></i> Prev</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button class="btn btn-warning" aria-label="Next" @click="next(next_page)" v-show="this.start_page != this.ultima_pagina" style="right: 0px;position: absolute;">
                                                                <span aria-hidden="true">Next <i class="fas fa-arrow-right"></i></span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                <div class="card-body table-responsive p-0">
                                                    <table class="table table-hover">
                                                        <tbody id="lead-table">
                                                            <tr class="th-bg">
                                                                <th>Ip</th>
                                                                <th>Start Login</th>
                                                                <th>End Login</th>
                                                            </tr>
                                                            <tr v-for="session in sessions" :key="session.id">
                                                                <td>{{ session.ip }}</td>
                                                                <td>{{ session.start_login }}</td>
                                                                <td>{{ session.end_login == null ? '-' : session.end_login }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <nav aria-label="Page navigation" class="text-center" style="position: relative;height: 50px;">
                                                    <ul class="pagination text-center">
                                                        <li>
                                                            <button class="btn btn-warning" aria-label="Previous" @click="prev(start_page)" v-show="start_page != 1">
                                                                <span aria-hidden="true"><i class="fas fa-arrow-left"></i> Prev</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button class="btn btn-warning" aria-label="Next" @click="next(next_page)" v-show="this.start_page != this.ultima_pagina" style="right: 0px;position: absolute;">
                                                                <span aria-hidden="true">Next <i class="fas fa-arrow-right"></i></span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    // Import component
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        props:['global'],
        data() {
            return {
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                minDate: new Date(1800,1,1),
                maxDate: new Date(2050,9,1),
                validate : false,
                notvalidate : true,   
                isLoading: false,           
                uploadReady: true,
                editmode: false,
                users : {},
                roles: {},
                sessions: [],
                modules : {},
                url:null,
                form: new Form({
                    id:this.global.user.id,
                    first_name : this.global.user.first_name,
                    last_name: this.global.user.last_name,
                    email: this.global.user.email,
                    password: this.global.user.password,
                    phone: this.global.user.phone,
                    cellphone: this.global.user.cellphone,
                    date_of_birth: this.global.user.date_of_birth,
                    file:this.global.user.file,
                    image:this.global.user.image,
                    country : this.global.user.country,
                    role : this.global.user.role_id,
                    module : ''
                }),
                dataccess: true,
                visualmodule: [],
                name_module:'',
                name_role:'',
                role: '',
                module: '',
                arrayeditRol: [],
                arrayeditModule: [],
                arrayidRol: [],
                arrayidModule: [],
                search:'',
                start_page: '',
                next_page: '',
                ultima_pagina :'',
                NUM_RESULTS: 49,
            }
        },
        components: {
            Loading
        },
        mounted() {
            var modules = ''
            this.global.layout.moduls.map( function(value,key ) {
                modules = modules+(key!=0?', ':'')+value.name;
            });
            this.form.module = modules
        },
        
        methods: {
            prev(start_p){
                var newval = start_p - 1;
                axios({
                    method: 'Get',
                    responseType:'json',
                    url: "/api/sessionuser?page="+newval+""
                }).then(response => {
                    if(response.status == 200){
                        this.feeds = response.data.data
                        this.start_page =  response.data.current_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                    }
                });
            },  
            next(next){
                axios({
                   method: 'Get',
                   responseType:'json',
                   url: "/api/sessionuser?page="+next+""
                }).then(response => {
                    if(response.status == 200) {
                        this.feeds = response.data.data
                        this.start_page =  response.data.current_page;                    
                        this.ultima_pagina = response.data.last_page;

                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }
                    }
                });
            },
            keymonitor: function() {
                var x = document.getElementById("password").value;
                var passw=  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/;
                if(x.match(passw)){
                    //console.log('igual')
                    this.validate = true
                    this.notvalidate = false
                }else{
                    //console.log('no es igual')
                    this.validate = false
                    this.notvalidate = false
                }
            },
            clear () {
                this.uploadReady = false
                this.url = false
                this.$nextTick(() => {
                    this.uploadReady = true
                    this.url = false
                })
            },
            onImageChange(e){
                //console.log(e.target.files[0])
                this.$Progress.start();
                var fileReader = new FileReader()

                fileReader.readAsDataURL(e.target.files[0])

                fileReader.onload = (e) => {
                    this.form.file = e.target.result
                }
                const file = e.target.files[0];
               /* this.url = URL.createObjectURL(file);*/
                this.url=URL.createObjectURL(file);

                this.isLoading = true;
                // simulate AJAX
                setTimeout(() => {
                  this.isLoading = false
                },50)

                //console.log(this.url);
            },
            updateUser(){
                this.$Progress.start();
                let button = $('.update');

                button.attr('disabled',false);
               
                this.form.post('/api/updateprofile',this.form)
                .then( (response) => {
                    Fire.$emit('AfterCreate');

                    swal.fire({
                        type: 'success',
                        title: 'User edit in successfully'
                        }).then( (res) => {
                            if(res){
                                    window.location.href = '/users';
                                }
                            });
                })
                .catch(() => {
                });
            },
            loadRoles() {
                axios.get("/api/role").then( ({data}) => (this.roles = data));
            },
            loadModules() {
                axios.get("/api/module").then( ({data}) => (this.modules = data));
            },
            createUser() {
             
              this.$Progress.start();
                this.form.post('api/user',this.form)
                .then( () => {
                    Fire.$emit('AfterCreate');
                    $('#addNew').modal('hide');

                    swal.fire({
                        type: 'success',
                        title: 'User Created in successfully'
                        });

                     this.visualmodule = [];
                     this.dataccess = false;
                     this.$Progress.finish();
                   
                })
                .catch( (errors) => {
                   // console.log(errors);
                })
            },
            addItem() {
             
               if(this.form.role != undefined && this.form.module != undefined){
                    this.form.module_role.push({'module' : this.form.module, 'namerol': this.form.role});
                    this.visualmodule.push({'module' : this.name_module, 'role' :  this.name_role});                   
                    this.dataccess = true;                    
                    this.module = '';
                    this.role = '';
               }
           
            },
             handleChange(e) {
                if(e.target.options.selectedIndex > -1) {
                    //console.log(e.target.options[e.target.options.selectedIndex].dataset.namemod);
                    this.name_module = e.target.options[e.target.options.selectedIndex].dataset.namemod;
                     
                }
            },
            handleChangeRol(e) {
                if(e.target.options.selectedIndex > -1) {
                  this.name_role = e.target.options[e.target.options.selectedIndex].dataset.namerol;
                }
            },
             removeItem(index) {
                this.form.module_role.splice(index, 1);
                this.visualmodule.splice(index, 1);
                this.dataccess = true;
                this.module = '';
                this.role = '';
                if(this.visualmodule.length == 0){
                        this.dataccess = false;
                    }else{
                        this.dataccess = true;
                    }
              //console.log(this.$el.target.options[this.$el.target.options.selectedIndex].da);
            },

            sessiones(){
                axios.post('/api/sessionuser',{
                    id : this.form.id
                })
                .then( (response) => {
                    this.sessions = response.data.data;
                    this.start_page =  response.data.current_page;                        
                    this.next_page = this.start_page+1;
                    this.ultima_pagina = response.data.last_page;
                });
            },
        },
        created() {
            //console.log(this.global)
            this.loadRoles();
            this.loadModules();
            this.sessiones();
            
            
          Fire.$on('AfterCreate',() => {
                this.loadRoles();
                this.loadModules();
           });
        //    setInterval(() => this.loadUsers(), 3000);
        },
        
    }
</script>


<style>
    
   
</style>
