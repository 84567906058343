<template>
    <div class="modal-event estilo-modal box-login" style="width: 700px;">
        <div class="row title-modal-edit" v-if="editMode">
            <div class="col-lg-12">
                <h3 > <i class="fas fa-edit"></i> Edit</h3>
                <i class="fas fa-times icon-close-target"  @click="close"></i>
            </div>
        </div>
        <div >            
            <div id="modalBody" class="modal-body">
            <form>
                <div class="row">
                    <div class="col-lg-12">
                        
                    </div>
                    <div class="col-lg-12">                        
                        <div class="camp-title-modal">
                            <input class="border-input description-modal input-form" v-if="editMode" type="text" id="title" name="title" v-model="campos.valor.title">
                            <h4 v-else>{{ campos.valor.title }}</h4>
                        </div>
                        <i v-if="editMode == false" class="fas fa-times icon-close-target"  @click="close"></i>
                    </div>
                </div>
                    <div class="campos-detail-modal">
                        <div class=" row">
                            <div :class="editMode == false ? 'col-lg-4' : 'col-lg-6'">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <img class="margin-icon" :src="'/images/icons/gps.png'" alt="" width="15px">
                                    </div>
                                    <div class="class-campo-text col-md-8">                                        
                                        <input class="border-input input-form" v-if="editMode" type="text" id="location" name="location"  v-model="campos.valor.location">
                                        <span v-else name="start" id="date"> {{ campos.valor.location }} </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="editMode == false ? 'col-lg-4' : 'col-lg-6'">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <img class="margin-icon" :src="'/images/icons/calendar.png'" alt="" width="15px">
                                    </div>
                                    <div class="class-campo-text col-md-8">                                        
                                        <div v-if="editMode" >
                                            <kendo-datepicker
                                                :min="minDate"
                                                :max="maxDate"
                                                :format="'MM/dd/yyyy'"
                                                v-model="campos.valor.date"
                                                class="input-form"
                                                style="background: #f1f1f1 !important"
                                                name="date" id="date">
                                            </kendo-datepicker>
                                        </div>
                                        <span v-else name="start" id="date"> {{ campos.valor.date | myDate }} </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="editMode == false ? 'col-lg-4' : 'col-lg-12'"  >
                                <div class="form-group class-inline row">
                                    <div :class="editMode == false ? 'class-campo-icon col-md-4' : 'class-campo-icon col-md-2'" >
                                        <img class="margin-icon" :src="'/images/icons/clock.png'" alt="" width="15px">
                                    </div>
                                    <div :class="editMode == false ? 'class-campo-text col-md-8' : 'class-campo-text col-md-10'">
                                        <div v-if="editMode" style="display:flex">
                                            <kendo-timepicker 
                                                :min="min"
                                                :max="max"
                                                :value="campos.valor.from"
                                                v-model="campos.valor.from"
                                                style="background: #f1f1f1 !important;margin-right: 25px;margin-left: 8px;" name="from" id="from" class="cont-modal-time border-input input-form">
                                            </kendo-timepicker>
                                            <kendo-timepicker                                     
                                                :min="min"
                                                :max="max"
                                                :value="campos.valor.to"
                                                v-model="campos.valor.to"
                                                style="background: #f1f1f1 !important" name="to" id="to" class="cont-modal-time border-input input-form">
                                            </kendo-timepicker> 
                                        </div>                                                                                
                                        <span v-else name="start" id="date"> {{ campos.valor.from | myTime }} - {{ campos.valor.to | myTime}} </span>                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <span class="" v-if="campos.valor.type == 'task'">Host</span>
                                        <span class="" v-else>Seller</span>
                                    </div>
                                    <div class="class-campo-text col-md-8">
                                        <select class="border-input input-form  input-form" v-if="editMode" name="user" v-model="campos.valor.seller_id" id="user"  >
                                            <option :value="null" disabled>Select User Role</option>
                                            <option v-for="userse in userssel" :value="userse.id" :key="userse.id">{{ userse.user_name }}</option>
                                        </select>
                                        <label v-else name="start" id="date">  {{ campos.valor.seller_name }} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <span class="">{{campos.valor.isclient == 1 ? 'Lead' : 'Client'}}</span>
                                    </div>                                  
                                    <div class="class-campo-text col-md-8">
                                        <a target="_blank" class="lead-style" :href="'/crm/leads/edit/'+ campos.valor.lead_id">{{ campos.valor.lead_name }} </a> 
                                    </div>
                                    <!--<input type="text" id="lead"  name="lead">-->
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <span class=" margin-loft">Created By</span>
                                    </div>
                                    <div class="class-campo-text col-md-8">
                                        <label name="description" id="description"> {{ campos.valor.creator_name }} {{ campos.created_at }} </label>
                                    </div>
                                    <!--<input type="text" id="description"  name="description">-->
                                </div>
                            </div>
                            <div class="col-lg-6" v-if="campos.valor.userupdate != null">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <span class=" margin-loft">Modified By</span>
                                    </div>
                                    <div class="class-campo-text col-md-8">
                                        <label name="description" id="description"> {{ campos.valor.updater_name }} {{ campos.updated_at }} </label>
                                    </div>
                                    <!--<input type="text" id="description"  name="description">-->
                                </div>
                            </div>
                            <div class="col-lg-6" v-if="campos.valor.type != 'task'">
                                <div class="form-group class-inline row">
                                    <div class="class-campo-icon col-md-4">
                                        <span class="margin-loft">Attended</span>
                                    </div>
                                    <div class="class-campo-text col-md-8">
                                        <label name="description" id="description" class="text-yes" v-if="campos.valor.attend == 0" > NO </label>
                                        <label name="description" id="description" class="text-no" v-else > YES </label>
                                        <!--<input type="text" id="description"  name="description">-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6" v-if="campos.valor.type != 'task'">
                                <div class="form-group class-inline row" v-if="campos.valor.attend == 2">
                                    <div class="class-campo-icon col-md-4">
                                        <span class="margin-loft">Sale Made</span>
                                    </div>
                                    <div class="class-campo-text col-md-8">
                                        <label :class="campos.valor.sale_made=='YES'?'text-no':'text-yes'" > {{campos.valor.sale_made}} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">                                    
                                    <label for="">Description</label>
                                    <br>
                                    <textarea :disabled="editMode == false" style="background: #f1f1f1 !important" class="description-modal input-form textarea-modal" type="text" id="date" name="date" v-model="campos.valor.description"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="campos.valor.attend == 2 && campos.valor.comment != null">
                                <div class="form-group ">
                                    <label for="">Comment</label>
                                    <br>                                    
                                    <textarea disabled style="background: #f1f1f1 !important" class="description-modal input-form textarea-modal" type="text" id="date" name="date" v-model="campos.valor.comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button style="font-size: 13px; padding: 6px; height: 35px;" v-if="bloquing" type="button"  class="btn btn-delete" @click="onClickDelete()"> <i class="fas fa-trash-alt"></i> DELETE </button>                
                <button style="font-size: 13px; padding: 6px; height: 35px;" v-if="editModeCancel" type="button" class="btn btn-danger btn-color-red" v-on:click="onClickCancel()"> <i class="fas fa-times fa-times-new"></i> CANCEL</button>
                <button style="font-size: 13px; padding: 6px; height: 35px;" v-if="editModeUpdate" type="button" class="btn btn-success btn-color-green" v-on:click="onClickUpdate()"> <i class="fas fa-save"></i> SAVE</button>                    
                <button style="font-size: 13px; padding: 6px; height: 35px;" v-if="editModeButton" type="button" class="btn btn-color-green" @click="onClickEdit()" > <i class="fas fa-edit"></i> EDIT</button>                                                
                <button style="width: 100px;font-size: 13px; padding: 6px; height: 35px;" :disabled="campos.valor.attend != 0" @click="attend(campos.valor.id)" v-if="campos.valor.seller_id == campos.idlayout && campos.valor.type != 'task'" type="button" class="btn btn-dark btn-color-blue"><i v-if="campos.valor.attend == 0" class="far fa-calendar"></i> <i v-else class="fas fa-calendar-check"></i><span v-if="campos.valor.attend == 0"> ATTEND </span> <span v-else> ATTENDED </span> </button>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: ['campos'],
        data() {
            return {
                bloquing: this.campos.valor.attend != 0 ? false : true,
                editMode:false,
                editModeButton: this.campos.valor.attend != 0 ? false : true,
                editModeCancel:false,
                editModeUpdate: false,
                attenMode: true,
                start: this.date,
                userssel : [],
                value: "00:00 AM",
                min: new Date(1950, 0, 1, 0, 0, 0),
                max: new Date(2049, 11, 31, 24, 0, 0),
                eventos: [],
                minDate: new Date(),
                maxDate: new Date(2050,9,1),
            }
        },
        mounted() {
            if(this.campos.valor.attend != 0){
                setTimeout(function(){
                    this.bloquing = true
                },0)                            
            }
            //console.log(this.campos)
        },
        methods: {
            attend(id){
                swal.fire({
                    title: 'Attending appointment?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes, Attend !'
                    }).then((result) => {
                        if (result.value) {                            
                            axios.post('/api/attendevent', {id:id})
                                .then(response => {
                                    if(response.status == 200) {
                                        window.location.href = '/crm/leads/attend/'+id;
                                    }
                                });
                        }
                    });
            },
            onClickEdit(){
                this.editMode = true;
                this.editModeCancel = true
                this.editModeUpdate = true
                this.editModeButton = false                
                axios.post('/api/sellerall/2',{ 
                    roles : '[1,2,5]',
                    type : '1'
                }).then(response => {
                    this.userssel = response.data;
                })
            },
            onClickCancel(){
                this.editMode = false;
                this.editModeCancel = false
                this.editModeUpdate = false
                this.editModeButton = true
            },
            onClickUpdate(){                
                if(document.getElementById('mes')){
                    var valuemes = document.getElementById('mes').innerHTML;
                }
                const params={
                    id: this.campos.valor.id,
                    title: this.campos.valor.title,
                    date: this.campos.valor.date,
                    from: this.campos.valor.from,
                    to: this.campos.valor.to,
                    host:  this.campos.valor.seller_id,
                    location: this.campos.valor.location,
                    description: this.campos.valor.description,
                    userupdate: this.campos.idlayout,
                    month: valuemes,
                };
                axios.post('/api/updateevent', params)
                    .then((response) => {
                        Fire.$emit('AfterCreate');

                        swal.fire({
                        type: 'success',
                        title: 'Event edit in successfully'
                        }).then( (res) => {
                            if(res){
                                    this.editMode = false;
                                    this.editModeCancel = false
                                    this.editModeUpdate = false
                                    this.editModeButton = true
                                    this.eventos = response.data;
                                    this.$emit('update', this.eventos);                                    
                                }
                            });                        
                });
            },
            onClickDelete(){
                if(document.getElementById('mes')){
                    var valuemes = document.getElementById('mes').innerHTML;
                }
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#ab9220',
                    cancelButtonColor: '#8f9194',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                const params = {
                                    id: this.campos.valor.id,
                                    month: valuemes,            
                                };
                                axios.post('/api/deleteevent', params).then((response)=>{
                                        swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success',
                                        ).then( (res) => {
                                            if(res){
                                                    this.editMode = false;
                                                    this.open = false;
                                                    this.eventos = response.data;
                                                    this.$emit('delete', this.eventos);
                                                    this.$emit('click',false);
                                                }
                                            });
                                    Fire.$emit('AfterCreate');
                                }).catch(()=> {
                                    swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    });
            },
            close(){
                this.editMode = false;
                this.$emit('click',false)
            },
            created(){
                //console.log(this.campos)
            Fire.$on('AfterCreate',() => {
                
           });
        },
        }
    }
</script>