<template lang="pug">
    .modal-event.estilo-modal.box-login
        .row.title-modal-edit
            .col-lg-12.col-md-12(style="text-align:center")
                h3 COMISSIONS
                i.fas.fa-times.icon-close-target(@click="closeModal")
        div
            #modalBody.modal-body
                form
                    div 
                        div.campos-detail-modal.row(style="margin: 0;")
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Program
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ program }}
                            div.col-lg-6
                                div.form-group.class-inline.row
                                    div.class-campo-icon.col-md-4 Client
                                    div.class-campo-text.col-md-8
                                        span(name="start" id="date") {{ client_name }}
                            br
                            br
                            .col-lg-12.col-md-12
                                .form-group
                                    table.table
                                        tbody(style="border: 1px solid #ccc;")
                                            tr(style="background: #f1f1f1;color: #999999;")
                                                th Type
                                                th User
                                                th Comission
                                            tr(v-for="(comission,index) in JSON.parse(comissions)" :key="index")
                                                td #[span(style="text-transform: capitalize;") {{comission.type}}]
                                                td {{comission.user_name}}
                                                td ${{comission.commission}}
            .modal-footer                

</template>


<script>
export default {
    props:['comissions' ,'nameProgram' ,'nameClient'],
    data() {
        return {
            program: this.nameProgram,
            client_name:this.nameClient,
        }
    },
    mounted() {
        
    },
    methods: {
        closeModal(){
            this.$emit('click',false)
        }
    },
    created() {
        
    },
}
</script>