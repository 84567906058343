<template>
    <div id="loading">
        <div>
            <div class="row ">
                <div class="col-md-12">
                    <div class="cont-title">
                        <div class="row">
                            <div class="col-lg-6">
                                <span>access list</span>
                            </div>
                            <div class="col-lg-6">
                                <a href="/users" type="submit" class="btn class-button-red" style="margin-right: 5px;width: 10%;margin-top: -3px;">Back</a>                                
                            </div>
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div style="display: inline-flex">
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalusers : start_page*perpage ) }} </span> <span style="padding-top: 7px;"> of </span> <span class="class-span-2"> {{ totalusers }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div >
                            <table class="table">
                                <tbody id="busq-user">
                                    <tr class="th-bg">
                                        <th>User</th>
                                        <th>Ip</th>
                                        <th>Start Login</th>
                                        <th>End Login</th>
                                    </tr>
                                    <tr v-for="session in sessions" :key="session.id">
                                        <td class="gris-color">{{ session.email }}</td>
                                        <td class="gris-color">{{ session.ip }}</td>
                                        <td class="gris-color">{{ session.start_login }}</td>
                                        <td class="gris-color">{{ session.end_login ? session.end_login : '-'  }}</td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>
                    <div class="cont-search-paginate">
                        <div class="row">
                            <div class="col-lg-3">
                                <div>
                                    <span class="class-span-1">{{ ((start_page - 1)*perpage +1)+'-'+( start_page == ultima_pagina  ? totalusers : start_page*perpage ) }} </span> <span> of </span> <span class="class-span-2"> {{ totalusers }} </span>
                                    <button class="btn class-btn-next-prev" aria-label="Previous" @click="prev(start_page)" :style="start_page != 1 ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-left"></i>
                                    </button>
                                    <button class="btn class-btn-next-prev" aria-label="Next" @click="next(next_page)"  :style="this.start_page != this.ultima_pagina ? '' : 'background:#CCCCCC ; pointer-events:none'">
                                        <i class="fas fa-caret-right"></i>
                                    </button>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props:['global'],
        data() {
            return {
                sessions: [],
                start_page: '',
                next_page: '',
                perpage:'',
                ultima_pagina :'',
                totalusers:'',
            }
        },
        methods: {
            allSessions(){
                axios.post('/api/sessionusers',{
                    id: this.global.id
                })
                .then(response => {
                    if(response.status == 200) {
                        this.sessions = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        this.next_page = this.start_page+1;
                        this.ultima_pagina = response.data.last_page;
                        this.totalusers = response.data.total;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                })
                .catch(err => {
                    console.error(err); 
                })
            },
            prev(start_p){
                var newval = start_p - 1;
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/sessionusers?page='+newval, {
                   id: this.global.id
                })
                .then(response => {
                    if(response.status == 200){
                        this.users = response.data.data
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        } 
                        this.ultima_pagina = response.data.last_page;
                        this.totalusers = response.data.total;
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },  
            next(next){
                var boton = document.getElementById('app');
                boton.classList.add('preloader')
                axios.post('/api/sessionusers?page='+next, {
                    id: this.global.id
                })
                .then(response => {
                    if(response.status == 200){
                        this.start_page =  response.data.current_page;
                        this.perpage = response.data.per_page;                   
                        this.ultima_pagina = response.data.last_page;
                        this.totalusers = response.data.total;
                        if(this.start_page == this.ultima_pagina){
                            this.next_page = this.start_page;
                        }else{
                            this.next_page = this.start_page+1;
                        }                        
                        setTimeout(() => {
                            var boton = document.getElementById('app');
                            boton.classList.remove('preloader')
                        }, 50); 
                    }
                });
            },
        },

        created() {
            this.allSessions()
        },
        
    }
</script>

